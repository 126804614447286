import React, {useContext, useEffect, useState} from 'react';
import {Calendar} from "primereact/calendar";
import {formatDateMediaGrid, formatDateOnly, fullDurationFromSeconds} from "../../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong, faX, faCloudUpload} from "@fortawesome/pro-light-svg-icons";
import RequestMediaFromCalendar from "./requestMediaFromCalendar";
import mainContext from "../../../../contexts/mainContext";
import fetchVideoBudget from "../../../../../api/device/fetchVideoBudget";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";

const CalendarDetails = ({}) => {

    const {calendar, deviceDetails, vehicle} = useContext(mainContext);

    const [dateSelected, setDateSelected] = useState(new Date());

    const [recDays, setRecDays] = useState([]);

    const [minDate, setMinDate] = useState();

    const [recSelected, setRecSelected] = useState(null);

    const [budget, setBudget] = useState();




    const handleDateChange = (e) => {
        setDateSelected(e.value)
        setRecSelected(null)
    }



    useEffect(async () => {
        if (calendar){

            let recDays = [];

            calendar?.modRecordingHistory?.forEach(item => {



                if(formatDateOnly(item.st) === formatDateOnly(item.et)){
                    if(!recDays.includes(formatDateOnly(item.st))){
                        recDays.push(formatDateOnly(item.st))
                    }
                } else {
                    if(!recDays.includes(formatDateOnly(item.st))){
                        recDays.push(formatDateOnly(item.st))
                    }
                    if(!recDays.includes(formatDateOnly(item.et))){
                        recDays.push(formatDateOnly(item.et))
                    }
                }
            });


            const theBudget = await fetchVideoBudget(vehicle?.properties);
            setBudget(theBudget);
            setRecDays(recDays)
        }

        const today = new Date()
        today.setMonth(today.getMonth() - 3)
        setMinDate(today)

    }, [calendar])


    const dateTemplate = (date) => {

        const pip = {
            position: 'absolute',
            width: '5px',
            height: '5px',
            background: 'green',
            borderRadius: '10px',
            top:-3,
            right:-3
        }

        const theDate = ("0" + date.day).slice(-2) + '-' + ("0" + (date.month + 1)).slice(-2) + '-' + date.year;

        if (recDays.includes(theDate)) {
            return (
                <div style={{position: 'relative'}}>
                    <strong>{date.day}</strong>
                    <div style={pip} />
                </div>
            );
        }
        return date.day;
    }



    const FilteredRecs = ({date}) => {

        const [toShow, setToShow] = useState([]);

        const setRecsToShow = async () => {
            let newToShow = [];
            const toFind = formatDateOnly(date);
            const found = calendar?.modRecordingHistory.filter(item => formatDateOnly(item.st) === toFind ||  formatDateOnly(item.et) === toFind);
            const test = JSON.parse(JSON.stringify(found)).reverse();


            if (test?.length > 1){
                let i = 0;
                let timePeriod = {};
                while (i < test?.length){

                    if(timePeriod?.et &&  new Date(test[i].st) - new Date(timePeriod.et) < 2000){
                        timePeriod.et = test[i].et;

                        if (i === test?.length - 1) newToShow.push(timePeriod)

                        i++;
                    } else if (timePeriod?.et && timePeriod?.st){

                        newToShow.push(timePeriod);
                        timePeriod = test[i];
                        if (i === test?.length - 1) newToShow.push(timePeriod)
                        i++;
                    } else {
                        timePeriod = test[i];
                        i++;
                    }
                }
                setToShow(newToShow)
            } else {
                setToShow(found)
            }

            console.log(found)
        }

        useEffect(async () => {
            if (date){
                await setRecsToShow();
            }
        }, [date])



        return (
            <div>
                <h4 style={{textAlign: 'center'}}>Recordings on Device For Selected Date</h4>
                <table style={{width: '100%', textAlign: 'center'}}>
                    <thead>
                        <tr>
                            <th>Start Time:</th>
                            <th />
                            <th>End Time:</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {toShow?.map((item, index) =>
                        <tr key={index}>
                            <td>{formatDateMediaGrid(item.st)}</td>
                            <td>
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </td>
                            <td>{formatDateMediaGrid(item.et)}</td>
                            <td>
                                <button className="p-button p-button-secondary p-button-sm" onClick={() => setRecSelected(item)}>
                                    <span className="p-button-label">
                                        <FontAwesomeIcon icon={faCloudUpload} />
                                        &nbsp;Upload media
                                    </span>
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        )
    }






    return (
        <div>
            <h4 style={{textAlign: 'center'}}>
                Total days with recordings: {recDays?.length}<br />
                Video Upload Budget remaining: {fullDurationFromSeconds(budget?.totalFleetBudget - budget?.usedSeconds)}
                {fetchUserLevel() === 'superuser' &&
                    <React.Fragment>
                        <br/>Total Recording Time: {calendar?.hours} hours & {calendar?.mins} minutes
                    </React.Fragment>
                }
            </h4>

            <Calendar dateTemplate={dateTemplate} value={dateSelected} onChange={(e) => handleDateChange(e) }
                inline showWeek minDate={minDate} maxDate={new Date()} style={{width: '100%'}}/>

            {dateSelected  && !recSelected &&
                <FilteredRecs date={dateSelected} />
            }

            {recSelected &&
                <div style={{textAlign:'center'}}>
                    <RequestMediaFromCalendar recSelected={recSelected} deviceDetails={deviceDetails} vehicle={vehicle} />
                    <button className="p-button" style={{background: '#D32F2F', width: '76%', textAlign:'center'}}
                            onClick={() => setRecSelected(null)}>
                        <span className="p-button-label">
                            <FontAwesomeIcon icon={faX} />
                            &nbsp;Cancel
                        </span>
                    </button>
                </div>
            }
        </div>
    )

}
export default CalendarDetails
