import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const SmokingStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89"  data-pr-position="bottom" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path style={{fill:'var(--text-color)'}} d="m223.95,89.65v4.66c0,14.17,5.63,27.75,15.64,37.77l9.18,9.18c7.8,7.8,12.18,18.37,12.18,29.39v4.05c0,6.64-5.2,12.34-11.84,12.43s-12.16-5.31-12.16-12v-3.82c0-4.89-1.97-9.6-5.43-13.07l-8.09-8.09c-15.03-15.03-23.47-35.46-23.47-56.69v-3.39c0-6.64,5.2-12.34,11.84-12.43s12.16,5.31,12.16,12Zm50,85.49v-4.66c0-14.17-5.63-27.75-15.64-37.77l-9.18-9.18c-7.8-7.8-12.18-18.37-12.18-29.4v-4.05c0-6.64,5.2-12.34,11.84-12.43s12.16,5.31,12.16,12v3.82c0,4.89,1.97,9.6,5.43,13.07l8.09,8.09c15.03,15.03,23.47,35.42,23.47,56.69v3.39c0,6.64-5.2,12.34-11.84,12.43-6.71.09-12.16-5.31-12.16-12Zm-195.63,36.81h134.44c6.73,0,12.19,5.46,12.19,12.19v36.63c0,6.73-5.46,12.19-12.19,12.19H78.32c-13.46,0-24.37-10.91-24.37-24.37v-12.26c0-13.46,10.91-24.37,24.37-24.37Zm60.63,24v12h61v-12h-61Zm122-11.45v35.14c0,6.64-5.2,12.34-11.84,12.43s-12.16-5.31-12.16-12v-36c0-6.69,5.45-12.09,12.16-12s11.84,5.79,11.84,12.43Zm37,0v35.14c0,6.64-5.2,12.34-11.84,12.43-6.71.09-12.16-5.31-12.16-12v-36c0-6.69,5.45-12.09,12.16-12s11.84,5.79,11.84,12.43Z"/>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Smoking</Tooltip>
            }
        </div>
    )
}
export default SmokingStacked
