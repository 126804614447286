import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const optionTemplate = (option, size, placeholder = "Select") => {

    return (

        <div style={{height: size !== 'desktop' ? '30px' : ''}}>
            {option?.icon &&
                <FontAwesomeIcon icon={option?.icon} />
            }

            {option?.display ?
                <span style={{marginLeft: '10px', lineHeight: size !== 'desktop' ? '21px' : ''}}>
                    {option?.display}
                </span>
            :
                <span>{placeholder}</span>
            }
        </div>
    );
}
