import {API_URL} from '../../Constants.js'



const fetchSnapshot = (file, size) => {

    let token = localStorage.getItem('access_token');

    AbortSignal.timeout ??= function timeout(ms) {
        const ctrl = new AbortController()
        setTimeout(() => ctrl.abort(), ms)
        return ctrl.signal
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token },
        redirect: 'follow',
        signal: AbortSignal.timeout(5000)
    };

    const fileStr = size ? file +'_' + size : file


    return fetch(API_URL + '/view-snapshot/' + fileStr, requestOptions)
        .then((response) => {
            if (response.status === 200){
                return response.blob()
            } else {
                return '401'
            }

        })
        .then((data) => {
                // console.log(data);

                return Promise.resolve(
                    data
                );


            }
        ).catch(err => {
            // console.log(err)
        })

}


export default fetchSnapshot;

// https://myfleetlive.org:8000/angFetchVidThumb/1000040_20210927112234-20210927112254_1_20

// https://myfleetlive.org:8000/view-snapshot/1000040_20211105084345_1_sm


// https://myfleetlive.org:8000/view-video/1000234_20211028110052-20211028110110_1_20_h265
