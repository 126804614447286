import React from 'react';
import {DurationFromSeconds} from "../../../../../functions/formatting/formatDate";

const ActivityReportTableFooter = ({data}) => {


    return (
        <div style={{
            display: 'flex', justifyContent: 'center',
            height: '73px', pageBreakInside: 'avoid', pageBreakAfter: 'auto', textAlign: 'center'}} className="reportTable">

            <div style={{lineHeight: '30px', fontSize: '1.1em'}}>
                <b>Day Totals:</b>&nbsp;
                {data.totalDistance.toFixed(0)}
                &nbsp;miles travelled,
                &nbsp;
                {DurationFromSeconds(data.totalDur)} driving time,
                &nbsp;
                {DurationFromSeconds(data.shiftDur - data.totalDur)} parked time
            </div>


        </div>
    )
}
export default ActivityReportTableFooter
