import React, {useEffect, useState} from 'react';
import {MflLogo as NotTextLogo} from "../svgs/mflLogo";
import StarWarsSplash from "./starWarsSplash";
import {isStarWars} from "../functions/isStarWars";
import LoadingSpinner from "../components/mainComponents/loadingSpinner";

const SplashScreen = () => {



    const style = {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 50000000,
        background: isStarWars() ? 'black' : 'var(--surface-ground)',
        overflow: 'hidden',
        textAlign: 'center',
    }


    return (
        <div style={style}>

            {isStarWars() ?
                <StarWarsSplash />
            :
                <div style={{height: '100%', width: '100%', position: 'relative'}}>
                    <div style={{position:'absolute', top: '50%', left:'50%', transform: 'translate(-50%,-50%)', textAlign: 'center'}}>
                        <LoadingSpinner />
                        <br/>
                        Loading...
                    </div>
                </div>
            }






        </div>
    )
}

export default SplashScreen
