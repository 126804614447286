import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";



const FetchMediaToken = (video) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    };


    return fetch(API_URL + '/media-token/' + video, requestOptions)
        .then((response) => {

            if(response.status === 403) {
                clearStorage();
            }

            if (response.status === 200){
                return response.json()
            } else {
                return '401'
            }
        })
        .then((data) => {
            return Promise.resolve(data);

        }).catch(err=>{
            console.log(err)});

}


export default FetchMediaToken;
