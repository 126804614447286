import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const JourneyEndStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="pedestrian" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id} style={{height, width: height, verticalAlign: 'middle'}}  data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95" fill="var(--surface-border)"/>
                <path id="journey_end" d="m99.95,52.95v23.31l32.7-8.15c18.06-4.5,37.11-2.42,53.79,5.92,20.48,10.24,44.27,10.9,65.22,2.09,1.52-.66,3.03-1.33,4.55-2.09l25.74-12.94v166.83l-26.3,9.86c-21.9,8.2-46.26,6.92-67.16-3.51-17.96-9.01-38.58-11.23-58.06-6.35l-30.48,7.58v60.44h-30V52.95h30Zm0,89.35l30-6.52v30.7l-30,6.52v30.66l22.88-5.72c2.39-.61,4.73-1.12,7.12-1.55v-29.69l18.84-4.07c4.02-.87,8.09-1.21,12.16-1.02v-29.89c6.38.19,12.75,1.22,18.94,3l11.06,3.23v31.27l-19.55-5.77c-3.42-.98-6.94-1.59-10.45-1.78v33.47c10.22.89,20.3,3.14,30,6.75v-32.78l10.64,3.14c6.33,1.87,12.8,3,19.36,3.47v-30.14c-3.66-.38-7.31-1.08-10.88-2.11l-19.13-5.62v-29.66c-6.09-1.78-12.09-4.13-17.91-7.03-3.84-1.92-7.92-3.28-12.09-4.13v34.21c-6.3-.19-12.59.39-18.75,1.74l-12.25,2.66v-35.12l-30,7.5v34.27Zm122,70.11c7.87.7,15.89-.33,23.44-3.19l6.56-2.44v-33.66l-3.7.84c-8.62,2.02-17.48,2.67-26.3,2.11v36.33Zm30-70.75v-33.23c-9.8,2.86-19.87,4.27-30,4.27v32.58c6.52.66,13.12.23,19.55-1.22l10.45-2.44v.05Z" style={{fill:"var(--text-color)"}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:"none"}}/>
                </g>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} Journey End</Tooltip>
            }
        </div>
    )
}
export default JourneyEndStacked
