import React from 'react';
import clearStorage from "../../functions/localStorage/clearStorage";
import {API_URL} from "../../Constants";

const FetchEventsVideoFile = (data) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token  },
        body: JSON.stringify(data)
    };

    return fetch(API_URL + '/f-events-video-file', requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
                return Promise.resolve(data);
            }
        );
}
export default FetchEventsVideoFile
