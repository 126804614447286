import {useEffect, useState} from "react";

const useIsVisible = (elementRef) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        let OPTIONS;
        if (elementRef?.current) {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry?.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(elementRef?.current);
                    }
                });
            }, OPTIONS);
            observer.observe(elementRef?.current);
        }
    }, [elementRef]);

    return isVisible;
};

export default useIsVisible;