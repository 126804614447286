import React, {useContext, useEffect, useState} from 'react';
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import gridContext from "../../../../contexts/gridContext";
import {findDataByKey, getStoreData} from "../../../../../functions/db";

const EventParamChips = ({}) => {

const {eventParams, removeParam, handleEventParams, eventCounts, tagLib} = useContext(gridContext);
console.log(eventParams)

    return (
        <div style={{marginTop: '95px', padding: '10px 10px', height: '50px'}}>
            {eventParams?.map(param => (
                <Chip key={param} label={tagLib?.[param]?.displayName} className="activeChips p-chip" removable
                      onRemove={() => removeParam(param)}/>
            ))}

            <Button label="Reset" onClick={() => handleEventParams([])}
                    style={{
                        cursor: 'pointer',
                        height: '28px',
                        padding: '0px 10px',
                        borderRadius: '2px', color: 'var(--v-list-button-text)',
                        margin: '3px', background: 'var(--v-list-button)'
                    }} className={'activeChips'}/>
        </div>
    )

}

export default EventParamChips