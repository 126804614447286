import React, {useContext, useEffect, useState} from 'react';
import SingleVideo from "./singleVideo";
import MultiVideo from "./multiVideo";
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import MultiVideoV2 from "./multiVideoV2";




const VideoTab = ({camPosArray, deviceDetails, budget, videosUploaded, addVideo, videos, handleVideosUploading, videosUploading,
                      handleEventModalWidth, handleSpeed, handleSpeedLimit, speedLimit}) => {


    const {event} = useContext(mainContext)
    const {winWidth} = useContext(indexContext)

    const [view, setView] = useState('standard');


        const handleView = () => {
        if (view === 'standard'){
            setView('multi')
        } else {
            setView('standard')
        }
    }


    return (
        <React.Fragment>
            {view === 'standard' ?
                <SingleVideo deviceDetails={deviceDetails} camPosArray={camPosArray} budget={budget} handleView={handleView}
                             addVideo={addVideo} handleVideosUploading={handleVideosUploading}  videosUploading={videosUploading}
                             handleSpeed={handleSpeed} handleSpeedLimit={handleSpeedLimit} speedLimit={speedLimit}/>
                :
                <MultiVideoV2 event={event} camPosArray={camPosArray} budget={budget} deviceDetails={deviceDetails} videos={videos}
                videosUploaded={videosUploaded} addVideo={addVideo} handleView={handleView} handleEventModalWidth={handleEventModalWidth}
                              handleSpeed={handleSpeed} handleSpeedLimit={handleSpeedLimit}/>

            }
        </React.Fragment>

    )
}

export default VideoTab
