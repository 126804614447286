import React, {useContext} from 'react';
import mainContext from "../../../../../contexts/mainContext";
import {gToKg} from "../../../../../../functions/formatting/gToKg";
import reportsContext from "../../../../../contexts/reportsContext";

const VehicleCo2ReportSummary = ({actTotals}) => {

    const {theDeviceDetails} = useContext(reportsContext)

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>
            <div className="reportTotalBubble">
                <div><b>Total<br />Journeys:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance?.toFixed(0)}&nbsp;miles</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />CO2:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {gToKg(((actTotals?.distance * 1.60934) * theDeviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions))}
                </div>

            </div>



            <div className="reportTotalBubble">
                <div><b>Average <br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{(actTotals?.distance / actTotals?.journeys)?.toFixed(0)}&nbsp;miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average CO2 <br />per journey:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {theDeviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions > 0 ?
                        <React.Fragment>
                            {gToKg((((actTotals?.distance * 1.60934) * theDeviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions) / actTotals?.journeys)?.toFixed(0))}
                        </React.Fragment>
                        :
                        <React.Fragment>N/A</React.Fragment>
                    }
                </div>
            </div>

        </div>

    )
}

export default VehicleCo2ReportSummary
