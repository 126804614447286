import React, {useContext, useEffect, useState} from 'react';
import indexContext from "../../../../contexts/indexContext";
import {Tooltip} from "primereact/tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faSolidStar} from "@fortawesome/pro-solid-svg-icons";
import {faStar} from "@fortawesome/pro-light-svg-icons";

const Star = ({data, starred}) => {
    






    return (
        <React.Fragment>
            <Tooltip target={".star" + data._id}>{starred?.includes(data._id) ? "Unstar" : "Star"}</Tooltip>
            <span style={{cursor: 'pointer'}} className={"star" + data?._id} data-pr-position="bottom">
                {starred?.includes(data._id)  ?
                    <FontAwesomeIcon icon={faSolidStar}   style={{fontSize:'18px', color: 'var(--star-colour)'}}/>
                    :
                    <FontAwesomeIcon icon={faStar} style={{color: 'var(--text-color)', fontSize: '18px'}}/>
                }
            </span>
        </React.Fragment>
    )
    
}
export default Star
