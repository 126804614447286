import {getSecondsDifference} from "./formatting/formatDate";

export const getDrivingStatus = (hb, lastLocAc, theTimeAtFetch) => {
    if (hb && lastLocAc){
        if (getSecondsDifference(hb, theTimeAtFetch) > 120){
            return 'parked'; // red p
        } else {
            if (lastLocAc !== "0"){
                return 'driving'; // green arrow
            } else {
                return 'idling'; // amber p idling
            }
        }
    } else {
        return 'parked'; // red p
    }
}
