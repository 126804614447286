import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const EyesClosedStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path id="fatigue" style={{fill: 'var(--text-color)'}} d="m175.8,100.99c-27.58,0-50.26,12.52-67.65,28.64-16.29,15.15-27.54,33.13-33.3,45.82,5.75,12.69,17.01,30.67,33.25,45.82,17.43,16.12,40.11,28.64,67.69,28.64s50.26-12.52,67.65-28.64c16.29-15.15,27.54-33.13,33.3-45.82-5.75-12.69-17.01-30.67-33.25-45.82-17.43-16.12-40.11-28.64-67.69-28.64Zm-81.48,13.79c19.93-18.53,47.3-34.1,81.48-34.1s61.56,15.57,81.48,34.1c19.8,18.4,33.04,40.36,39.35,55.46,1.4,3.34,1.4,7.07,0,10.41-6.3,15.1-19.55,37.1-39.35,55.46-19.93,18.53-47.3,34.1-81.48,34.1s-61.56-15.57-81.48-34.1c-19.8-18.36-33.04-40.36-39.3-55.46-1.4-3.34-1.4-7.07,0-10.41,6.26-15.1,19.5-37.1,39.3-55.46Zm79.19,94.78c20.5,1.34,37.4-15.55,36.05-36.05-1.17-17.88-16.49-31.56-34.41-31.56h-.2c.55,2.16.85,4.44.85,6.77,0,14.93-12.14,27.08-27.08,27.08-2.33,0-4.61-.3-6.77-.85v.2c0,17.92,13.68,33.24,31.56,34.41Zm2.29-88.27c29.91,0,54.15,24.24,54.15,54.15s-24.24,54.15-54.15,54.15-54.15-24.24-54.15-54.15,24.24-54.15,54.15-54.15Z" />
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Eyes closed</Tooltip>
            }
        </div>
    )
}
export default EyesClosedStacked
