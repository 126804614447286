import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faMapPin} from "@fortawesome/free-solid-svg-icons";
import {faCircle as farCircle} from "@fortawesome/free-regular-svg-icons";

const ViewTrackMarker = ({}) => {
    return (
        <span className="fa-layers fa-fw" style={{color: 'black', marginLeft: '-11px'}}>
            <FontAwesomeIcon icon={faCircle} size='3x' color="white"/>
            <FontAwesomeIcon icon={farCircle} size='3x' color="lightgrey"/>
            <FontAwesomeIcon icon={faMapPin} size='lg' style={{marginLeft:'11px'}}/>
        </span>
    )
}


export default ViewTrackMarker
