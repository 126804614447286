
import React, {useContext, useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {purple, teal, pink} from './CustomIcons';
import MapCustomControl from './MapCustomControl.js';
import {Colours} from "../../../../colours";
import ReactDOM from "react-dom";
import StoppedMarker from "./mapMarkers/stoppedMarker";
import HyperlapseMarker from "./mapMarkers/hyperlapseMarker";
import ViewTrackMarker from "./mapMarkers/viewTrackMarker";
import VehicleDetail from "../../abovePanelComponents/vehicle/VehicleDetail";
import ListJourneys from "../../abovePanelComponents/journeys/ListJourneys";
import fetchLastJourney from "../../../../api/journeys/fetchLastJourney";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";
import SpeedChartV3 from "./speedChartV3";
import {faGaugeMax, faClockRotateLeft} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import jStartFlag from "../../../../svgs/mapIcons/jStartFlag.png";
import jEndedFlag from "../../../../svgs/mapIcons/jEndedFlag.png";
import TrackModalParent from "../../modals/trackModal/trackModalParent";
import usePersistState from "../../../../functions/localStorage/persistState";
import EventModalParent from "../../modals/eventModal/eventModalParent";
import DeviceDetailsModalParent from "../../modals/deviceDetailsModal/deviceDetailsModalParent";
import {fetchEventsForJourney} from "./mapFunctions/fetchEventsForJourney";
import {Toast} from "primereact/toast";
import {TOAST_DURATION} from "../../../../Constants";
import LastSnapshotPanel from "./lastSnapshotPanel";
import {filterVehicleList} from "../../../../functions/vListFilterFunc";
import DaySummary from "./daySummary";
import ReactMapControls from "./reactMapControls";
import {findDataByKey, getStoreData} from "../../../../functions/db";
import {getMapIcons} from "./mapFunctions/getMapIcons";


const styles = {
    flex: "1",
    position: "relative",
    marginTop: '-64px',
    // marginBottom: '-27px'
};

const el = document.createElement('div');
const marker = new mapboxgl.Marker(el);

const elStart = document.createElement('div');

const markerStart = new mapboxgl.Marker(elStart);


const elStopped = document.createElement('div');
const markerStopped = new mapboxgl.Marker(elStopped);

const hlStart = document.createElement('div');
const startHlMarker = new mapboxgl.Marker(hlStart)
const hlEnd = document.createElement('div');
// const endHlMarker = new mapboxgl.Marker(hlEnd)

ReactDOM.render(<HyperlapseMarker color="green"/>, hlStart);
ReactDOM.render(<HyperlapseMarker color="red"/>, hlEnd);


const pointSelected = document.createElement('div');
const pointSelectedMarker = new mapboxgl.Marker(pointSelected)
ReactDOM.render(<ViewTrackMarker />, pointSelected);

const Map2 = ({}) => {

    const {vehiclesFilter, vehicle, journey, event, zoom, handleVehicle, vehicles,
        handleEvent, sideBarWidthPx, openModal, closeModal, lastMapCenter,
        setLastMapCenter, recenterMap, setRecenterMap, date, pageToShow, handleTrueLocation,
         lastJourney, displayTrackClickModal, vehicleFilterParams, journeys} = useContext(mainContext);

    const {winWidth, darkMap, darkMode} = useContext(indexContext);

    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);
    const [mapReopened, setmapReopened] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState();
    const toast = useRef(null);
    const [showSpeedPanel, setShowSpeedPanel] = usePersistState(false, 'showJourneyProfile');
    const [showStopPanel, setShowStopPanel] = useState(false);
    const [hyperlapseLine, setHyperlapseLine ] = useState();
    const [track, setTrack] = useState(null);
    const [liveTrack, setLiveTrack] = useState(false);
    const vehiclesInterval = useRef();
    const satelliteImagery = useRef(false);


    const handleLiveTrack = (val) => setLiveTrack(val)
    const handleHyperlapseLine = (obj) => setHyperlapseLine(obj);

    const handleTrack = async (e) => {
        await setTrack(e);//asynchronous

        if (e){
            openModal('displayTrackClickModal')
        } else {
            closeModal('displayTrackClickModal')
        }
    }



    const showToast = (summary, msg) => {
        // toast.current.replace({severity:'info', summary: summary,
        //     detail: msg, life: 3000});
    }

    const handleSelectedPoint = (point) => {
        setSelectedPoint(point);
    }

    const handleStartEnd = async (flag) => {
        console.log(journey)
        if (flag === 'start') await handleTrack(journey?.features[0]);
        else await handleTrack(journey?.features[journey?.features?.length - 1])
    }





    const forEachLayer = (text, cb) => {
        map.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }
// Changing the map base style
    const changeStyle = (style) => {
        if (map){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'vehicle-points-layer',
                'line-layer',
                'line-layer2',
                'journey-points-layer',
                'start-journey-layer',
                'events-layer',
                'end-journey-layer',
                'clusters',
                'point-selected-layer',
                'add-3d-buildings'
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = map?.getSource(layer.source)?.serialize();
                    savedLayers.push(layer);
                });
            });

            map.setStyle(`mapbox://styles/${style}`);

            setTimeout(() => {

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        map.removeImage(icon.name);
                        map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        map.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {
                    const mapLayer = map.getLayer(layer.id);
                    if(typeof mapLayer === 'undefined') {
                        map.addLayer(layer);
                    }
                });


                map.setPaintProperty('line-layer', 'line-color', getComputedStyle(document.body).getPropertyValue('--journey-line-colour'))

            }, 1500);
        }
    }

    //const CustomControl = new MapCustomControl([showToast, handleLiveTrack, darkMap, changeStyle]);

    useEffect(() => {
        return () => clearInterval(vehiclesInterval.current)
    }, [])


    useEffect(() => {
        if (!displayTrackClickModal){
            handleHyperlapseLine()
        }
    }, [displayTrackClickModal])



    const resetStyle = () => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn')
            } else {
                changeStyle('fleetfocus/clyreqdlj00d701qr81uf1gsw')
            }
        }
    }



    useEffect(() => {
        if (map){
            resetStyle();
        }
    }, [darkMap, darkMode])





    const storeForwardColour = () => {
        if (fetchUserLevel() === 'superuser'){
            return '#ec1818'
        } else {
            return '#ffffff'
        }
    }

    const setMyTimer = () => {
        var toExactMinute = 60000 - (new Date().getTime() % 60000);

        setTimeout(async () => {
            const dataPre = await filterVehicleList(vehicleFilterParams);

            const data = dataPre?.features.filter(item => JSON.stringify(item.geometry?.coordinates) !== JSON.stringify([0,0]))

            map.getSource('vehicle-points-data').setData({features: data, type: "FeatureCollection"});

            vehiclesInterval.current = setInterval(async() => {
                const dataPre = await filterVehicleList(vehicleFilterParams);

                const data = dataPre?.features.filter(item => JSON.stringify(item.geometry?.coordinates) !== JSON.stringify([0,0]))

                map.getSource('vehicle-points-data').setData({features: data, type: "FeatureCollection"});
            }, 60000)
        }, toExactMinute)
    }





    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";
        const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn'
            : 'mapbox://styles/fleetfocus/clyreqdlj00d701qr81uf1gsw';
        const initializeMap = ({setMap, mapContainer}) => {

            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center: [0.9142, 51.9037],
                zoom: 0,
                maxZoom: 19,
                minZoom: 1,
                pitchWithRotate: true,
                dragRotate: true
            });


            // disable map rotation using right click + drag
            // map.dragRotate.disable();

            // disable map rotation using touch rotation gesture
            // map.touchZoomRotate.disableRotation();

            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    countries: 'gb',
                    placeholder: 'address search',
                    mapboxgl,

                }),  'bottom-right'
            );


            // map.addControl(CustomControl, 'top-right');

            map.on('load', () => {

                //map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0)

                // if (window.myJourneyInterval !== undefined && window.myJourneyInterval !== 'undefined') {
                //     window.clearInterval(window.myJourneyInterval);
                // }

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    customIcon.src = icon.src;
                });

                setMap(map);
                map.resize();

                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();

                // add the data source for new a feature collection with no features
                map.addSource('vehicle-points-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                    cluster: true,
                    clusterMaxZoom: 16, // Max zoom to cluster points on
                    clusterRadius: 60
                });

                map.addSource('events-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    }
                });

                map.addSource('journeyLine', {
                    type: 'geojson',
                    lineMetrics: true,
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                });

                map.addSource('hyperlapseLine', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                });

                map.addSource('journey-points', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    },
                    generateId: true
                });
//////////////////////////////////////////////////////////////// S+E LAYERS
                map.addSource('start-journey-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    }
                });

                map.addSource('end-journey-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    }
                });

                map.addSource('point-selected-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    }
                });


                // now add the layer, and reference the data source above by name
                map.addLayer({
                    id: 'vehicle-points-layer',
                    source: 'vehicle-points-data',
                    type: 'symbol',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        //  'icon-image': 'rectangle-orange-5',
                        'icon-image': [
                            'case',
                            ['==', 'parked', ['get', 'drivingStatus']], 'vStoppedMarker',
                            ['==', 'driving', ['get', 'drivingStatus']], 'jInProgressArrow',
                            'vIdlingArrow'
                        ],

                        'icon-ignore-placement': false,
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,
                        'text-field': ['get', 'registration'],
                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'icon-size': 0.95,  // 0.75,
                        'icon-anchor': 'left',
                        'icon-offset': [-25, 0],
                        'text-size': 13,
                        'text-anchor': 'bottom-left',
                        'text-offset': [1.0, 0.6],
                        'icon-rotate': [
                            'case',
                            ['==', "offline", ['get', 'deviceStatus']], 0,
                            ['==', "online", ['get', 'deviceStatus']], ['get', 'head'],
                            ['get', 'head']
                        ],
                        'visibility': 'visible',

                    },
                    paint: {
                        // Sleep Offline Driving
                        'text-color': '#000000',
                        "text-halo-color": "#fff",
                        "text-halo-width": 10
                    }
                });



                map.addLayer({
                    id: 'end-journey-layer',
                    source: 'end-journey-data',
                    type: 'symbol',
                    layout: {
                        'icon-image': [
                            'case',
                            ['==', true, ['get', 'completed']], 'jEndedFlag',
                            ['==', 'parked', ['get', 'drivingStatus']], 'jEndedFlag',
                            // ['==', 'driving', ['get', 'drivingStatus']], 'jInProgressArrow',
                            'jInProgressArrow'
                        ],
                        'icon-ignore-placement': false,
                        'icon-rotation-alignment': 'viewport',
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,
                        'icon-size': 1,
                        'icon-anchor': 'center',
                        'icon-rotate':
                            [
                                'case',
                                ['==', 'parked', ['get', 'drivingStatus']], 0,
                                ['==', true, ['get', 'completed']], 0,
                                ['get', 'head']
                            ],
                    }
                });

                map.addLayer({
                    id: 'start-journey-layer',
                    source: 'start-journey-data',
                    type: 'symbol',
                    layout: {
                        'icon-image': 'jStartFlag',
                        'icon-ignore-placement': false,
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,
                        'icon-size': 1,
                        'icon-anchor': 'center',
                    },
                    paint: {
                        'icon-color': '#000000',
                        'text-color': '#ffffff'
                    }
                }, 'end-journey-layer');

                map.addLayer({
                    id: 'journey-points-layer',
                    source: 'journey-points',
                    type: 'symbol',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'visibility': 'visible',
                        'icon-image': 'lpArrow',
                        'icon-rotation-alignment': 'map',
                        'icon-ignore-placement': false,
                        'icon-padding': 0,
                        'icon-allow-overlap': false,
                        // 'icon-size': 0.6,

                        'icon-size': {
                            stops: [
                                [1, 0.6],
                                [14, 0.6],
                                [15, 0.9]
                            ]
                        },


                        'icon-rotate': ['get', 'head'],
                    },
                    paint: {
                        'icon-color': [
                            'case',

                            ['==', 'events', ['get', 'dataSource']], fetchUserLevel() === 'superuser' ? ' blue' : 'white',
                            ['==', 'video', ['get', 'dataSource']], fetchUserLevel() === 'superuser' ? ' green' : 'white',
                            ['==', true, ['get', 'storeForward']], fetchUserLevel() === 'superuser' ? ' red' : 'white',
                            ['==', false, ['get', 'storeForward']], '#ffffff',

                            '#ffffff'
                        ],
                        "icon-halo-color": "grey",
                        "icon-halo-width": 2
                    }
                }, 'start-journey-layer');

                if (!fetchBlockedEndpoints().includes('view-snapshot')) {
                    map.addLayer({
                        id: 'events-layer',
                        source: 'events-data',
                        type: 'symbol',
                        layout: {
                            'icon-image': [
                                'case',
                                // ['==', 'severe', ['get', 'severity']], 'circle-red-svg',
                                // ['==', 'moderate', ['get', 'severity']], 'circle-svg',
                                ['==', 'user', ['get', 'eventSource']], 'userCircle',

                                // ['in', 'Collision warning', ['get', 'eventTags']], 'collisionCircle',
                                // ['in', 'cornering', ['get', 'eventTags']], 'corneringCircle',
                                // ['in', 'Distraction', ['get', 'eventTags']], 'distractionCircle',
                                // ['in', 'Inattentive', ['get', 'eventTags']], 'distractionCircle',
                                // ['in', 'Drowsiness', ['get', 'eventTags']], 'fatigueCircle',
                                // ['in', 'Fatigue', ['get', 'eventTags']], 'fatigueCircle',
                                // ['in', 'Lane Departure', ['get', 'eventTags']], 'corneringCircle',
                                // ['in', 'Pedestrian', ['get', 'eventTags']], 'pedestrianCircle',
                                // ['in', 'Mobile phone', ['get', 'eventTags']], 'phoneCircle',
                                // ['in', 'Smoking', ['get', 'eventTags']], 'smokingCircle',
                                // ['in', 'speeding', ['get', 'eventTags']], 'speedingCircle',
                                // ['in', 'Tailgating', ['get', 'eventTags']], 'tailgatingCircle',
                                'circle-svg'
                            ],
                            'icon-ignore-placement': false,
                            'icon-allow-overlap': true,
                            'text-allow-overlap': true,
                            'icon-size': 1,
                            'icon-anchor': 'center',

                        },
                        // paint: {
                        //     "icon-color": 'red',
                        //     'text-color': '#ffffff'
                        // }
                    }, 'end-journey-layer');


                }






                map.addLayer({
                    id: 'point-selected-layer',
                    source: 'point-selected-data',
                    type: 'symbol',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': 'dashed-circle',
                        'icon-ignore-placement': false,
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 1,
                        'icon-rotate': ['get', 'head']
                    },
                    paint: {
                        'icon-color': 'white'
                    }
                }, 'journey-points-layer');


                markerStopped.remove();

                map.on('mousemove', 'journey-points-layer', e => {
                    if (e?.features?.length > 0){
                        const j = map.getSource('journey-points')._data;

                        const track = j?.features.filter(item => {
                            return item.properties.time === e.features[0].properties.time
                        });

                        if (track.length > 0) {
                            setSelectedPoint(track[0]);
                        }
                    }
                });


                map.on('click', 'point-selected-layer', async e => {
                    await handleTrack(selectedPoint)
                });



                map.on('click', 'journey-points-layer', async e => {

                    const j = map.getSource('journey-points')._data;

                    const track = j?.features.filter(item => {
                        return item.properties.time === e.features[0].properties.time
                    });


                    if (track.length > 0) {
                        await handleTrack(track[0]);
                    }
                });

                map.on('click', 'end-journey-layer', async (e) => {
                    const j = map.getSource('journey-points')._data;

                    const track = j?.features.filter(item => {
                        return item.properties.time === e.features[0].properties.time
                    });


                    if (track.length > 0) {
                        await handleTrack(track[0]);
                    }
                });
                map.on('click', 'start-journey-layer', async e => {
                    const j = map.getSource('journey-points')._data;

                    const track = j?.features.filter(item => {
                        return item.properties.time === e.features[0].properties.time
                    });


                    if (track.length > 0) {
                        await handleTrack(track[0]);
                    }

                });


                map.on('click', 'vehicle-points-layer', (e) => {
                    let test = JSON.parse(JSON.stringify(e.features[0]));
                    test.properties.tags = JSON.parse(test.properties?.tags ? test.properties?.tags : '[]');
                    test.properties.lastLocation = JSON.parse(test.properties?.lastLocation )

                    //


                    handleVehicle(test);
                });




                if (!fetchBlockedEndpoints().includes('view-snapshot')) {
                    map.on('click', 'events-layer', (e) => {
                        handleEvent(e.features[0].properties);
                    });
                }

                /**/


                map.on('click', 'clusters', (e) => {
                    // console.log('clicked cluster');
                    const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
                    const clusterId = features[0].properties.cluster_id;
                    // console.log(clusterId);

                    const point_count = features[0].properties.point_count;
                    // console.log(point_count);
                    const clusterSource = map.getSource('vehicle-points-data');

                    clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                        // console.log('getClusterLeaves', err, aFeatures);
                        // console.log('zoom' + map.getZoom());
                        if (map.getZoom() === 16) {
                            this.openClusterPopup(aFeatures);
                        }
                    });

                    map.getSource('vehicle-points-data').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom
                        });

                        // console.log('zoom2: ' + map.getZoom());

                    });
                });

                map.addLayer({
                    id: 'clusters',
                    type: 'symbol',
                    source: 'vehicle-points-data',
                    filter: ['has', 'point_count'],

                    layout: {
                        'icon-image': 'cluster-marker',
                        //'icon-image': 'rectangle-orange-5',

                        'text-field': '{point_count_abbreviated}',
                        // 'icon-ignore-placement': true,

                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,

                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'icon-size': 0.9,
                        // 'icon-anchor': 'bottom-left',
                        // 'icon-offset': [-20, 0],
                        'text-anchor': 'bottom',
                        'text-offset': [0, 0.4],
                    }
                });


                map.addLayer({
                    id: 'line-layer',
                    type: 'line',
                    source: 'journeyLine',

                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                        'visibility': 'visible'
                    },
                    paint: {
                        'line-color': getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                        /*'line-offset': {    // works only for MAP SNAPPED!!
                            stops: [
                                [1, 0],
                                [14, 0],
                                [18, -5],
                                [22, -15]
                            ]
                        },*/
                        'line-width': {
                            base: 10,
                            stops: [
                                [1, 5],
                                [15, 12]
                            ]
                        },
                        'line-opacity': {
                            stops: [
                                [1, 1],
                                [14, 0.5]
                            ]
                        }

                    }

                }, 'journey-points-layer');      // 'eventsLayer');

                map.addLayer({
                    id: 'line-layer2',
                    type: 'line',
                    source: 'hyperlapseLine',

                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                        'visibility': 'visible'
                    },
                    paint: {
                        'line-color': 'pink',
                        'line-width': {
                            base: 10,
                            stops: [
                                [1, 5],
                                [15, 12]
                            ]
                        },
                    }
                }, 'journey-points-layer');      // 'eventsLayer');
            });


        };


        if (!map) initializeMap({setMap, mapContainer});
    }, [map]);


    useEffect(async () => {
        if (map) {

            // here is where we will update map data from indexeddb


            markerStopped.remove();

            if (!vehicle){
                map.getSource('journey-points').setData({
                    type: 'FeatureCollection',
                    features: []
                });

                map.getSource('journeyLine').setData({
                    type: 'FeatureCollection',
                    features: []
                });

                map.getSource('hyperlapseLine').setData({
                    type: 'FeatureCollection',
                    features: []
                });

                const dataPre = await filterVehicleList(vehicleFilterParams);

                const data = dataPre?.features.filter(item => JSON.stringify(item.geometry?.coordinates) !== JSON.stringify([0,0]))
                map.getSource('vehicle-points-data').setData({
                    features: data,
                    type: "FeatureCollection"
                });

                // let coords = [];
                // vehiclesFilter.features.forEach((f) => {
                //     coords.push(f?.geometry?.coordinates);
                // });
                //
                // const bounds = coords.reduce(function (b, coord) {
                //     return b.extend(coord);
                // }, new mapboxgl.LngLatBounds(coords[0], coords[0]));
                // let padding = {};
                //
                // // HERE
                //
                // map.fitBounds(bounds, {
                //     padding: {bottom: 100, left: 300, top: 100, right: 100}, maxZoom: 16
                // });

                map.getSource('events-data').setData({type: 'FeatureCollection', features: []});
            }

            if(pageToShow === '/map'){
                setmapReopened(!mapReopened)
            }

        }
    }, [map, vehicleFilterParams])


    useEffect(() => {
        if (map) {
            if (zoom !== null) {
                map.easeTo({center: zoom, zoom: 0, duration: 1000});
            }
        }
    }, [map, zoom])


    useEffect(async () => {
        // need this to ensure that the map is loaded

        if (map) {

            setSelectedPoint(null)
            markerStopped.remove();

            map.getSource('start-journey-data').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('end-journey-data').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('journey-points').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('journeyLine').setData({
                type: 'FeatureCollection',
                features: []
            });


            map.getSource('events-data').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('hyperlapseLine').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('point-selected-data').setData({
                type: 'FeatureCollection',
                features: []
            });


//////////////////////////////////////////
            if(!vehicle){
                map.resize();

                const dataPre = await filterVehicleList(vehicleFilterParams);

                const data = dataPre?.features.filter(item => JSON.stringify(item.geometry?.coordinates) !== JSON.stringify([0,0]))

                map.getSource('vehicle-points-data').setData({
                    features: data,
                    type: "FeatureCollection"
                });

                let coords = [];
                dataPre.features.forEach((f) => {
                    coords.push(f?.geometry?.coordinates);
                });

                const bounds = coords?.reduce(function (b, coord) {
                    if (JSON.stringify(coord) !== JSON.stringify([0,0])){
                        return b?.extend(coord);
                    } else {
                        return b
                    }

                }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

                if (Object.keys(bounds).length > 0){
                    map.fitBounds(bounds, {
                        padding: {bottom: 50, left: 50, top: 10, right: 150}, maxZoom: 16
                    });
                }

                map.getSource('events-data').setData({type: 'FeatureCollection', features: []});


                map.setLayoutProperty('vehicle-points-layer', 'visibility', 'visible');


                map.setLayoutProperty('clusters', 'visibility', 'visible');
                // document.getElementById('toggleClustersButton').style.background = Colours.mapControlClicked;
                return
            }

            ///////////////////////////////////


            map.setLayoutProperty('vehicle-points-layer', 'visibility', 'none');
            map.setLayoutProperty('clusters', 'visibility', 'none');
            // document.getElementById('toggleClustersButton').style.background = '#ffffff';



            // no journey just fly to vehicle, add pin & return
            if (journey == null) {

                const dateString = [
                    date.getFullYear(),
                    ('0' + (date.getMonth() + 1)).slice(-2),
                    ('0' + date.getDate()).slice(-2)
                ].join('-')



                        marker.remove();
                        markerStart.remove();

                        map.flyTo({
                            center: [vehicle?.properties?.lastLocation?.lng, vehicle?.properties?.lastLocation?.lat],
                            // offset: [(viewportX / 6), 0],
                            zoom: 16,
                            speed: 3
                        });

                        ReactDOM.render(<StoppedMarker openModal={openModal} />, elStopped);
                        markerStopped.setLngLat([vehicle?.properties?.lastLocation?.lng, vehicle?.properties?.lastLocation?.lat]).addTo(map);


                // replace with last location
                // fetchLastJourney(vehicle?.properties?.dn, dateString).then(r => {
                //     if (r?.length === 1){
                //         marker.remove();
                //         markerStart.remove();
                //
                //         map.flyTo({
                //             center: [r[0]?.finish?.lng, r[0].finish?.lat],
                //             // offset: [(viewportX / 6), 0],
                //             zoom: 16,
                //             speed: 3
                //         });
                //
                //         handleTrueLocation(r[0])
                //
                //         ReactDOM.render(<StoppedMarker vehicle={vehicle} openModal={openModal} closeModal={closeModal} journey={r[0]}/>, elStopped);
                //         markerStopped.setLngLat([r[0]?.finish?.lng, r[0].finish?.lat]).addTo(map);
                //     } else {
                //         // toast
                //         toast.current.show({ severity: 'warn', summary: 'No GPS data', detail: 'There may be a problem with this device, please contact support',
                //             life: TOAST_DURATION });
                //     }
                // })

                // if(window.myJourneyInterval !== undefined && window.myJourneyInterval !== 'undefined'){
                //     window.clearInterval(window.myJourneyInterval);
                // }
                return;
            }

            let eventsData = await fetchEventsForJourney(journey, pageToShow);

            if (eventsData?.features?.length > 0){
                map.getSource('events-data').setData(eventsData);
            }

            map.getSource('journey-points').setData(journey);

            map.getSource('start-journey-data').setData({
                type: "FeatureCollection",
                features: [journey.features[0]]
            });


            const jEndData = journey.features[(journey.features).length - 1];

            if (jEndData){
                jEndData.properties.completed = journey?.details?.completed
                jEndData.properties.drivingStatus = journey?.details?.drivingStatus
            }


            map.getSource('end-journey-data').setData({
                type: "FeatureCollection",
                features: [jEndData]
            });

            let coords = [];

            journey.features.forEach((f) => {
                coords.push(f?.geometry?.coordinates);
            });

            // ReactDOM.render(<EndMarker journey={journey} vehicle={vehicle} openModal={openModal} closeModal={closeModal}/>, el);

            try {
                if (coords?.length > 2){
                    marker.setLngLat(coords[coords.length - 1]).addTo(map);
                    // marker.setOffset([0,0]);
                    // marker.setOffset([finishOffsetX,finishOffsetY]);
                    // ReactDOM.render(<StartMarker journey={journey} vehicle={vehicle} openModal={openModal} closeModal={closeModal}/>, elStart);
                    markerStart.setLngLat(coords[0]).addTo(map);
                }
            } catch (e) {
                console.log(e)
            }

            setLastMapCenter(coords);

            let bounds;

            if (coords?.length > 0){


                bounds = coords.reduce(function (b, coord) {
                    return b.extend(coord);
                }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

                map.fitBounds(bounds, {
                    padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16
                });

            }



            if(hyperlapseLine){
                let hlCoords = [];
                hyperlapseLine.forEach((f) => {
                    hlCoords.push(f?.geometry?.coordinates);
                });

                map.getSource('hyperlapseLine').setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: hlCoords
                    }
                });
            }



            const coordsData = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: coords
                }
            };
            map.getSource('journeyLine').setData(coordsData);
            markerStopped.remove();
            startHlMarker.remove();
            /*  endHlMarker.remove();*/
            map.resize();
            pointSelectedMarker.remove();
        }

    }, [journey, event, vehicle, mapReopened])


    useEffect(() => {

        if (map) {

            //setMap(map);
            //map.resize();
            window.setTimeout(()=>map.resize(), 50);
            window.setTimeout(()=>map.resize(), 100);
            window.setTimeout(()=>map.resize(), 150);
            window.setTimeout(()=>map.resize(), 200);
            window.setTimeout(()=>map.resize(), 250);
            window.setTimeout(()=>map.resize(), 300);

            const center = map.getCenter();

            map.flyTo({
                center: center,
                speed: 2
            });
        }

    }, [sideBarWidthPx])

    // useEffect(() => {
    //     if (lastMapCenter && recenterMap && map){
    //         setRecenterMap(false);
    //
    //         const bounds = lastMapCenter.reduce(function (b, coord) {
    //             return b.extend(coord);
    //         }, new mapboxgl.LngLatBounds(lastMapCenter[0], lastMapCenter[0]));
    //
    //         // map.fitBounds(bounds, {
    //         //     padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16
    //         // });
    //     }
    //
    // }, [recenterMap]);




    useEffect(() => {
        if (map){
            if(hyperlapseLine){

                let hlCoords = [];
                hyperlapseLine.forEach((f) => {
                    hlCoords.push(f?.geometry?.coordinates);
                });


                map.getSource('hyperlapseLine').setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: hlCoords
                    }
                });

                // startHlMarker.setLngLat(coords[0]).addTo(map);

                // if(coords?.length > 1){
                // if(coords?.length > 1){
                //     endHlMarker.setLngLat(coords[coords.length - 1]).addTo(map);
                // }



                map.resize();
            } else {

                // startHlMarker.remove();
                // endHlMarker.remove();


                // startHlMarker.remove();
                // endHlMarker.remove();
                map.getSource('hyperlapseLine').setData({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                });


            }
        }

    }, [hyperlapseLine])


    useEffect(() => {
        if (map){
            if (selectedPoint){
                map?.getSource('point-selected-data')?.setData({
                    type: 'FeatureCollection',
                    features: [selectedPoint]
                });

            } else {
                map?.getSource('point-selected-data')?.setData({
                    type: 'FeatureCollection',
                    features: []
                });
            }
        }
    }, [selectedPoint])

    useEffect(async () => {
        if (map && vehicles){

            clearInterval(vehiclesInterval.current)
            setMyTimer();

            if (map.getSource('vehicle-points-data')?._data?.features?.length < 1){
                map.getSource('vehicle-points-data').setData(vehicles);

                let coords = [];

                vehicles.features.forEach((f) => {
                    coords.push(f?.geometry?.coordinates);
                });

                const bounds = coords?.reduce(function (b, coord) {
                    if (JSON.stringify(coord) !== JSON.stringify([0,0])){
                        return b?.extend(coord);
                    } else {
                        return b
                    }

                }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

                if (Object.keys(bounds).length > 0){
                    map.fitBounds(bounds, {
                        padding: {bottom: 50, left: 50, top: 10, right: 150}, maxZoom: 16
                    });
                }

            }

        }
    }, [vehicles]);





    useEffect(() => {
        if (journey && map){

            if(liveTrack){
                map.easeTo({
                    center: journey.features[journey?.features?.length - 1].geometry.coordinates,
                    zoom:16,
                    bearing: journey.features[journey?.features?.length - 1].properties.head,
                    pitch: 70
                });

                map.setLayoutProperty('end-journey-layer', 'icon-rotation-alignment', 'map');

            } else {
                map.setLayoutProperty('end-journey-layer', 'icon-rotation-alignment', 'viewport');

                let coords = [];

                journey.features.forEach((f) => {
                    coords.push(f?.geometry?.coordinates);
                });

                let bounds

                if (coords?.length > 0){
                    bounds = coords.reduce(function (b, coord) {
                        return b.extend(coord);
                    }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

                }

                if (bounds){
                    map.fitBounds(bounds, {
                        padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16, pitch:0
                    });
                }
            }
        }
    }, [liveTrack])


    useEffect(() => {
        if (lastJourney?.details?._id !== journey?.details?._id && map && journey?.features){
            let coords = [];

            journey.features.forEach((f) => {
                coords.push(f?.geometry?.coordinates);
            });

            let bounds

            if (coords?.length > 0){
                bounds = coords.reduce(function (b, coord) {
                    return b.extend(coord);
                }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

            }

            if (bounds){
                map.fitBounds(bounds, {
                    padding: {bottom: 150, left: 150, top: 150, right: 150}, maxZoom: 16, pitch:0
                });
            }
        }
    }, [journey]);


    useEffect(() => {
        if (map){
            map.resize()
        }
    }, [showSpeedPanel, showStopPanel])



    return (
        <React.Fragment>
                <div style={{transition: 'opacity .5s ease', opacity : vehicle ? 1 : 0,  height : vehicle ? 'auto' : '0px'}}>

                    <VehicleDetail />

                    <ListJourneys  />

                    {winWidth > 800  && journeys?.length > 0 &&
                        <div key={journey?.details?._id} style={{
                            position: 'relative', borderBottom: '1px solid var(--surface-border)',
                            background: 'var(--surface-ground)'
                        }}>

                                <span style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    bottom: -27,
                                    left: 10,
                                    background: showSpeedPanel ? 'var(--v-list-button-selected)' : 'var(--surface-ground)',
                                    color: showSpeedPanel ? 'var(--v-list-button-text-selected)' : 'var(--text-color)',
                                    zIndex: 1,
                                    borderRadius: '0px 0px 3px 3px',
                                    padding: '5px',
                                    border: '1px solid var(--surface-border)',
                                    borderTop: 'none'
                                }}
                                      onClick={() => {
                                          setShowSpeedPanel(!showSpeedPanel);
                                          setShowStopPanel(false)
                                      }}>
                                    <FontAwesomeIcon icon={faGaugeMax} style={{marginRight: '5px', fontSize: '16px'}}/>
                                    Journey profile
                                </span>

                            <span style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                bottom: -27,
                                left: 120,
                                background: showStopPanel ? 'var(--v-list-button-selected)' : 'var(--surface-ground)',
                                color: showStopPanel ? 'var(--v-list-button-text-selected)' : 'var(--text-color)',
                                zIndex: 1,
                                borderRadius: '0px 0px 3px 3px',
                                padding: '5px',
                                border: '1px solid var(--surface-border)',
                                borderTop: 'none'
                            }}
                                  onClick={() => {
                                      setShowSpeedPanel(false);
                                      setShowStopPanel(!showStopPanel)
                                  }}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} style={{marginRight: '5px', fontSize: '16px'}}/>
                                    Day summary
                                </span>

                            {showSpeedPanel &&
                                <SpeedChartV3 handleSelectedPoint={handleSelectedPoint} selectedPoint={selectedPoint}
                                              handleTrack={handleTrack}/>
                            }

                            {showStopPanel &&
                                <DaySummary />
                            }
                        </div>

                    }
                </div>


            <div className="mapContainer" id="mapContainer"
                 style={{marginTop: '63px', flexDirection: 'column', position: 'relative'}}>
                <div ref={el => (mapContainer.current = el)} style={styles}/>

                <div style={{position: 'absolute', top:"-50px", right:10}} className="customControls">
                    <ReactMapControls map={map} changeStyle={changeStyle} resetStyle={resetStyle} handleLiveTrack={handleLiveTrack}/>
                </div>
                {/*{vehicle && !journey &&*/}
                {/*    <LastSnapshotPanel />*/}
                {/*}*/}
            </div>


            <TrackModalParent track={track} handleHyperlapseLine={handleHyperlapseLine}/>
            <EventModalParent/>
            <DeviceDetailsModalParent/>
            <Toast ref={toast} appendTo={null} position="bottom-right"/>

        </React.Fragment>

    );
};

export default Map2;
