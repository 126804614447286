import React, {useEffect, useState} from 'react';
import {MflLogo as NotTextLogo} from "./svgs/mflLogo"
import Webfleet from "./svgs/webfleet.png"
import ReactDOM from 'react-dom';
import './index.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import MFLMenuBar from './components/menuBar/menuBar'
import MainParent from "./main/MainParent";
import Preferences from './preferences/Preferences.js';
import Login from './login/Login.js';
import useToken from './login/useToken.js';
import {Colours} from "./colours";
import fetchNotifications from "./api/notifications/fetchNotifications";
import fetchFleets from "./api/fetchFleets";
import usePersistState from "./functions/localStorage/persistState";
import { addLocale } from 'primereact/api';
import indexContext from "./components/contexts/indexContext";
import RudderMenu from "./components/menuBar/rudderMenu";
import MobileHeader from "./components/menuBar/mobileHeader";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from "./errorPage";
import SplashScreen from "./splashScreen/splashScreen";
import {isWebfleet} from "./functions/isWebfleet";
import {fetchUserLevel} from "./functions/fetchUserLevel";
import {addData, getStoreData, initDB} from "./functions/db";
import appInfo from "../package.json"
import fetchNotificationsCount from "./api/notifications/fetchNotificationsCount";
import fetchEventTagDump from "./api/events/fetchEventTagDump";



const App = () => {

    const { token, setToken } = useToken();
    const [pageToShow, setPageToShow] = useState('/map');
    const [notifications, setNotifications] = useState(0);
    const [newNotiCount, setNewNotiCount] = useState(0);
    const [fleets, setFleets] = useState([]);
    const [showSidebar, setShowSidebar] = usePersistState(true, 'showSidebar');
    const [showShortJourneys, setShowShortJourneys] = usePersistState(false, 'showShortJourneys');
    const [darkMode, setDarkMode] = usePersistState("light", 'darkMode');
    const [darkMap, setDarkMap] = usePersistState(false, 'darkMap');
    const [winWidth, setWinWidth] = useState(window.innerWidth)
    const [showSplash, setShowSplash] = useState(true);



    const themes = ['light', 'dark', 'lightTeal', 'darkTeal', 'lightPink', 'darkPink'];

    const handleShowSplashScreen = () => {
        setShowSplash(!showSplash)
    }

    const handleShowSidebar = (val) => {
        setShowSidebar(val);
    }

    const handleShortJourneys = () => {
        setShowShortJourneys(!showShortJourneys);
    }

    const handleDarkMode = (theme) => {
        let themeLink = document.getElementById('app-theme');
        if (themes?.includes(theme)){

            if (themeLink) {
                themeLink.href = theme + '.css';
            }
            setDarkMode(theme);
        } else {
            themeLink.href = 'light.css'
        }


    }

    const handleDarkMap = () => {
        setDarkMap(!darkMap)
    }


    const handlePageToShow = (v) => {
        setPageToShow(v);
    }




    // change this to show count only, move noti arr onto grid
    const handleRefreshNotifications = async (refresh = true, params ={
        'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } },
        'notificationType': {"$in": ["health"]},
        fleetId : {"$in": fleets?.reduce((acc, curVal) => acc.concat(curVal.fleetId), [])}
    }) => {
        if (token){
            if (fetchUserLevel() !== 'superuser'){
                params.mflGroup = localStorage.getItem('mflGroup');
            }
            const r = await fetchNotificationsCount(params);
            const r2 = await fetchNotifications(params);
            setNotifications(r2)

            setNewNotiCount(r?.count)
        }
    }

    const handleNotiCount = (offset) => {
        setNewNotiCount((newNotiCount > 0 ? newNotiCount : 0) + offset);
    }



    const detectSize = () => {
        setWinWidth(window.innerWidth)
    }

    // get base url of site hosting, for router to use
    const getBasename = path => path.substr(0, path.lastIndexOf('/'));

    const refreshFleets = async () => {
        const fleets = await fetchFleets();

        setFleets(fleets);

        const tags = await fetchEventTagDump();

        for (let i = 0; i < tags?.length; i++) {
            await addData('eventTags', tags[i]);
        }


    }


    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [winWidth])

    useEffect(async () => {

        await initDB();

        // create a global locale for the calendar components
        addLocale('en_gb', {
            firstDayOfWeek: 1,
            dayNames: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
            monthNamesShort: ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"],
            today: 'Today',
            clear: 'Clear'
        });
        // target theme css file - defaults to light.css
        let themeLink = document.getElementById('app-theme');
        // determine what file to use


        // apply theme
        if (themeLink && themes?.includes(darkMode)) {
            themeLink.href = darkMode + '.css';
        } else {
            themeLink.href = 'light.css'
        }
        //fetch fleets for notification fetching
        if (token){
            refreshFleets()
        }

    }, []);

    useEffect(() => {
        if(fleets?.length > 0 && token){
            handleRefreshNotifications();
            // setInterval(() => {
            //     if ( new Date().getSeconds() === 0 ) handleRefreshNotifications(false)
            // }, 60000);
        }
    }, [fleets])


    useEffect(() => {
        let str = "myfleetlive.ai";

        if (newNotiCount > 0){
            str += " (" + newNotiCount + ")"
        }

        document.title = str;
    }, [newNotiCount])




    // no token force user to login
    if(!token) {
        return <Login setToken={setToken} />
    }

    // if token allow access
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Router basename={getBasename(window.location.pathname)}>
                <indexContext.Provider value={{pageToShow, handlePageToShow, handleRefreshNotifications, handleShowSidebar,
                    showSidebar, showShortJourneys, handleShortJourneys, darkMode, winWidth, notifications, handleDarkMode, fleets,
                    handleNotiCount, newNotiCount, handleShowSplashScreen, darkMap, handleDarkMap, refreshFleets}}>


                    <React.Fragment>
                        {showSplash && <SplashScreen />}

                        {(token && winWidth > 800) ?
                            <MFLMenuBar />
                            :
                            <MobileHeader />
                        }
                        <div className="content" >
                            <Routes>
                                <Route exact path='/' element={<MainParent /> }/>
                                <Route path=":urlQuery" element={<MainParent />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </div>

                        {token && winWidth > 800 &&
                            <footer className="surface-card text-color">

                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <NotTextLogo style={{fill:Colours.logoTextColour}} size='33px'/>
                                    <span style={{marginLeft: '5px'}}>
                                        myfleetlive.ai v{appInfo?.version}
                                    </span>


                                    {isWebfleet() &&

                                        <React.Fragment>
                                            <img src={Webfleet} style={{height: '33px', marginLeft: '10px'}} />
                                            <span style={{marginLeft: '5px'}}>
                                                Webfleet Connected
                                            </span>
                                        </React.Fragment>

                                    }




                                </div>

                                <span>
                                    {localStorage.getItem('email')}
                                </span>
                            </footer>
                        }


                        {token && winWidth < 800 &&
                            <RudderMenu />
                        }

                    </React.Fragment>


                </indexContext.Provider>
            </Router>
        </ErrorBoundary>
    )
}


ReactDOM.render(<App />, document.querySelector('#root'));
// serviceWorkerRegistration.unregister();
