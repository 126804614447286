import React from 'react';

const FormatAddress = ({data, type}) => {
    let add;
    

    if (type === 'start'){
        add = data?.startAddress?.addressData;
    } else {
        add = data?.endAddress?.addressData;
    }

    let str = '';
    str += add?.road ? add?.road : '';



    if (add?.village){
        str += str?.length > 0 ? ', ' : '';
        str += add?.village;

    } else {
        if (add?.town) {
            str += str?.length > 0 ? ', ' : '';
            str += add?.town
        } else {
            str += str?.length > 0 ? ', ' : '';
            str += add?.city;
        }
    }

    if (add?.postcode){
        str += str?.length > 0 ? ', ' : '';
        str += add?.postcode
    }

    return (
        <div>
            {str}
        </div>
    )
}

export default FormatAddress
