import {API_URL} from '../Constants.js'
import clearStorage from "../functions/localStorage/clearStorage";



const FetchFleets = () => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({active: {'$in': [1, null]}})
    };


    return fetch(API_URL + '/fleets', requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
            return Promise.resolve(data);

        }).catch(err=>{
            console.log(err)});

}


export default FetchFleets;
