import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {ConfirmPopup} from "primereact/confirmpopup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleX, faLocationPlus, faPenToSquare} from "@fortawesome/pro-regular-svg-icons";
import {Dropdown} from "primereact/dropdown";
import mainContext from "../../contexts/mainContext";
import {findByIndexProp, updateData} from "../../../functions/db";
import postEditTags from "../../../api/device/postEditTags";
import indexContext from "../../contexts/indexContext";
import {TOAST_DURATION} from "../../../Constants";
import {InputText} from "primereact/inputtext";

const EditTags = ({d, handleShowPopout, handleMouseOver, mouseOver, showPopout}) => {

    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: 'var(--surface-300)',
        fontSize: '11px',
        color: 'var(--text-color)',
        border: "none"
    }

    const {vehicleFilterParams, handleVehicleFilterParams, toast} = useContext(mainContext)


    const [visible, setVisible] = useState(false);
    const [tag, setTag] = useState('');
    const [fleetTags, setFleetTags] = useState([]);
    const id = useRef(Math.random());



    const [latestVehicle, setLatestVehicle] = useState(d);



    useEffect(async () => {
        if (visible){
            await resetFleetTags()
        }
    }, [visible]);


    const resetFleetTags = async () => {
        const vehiclesForFleet = await findByIndexProp('vehicles', 'fleetId', latestVehicle?.properties?.fleetId);
        const filteredTags = vehiclesForFleet.reduce((acc, curVal) => acc.concat(curVal?.properties?.tags), []);

        let arr = Array.from(new Set(filteredTags));
        arr = arr.filter(function( element ) {
            return element !== undefined && element?.length > 0;
        });
        setFleetTags(arr?.length > 0 ? arr : [])
    }




    //save update v in db as well as make api call to update tags
    const saveChanges = async () => {

        const data = {
            dn: latestVehicle?.properties?.dn,
            tags: latestVehicle?.properties?.tags
        }

        await updateData('vehicles', latestVehicle?.properties?.dn, latestVehicle)

        try {
            await postEditTags(data);
        } catch (e) {
        }
        toast?.current.show({ severity: 'success', summary: 'All done', detail: 'Tags updated', life: TOAST_DURATION });
        await resetFleetTags();
        handleMouseOver(false);
        handleShowPopout(false);

    }


    const removeTag = (val) => {
        const clone = JSON.parse(JSON.stringify(latestVehicle));
        const index = clone?.properties?.tags.indexOf(val);
        if (index > -1) {
            clone?.properties?.tags.splice(index, 1);
        }
        setLatestVehicle(clone)
    }

    const addTag = (val) => {
        const clone = JSON.parse(JSON.stringify(latestVehicle));

        if (clone?.properties?.tags){
            clone?.properties?.tags.push(val);
        } else {
            clone.properties.tags = [val]
        }



        console.log(clone)
        setTag(null);
        setLatestVehicle(clone)
    }

    const applyTag = (val) => {
        handleVehicleFilterParams({
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: new Set([...vehicleFilterParams?.chips, val]),
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        })
    }

    const popupBody = () => {
        return (
            <div onClick={e => e.stopPropagation()} style={{textAlign:'center'}}>


                <div style={{marginBottom: '5px', fontWeight: 'bold', fontSize: '14px'}}>
                    Quick Tag Editor
                </div>

                {latestVehicle?.properties?.tags?.length > 0 ?
                    <div style={{paddingTop: '15px', paddingBottom: '15px', textAlign: 'center'}}>

                        Current tags<br/>

                        {latestVehicle?.properties?.tags?.sort()?.map(tag => (
                            <Button style={tStyle} className="p-mb-2" key={tag}
                                    label={<React.Fragment>{tag}&nbsp;<FontAwesomeIcon
                                        icon={faCircleX}/></React.Fragment>}
                                    onClick={() => removeTag(tag)}/>
                        ))}
                    </div>
                    :
                    <div style={{textAlign: 'center'}}>
                        No tags set for vehicle, enter using the controls below
                    </div>
                }


                {fleetTags?.length > 0 &&
                    <div style={{paddingTop: '15px', paddingBottom: '15px', textAlign: 'center', width: '200px', margin: '0 auto'}}>
                        Existing Fleet tags
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                            {fleetTags?.map(tag => (
                                <Button style={tStyle} key={tag}
                                        label={tag}
                                        className="p-button p-mb-2" onClick={() => addTag(tag)}
                                        disabled={latestVehicle?.properties?.tags?.includes(tag)}

                                />
                            ))}


                        </div>
                    </div>

                }


                <div className="p-inputgroup">
                    {fleetTags?.length > 0 ?
                        <Dropdown value={tag} onChange={(e) => setTag(e.value)} options={fleetTags}
                                  editable placeholder="Tag Name"/>

                    :
                        <InputText value={tag} onChange={(e) => setTag(e.target.value)} />

                    }

                    {/*<InputText value={tag} onChange={(e) => setTag(e.value)} />*/}


                    <Button onClick={() => {

                        if (tag?.length < 13){

                            addTag(tag);
                            setTag('');
                        }
                    }} label='Add' className="p-button-secondary"/>
                </div>
                {tag?.length > 10 &&
                    <small className="p-error block">Tag must be 10 characters or less.</small>
                }


            </div>
        )
    }


    return (
        <span onClick={e => e.stopPropagation()}>

            {latestVehicle?.properties?.tags?.length > 0 && latestVehicle?.properties?.tags?.map(t => (
                <Button style={tStyle} key={t} label={t} className="p-mb-2" onClick={() => applyTag(t)}/>))
            }

            {(!latestVehicle?.properties?.tags || latestVehicle?.properties?.tags?.length < 1)  && !mouseOver &&
                <div style={{width: '10px', height: '24px'}}>&nbsp;</div>

            }

            {(showPopout || mouseOver) &&
                <Button style={tStyle} label={<FontAwesomeIcon icon={faPenToSquare}/> } className=" button-success" id={id?.current}
                        onClick={(e) => {
                            e.stopPropagation()
                            setVisible(true);
                            handleShowPopout(true);
                        }} tooltip="Edit tags"/>
            }




            <ConfirmPopup target={document.getElementById(id?.current)} visible={visible}
                          onHide={() => {
                              setVisible(false);
                              handleShowPopout(false);
                              handleMouseOver(false);
                          }}
                          // icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}
                          message={popupBody}
                          acceptLabel={"Save Changes"} rejectLabel={"Cancel"}
                          accept={saveChanges}
                          rejectClassName="p-button-danger"
                          acceptClassName="p-button-success"
            />






        </span>

    )
}

export default EditTags