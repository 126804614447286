import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const ShockStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  data-pr-position="bottom" style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <g id="shock">
                    <path d="m190.95,281.17c-5.58,2.76-14.37,5.46-25.16,8.08-10.45,2.59-21.17,3.92-31.94,3.96-24.22,0-43.53-6.99-57.92-20.97-14.39-13.98-21.59-33.81-21.58-59.49,0-24.15,7.19-43.51,21.58-58.08,14.39-14.57,34.07-21.85,59.05-21.84,12.91,0,23.4,1.23,31.47,3.68,8.61,2.66,25.5,9.99,25.5,9.99v36.45h-3.53c-1.89-1.45-4.58-3.5-8.06-6.16-3.25-2.49-6.66-4.76-10.22-6.79-4.27-2.37-8.78-4.28-13.45-5.69-5.23-1.61-10.68-2.41-16.16-2.38-6.19-.08-12.35.98-18.17,3.1-5.53,2.09-10.55,5.34-14.71,9.54-4.09,4.15-7.33,9.39-9.72,15.73-2.39,6.34-3.59,13.73-3.58,22.16,0,18.01,4.54,31.3,13.62,39.86,9.08,8.56,21.48,12.84,37.19,12.83,1.39,0,3.01-.03,4.89-.1,1.87-.07,3.5-.17,4.88-.31v-30.79h-30v-29h66v76.23Z" style={{fill: 'var(--text-color)'}}/>
                    <polygon points="190.95 135.37 158.85 62.83 190.95 56.05 190.95 135.37" style={{fill: 'var(--text-color)'}}/>
                    <polygon points="198.65 138.25 239.51 70.25 263.53 92.59 198.65 138.25" style={{fill: 'var(--text-color)'}}/>
                    <polygon points="203.48 147.17 282.23 137.54 279.39 170.23 203.48 147.17" style={{fill: 'var(--text-color)'}}/>
                    <path d="m247.31,293.46c-7.26.04-14.51-.79-21.57-2.49-6.78-1.65-12.37-3.59-16.79-5.8v-27.23h2.67c1.53,1.17,3.28,2.46,5.24,3.87,2.57,1.74,5.32,3.22,8.19,4.41,3.27,1.51,6.67,2.72,10.16,3.63,4.01,1.04,8.14,1.55,12.28,1.52,4.1.08,8.18-.64,12-2.11,3.47-1.41,5.2-3.65,5.2-6.72,0-2.39-.8-4.2-2.4-5.43s-4.69-2.39-9.29-3.5c-2.45-.61-5.55-1.27-9.29-1.98-3.36-.61-6.68-1.42-9.94-2.44-8.09-2.52-14.18-6.24-18.26-11.18-4.08-4.94-6.12-11.42-6.14-19.46-.02-4.54,1.07-9.01,3.17-13.03,2.28-4.27,5.47-8,9.34-10.9,4.12-3.12,9.2-5.59,15.25-7.42,6.04-1.83,12.87-2.75,20.47-2.76,6.72-.06,13.42.67,19.96,2.16,5.29,1.18,10.44,2.92,15.36,5.2v26.13h-2.58c-1.68-1.25-3.43-2.4-5.24-3.45-2.26-1.37-4.6-2.62-7-3.73-2.92-1.3-5.95-2.32-9.06-3.04-3.36-.8-6.81-1.2-10.26-1.2-4.66,0-8.6.8-11.82,2.39-3.21,1.59-4.82,3.71-4.83,6.35-.1,2.11.79,4.14,2.39,5.52,1.6,1.35,5.06,2.67,10.4,3.97,2.76.67,5.93,1.33,9.52,1.98,3.51.63,6.97,1.5,10.35,2.62,7.42,2.39,13.05,5.9,16.88,10.54,3.83,4.63,5.75,10.78,5.75,18.44,0,4.82-1.16,9.57-3.4,13.85-2.29,4.41-5.6,8.21-9.66,11.09-4.72,3.4-10,5.95-15.6,7.55-5.99,1.76-13.15,2.65-21.49,2.65Z" style={{fill: 'var(--text-color)'}}/>
                </g>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill: 'none'}} />
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill: 'none'}} />
                </g>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Bump</Tooltip>
            }
        </div>
    )
}
export default ShockStacked
