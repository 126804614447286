import {optionTemplate} from "./optionTemplate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const selectedOptionTemplate = (option, placeholderIcon) => {
    if (option){
        return optionTemplate(option);
    }

    if (placeholderIcon){
        return <FontAwesomeIcon icon={placeholderIcon} className="p-button-label"/>
    } else {
        return <span style={{color: 'var(--text-color)', lineHeight: '24px'}}>Please select</span>
    }
}
