import {API_URL} from '../../Constants.js'


const fetchCalendar = (vehicle) => {

    let token = localStorage.getItem('access_token');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    return fetch(API_URL + '/calendar/' + vehicle.properties.dn, requestOptions)
        .then((response) => {
            if(response.status === 403) {
                localStorage.removeItem('token');
            }
            return response.json()
        })
        .then((data) => {
            return data
        });
}


export default fetchCalendar;
