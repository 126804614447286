import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const UserStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill="var(--surface-border)"/>
                <path d="m175.23,175.27c33.35,0,60.38-27.04,60.38-60.38s-27.03-60.38-60.38-60.38-60.38,27.04-60.38,60.38,27.03,60.38,60.38,60.38Zm23.89,22.68h-47.78c-45.13,0-81.73,36.63-81.66,81.78.01,8.98,7.37,16.22,16.35,16.22h178.43c9.02.01,16.32-7.29,16.32-16.34,0-45.1-36.57-81.66-81.66-81.66Z" style={{fill: 'var(--text-color)'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:'none'}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill:'none'}}/>
                </g>            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>User</Tooltip>
            }
        </div>
    )
}
export default UserStacked
