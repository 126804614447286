import React, {useContext, useEffect, useState} from 'react';
import FormatAddress from "../../../../../../functions/formatting/formatAddress";
import {DurationFromSeconds, formatTimeOnly} from "../../../../../../functions/formatting/formatDate";
import {regFromDn} from "../../../../../../functions/formatting/regFromDn";
import indexContext from "../../../../../contexts/indexContext";
import mainContext from "../../../../../contexts/mainContext";
import AddPlaceButton from "../../../grid/cardComponents/addPlaceButton";
import {gToKg} from "../../../../../../functions/formatting/gToKg";
import reportsContext from "../../../../../contexts/reportsContext";


const VehicleCo2ReportTableRow = ({data, index}) => {

    const {darkMode} = useContext(indexContext);
    const {theVehicle, theDeviceDetails} = useContext(reportsContext);
    const {vehicles, handleDate, handleJourney, handlePageToShow, vehicle} = useContext(mainContext);

    const [background, setBackground] = useState(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')


    useEffect(() => {
        setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')
    }, [darkMode])


    const viewJourney = (data) => {
        handleDate(new Date(data?.startTime));
        setTimeout(() => {
            handleJourney(data?._id);
            handlePageToShow('/map');

        }, 500);
    }

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '5% 10% 10% 23% 10% 23% 10% 10%',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
            textAlign: 'center'}} className="reportTable"
             onMouseEnter={() => setBackground(darkMode ? 'rgba(115,24,236, 0.3)' : 'rgba(115,24,236, 0.1)')}
             onMouseLeave={() => setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')}
             key={index}

        >
            <div style={{lineHeight: '73px',paddingLeft: '10px'}} onClick={() => viewJourney(data)}>
                {(index+1)}
            </div>

            <div style={{lineHeight: '73px',paddingLeft: '10px'}}>
                {regFromDn(vehicles, data?.dn)}
            </div>

            <div style={{lineHeight: '73px'}}>
                {formatTimeOnly(data?.startTime)}
            </div>
            <div  style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="start"/>
                <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={data?.start?.lat} lng={data?.start?.lng} />
            </div>
            <div  style={{lineHeight: '73px'}}>
                {formatTimeOnly(data?.endTime)}
            </div>
            <div  style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="finish"/>
                <AddPlaceButton fleetId={vehicle?.properties?.fleetId} lat={data?.finish?.lat} lng={data?.finish?.lng} />
            </div>

            <div style={{lineHeight: '73px'}}>
                {data?.distance.toFixed(0)}
                &nbsp;miles
            </div>
            <div  style={{lineHeight: '73px'}}>
                {theDeviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions > 0 ?
                    <React.Fragment>
                        {gToKg(((data?.distance * 1.60934) * theDeviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions).toFixed(0))}
                    </React.Fragment>
                :
                    <React.Fragment>N/A</React.Fragment>
                }
            </div>

        </div>
    )
}
export default VehicleCo2ReportTableRow
