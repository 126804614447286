import React from 'react';
import mflWaiting from '../../svgs/mfl_waiting.gif'

const LoadingSpinner = () => {
    return (
        //<img src={mflWaiting} style={{width: '80px', height: '80px'}}/>
        <div className={"loader"}/>
    )
}
export default LoadingSpinner
