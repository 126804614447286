import React, {useContext, useEffect, useState} from 'react';
import FormatAddress from "../../../../../../functions/formatting/formatAddress";
import {
    DurationFromSeconds,
    formatTimeOnly,
    fullDurationFromSeconds
} from "../../../../../../functions/formatting/formatDate";
import {regFromDn} from "../../../../../../functions/formatting/regFromDn";
import indexContext from "../../../../../contexts/indexContext";
import mainContext from "../../../../../contexts/mainContext";
import AddPlaceButton from "../../../grid/cardComponents/addPlaceButton";
import {fetchUserLevel} from "../../../../../../functions/fetchUserLevel";


const FleetIdlingReportTableRow = ({data, index}) => {

    const {darkMode} = useContext(indexContext);

    const {vehicles, handleDate, handleJourney, handlePageToShow, vehicle} = useContext(mainContext);

    const [background, setBackground] = useState(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')


    useEffect(() => {
        setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')
    }, [darkMode])


    const viewJourney = (data) => {
        // handleDate(new Date(data?.startTime));
        // setTimeout(() => {
        //     handleJourney(data?._id);
        //     handlePageToShow('/map');
        //
        // }, 500);
    }

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '20% 20% 20% 20% 20%',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
            textAlign: 'center'}} className="reportTable"
             onMouseEnter={() => setBackground(darkMode ? 'rgba(115,24,236, 0.3)' : 'rgba(115,24,236, 0.1)')}
             onMouseLeave={() => setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')}
             key={index}

        >
            <div style={{lineHeight: '73px',paddingLeft: '10px'}} onClick={() => viewJourney(data)}>
                {regFromDn(vehicles, data?.dn)}
            </div>

            <div style={{lineHeight: '73px'}}>
                {data?.journeys?.length}
            </div>

            <div style={{lineHeight: '73px'}}>
                {data?.totalDistance?.toFixed(0)}
                &nbsp;miles
            </div>
            <div  style={{lineHeight: '73px'}}>
                {fullDurationFromSeconds(data?.totalDur)}
            </div>
            <div  style={{lineHeight: '73px'}}>
                {fetchUserLevel() === 'superuser' ?
                    <span>( i-{data.totalIdleTime} v ti-{data?.totalTrueIdleTime})</span>
                :
                    <React.Fragment>
                        {data?.totalIdleTime ?
                            <React.Fragment>{fullDurationFromSeconds(data?.totalIdleTime)}</React.Fragment> :
                            <React.Fragment>N/A</React.Fragment>}
                    </React.Fragment>
                }

            </div>

        </div>
    )
}
export default FleetIdlingReportTableRow
