import fetchVideoDetails from "../../../../api/media/fetchVideoDetails";
import fetchHyperlapseDetail from "../../../../api/media/fetchHyperlapseDetail";

const videoUploadProgress = async (item) => {

    let progress = {
        stage: 'pending',
        percentage: 0,
        details: {}
    };


    if(item?.startTimeHyper){
       const hl = await fetchHyperlapseDetail(item?._id);

       progress.details = hl;

        if (hl?.plan?.length > 0){
            progress.stage = 'uploading';
            progress.percentage = (hl?.plan?.length / hl?.totalFrames) * 100;
        }


        if (progress.percentage > 50){
            progress.stage = 'processing'
        }

    } else {
        const r = await fetchVideoDetails(item.filename ? item.filename : item.file);
        progress.details = r;

        if (r?.lastFrame) {
            progress.stage = 'uploading';
            const st = new Date(r?.st).getTime();
            const lastRaw = r?.lastFrame?.date;
            const last = new Date(lastRaw.split('.')[0]).getTime();
            const secondsUploaded = (last-st)/1000;
            const uploadedPerc = (secondsUploaded/r?.clipDuration)*100;
            progress.percentage = uploadedPerc / 2 ;

        }

        if (r?.lastMp4Frame || r?.uploadComplete) {
            progress.stage = 'processing';

            if (r?.lastMp4Frame?.frames){
                progress.percentage = 50 + ((r?.lastMp4Frame?.frames / r?.totalFrames) * 100) / 2;
            } else {
                progress.percentage = 50;
            }

        }

        progress.percentage = progress?.percentage > 100 ? 100 : progress?.percentage;


        if (!r?._id){
            progress.stage = 'error';
        }

        if (r?.mp4Complete){
            progress.stage = 'processing';
            progress.percentage = 100;
        }
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    return progress;
}

export default videoUploadProgress
