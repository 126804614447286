import React, {useContext, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudArrowUp,
    faEye,
    faLocationPlus,
    faVideo,
    faX,
    faFastForward,
    faSpinner, faCircleInfo
} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import postVideoRequest from "../../../api/media/postVideoRequest";
import {formatDate, formatTimeOnly} from '../../../functions/formatting/formatDate'
import {NOTIFICATIONS_CALLBACK_URL, TOAST_DURATION, videoUploadOrder} from "../../../Constants";
import VideoBudgetMessage from "./videoBudgetMessage";
import fetchVideoBudget from "../../../api/device/fetchVideoBudget";
import VideoUploadProgressTracker from "./videoUploadProgressTracker";
import mainContext from "../../contexts/mainContext";
import {Slider} from "primereact/slider";
import fetchEventsVideoFile from "../../../api/events/fetchEventsVideoFile";
import {videoDurationOptions} from "./requestingMediaFunctions/videoDurationOptions";
import {ConfirmPopup} from "primereact/confirmpopup";
import postHyperlapseRequest from "../../../api/media/postHyperlapseRequest";
import {Message} from "primereact/message";
import {camPosFromCh} from "../../../functions/formatting/camPosFromCh";
import {Checkbox} from "primereact/checkbox";
import VideoUploadProgressTrackerV2 from "./uploadProgressTrackerV2";
import {Toast} from "primereact/toast";
import {Tooltip} from "primereact/tooltip";


const RequestVideo = ({st, deviceDetails}) => {


    const [mediaDuration, setMediaDuration] = useState({
        duration: 20,
        label: 'Video duration: 20 Seconds'
    });

    const [startTime, setStartTime] = useState(31)

    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [requestedMessage, setRequestedMessage] = useState();
    const [budget, setBudget] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const [emailChecked, setEmailChecked] = useState(true);
    const [sendingRequest, setSendingRequest] = useState(false)

    const {vehicles, pageToShow, handleRefreshNotifications, toast} = useContext(mainContext);


    const handleMediaDurationChange = (e) => {
        setMediaDuration(e)
    }

    const addRemoveCameraToReq = (v) => {

        if(setHasAllCams()){
            setCamsToReq(new Set([v]))
        } else {
            if(camsToReq.has(v)){
                camsToReq.delete(v)
                setCamsToReq(new Set([...camsToReq]));
            } else {
                setCamsToReq(new Set([...camsToReq, v]));
            }
        }
    }


    const setHasAllCams = () => {
        return JSON.stringify(camsToReq) === JSON.stringify(new Set(deviceDetails?.cameras)) && camsToReq.size === deviceDetails?.cameras?.length
    }

    const addAllCamsToReq = () => {

        if (setHasAllCams()){
            setCamsToReq(new Set([]))

        } else {
            setCamsToReq(new Set(deviceDetails?.cameras))

        }

    }


    const requestMedia = async () => {

        setSendingRequest(true);

        let requests = [];
        let toastMessages =  [];

        if (camsToReq.size > 0){
            let cams = [...camsToReq];
            const order = videoUploadOrder;

            cams.sort((a, b) => order.indexOf(a.camPosition) - order.indexOf(b.camPosition));


            const videoToRequest = {
                st: formatDate(new Date(new Date(st).getTime() + ((startTime - 31) * 1000))),
                et: formatDate(new Date(new Date(st).getTime() + ((startTime - 31) * 1000) + (mediaDuration.duration*1000))),
                dn: deviceDetails.dn,
                ch: cams[0]?.channel,
                callback: NOTIFICATIONS_CALLBACK_URL,
            };



            const r = await postVideoRequest(videoToRequest, deviceDetails, emailChecked);
            requests.push(r.data);

            if (r.status === 'ok'){
                toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[0].camPosition + ' video requested', life: TOAST_DURATION },)
            } else {
                toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[0].camPosition + ' error ' + r.error, life: TOAST_DURATION },)
            }


            setRequestedMessage({type: cams?.length === 1 ? 'Video Requested' : 'Videos Requested' , data: []});
            await new Promise(r => setTimeout(r, 2000));

            if (cams?.length > 1){
                const event = await fetchEventsVideoFile({
                    dn: deviceDetails.dn,
                    file: r?.data?.filename
                });

                for (let i = 1; i < cams?.length; i++) {
                    const subReqs = {
                        st: formatDate(new Date(new Date(st).getTime() + ((startTime - 31) * 1000))),
                        et: formatDate(new Date(new Date(st).getTime() + ((startTime - 31) * 1000) + (mediaDuration.duration*1000))),
                        dn: deviceDetails.dn,
                        ch: cams[i].channel,
                        callback: NOTIFICATIONS_CALLBACK_URL,
                        eventId: event[0]._id
                    };
                    const subReqRes = await  postVideoRequest(subReqs, deviceDetails);

                    if (r.status === 'ok'){
                        toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[i].camPosition + ' video requested', life: TOAST_DURATION },)
                    } else {
                        toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[i].camPosition + ' error ' + r.error, life: TOAST_DURATION },)
                    }

                    requests.push(subReqRes.data);
                }
            }

            setRequestedMessage({type: 'video', data: requests});

            toast?.current?.show(toastMessages);
            setSendingRequest(false);
        }
    };

    const resetForm = () => {
        setRequestedMessage(null);
        setCamsToReq(new Set([]));
        setMediaDuration(null);

    }

    const requestHyperlapse = async () => {


        let cams = [...camsToReq];

        for (let i = 0; i < cams?.length; i++) {
            const dataToSend = {
                dn: deviceDetails.dn,
                channel: cams[i].channel,
                startTimeHyper: formatDate(new Date(new Date(st).getTime() + ((startTime -31) * 1000))),
                durationMinutes: Math.floor(mediaDuration.duration / 60),
                totalFrames: mediaDuration.duration,
                callback: NOTIFICATIONS_CALLBACK_URL
            };

           await postHyperlapseRequest(dataToSend, deviceDetails, emailChecked);

        }
        setShowConfirm(false);
        setRequestedMessage({type: 'Hyperlapse Requested'});
        toast.current.show({ severity: 'success', summary: 'All done', detail: 'Hyperlapse requested', life: TOAST_DURATION })
        setTimeout(() => {
            setRequestedMessage(null)
        }, 2000)

    }

    useEffect(() => {
        if(deviceDetails){
            fetchVideoBudget({dn: deviceDetails?.dn}).then(r => {
                setBudget(r);
            });
            const front = deviceDetails?.cameras.filter(cam => cam.camPosition === 'Front');
            setCamsToReq(new Set(front))
        }
    }, [deviceDetails]);


    const ConfirmFooter = () => {
        return (
            <div style={{display: 'flex', padding: '0px 1.5rem 1.5rem 1.5rem', justifyContent: 'space-between'}}>
                <button className="p-button p-button-sm" style={{background: 'rgba(92,132,89,0.95)', color: 'white'}}
                        onClick={requestMedia}>
                    <FontAwesomeIcon icon={faVideo} />
                    &nbsp;Video
                </button>
                <button className="p-button p-button-sm" style={{background: 'rgba(92,132,89,0.95)', color: 'white'}}
                        onClick={requestHyperlapse}>
                    <FontAwesomeIcon icon={faFastForward} />
                    &nbsp;Hyperlapse
                </button>
                <button className="p-button p-button-sm" style={{background: 'rgba(255, 0, 36, .9)', color: 'white'}}
                        onClick={() => setShowConfirm(false)}>
                    <FontAwesomeIcon icon={faX} />
                    &nbsp;Cancel Upload
                </button>
            </div>
        )
    }



    return (
        <div style={{textAlign: 'center', padding: '5px'}}>


            {requestedMessage?.type !== 'video' &&
              <React.Fragment>
                  {/*<div style={{marginBottom: '20px', lineHeight: '20px', marginTop: '20px', fontSize: '12px'}}>*/}
                  {/*   To create a new event and upload video, select the camera view(s) and duration required and click upload.*/}
                  {/*    The start time of the video can be finely adjusted using the slider. You can monitor the progress of your*/}
                  {/*    upload here or check your notification Inbox at any time.*/}
                  {/*</div>*/}




                  {deviceDetails?.cameras.length > 1 &&
                      <Button onClick={() => addAllCamsToReq()} style={{marginRight: '5px', marginBottom: '5px'}}
                              className={setHasAllCams() ?
                                  'p-button' : 'p-button p-button-secondary'}
                              tooltip={'All cameras'} tooltipOptions={{position: 'bottom'}}>
                          <span className="p-button-label">All</span>

                      </Button>
                  }

                  {deviceDetails?.cameras.map(function (cam, index){
                      return (
                          <React.Fragment>
                              {cam?.isRec === '1' &&
                                  <div key={index} style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
                                      <Button onClick={() => addRemoveCameraToReq(cam)} style={{marginTop: '5px'}}
                                              className={(camsToReq.has(cam)) ? 'p-button' : 'p-button p-button-secondary'}
                                              tooltip={'Channel: ' + cam.channel} tooltipOptions={{position: 'bottom'}}
                                      >
                                          <span className="p-button-label">{cam.camPosition}</span>
                                      </Button>
                                  </div>
                              }
                          </React.Fragment>
                      )
                  })}

                  <br /><br />



                  <Dropdown value={mediaDuration} options={videoDurationOptions(deviceDetails)} onChange={(e) => handleMediaDurationChange(e.value)}
                            placeholder="Select duration" optionLabel="label" style={{width: '80%', height: '40px', lineHeight: '30px'}}/>

                  <br />
                  <br />


                  {`Adjust start time: ${startTime - 31} seconds`}

                  <br /><br />

                  <Slider value={startTime} step={1}  min={1} max={61} style={{width: '80%', margin: '0 auto', marginTop: '5px'}}
                          onChange={(e) => setStartTime(e.value)}/>


                  <br /><br />
                  <div>
                      <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
                      <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
                  </div>

                  <br /><br />

                  {/*<div style={{display: 'inline-block', marginBottom: '5px'}}>*/}
                  {/*    <h4>Upload summary:</h4>*/}
                  {/*    Start time: {formatTimeOnly(new Date(new Date(st).getTime() + (startTime * 1000)))}<br />*/}
                  {/*    End time: {formatTimeOnly(new Date(new Date(st).getTime() + (startTime * 1000) + (mediaDuration.duration*1000)))} <br />*/}
                  {/*    Duration: {mediaDuration.label.substring(15, mediaDuration.label.length)}*/}
                  {/*</div>*/}

                  {/*{!requestedMessage ?*/}
                  {/*    <button onClick={async () => {*/}
                  {/*        if (mediaDuration.duration >= 120){*/}
                  {/*            setShowConfirm(true);*/}
                  {/*        } else {*/}
                  {/*            await requestMedia();*/}
                  {/*        }}} className="p-button" disabled={!mediaDuration || camsToReq.size < 1}*/}
                  {/*            style={{width: '80%',  background: '#5c8459', color: 'white', marginTop: '10px'}}>*/}
                  {/*      <span className="p-button-label">*/}
                  {/*          <FontAwesomeIcon icon={faCloudArrowUp}/>*/}
                  {/*          &nbsp;Upload video*/}
                  {/*      </span>*/}
                  {/*    </button>*/}
                  {/*:*/}
                  {/*  <Message  style={{width: '80%'}} severity="success" text={requestedMessage.type} />*/}
                  {/*}*/}

                  <div>



                  {camsToReq?.size > 0 ?
                      <div  style={{width:'80%', margin: '0 auto'}}>
                          <VideoBudgetMessage budget={budget} camsToReq={camsToReq} duration={mediaDuration?.duration}/>
                      </div>
                  :
                      <Message severity="warn" text="Please select camera(s)" style={{width:'80%'}} />
                  }
                  </div>

                  <button onClick={async () => {
                      if (mediaDuration.duration >= 120){
                          setShowConfirm(true);
                      } else {
                          await requestMedia();
                      }}} className="p-button" disabled={!mediaDuration || camsToReq.size < 1 || sendingRequest ||
                      ((budget?.totalFleetBudget - budget?.usedSeconds) < (mediaDuration?.duration)*camsToReq.size)}
                          style={{width: '80%', marginTop: '10px'}}>



                      {sendingRequest ?
                          <span className="p-button-label">
                            <FontAwesomeIcon icon={faSpinner} spin/>
                              &nbsp;Sending request
                          </span>
                      :
                          <span className="p-button-label">
                            <FontAwesomeIcon icon={faCloudArrowUp}/>
                              &nbsp;Upload video
                          </span>
                      }

                  </button>

                  <div style={{textAlign: 'center', marginTop: '20px'}} className="videoUploadFromTrackHelp" data-pr-position="top">
                      <FontAwesomeIcon icon={faCircleInfo} />
                      &nbsp;Help
                  </div>

                  <Tooltip target=".videoUploadFromTrackHelp">
                      <div style={{width: '250px'}}>
                          To create a new event and upload video, select the camera view(s) and duration required and click upload.<br/><br/>
                          The start time of the video can be finely adjusted using the slider.

                          <br/><br/>You can monitor the progress of your upload here or check your notification Inbox at any time.
                      </div>
                  </Tooltip>



                  <ConfirmPopup visible={showConfirm} onHide={() => setShowConfirm(false)} style={{width: '35vw', fontSize: '14px'}}
                                message="Video clips over 2 minutes can take a long time to upload.
                                Hyperlapse videos upload much faster and can be used to find incidents quickly.
                                Would you like to upload a hyperlapse video first?"
                                icon="pi pi-exclamation-triangle" footer={ConfirmFooter} className="confirmPopupWarn"/>



              </React.Fragment>
            }


            {requestedMessage?.type === 'video' &&
                <React.Fragment>
                    {requestedMessage.data.map(req => (
                        <div style={{marginTop: '20%'}}>
                            {camPosFromCh(deviceDetails, req?.ch)}
                            <VideoUploadProgressTrackerV2 upload={req} vehicles={vehicles} page="trackModal" handleRefreshNotifications={handleRefreshNotifications}/>
                        </div>
                    ))}
                </React.Fragment>
            }




        </div>
    )
}

export default RequestVideo
