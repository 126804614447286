import React from 'react';
import {Tooltip} from "primereact/tooltip";

const HardDiskStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89"
                 style={{height, width: height, verticalAlign: 'middle'}}
                 className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path
                    d="M75.949 70.266C67.099 70.266 59.949 77.416 59.949 86.266L59.949 170.566c4.7-2.7 10.15-4.3 16-4.3L267.949 166.266c5.85 0 11.3 1.55 16 4.3L283.949 86.266c0-8.85-7.15-16-16-16L75.949 70.266zM59.949 198.266l0 48c0 8.85 7.15 16 16 16L267.949 262.266c8.85 0 16-7.15 16-16L283.949 198.266c0-8.85-7.15-16-16-16L75.949 182.266c-8.85 0-16 7.15-16 16zM43.949 198.266 43.949 86.266C43.949 68.616 58.299 54.266 75.949 54.266L267.949 54.266c17.65 0 32 14.35 32 32L299.949 198.266l0 48c0 17.65-14.35 32-32 32L75.949 278.266c-17.65 0-32-14.35-32-32L43.949 198.266zm160 12a12 12 0 1 1 0 24 12 12 0 1 1 0-24zm36 12a12 12 0 1 1 24 0 12 12 0 1 1-24 0z"
                    style={{fill: 'var(--text-color)', strokeWidth: '0px'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill: 'none'}}/>
                </g>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Severe
                    - {event?.notificationDetails.title}</Tooltip>
            }
        </div>
    )
}
export default HardDiskStacked
