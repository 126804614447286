import React, {useEffect, useState, useContext} from 'react';
import {Dialog} from "primereact/dialog";
import VehicleStoppedModal from "./vehicleStoppedModal";
import EventModal from "./eventModal/eventModal";
import TrackModal from "./trackModal/trackModal";
import LivestreamModal from "../livestreaming/livestreamModal";
import DeviceDetailsModal from "./deviceDetailsModal/deviceDetailsModal";
import ChunkedVideoPlayer from "../viewMedia/chunkedVideoPlayer";
import indexContext from "../../contexts/indexContext";
import {formatDateMediaGrid, formatDateModalHeader, getDaysDifference} from "../../../functions/formatting/formatDate";
import {MANAGEMENT_PLATFORM} from "../../../Constants";
import {regFromDn} from "../../../functions/formatting/regFromDn";
import mainContext from "../../contexts/mainContext";
import {ProgressSpinner} from "primereact/progressspinner";
import {faBuildings, faTrash, faTrashSlash, faRefresh, faX, faStar} from "@fortawesome/pro-regular-svg-icons";
import {faStar as faSolidStar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VideoPlayerDesktop from "../viewMedia/videoPlayerDesktop";
import {fleetFromDn} from "../../../functions/formatting/fleetFromDn";
import {friendlyFleetName} from "../../../functions/formatting/friendlyFleetName";
import VideoPlayerModal from "./videoPlayerModal/videoPlayerModal";
const MainModalParent = ({}) => {

    const {vehicle, deviceDetails, closeModal, displayStoppedClickModal, displayVideoModal,
        videoToShow, trueLocation, vehicles, displayLivestreamModal} = useContext(mainContext);

    const {winWidth, fleets} = useContext(indexContext);







    return (
        <div>

            {vehicle &&
                <div>

                    {/*VEHICLE STOPPED MODAL*/}

                    <Dialog visible={displayStoppedClickModal} position={'right'} style={{width: winWidth >= 800 ? '30vw' : '90vw',
                        height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}}
                            onHide={() => closeModal('displayStoppedClickModal')}
                            modal={false}>
                        <div style={{marginTop: '-50px', position: 'absolute', fontWeight: 'bold', fontSize: '16px', width: '250px'}}>
                            Vehicle Parked

                            <div style={{fontWeight: 'normal'}}>
                                {formatDateModalHeader(trueLocation?.finish?.at)}
                            </div>
                        </div>
                        <VehicleStoppedModal />
                    </Dialog>




                </div>

            }


            {/*LIVESTREAM MODAL*/}

            <Dialog visible={displayLivestreamModal} closable={false} style={{width: winWidth >= 800 ? '50vw' : '90vw', height: winWidth >= 800 ? '90%' : '100%'}}
                    onHide={() => closeModal('displayLivestreamModal')}>


                <div style={{fontWeight: 'bold', fontSize: '16px', marginTop: '-15px', position: 'absolute'}}>
                    LiveCam - {vehicle?.properties?.registration}

                </div>

                <div style={{marginTop: '15px'}}>
                    <LivestreamModal vehicle={vehicle} deviceDetails={deviceDetails} closeModal={closeModal}/>

                </div>
            </Dialog>

            {/*VIDEO PLAYER MODAL*/}

            <Dialog visible={displayVideoModal} style={{width: winWidth >= 800 ? '55vw' : '100vw',
                height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}}
                    onHide={() => closeModal('displayVideoModal')} >
                <VideoPlayerModal />

            </Dialog>

        </div>
    )
}

export default MainModalParent
