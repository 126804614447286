import React, {useContext, useState} from 'react';
import ReportsDatePicker from "../../panelContent/reports/reportsDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faPrint} from "@fortawesome/pro-regular-svg-icons";

import './reportsHeader.css';
import ReportTypeDropdown from "./reportTypeDropdown";
import {InputSwitch} from "primereact/inputswitch";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import {Dropdown} from "primereact/dropdown";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";


const ReportsHeader = ({handleReportType, handleDateRange, dateRange, reportType, handlePrint, showKpi, handleShowKpi,
                       theFleet, theVehicle}) => {

    const Title = {
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px'
    }

    const TitleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        height: '46px',
        background: 'var(--surface-ground)'
    }
    const buttonStyle = {
        width: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        background: 'var(--v-list-button)',
        height: '38.75px',
        marginRight: '10px',
        borderRadius: '2px',
        padding: '1rem 0.5rem',
        color: 'var(--v-list-button-text)'
    }

    const buttonStyleSelected = {
        width: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--v-list-button-selected)',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: 'var(--v-list-button-text-selected)',
        height: '38.75px',
        marginRight: '10px',
        borderRadius: '2px',
        padding: '1rem 0.5rem'
    }



    return(
        <div style={TitleContainer}>
            <div style={Title}>
                {reportType ?
                    <span>{reportType.display} Report</span>
                :
                    <span>Reports Menu</span>
                }
                {theFleet && <span>&nbsp;- {theFleet?.fleetName}</span>}
                {theVehicle && <span>&nbsp;- {theVehicle?.properties?.registration}</span>}

            </div>

            {reportType &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '16px'}}>


                    {reportType &&
                        <div style={{background: "var(--v-list-button)", color: "var(--v-list-button-text)", cursor: 'pointer', alignItems: 'center', padding: '1rem 0.5rem',
                            display: 'flex', justifyContent: 'center', height: '38.67px', borderRadius: '2px', marginRight: '10px'}}
                             onClick={() => {
                                 handleReportType(null);
                             }}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            &nbsp;Back
                        </div>
                    }


                    {/*<ReportsDatePicker handleDateRange={handleDateRange} dateRange={dateRange} />*/}



                    {/*/!*<ReportTypeDropdown handleReportType={handleReportType} reportType={reportType} reportTypeOptions={reportTypeOptions}/>*!/*/}
                    {/*{reportType?.type?.includes('fleet') &&*/}
                    {/*    <Dropdown options={fleets} optionLabel="fleetName"*/}
                    {/*              style={{height: '46px', width: '202px', border: 'none', borderRadius: '0px', lineHeight: '35px', marginRight: '10px'}}*/}
                    {/*              placeholder="Select fleet"  value={theFleet}*/}
                    {/*              onChange={(e) => {*/}
                    {/*                  setTheFleet(e.value)*/}
                    {/*                  handleFleetsToShow(new Set([e.value.fleetId]))*/}
                    {/*              }}*/}
                    {/*    />*/}

                    {/*}*/}

                    {/*{reportType?.type?.includes('vehicle') &&*/}
                    {/*    <Dropdown options={vehicles?.features} optionLabel="properties.registration"*/}
                    {/*              style={{height: '46px', width: '202px', border: 'none', borderRadius: '0px', lineHeight: '35px', marginRight: '10px'}}*/}
                    {/*              placeholder="Select vehicle"  value={vehicle}*/}
                    {/*              onChange={(e) => handleVehicle(e.value)}*/}
                    {/*    />*/}

                    {/*}*/}





                    {/*<div style={{paddingTop: '10px', marginRight: '10px'}}>*/}
                    {/*    <span style={{paddingRight: '5px'}}>View KPI's</span>*/}
                    {/*    <InputSwitch checked={showKpi} onChange={() => handleShowKpi()} />*/}
                    {/*</div>*/}

                    <div style={showKpi ? buttonStyleSelected : buttonStyle}
                         onClick={() => handleShowKpi()}>

                        {showKpi ?
                            <React.Fragment>Showing KPI's</React.Fragment>
                            :
                            <React.Fragment>Show KPI's</React.Fragment>
                        }

                    </div>



                    {/*<div style={{paddingTop: '9px', marginleft: '5px'}}>*/}
                    {/*    <button onClick={() => handlePrint()} className="p-button p-button-sm" style={{background: 'rgb(92, 132, 89)', color: 'white'}}>*/}
                    {/*        <FontAwesomeIcon icon={faPrint} />*/}
                    {/*        &nbsp;Print*/}
                    {/*    </button>*/}
                    {/*</div>*/}


                    <div style={{background: "var(--v-list-button)", color: "var(--v-list-button-text)", cursor: 'pointer', alignItems: 'center', padding: '1rem 0.5rem',
                        display: 'flex', justifyContent: 'center', height: '38.75px', borderRadius: '2px'}}
                         onClick={() => handlePrint()}>
                        <FontAwesomeIcon icon={faPrint} />
                        &nbsp;Print
                    </div>


                </div>

            }

        </div>
    )




}
export default ReportsHeader
