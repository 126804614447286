import React from 'react';

export const MflLogo = ({size, textColor = 'black', iconColor = 'rgb(115, 24, 236)'}) => {
        return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505.3 93.05" x="0" y="0"
                style={{width: size}}  onClick={() => window.location.reload()}>
                  <g>
                    <path d="m46.52,37.21c5.14,0,9.31,4.17,9.31,9.31s-4.17,9.31-9.31,9.31-9.3-4.17-9.3-9.31,4.17-9.31,9.3-9.31Zm-18.61,18.61c-5.14,0-9.3-4.17-9.3-9.31s4.17-9.31,9.3-9.31,9.31-4.17,9.31-9.3,4.17-9.31,9.31-9.31,9.3-4.17,9.3-9.31S51.66,0,46.53,0s-9.31,4.17-9.31,9.3-4.17,9.31-9.31,9.31-9.3,4.17-9.3,9.31-4.17,9.3-9.31,9.3S0,41.38,0,46.52s4.17,9.31,9.31,9.31,9.31,4.16,9.31,9.3,4.17,9.31,9.3,9.31,9.31-4.17,9.31-9.31-4.17-9.3-9.31-9.3h-.01Zm55.83-18.61c-5.14,0-9.31-4.17-9.31-9.3s-4.16-9.31-9.3-9.31-9.31,4.17-9.31,9.31,4.17,9.3,9.31,9.3,9.3,4.17,9.3,9.31-4.16,9.31-9.3,9.31-9.31,4.16-9.31,9.3-4.17,9.31-9.3,9.31-9.31,4.17-9.31,9.3,4.17,9.31,9.31,9.31,9.3-4.17,9.3-9.31,4.17-9.3,9.31-9.3,9.3-4.17,9.3-9.31,4.17-9.3,9.31-9.3,9.31-4.17,9.31-9.31-4.17-9.31-9.31-9.31Z" style={{fill: iconColor}}/>
                    <path d="m164.47,44.52c0-6.23-3.39-9.43-8.51-9.43s-8.57,3.21-8.57,9.43v18.99h-6.97v-18.99c0-6.23-3.39-9.43-8.51-9.43s-8.57,3.21-8.57,9.43v18.99h-7.03V29.53h7.03v3.89c2.28-2.78,5.98-4.44,10.05-4.44,5.37,0,9.87,2.28,12.21,6.72,2.1-4.13,6.91-6.72,11.84-6.72,7.96,0,14,5,14,14.49v20.04h-6.97v-18.99h0Z" style={{fill: textColor}}/>
                    <path d="m200.97,29.53h7.28l-20.84,49.95h-7.28l6.91-16.53-13.38-33.42h7.83l9.56,25.9,9.93-25.9h-.01Z" style={{fill: textColor}}/>
                    <path d="m216.85,35.27h-4.01v-5.74h4.01v-2.4c0-7.83,4.01-11.22,12.89-11.22v5.86c-4.32,0-5.8,1.48-5.8,5.37v2.4h6.29v5.74h-6.29v28.24h-7.09v-28.24h0Z" style={{fill: textColor}}/>
                    <path d="m234.68,17.88h7.03v45.63h-7.03V17.88Z" style={{fill: textColor}}/>
                    <path d="m264.59,64.06c-9.74,0-16.96-6.91-16.96-17.58s6.91-17.51,16.96-17.51,16.53,6.72,16.53,16.71c0,1.17-.06,2.28-.25,3.45h-25.96c.49,5.61,4.5,9,9.68,9,4.32,0,6.72-2.1,8.02-4.75h7.59c-1.91,5.92-7.28,10.67-15.6,10.67h0Zm-9.62-20.6h18.81c-.12-5.24-4.26-8.57-9.5-8.57-4.75,0-8.57,3.21-9.31,8.57Z" style={{fill: textColor}}/>
                    <path d="m301.99,64.06c-9.74,0-16.96-6.91-16.96-17.58s6.91-17.51,16.96-17.51,16.53,6.72,16.53,16.71c0,1.17-.06,2.28-.25,3.45h-25.96c.49,5.61,4.5,9,9.68,9,4.32,0,6.72-2.1,8.02-4.75h7.59c-1.91,5.92-7.28,10.67-15.6,10.67h0Zm-9.62-20.6h18.81c-.12-5.24-4.26-8.57-9.5-8.57-4.75,0-8.57,3.21-9.31,8.57Z" style={{fill: textColor}}/>
                    <path d="m324.9,35.27h-4.01v-5.74h4.01v-8.45h7.09v8.45h8.26v5.74h-8.26v18.81c0,2.53.99,3.58,3.95,3.58h4.32v5.86h-5.55c-6.04,0-9.81-2.53-9.81-9.44v-18.81h0Z" style={{fill: textColor}}/>
                    <path d="m349.05,17.88h7.03v45.63h-7.03V17.88Z" style={{fill: textColor}}/>
                    <path d="m363.11,20.53c0-2.53,1.97-4.5,4.5-4.5s4.44,1.97,4.44,4.5-1.97,4.5-4.44,4.5-4.5-1.97-4.5-4.5Zm.92,9h7.03v33.98h-7.03V29.53Z" style={{fill: textColor}}/>
                    <path d="m382.55,29.53l9.62,27.69,9.62-27.69h7.46l-12.95,33.98h-8.39l-12.89-33.98h7.53Z" style={{fill: textColor}}/>
                    <path d="m426.79,64.06c-9.74,0-16.96-6.91-16.96-17.58s6.91-17.51,16.96-17.51,16.53,6.72,16.53,16.71c0,1.17-.06,2.28-.25,3.45h-25.96c.49,5.61,4.5,9,9.68,9,4.32,0,6.72-2.1,8.02-4.75h7.59c-1.91,5.92-7.28,10.67-15.6,10.67h0Zm-9.62-20.6h18.81c-.12-5.24-4.26-8.57-9.5-8.57-4.75,0-8.57,3.21-9.31,8.57Z" style={{fill: textColor}}/>
                    <path d="m451.71,54.06c1.33,0,2.47.47,3.42,1.4s1.43,2.08,1.43,3.46c0,1.29-.48,2.39-1.43,3.32-.95.93-2.09,1.4-3.42,1.4s-2.39-.46-3.32-1.4c-.93-.93-1.4-2.04-1.4-3.32,0-1.37.46-2.53,1.4-3.46.93-.93,2.04-1.4,3.32-1.4Z" style={{fill: textColor}}/>
                    <path d="m475.04,28.93c4.43,0,7.81,1.15,10.14,3.46,2.33,2.3,3.49,5.65,3.49,10.04v21.2h-7.51v-3.06c-1.2,1.15-2.56,2.06-4.09,2.72-1.53.67-3.25,1-5.15,1-3.41,0-6.16-.86-8.24-2.59s-3.12-4.19-3.12-7.38c0-1.82.32-3.36.96-4.62.64-1.26,1.53-2.3,2.66-3.12s2.46-1.43,3.99-1.83,3.16-.64,4.89-.73l8.11-.46v-1c0-2.22-.48-3.96-1.43-5.22-.95-1.26-2.49-1.89-4.62-1.89s-3.68.58-4.65,1.73c-.98,1.15-1.46,2.5-1.46,4.05l-7.51-.27c0-1.51.31-2.98.93-4.42s1.51-2.73,2.66-3.86c1.15-1.13,2.57-2.04,4.25-2.72,1.68-.69,3.59-1.03,5.72-1.03h-.02Zm-.93,20.87c-1.77.09-3.22.46-4.35,1.1s-1.69,1.74-1.69,3.29c0,1.42.48,2.42,1.43,2.99.95.58,2.18.86,3.69.86,2.39,0,4.33-.6,5.82-1.79,1.48-1.2,2.23-3.1,2.23-5.72v-1.2l-7.11.46h-.02Z" style={{fill: textColor}}/>
                    <path d="m496.36,20.53c0-2.53,1.97-4.5,4.5-4.5s4.44,1.97,4.44,4.5-1.97,4.5-4.44,4.5-4.5-1.97-4.5-4.5Zm.92,9h7.03v33.98h-7.03V29.53Z" style={{fill: textColor}}/>
                  </g>
                </svg>
        )
        }

export default MflLogo
