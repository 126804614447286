import React, {useEffect} from 'react';
import {Calendar} from "primereact/calendar";
import {formatDateOnly} from "../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";

const ReportsDatePicker = ({handleDateRange, dateRange}) => {

    let min = new Date();
    min.setDate(min.getDate() - 90)
    let max = new Date();

    useEffect(() => {



        if(!dateRange) {

            let start = new Date();
            let end = new Date(start.setDate(start.getDate() - 1));
            const theDay = start.getDay();

            if (theDay === 0){
                handleDateRange([new Date(start.setDate(end.getDate() - 7)), end]);
            } else {
                const theOffset = 6 + (theDay);
                start = new Date(start.setDate(start.getDate() - theOffset));
                const copy = new Date(start.getTime())
                end = copy.setDate(copy.getDate() + 4);
                handleDateRange([new Date(start), new Date(end)]);
            }
        }
    }, [])




    const handleCalendarButtons = (type) => {
        const today = new Date();
        const yesterday = new Date(today.setDate(today.getDate() - 1));

        switch (type){
            case 'today':
                handleDateRange([new Date(), new Date()]);
                return;
            case 'yesterday':

                handleDateRange([new Date(yesterday.setHours(0)), new Date(yesterday.setHours(23))]);
                return;
            case '7Days':
                handleDateRange([new Date(today.setDate(today.getDate() - 6)), yesterday]);
                return;
            case '30Days':
                handleDateRange([new Date(today.setDate(today.getDate() - 30)), yesterday]);
                return;
            case 'month':
                const month = today.getMonth() - 1;
                handleDateRange([new Date(today.getFullYear(), month, 1), new Date(today.getFullYear(), month + 1, 0)])
                return;
            case '3Month':
                const threeMonth = today.getMonth() - 3;
                handleDateRange([new Date(today.getFullYear(), threeMonth, 1), new Date(today.getFullYear(), threeMonth + 3, 0)])
                return;
            default:
                handleDateRange(null);
                return;
        }
    }



    const CalendarFooter = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <button onClick={() => handleCalendarButtons('today')} className="p-button p-button-secondary p-button-sm">
                    Today
                </button>
                <button onClick={() => handleCalendarButtons('yesterday')} className="p-button p-button-secondary p-button-sm">
                    Yesterday
                </button>
                <button onClick={() => handleCalendarButtons('7Days')} className="p-button p-button-secondary p-button-sm">
                    Last 7 days
                </button>
                <button onClick={() => handleCalendarButtons('month')} className="p-button p-button-secondary p-button-sm">
                    Last month
                </button>
                {/*<button onClick={() => handleCalendarButtons('30Days')} className="p-button p-button-secondary p-button-sm">*/}
                {/*    Last 30 days*/}
                {/*</button>*/}

               <div style={{width: '100%'}} />

                {/*<button onClick={() => handleCalendarButtons('month')} className="p-button p-button-secondary p-button-sm">*/}
                {/*    Last month*/}
                {/*</button>*/}
                {/*<button onClick={() => handleCalendarButtons('3Month')} className="p-button p-button-secondary p-button-sm">*/}
                {/*    Last 3 months*/}
                {/*</button>*/}
                <button onClick={() => handleCalendarButtons('clear')} className="p-button p-button-secondary p-button-sm">
                    Clear
                </button>
            </div>
        )
    }




    return (
        <div style={{position: 'relative', height: '46px', marginRight: '10px'}}>
            <Calendar id="range" value={dateRange} onChange={(e) => handleDateRange(e.value)}
                      selectionMode="range" readOnlyInput footerTemplate={CalendarFooter} maxDate={new Date()}
                      dateFormat="dd/mm/yy" showIcon className="reportDatePicker" minDate={min} maxDate={max}
                      style={{borderRadius: '0px', border: 'none', height: '100%'}} locale="en_gb" />

            <div style={{position:'absolute', background: 'var(--surface-card)', width: '98%', height: '46px', top:0, left:5,
                pointerEvents: 'none', lineHeight: '46px'}}>


                {dateRange?.[0] && dateRange?.[1] ?
                    <span>
                        {formatDateOnly(dateRange?.[0])} - {formatDateOnly(dateRange?.[1])}
                        <FontAwesomeIcon icon={faCalendar} style={{marginLeft: '15px'}}/>
                    </span>
                :
                    <span>
                        Select date range
                        <FontAwesomeIcon icon={faCalendar} style={{marginLeft: '64px'}}/>
                    </span>
                }

            </div>
        </div>
    )
}

export default ReportsDatePicker
