import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../reportFunctions/fetchActivityData";
import {formatDateOnly, formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import LoadingSpinner from "../../../loadingSpinner";
import VehicleCo2ReportSummary from "./vehicle/vehicleCo2ReportSummary";
import VehicleCo2ReportTable from "./vehicle/vehicleCo2ReportTable";
import FleetCo2ReportTable from "./fleet/FleetCo2ReportTable";
import FleetCo2ReportSummary from "./fleet/fleetCo2ReportSummary";
import DeviceDetailsModalParent from "../../../modals/deviceDetailsModal/deviceDetailsModalParent";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import indexContext from "../../../../contexts/indexContext";
import ReportsParameterSelection from "../reportsParameterSelection";
import reportsContext from "../../../../contexts/reportsContext";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";



const CO2Report = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {

    const {vehicles, openModal} = useContext(mainContext);
    const {fleets} = useContext(indexContext);
    const {theVehicle, theFleet, handleTheDeviceDetails, theDeviceDetails} = useContext(reportsContext);

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [showReport, setShowReport] = useState(false);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }



    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'journeys': return totalValue + item?.journeys?.length;
                case 'startTime':
                    const st = new Date(item?.firstJourney?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  item?.firstJourney ? totalValue +  stMins : totalValue + 0;
                case 'endTime':
                    const et = new Date(item?.lastJourney?.endTime);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  item?.lastJourney ? totalValue +  etMins : totalValue + 0;
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = () => {

        if (theVehicle || theFleet){
            fetchActivityData(dateRange, theVehicle, theFleet, vehicles).then(r => {
                setActivityData(r);
                let total


                if (theVehicle){
                    total = {
                        journeys: accumulator(r, 'journeys'),
                        distance : accumulator(r, 'totalDistance'),
                        driving: accumulator(r, 'totalDur'),
                        stopped: accumulator(r, 'totalStopped'),
                        startTime : accumulator(r, 'startTime') * 60,
                        endTime : accumulator(r, 'endTime') * 60
                    }
                } else {
                    let distance = 0;
                    let co2 = 0;
                    let journeys = 0;


                    r?.forEach(day => {
                        day.vData.forEach(v => {
                            journeys += v.journeys?.length;
                            distance += v?.totalDistance;
                            co2 += ((v?.totalDistance * 1.60934) * v?.co2Per)
                        });
                    })


                    total = {
                        journeys,
                        distance,
                        co2
                    }
                }

                setActTotals(total);

                const cache = {
                    actData: r,
                    totals: total,
                    dateRange: JSON.stringify(dateRange)
                }

                if (theVehicle){
                    cache.dn = theVehicle?.properties.dn
                } else {
                    cache.fleet = theFleet
                }



                localStorage.setItem('co2Report', JSON.stringify(cache));
                handleReportLoading(false);
            });
            setShowReport(true)
        } else {
            setShowReport(false)
        }

    }

    useEffect(async () => {



        if (localStorage.getItem('co2Report')){
            handleReportLoading(false);
            setShowReport(true)
            const cache = JSON.parse(localStorage.getItem('co2Report'));


            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }
            } else {
                if (generateReport) {
                    let details = await fetchDeviceDetails((theVehicle))

                    console.log(details)
                    handleTheDeviceDetails(details[0]);

                    if (!details[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions) {
                        setShowReport(false);
                    } else {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }
            }
        } else {

            if(dateRange && (theVehicle || theFleet) && generateReport){
                let details = await fetchDeviceDetails((theVehicle))

                handleTheDeviceDetails(details[0]);

                if (!details[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions) {
                    setShowReport(false);
                } else {
                    handleReportLoading(true);
                    generateReportData();
                }
            } else {
                setShowReport(true)
            }
        }

    }, [dateRange, theVehicle, theFleet, generateReport]);



    return (
        <React.Fragment>
            {showReport ?
                <React.Fragment>
                    {generateReport ?
                        <React.Fragment>
                            {reportLoading ?
                                // <ProgressSpinner />
                                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                            'translate(-50%, -50%)'}}>
                                        <LoadingSpinner />
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div style={{height: '100%', width:'100%', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}
                                         className={reportToPrint === 'co2' ? "section-to-print" : ''}>
                                        <div className="hideNormalShowPrint">
                                            <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>
                                                    <h1>
                                                        Co2 report
                                                    </h1>
                                                    {theVehicle &&
                                                        <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                            <b>Driver:</b>&nbsp;
                                                            {(theVehicle?.properties?.driver && theVehicle?.properties.driver.length > 0) ?
                                                                <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                                                :
                                                                <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                                            }
                                                            <br />

                                                            <b>Date range:</b>&nbsp;
                                                            {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                            &nbsp;->&nbsp;
                                                            {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                                        </div>
                                                    }

                                                    {theFleet &&
                                                        <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                            <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet, fleets)}
                                                            <br />

                                                            <b>Date range:</b>&nbsp;
                                                            {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                            &nbsp;->&nbsp;
                                                            {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                                        </div>
                                                    }

                                                </div>
                                                <div>
                                                    <MflLogo style={{fill:Colours.logoTextColour}} size='180px' textColor={'black'}/>
                                                    <div  style={{marginTop: '25px',fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                        <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                        <br />
                                                        <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                                    </div>
                                                </div>
                                            </div>

                                            {showKpi && theVehicle &&
                                                <VehicleCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                            }

                                            {showKpi && theFleet && !theVehicle &&
                                                <FleetCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                            }


                                        </div>

                                        <div className="hideOnPrint">
                                            {showKpi && theVehicle &&
                                                <VehicleCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                            }

                                            {showKpi && theFleet && !theVehicle &&
                                                <FleetCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                            }


                                            {theVehicle &&
                                                <div style={{fontSize:"1.3em", lineHeight: "25px", padding:"20px 0px 20px 0px"}}>
                                                    <b>Date range:</b>&nbsp;
                                                    {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                    &nbsp;->&nbsp;
                                                    {formatDateTimesheetReport(activityData?.[activityData.length -1]?.date)}
                                                    <br />
                                                    <b>Driver:</b>&nbsp;
                                                    {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                                        <span>{theVehicle.properties.driver}  ({theVehicle.properties.registration})</span>
                                                        :
                                                        <span>No driver assigned ({theVehicle.properties.registration})</span>
                                                    }
                                                </div>
                                            }
                                        </div>





                                        <div style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}} className="scroller">
                                            {theVehicle ?
                                                <React.Fragment>
                                                    {activityData?.map(item =>  <VehicleCo2ReportTable data={item} />) }
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {activityData?.map(item =>  <FleetCo2ReportTable data={item} />) }
                                                </React.Fragment>
                                            }
                                        </div>






                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <div style={{padding: '40px 10px 10px 10px',width: '100%',height: '100%',position: 'relative'}}>
                            <div style={{fontWeight: 'bold',fontSize: '18px',position: 'absolute',textAlign: 'center',
                                top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
                                Please select a vehicle and timespan

                                <br />


                                <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                            </div>
                        </div>

                    }
                </React.Fragment>
            :
                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                            'translate(-50%, -50%)', textAlign: 'center'}}>
                       There is no Co2 data currently for the vehicle selected. Please open the diagnsotics
                        and settings window for this vehicle, and this data can be entered under the vehicle tab<br/><br/>
                        {/*<button style={{marginTop: '10px'}} className="p-button p-button-sm" onClick={() => openModal('displayDeviceDetailsModal')}>Diagnostics and settings</button>*/}
                    </div>
                </div>
            }

            {/*<DeviceDetailsModalParent />*/}

        </React.Fragment>
    )
}
export default CO2Report

