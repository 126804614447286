import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import TimesheetReportTable from "./timesheetReportTable";
import {fetchActivityData} from "../reportFunctions/fetchActivityData";
import LoadingSpinner from "../../../loadingSpinner";
import reportsContext from "../../../../contexts/reportsContext";
import ReportsParameterSelection from "../reportsParameterSelection";

const TimesheetReport = ({}) => {

    const {dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi, theVehicle} = useContext(reportsContext)



    const [timesheetData, setTimesheetData] = useState([]);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }




    useEffect(async () => {
        if(dateRange && theVehicle && generateReport){

            handleReportLoading(true);

            fetchActivityData(dateRange, theVehicle).then(r => {
                setTimesheetData(r);
                handleReportLoading(false);
            });
        }

    }, [dateRange, theVehicle, generateReport]);


    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        // <ProgressSpinner />
                    :
                        <TimesheetReportTable timesheetData={timesheetData} reportToPrint={reportToPrint} showKpi={showKpi}/>
                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px',width: '100%',height: '100%',position: 'relative'}}>
                    <div style={{fontWeight: 'bold',fontSize: '18px',position: 'absolute',textAlign: 'center',
                    top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}>
                        Please select a vehicle and timespan

                        <br />


                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                    </div>
                </div>



            }
        </React.Fragment>
    )


}
export default TimesheetReport
