import {fetchUserLevel} from "../fetchUserLevel";
import {dropTable} from "../db";

const clearStorage = async () => {

    const showSidebar = localStorage.getItem('showSidebar');
    const showShortJourneys = localStorage.getItem('showShortJourneys');
    const darkMode = localStorage.getItem('darkMode');
    const darkMap = localStorage.getItem('darkMap');
    const showJourneyProfile = localStorage.getItem('showJourneyProfile');
    const displayType = localStorage.getItem('displayType');
    const vFilter = localStorage.getItem('vFilter');
    const auth = fetchUserLevel();

    localStorage.clear();
    localStorage.setItem('showSidebar',showSidebar);
    localStorage.setItem('darkMode',darkMode);
    localStorage.setItem('darkMap',darkMap);
    localStorage.setItem('showJourneyProfile', showJourneyProfile);
    localStorage.setItem('displayType', displayType);
    localStorage.setItem('vFilter', vFilter);
    if (auth === 'superuser'){
        localStorage.setItem('showShortJourneys',showShortJourneys);
    }
    window.location = '/login';
}

export default clearStorage
