import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../contexts/mainContext";
import {DurationFromSeconds, fullDurationFromSeconds} from "../../../../functions/formatting/formatDate";

const DaySummary = () => {

    const {journeys} = useContext(mainContext);

    const [totals, setTotals] = useState({});

    useEffect(() => {
        if (journeys){
            let tots = {
                driving:0,
                idling:0,
                stopped:0,
                events:[],
                distance: 0,
                journeys:0
            }

            journeys?.forEach((j, i) => {

                if (i < journeys?.length){

                    if (journeys[i + 1]?.startTime && j?.endTime){
                        let time = (new Date(journeys[i + 1]?.startTime).getTime() - new Date(j?.endTime).getTime()) / 1000
                        tots.stopped += time
                    }


                }

                console.log(tots)
                tots.journeys += j.completed ? 1 : 0;
                tots.driving += (new Date(j?.endTime) - new Date(j?.startTime)) / 1000;
                tots.idling += j?.trueIdling > 0 ? j?.trueIdling : 0;
                // tots.stopped += j?.stopTime > 0 ? j?.stopTime : 0;
                tots.events.concat(j?.eventSums)
                tots.distance += j?.distance ? j?.distance : 0;
            });
            setTotals(tots)
        }
    }, [journeys]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            fontSize: '1.1em',
            height: '90px',
            padding: '5px'
        }}>
            <div className="reportTotalBubble">
                <div><b>Journeys completed:</b></div>
                <div style={{paddingBottom: '15px'}}>{totals?.journeys}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Distance travelled:</b></div>
                <div style={{paddingBottom: '15px'}}>{totals?.distance > 0 ? totals?.distance?.toFixed(0) : 0} miles
                </div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Driving time:</b></div>
                <div
                    style={{paddingBottom: '15px'}}>{DurationFromSeconds(totals?.driving > 0 ? totals?.driving : 0)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Parked time:</b></div>
                <div
                    style={{paddingBottom: '15px'}}>{DurationFromSeconds(totals?.stopped > 0 ? totals?.stopped : 0)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Engine idling:</b></div>
                <div
                    style={{paddingBottom: '15px'}}>{DurationFromSeconds(totals?.idling > 0 ? totals?.idling : 0)}</div>
            </div>
            {/*<div className="reportTotalBubble">*/}
            {/*    <div><b>Driving events:</b></div>*/}
            {/*    <div style={{paddingBottom: '15px'}}>{totals?.events?.length > 0 ? totals?.events?.length : 0}</div>*/}
            {/*</div>*/}
        </div>
    )
}

export default DaySummary