import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const BrakingStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <g id="braking_Image" data-name="braking Image">
                    <path d="m184.57,205.39c10.63-.09,19.17-8.78,19.08-19.41-.09-10.41-8.44-18.86-18.85-19.07-10.63-.22-19.42,8.22-19.64,18.85-.22,10.63,8.22,19.42,18.85,19.64.19,0,.37,0,.56,0Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m190.95,150.04c0-3.31-2.64-6.02-5.95-6.12-3.32,0-6.02,2.66-6.06,5.97-.07,3.27,2.52,5.98,5.79,6.05,1.06.02,2.11-.24,3.17-.78,1.89-.98,3.03-2.98,3.06-5.11v-.02Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m147.97,192.95h.02c2.29.01,4.46-1.25,5.38-3.35,2.32-5.31-2.75-10.41-8.06-8.14-2.1.89-3.37,3.05-3.36,5.33v.11c.01,3.34,2.7,6.03,6.02,6.05Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m227.25,186.42c-.07-3.32-2.82-5.95-6.14-5.87-3.32.07-5.95,2.82-5.87,6.14.07,3.31,2.81,5.93,6.12,5.87,3.32-.08,5.95-2.82,5.89-6.14Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m190.71,223.15c0-3.32-2.68-6.02-6-6.03-3.32,0-6.02,2.68-6.03,6,0,3.3,2.65,5.99,5.95,6.03,3.31-.02,6.01-2.68,6.07-6Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m184.89,107.54c42.99,0,77.84,34.84,77.85,77.83,0,42.99-34.84,77.84-77.83,77.85-42.99,0-77.84-34.84-77.85-77.83,0-20.65,8.2-40.46,22.8-55.06,14.56-14.64,34.37-22.85,55.02-22.79m0-32.47c-60.91,0-110.29,49.38-110.29,110.31s49.38,110.31,110.31,110.31,110.31-49.38,110.31-110.31-49.38-110.29-110.31-110.29l-.02-.02Z" style={{fill: 'var(--text-color)'}}/>
                    <path d="m166.57,54.69c2.43,1.61,2.77,4.18,2.36,7.57-1.01,8.65-1.07,17.38-.17,26.04.78,7.64.06,8.92-5.25,10.16-33.95,7.92-56.96,32.38-64.47,64.76-.33,4.86-2.42,5.73-9.01,5-9.01-.94-18.1-.88-27.1.16-3.59.39-6.26-.06-7.99-2.84v-4.68c.15-.75.37-1.5.44-2.26,4.43-49.52,39.53-90.04,87.88-101.32,6.41-1.49,13.03-2.11,19.51-3.32l3.8.73Z" style={{fill: "var(--text-color)", stroke: "#6d6e70", strokeMiterlimit: 10, strokeWidth: "2px"}}/>
                </g>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:'none'}}/>
                </g>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Braking</Tooltip>
            }
        </div>
    )
}
export default BrakingStacked
