import React, {useEffect, useRef, useState} from 'react';
import Hls from "hls.js";
import {LIVESTREAM_URL} from "../../../Constants";
import startStream from "../../../api/startStream";
import startStreamStatus from "../../../api/startStreamStatus";
import {fetchUserLevel} from "../../../functions/fetchUserLevel";

const Livestream = ({dn, cam, play, toShow, handleDeviceOnline, deviceOnline}) => {


    let hls;
    let hlsTryLoadTimer = useRef();
    let statusTimer = useRef();
    let statusData = useRef([])

    const videoContainer = useRef();
    const videoPlayer = useRef();
    const [playing, setPlaying] = useState(false);
    const [buffering, setBuffering] = useState(false);
    const [connected, setConnected] = useState(false)
    const [mounted, setMounted] = useState(false);



    useEffect( () => {
        return async () => {
            if(toShow?.includes(cam?.channel)) {
                await startStopStream(0)
            }
        }
    }, []);


    useEffect(async() => {
        if(toShow?.includes(cam?.channel)) {
            setMounted(true)
        } else{
            setMounted(false)
        }
    }, [toShow]);


    useEffect(async () => {
        if (toShow?.includes(cam?.channel) && mounted){
            if(play){
                await startStopStream(1)
            } else {
                await startStopStream(0)
            }
        }
    }, [play]);




    const config = {
        manifestLoadingRetryDelay: 100,
        liveDurationInfinity: true,
        backBufferLength: 2,
        maxLiveSyncPlaybackRate: 2,
        xhrSetup: xhr => {
            // headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token').access_token  }
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        }
    }


    const kbps = (data) => {
        return Math.round((statusData?.[statusData?.length-1]?.bytesRead -
            statusData?.[statusData?.length -2]?.bytesRead / 8) / 3000)
    }



    const initHlsPlayback = () => {

        if (Hls.isSupported()) {
            hls = new Hls(config);

            hls.loadSource(`${LIVESTREAM_URL}/stream/${dn}_${cam.channel}_0.m3u8`);

            hls.attachMedia(videoPlayer?.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {

                clearTimeout(hlsTryLoadTimer.current);

                videoPlayer?.current?.play();
                setBuffering(false);
                setPlaying(true);


                statusTimer.current = setInterval(async () => {
                    const status = await startStreamStatus(dn, cam.channel);

                    if (status.ok){
                        statusData?.current?.push(status);

                        if (status?.timeClosed){
                            videoPlayer?.current?.pause();
                            clearInterval(statusTimer.current);
                        }
                    } else {
                        console.log('offline')
                    }



                }, 2000)

            });


            hls.on(Hls.Events.ERROR,  (event, data) => {

                if (data.details === 'bufferStalledError') {
                    console.log('bufferStalledError');
                }

                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            if (data.details === 'manifestParsingError' ) {
                                console.log(`waiting for stream to start ...`);
                            } else {
                                console.log('unknown network error encountered, try to recover');
                            }
                            hlsTryLoadTimer.current = setTimeout(() => hlsTryLoad(), 1000);
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log('fatal media error encountered, try to recover');
                            hls.destroy();
                            hls.recoverMediaError();
                            break;
                        default:
                            // cannot recover
                            console.log('cannot recover');
                            hls.destroy();
                            setPlaying(false)
                            break;
                    }
                }
            });
        } else if (videoPlayer?.current?.canPlayType('application/vnd.apple.mpegurl')) {
            // alert("Livestream is not currently supported on Apple devices");
        }
    }

    const hlsTryLoad = () => {
        hls.loadSource(`${LIVESTREAM_URL}/stream/${dn}_${cam.channel}_0.m3u8`);
    }


    const startStopStream = async (startStop) => {

        clearTimeout(hlsTryLoadTimer.current);
        clearInterval(statusTimer?.current);
        videoPlayer?.current?.setAttribute('src', '');
        setPlaying(false);
        setBuffering(false);
        setConnected(false);

        const start = await startStream(`${dn}_${cam.channel}_0`, startStop);

        if (startStop){


            if (start.streamId) setConnected(true);

            let counter = 0;



            statusTimer.current = setInterval(async () => {
                counter ++;

                if (counter > 60) {
                    clearInterval(statusTimer.current)
                    console.log('stream failed to buffer')
                }


                const status = await startStreamStatus(dn, cam.channel);

                if (status.ok){


                    setBuffering(true)
                    statusData?.current?.push(status);
                    if (status?.bytesRead){
                        clearInterval(statusTimer?.current);
                        initHlsPlayback();
                    }
                } else {

                    clearInterval(statusTimer.current);
                    handleDeviceOnline(false);
                }
            },1000)

        }
    }

    return (
        <div style={{flex: `${toShow?.length > 1 ? '0' : '1'} 0 49%`, display: toShow?.includes(cam?.channel) ? 'block' : 'none'}}>
            <div ref={videoContainer} id="videoContainer" style={{padding: '5px', width: '100%', position: 'relative'}}>
                <div style={{textAlign: 'center'}}>
                    <svg style={{width: '24px', height: '24px', verticalAlign: 'middle'}} viewBox="0 0 24 24">
                        <path fill="currentColor"
                              d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z"/>
                    </svg>
                    {cam?.camPosition}
                    {fetchUserLevel() === 'superuser' && ` (${cam?.channel})`}

                    {statusData?.length > 1 &&
                        <div>
                            {`${kbps()} kbps`}
                        </div>

                    }

                </div>

                <video ref={videoPlayer} style={{width: '100%', maxWidth: '100%', height: 'auto', background: 'var(--surface-border)'}}></video>

                {!playing && !buffering && !connected && deviceOnline &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        textAlign: 'center'
                    }}>
                        camera ready to stream
                    </div>
                }

                {connected && !playing && !buffering && deviceOnline &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        textAlign: 'center'
                    }}>
                        stream connecting
                    </div>
                }

                {buffering && !playing && connected && deviceOnline &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        textAlign: 'center'
                    }}>
                        stream buffering
                    </div>
                }

                {!deviceOnline && <div>off bro</div>}
            </div>
        </div>

    )
}

export default Livestream
