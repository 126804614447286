import {API_URL} from "../Constants";


const startStream = (stream, onOff) => {
    const requestOptions = {
        method: 'GET',
        // headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token').access_token  },
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token')  },
    };

    let url = `${API_URL}/live-preview/${stream}/${onOff}`

    return fetch(url, requestOptions)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data;
        })

}


export default startStream;
