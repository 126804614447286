import React from 'react';


const StoppedMarker = ({openModal}) => {

    return (
        <div onClick={() => openModal('displayStoppedClickModal')} style={{cursor: 'pointer'}}>
            <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" width="40">
                <circle cx="40" cy="40" r="30"  stroke="gray"  strokeWidth="4"  fillOpacity="1"  fill="white" />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 1024" x="15" y="18">
                    <path fill="red" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/>
                </svg>
            </svg>


        </div>

    )

}


export default StoppedMarker
