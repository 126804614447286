import React, {useContext, useRef, useState} from 'react';
import {ConfirmPopup} from "primereact/confirmpopup";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLocationPlus, faTrash} from "@fortawesome/pro-regular-svg-icons";
import mainContext from "../../../../contexts/mainContext";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import postUpdateVehicle from "../../../../../api/device/postUpdateVehicle";
import {TOAST_DURATION} from "../../../../../Constants";

const EditCo2Button = ({}) => {

    const {deviceDetails, handleDeviceDetails, toast} = useContext(mainContext);

    const [visible, setVisible] = useState(false);
    const [co2, setCo2] = useState(deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions);
    const id = useRef(Math.random())



    const popupBody = () => {
        return (
            <div>
                <div style={{marginBottom: '5px',fontWeight: 'bold', fontSize: '14px'}}>Edit Estimate CO2 Emissions (g/km)</div>
                <InputNumber value={co2} onChange={(e) => setCo2(e.value)} placeholder="Co2 g/km"
                             minFractionDigits={0} maxFractionDigits={2}/>
                {/*<br />*/}
                {/*Category:*/}
            </div>
        )
    }

    const saveCo2Changes = async () => {
        if (deviceDetails){

            const {appVersion, deviceDob, deviceStatus, healthChecked, id, lastConnetion, lastDisconnected, lastHeartbeat,
                lastLocation, latestSnapshots, notes, overrun, overrunRecording,  timezone, _id, ...newVehicle} = deviceDetails



            let factor = 2.31;

            if (newVehicle.vehicleDvlaData.VehicleRegistration.FuelType === 'DIESEL'){
                factor = 2.68;
            }

            let mpg = 0

            if (co2 > 0) mpg = (1 / ((co2 / (factor * 1000)) * 0.352))

            newVehicle.vehicleDvlaData.TechnicalDetails.Consumption.Combined.Mpg = mpg.toFixed(0);
            newVehicle.vehicleDvlaData.VehicleRegistration.Co2Emissions = co2;


            const res = await postUpdateVehicle(newVehicle);

            if (res?.modifiedCount === 1 && res?.matchedCount === 1){
                toast.current.show({ severity: 'success', summary: 'All done', detail: 'Estimate CO2 Emissions updated',
                    life: TOAST_DURATION });

                const newDetails = deviceDetails;
                newDetails.vehicleDvlaData.VehicleRegistration.Co2Emissions = co2;
                handleDeviceDetails(newDetails);


            } else if (res?.modifiedCount === 0 && res?.matchedCount === 1) {
                toast.current.show({ severity: 'warn', summary: 'Not saved', detail: 'Nothing to update',
                    life: TOAST_DURATION });
            } else{
                toast.current.show({ severity: 'error', summary: 'Not saved', detail: 'Something has gone wrong!',
                    life: TOAST_DURATION });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Not saved', detail: 'Device details missing',
                life: TOAST_DURATION });
        }
    }



    return (
        <React.Fragment>

            {co2} g/km

            <Button style={{padding: '2px 2px', margin: '3px', background: 'var(--surface-300)', fontSize: '11px', color: 'var(--text-color)'}}
                    label={<FontAwesomeIcon icon={faEdit} />} className="p-mb-2" onClick={(e) => {
                setVisible(true)
            }} />



            <ConfirmPopup target={document.getElementById(id?.current)} visible={visible}
                          onHide={() => setVisible(false)}
                          icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}
                          message={popupBody}
                          accept={saveCo2Changes}
                          reject={() => {
                              setCo2(deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions)}
                          }
                          rejectClassName="p-button-danger"
                          acceptClassName="p-button-success"
            />
        </React.Fragment>
    )
}

export default EditCo2Button
