import React, {useEffect, useState} from 'react';
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import {API_URL} from "../../../Constants";
import mflWaiting from "../../../svgs/mfl_waiting.gif"

const HoverVideoPlayer = ({video, handleClick, playing = true}) => {


    const [contentSrc, setContentSrc] = useState(null);
    const [authorised, setAuthorised] = useState(true)

    useEffect(async () => {
        if (video){
            const file = video?.file ? video?.file : video?.filename
            const token = await FetchMediaToken(file);

            if (token !== '401'){
                setContentSrc(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);
                setAuthorised(true)
            } else {
                setAuthorised(false)
            }
        }

    }, [video]);

    return (
        <div style={{width: '100%', position: 'absolute', aspectRatio: '16/9'}} onClick={(e) => handleClick(e)}>
            {authorised &&
                <video style={{width: '100%'}} autoPlay loop  controlsList="nodownload" src={contentSrc} muted={true}></video>
            }
        </div>
    )
};

export default HoverVideoPlayer;
