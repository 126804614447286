import React, {useContext} from 'react';
import indexContext from "../../../../../contexts/indexContext";
import CO2ReportTableFooter from "../vehicle/co2ReportTableFooter";
import FleetCo2ReportTableHeader from "./FleetCo2ReportTableHeader";
import FleetCo2ReportTableRow from "./fleetCo2ReportTableRow";
import FleetCO2ReportTableFooter from "./fleetCo2TableFooter";

const FleetCo2ReportTable = ({data}) => {

    const {darkMode} = useContext(indexContext)

    const parkedRow = {
        textAlign: 'center',
        lineHeight: '45px',
        borderBottom: '1px solid var(--surface-border)',
        background: (darkMode ? 'var(--surface-card)' : '#e2e2e2')
    }

    return (
        <React.Fragment>
            <FleetCo2ReportTableHeader date={data.date} data={data}/>

            {data?.vData?.length >= 1 &&
                <React.Fragment key={data?.vData?.[0]?.dn}>
                    {data?.vData?.map((item, index) =>
                        <FleetCo2ReportTableRow data={item} index={index} />
                    )}
                    <FleetCO2ReportTableFooter data={data} />

                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default FleetCo2ReportTable
