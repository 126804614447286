import React, {useEffect, useMemo, useState} from 'react';
import fetchReverseGeocode from "../../../api/fetchReverseGeocode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/pro-light-svg-icons";
import {Tooltip} from "primereact/tooltip";
import {applySpacing} from "../../../functions/formatting/applySpacing";

const VehicleLiveLocationV2 = ({d, icon = false}) => {


    const [lat, setLat]  = useState();
    const [lng, setLng]  = useState();
    const [geoData, setGeoData] = useState();
    const [rand, setRand] = useState();


    useEffect(async () => {
        if (lat && lng){
            setRand(Math.random().toString().replaceAll(".", ""));
            const latLngParent = d.lastLocation ? d.lastLocation : d;

            if (latLngParent.lat && latLngParent.lng){
                setGeoData(await fetchReverseGeocode(lat, lng));
            }
        }
    }, [lat, lng])


    useEffect(() => {
        if (d){
            setLat(d?.lastLocation ? d.lastLocation.lat : d?.lat);
            setLng(d?.lastLocation ? d.lastLocation.lng : d?.lng);
        }
    }, [d]);


    return (
        <div>
            {geoData && geoData.features && geoData.features[0].properties ?
                <React.Fragment>
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '100%', verticalAlign: 'top'}}
                         className={"location" + rand} data-pr-position="bottom">

                        {icon &&
                            <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginLeft: '5px', paddingRight: '5px', display: 'inline-block'}}/>
                        }

                        {geoData?.features?.[0]?.properties?.address?.village ?
                            <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.village)}</span>
                            :
                            <React.Fragment>
                                {geoData?.features?.[0]?.properties?.address?.town ?
                                    <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.town)}</span>
                                    :
                                    <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.city)}</span>
                                }
                            </React.Fragment>

                        }

                        {geoData?.features?.[0]?.properties?.address?.postcode}
                    </div>
                    <Tooltip target={".location" + rand}>
                        <span>
                            {applySpacing(geoData?.features?.[0]?.properties?.address?.road)}
                            {geoData?.features?.[0]?.properties?.address?.village ?
                                <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.village)}</span>
                                :
                                <React.Fragment>
                                    {geoData?.features?.[0]?.properties?.address?.town ?
                                        <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.town)}</span>
                                        :
                                        <span>{applySpacing(geoData?.features?.[0]?.properties?.address?.city)}</span>
                                    }
                                </React.Fragment>

                            }

                            {geoData?.features?.[0]?.properties?.address?.postcode}
                    </span>
                    </Tooltip>
                </React.Fragment>
                :
                <div>&nbsp;</div>
            }
        </div>
    )
}

export default VehicleLiveLocationV2
