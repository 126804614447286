import React, {useContext, useEffect, useState} from 'react';
import fetchLastestSnapshot from "../../../api/media/fetchLatestSnapshot";
import ImageMfl from "./ImageMfl";
import mainContext from "../../contexts/mainContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/pro-regular-svg-icons";

const LatestCamSnapshot = ({cam}) => {
    console.log(cam)

    const {deviceDetails} = useContext(mainContext)
    const [snap, setSnap] = useState()

    useEffect(async () => {
        if (cam){
            const ch = cam.channel;
            setSnap(await fetchLastestSnapshot({dn: deviceDetails?.dn, ch, limit:1}))
        }
    }, [cam]);

    const timeFromFileName = (file) => {
        const split = file?.split('_')[1];
        const date = split?.substr(6, 2) + '/' + split?.substr(4, 2) + '/' +
            split?.substr(0, 4) +  ' ' + split?.substr(8, 2) + ':' +split?.substr(10, 2)
        return date
    }

    return (
        <React.Fragment>
            <div>
                <FontAwesomeIcon icon={faVideo}/>
                &nbsp;{cam.camPosition} camera <br />
                {timeFromFileName(snap?.[0]?.snapshots?.file)}
            </div>
            <div style={{position: 'relative'}}>
                {snap &&
                    <ImageMfl size={"lrg"} file={snap?.[0]?.snapshots?.file}/>
                }
            </div>
        </React.Fragment>
    )
}

export default LatestCamSnapshot
