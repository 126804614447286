import React, {useContext, useEffect, useState} from 'react';
    import FormatAddress from "../../../../../functions/formatting/formatAddress";
import {DurationFromSeconds, formatTimeOnly} from "../../../../../functions/formatting/formatDate";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";
import AddPlaceButton from "../../grid/cardComponents/addPlaceButton";
import reportsContext from "../../../../contexts/reportsContext";


const ActivityReportTableRow = ({data, index}) => {

    const {darkMode} = useContext(indexContext);

    const {vehicles, handleDate, handleJourney, handlePageToShow} = useContext(mainContext);
    const {theVehicle} = useContext(reportsContext)





    const viewJourney = (data) => {
        handleDate(new Date(data?.startTime));
        setTimeout(() => {
            handleJourney(data?._id);
            handlePageToShow('/map');

        }, 500);
    }

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '5% 10% 8% 20% 10% 20% 10% 10% 8%',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
            textAlign: 'center'
        }} className="reportTable"
             key={index}

        >
            <div style={{lineHeight: '73px', paddingLeft: '10px'}} onClick={() => viewJourney(data)}>
                {(index + 1)}
            </div>

            <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                {regFromDn(vehicles, data?.dn)}
            </div>

            <div style={{lineHeight: '73px'}}>
                {formatTimeOnly(data?.startTime)}
            </div>
            <div style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="start"/>
                <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={data?.start?.lat}
                                lng={data?.start?.lng}/>
            </div>
            <div style={{lineHeight: '73px'}}>
                {formatTimeOnly(data?.endTime)}
            </div>
            <div style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="finish"/>
                <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={data?.finish?.lat}
                                lng={data?.finish?.lng}/>
            </div>


            <div style={{lineHeight: '73px'}}>
                {data?.distance.toFixed(0)}
                &nbsp;miles
            </div>
            <div style={{lineHeight: '73px'}}>
                {data?.duration}
            </div>

            <div style={{lineHeight: '73px'}}>
                {data?.stopped ?
                    <React.Fragment>
                        {data?.stopped > 60 ?
                            <span>
                                {DurationFromSeconds(data?.stopped)}
                            </span>
                            :
                            <span>0m</span>
                        }
                    </React.Fragment>

                    :
                    <span>-</span>
                }
            </div>

        </div>
    )
}
export default ActivityReportTableRow
