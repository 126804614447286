import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const SosStacked = ({event, height, showTool = true}) => {
    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path  d="M16.42,184.88c-.76,1.33-1.19,2.86-1.19,4.43,0,4.86,3.9,8.76,8.76,8.76h195.74c4.86,0,8.76-3.9,8.76-8.76,0-1.57-.43-3.05-1.19-4.43L131.62,20.85c-2.05-3.47-5.76-5.62-9.76-5.62s-7.71,2.14-9.76,5.62L16.42,184.88ZM3.28,177.17L98.96,13.14c4.76-8.14,13.47-13.14,22.9-13.14s18.14,5,22.9,13.14l95.68,164.04c2.14,3.67,3.28,7.85,3.28,12.09,0,13.23-10.76,23.99-23.99,23.99H23.99c-13.23,0-23.99-10.76-23.99-23.99,0-4.24,1.14-8.43,3.28-12.09ZM121.86,60.93c4.19,0,7.62,3.43,7.62,7.62v60.93c0,4.19-3.43,7.62-7.62,7.62s-7.62-3.43-7.62-7.62v-60.93c0-4.19,3.43-7.62,7.62-7.62ZM110.44,167.56c0-6.31,5.11-11.42,11.42-11.42s11.42,5.11,11.42,11.42-5.11,11.42-11.42,11.42-11.42-5.11-11.42-11.42Z" style={{fill: 'var(--text-color)', transform: 'translate(15%,13%)'}}/>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Driver button</Tooltip>
            }
        </div>
    )
}
export default SosStacked
