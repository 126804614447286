import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../../contexts/mainContext";
import {gToKg} from "../../../../../../functions/formatting/gToKg";
import {DurationFromSeconds} from "../../../../../../functions/formatting/formatDate";

const FleetCO2ReportSummary = ({actTotals}) => {

    console.log(actTotals)


    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>

            <div className="reportTotalBubble">
                <div><b>Total<br />Journeys:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance?.toFixed(0)}&nbsp;miles</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />CO2:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {gToKg(actTotals?.co2)}
                </div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average <br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{(actTotals?.distance / actTotals?.journeys)?.toFixed(0)}&nbsp;miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average CO2 <br />per journey:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {gToKg(actTotals?.co2 / actTotals?.journeys)}
                </div>
            </div>
        </div>
    )
}

export default FleetCO2ReportSummary
