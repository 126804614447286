import React, {useContext, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faFilter} from "@fortawesome/pro-light-svg-icons";
import indexContext from "../../../contexts/indexContext";
import {
    faTrash,
    faStar,
    faRectangleHistory,
    faEllipsis,
    faSquareCheck, faWifiExclamation, faVideoCamera, faCloudArrowUp, faBookmark, faSdCard, faTrashSlash
} from "@fortawesome/pro-regular-svg-icons";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {Button} from "primereact/button";
import abovePanelContext from "../../../contexts/abovePanelContext";
import {confirmPopup} from "primereact/confirmpopup";
import {handleNotificationReadV2} from "../../panelContent/grid/gridFunctions/handleNotificationRead";

const NotificationsFilters = ({}) => {

    const {winWidth} = useContext(indexContext);
    const {action, actionOptions, handleAction, handleReadNotis, refresh, handleSelectAll, handleStarredNotis, selectAll,
        markSelectedRead, handleAllNotis, handleNotiType, readNotis} = useContext(abovePanelContext);

    const [activeIndex, setActiveIndex] = useState(4);
    const [showOptions, setShowOptions] = useState(false)


    const itemsMobile = [

        {display: 'Cameras', value: 'cameraHealth', index: 0, icon: faVideoCamera},
        {display: 'Devices', value: 'missing', index: 1, icon: faWifiExclamation},
        {display: 'Updates', value: 'modeChanged', index: 2, icon: faBookmark},
        {display: 'Storage', value: 'hardDiskHealth', index: 3, icon: faSdCard},
        {display: 'All', value: 'unread', index: 4, icon: faRectangleHistory},
        // {value: 'read', index: 5, icon: faTrash},
        // {value: 'starred', index: 6, icon: faStar}
    ];




    const optionsStyle = {
        position: "absolute",
        display: "none",
        zIndex: "100000000!important",
        right:"10px",
        boxShadow:" 0 3px 12px rgba(0, 0, 0, 0.15)",
        top: "82px"
    }
    const showOptionsStyle = {
        position: "absolute",
        display: "block",
        zIndex: "100000000!important",
        right:"10px",
        boxShadow:" 0 3px 12px rgba(0, 0, 0, 0.15)",
        top: "82px",
        padding: '10px',
        background: 'var(--surface-card)',
        borderRadius: '3px',
        textAlign: 'center'
    }


    const changeDropdownTab = async (e) => {
        const obj = {
            value: {
                value: e
            },
            index : itemsMobile.filter(item => item.value === e)[0].index
        }``
        await changeTab(obj)
    }

    const changeTab = (e) => {
        if (e.index === activeIndex){
            setActiveIndex(4)
        } else {
            setActiveIndex(e.index)
        }

        switch (e.value.value){
            case 'unread': handleAllNotis();break;
            case 'read': handleReadNotis(); break;
            case 'starred': handleStarredNotis(); break;
            default: handleTypeofNoti(e.value.value); break;
        }
    }


    const handleTypeofNoti = (val) => {
        handleNotiType(val)
    }

    const selectedTypeTemplateMobile = () => {
        const test = itemsMobile.filter(item => item.index === activeIndex)[0]
        return selectedOptionTemplate(test)
    }

    const confirmDeleteAll = (e) => {

        confirmPopup({
            target: e.currentTarget,
            message: `Are you sure you want to ${readNotis ? 'undelete' : 'delete'} all alerts?`,
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await markSelectedRead()
            },
            reject: () => {
                return
            }
        });

    }




    return (

        <div style={{height: '51px', display: 'flex', alignItems: 'center', borderBottom: '1px solid var(--surface-border)',
            background: 'var(--surface-ground)'}}>

            {winWidth > 800 ?
                <React.Fragment>
                    <div style={{
                        marginTop: '-1px',
                        overflow: 'hidden',
                        display: 'flex',
                        marginLeft: '10px',
                        flexFlow: 'row wrap',
                        maxHeight: '46px',
                        gap: '4px'
                    }}>
                        {itemsMobile.map(item => (
                            <button className={"p-button p-button-sm eventFilterButton"}
                                    key={item.index}
                                    onClick={() => changeTab({index: item.index, value: item})}
                                    style={{
                                    background: item?.index === activeIndex ? 'var(--v-list-button-selected)'  : 'var(--v-list-button)',
                                    color: item?.index === activeIndex ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                                    flex: 'none'
                                }}>

                                <span className="p-button-label"
                                      style={item?.display ? {fontWeight: 500} : {
                                          padding: '0px 10px 0px 10px',
                                          fontWeight: 500
                                      }}>
                                    <FontAwesomeIcon icon={item.icon}/>
                                    <br/>{item.display}
                                </span>
                            </button>
                        ))}
                    </div>

                    <div style={{flex: 1, background: 'var(--surface-ground)'}}/>


                    <div>
                        <button className="p-button p-button-sm eventFilterButton" style={{background: 'var(--v-list-button)', color: 'var(--v-list-button-text)'}}
                                onClick={() => refresh()}>
                            <span className="p-button-label">
                                <FontAwesomeIcon icon={faArrowsRotate}/>
                                <br/>Refresh
                                </span>
                        </button>

                    </div>

                    <button className="p-button p-button-sm eventFilterButton" style={{marginRight: '20px', marginLeft: '4px' ,background: 'var(--v-list-button)', color: 'var(--v-list-button-text)'}}
                            disabled={false}
                            onClick={(e) => confirmDeleteAll(e)}>

                        <span className={"p-button-label"}>
                            {readNotis ?
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faTrashSlash}/>
                                    <br/>Undelete
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faTrash}/>
                                    <br/>Delete
                                </React.Fragment>
                            }
                        </span>
                    </button>





                    {/*<button  className="tStyle p-mb-2" style={{marginRight: '20px', width:'50px'}}*/}
                    {/*         onClick={() => setShowOptions(!showOptions)}*/}
                    {/*         aria-haspopup aria-controls="overlay_panel" >*/}
                    {/*    <span className="p-button-label">*/}
                    {/*        <FontAwesomeIcon icon={faEllipsis}/>*/}
                    {/*    </span>*/}
                    {/*</button>*/}

                    {/*<div style={showOptions ? showOptionsStyle : optionsStyle} className="nav-dropdown1">*/}

                    {/*    <button className="tStyle" onClick={() => handleSelectAll()}>*/}
                    {/*        <FontAwesomeIcon icon={faSquareCheck} />&nbsp;*/}
                    {/*        {selectAll ?*/}
                    {/*            <React.Fragment>Unselect all</React.Fragment>*/}
                    {/*            :*/}
                    {/*            <React.Fragment>Select all</React.Fragment>*/}
                    {/*        }*/}
                    {/*    </button>*/}

                    {/*    <div className="p-inputgroup">*/}
                    {/*        <Dropdown value={action} onChange={(e) => handleAction(e.value)}*/}
                    {/*                  options={actionOptions} placeholder="Select action" className="notificationDropdown"*/}
                    {/*                  style={{fontSize: '1rem!important', height: '46px', borderRadius: '0px', lineHeight: '32px', border: 'none'}}*/}
                    {/*                  valueTemplate={(e) => selectedOptionTemplate(e)} itemTemplate={(e) => optionTemplate(e)} optionLabel="display"/>*/}

                    {/*        <Button onClick={() => markSelectedRead()} label="Go" className="p-button p-button-sm"*/}
                    {/*                style={{background: 'var(--surface-300)', color: 'var(--text-color)'}}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </React.Fragment>
                :
                <div style={{
                    paddingTop: '5px',
                    height: '50px',
                    paddingLeft: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1
                }}>
                    <Dropdown value={activeIndex} onChange={(e) => changeDropdownTab(e.value)} options={itemsMobile} optionLabel="label"
                              placeholder="Select event filter" valueTemplate={selectedTypeTemplateMobile} itemTemplate={(e) => optionTemplate(e)}
                              style={{height: '40px'}}/>


                    <div style={{background: "var(--v-list-button)", cursor: 'pointer', width: '40px', alignItems: 'center',
                        display: 'flex', justifyContent: 'center', height: '40px', marginRight: '25px'}}
                         onClick={() => refresh()}>
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </div>
                </div>
            }
        </div>
    )
}
export default NotificationsFilters
