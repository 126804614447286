import {Colours} from "../../../../colours";


const svgStyle = 'style="width:20px;height:19px;color: ' + Colours.mapControlUnclickedTextColour + ';"';

class MapCustomControl2 {


    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this.zoomin = document.createElement('button');
        this.zoomin.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"'+svgStyle+'><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M288 192H224V128c0-8.844-7.156-16-16-16S192 119.2 192 128v64H128C119.2 192 112 199.2 112 208S119.2 224 128 224h64v64c0 8.844 7.156 16 16 16S224 296.8 224 288V224h64c8.844 0 16-7.156 16-16S296.8 192 288 192zM507.3 484.7l-141.5-141.5C397 306.8 416 259.7 416 208C416 93.13 322.9 0 208 0S0 93.13 0 208S93.13 416 208 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z"/></svg>';
        this.zoomin.onclick = () => {  this.map.zoomIn({duration: 500});  };
        this.zoomout = document.createElement('button');
        this.zoomout.onclick = () => {  this.map.zoomOut({duration: 500});  };
        this.zoomout.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"'+svgStyle+'><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M288 192H128C119.2 192 112 199.2 112 208S119.2 224 128 224h160c8.844 0 16-7.156 16-16S296.8 192 288 192zM507.3 484.7l-141.5-141.5C397 306.8 416 259.7 416 208C416 93.13 322.9 0 208 0S0 93.13 0 208S93.13 416 208 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z"/></svg>';
        this.container.appendChild(this.zoomin);
        this.container.appendChild(this.zoomout);


        this.satellite = true;
        this.togsatellite = document.createElement('button');
        this.togsatellite.title = 'Toggle satellite';
        this.togsatellite.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"'+svgStyle+'><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M465.9 164.7c18.72-18.72 18.72-49.16 0-67.88l-50.73-50.75c-18.11-18.09-49.75-18.16-67.89 0L186.4 206.1C161.4 197 134.8 192 108.3 192C73.75 192 39.24 200.4 8.035 217.1C-1.377 222.3-2.666 235.5 4.939 242.9l120.7 120.8l-25.11 25.11C94.31 385.8 87.39 384 80 384C53.53 384 32 405.5 32 432S53.53 480 80 480S128 458.5 128 432c0-7.391-1.816-14.3-4.811-20.56l25.11-25.11l120.8 120.8C272.3 510.4 276.7 512 280.1 512c5.574 0 11.05-2.691 13.89-7.941c29.6-55.22 32.41-120.5 9.705-178L465.9 164.7zM79.98 447.1c-8.828 0-15.1-7.197-15.1-16.01s7.167-16.01 15.1-16.01s15.1 7.198 15.1 16.01S88.8 447.1 79.98 447.1zM275.1 468.8L43.34 236.1C63.94 228.1 86.03 224 108.3 224c48.02 0 93.16 18.7 127.1 52.65C286.6 327.9 301.7 402.8 275.1 468.8zM289.6 295.7c-8.805-14.78-18.92-28.99-31.64-41.72C245.3 241.3 231.3 230.8 216.6 222l153.4-153.4c6.094-6.062 16.59-6.031 22.62 0l50.75 50.75c6.234 6.25 6.234 16.38 0 22.62L289.6 295.7zM498.5 253.7l-22.77-24.56c-6.016-6.531-16.16-6.875-22.61-.875c-6.484 6.031-6.859 16.16-.8594 22.62l23.19 25C478.4 278.8 480 282.7 480 286.9s-1.609 8.062-4.547 11l-81.59 81.56c-5.875 5.875-16.09 5.875-21.97 0c-6.25-6.25-16.39-6.25-22.64 0s-6.25 16.38-.0156 22.62C358.2 411.1 370.2 416 382.9 416s24.64-4.938 33.63-13.94l81.58-81.56C516.6 301.1 516.6 271.8 498.5 253.7zM121.2 167.4c4.094 0 8.188-1.562 11.31-4.688c6.25-6.25 6.25-16.38 0-22.62C129.6 137.2 128 133.3 128 129.1s1.609-8.062 4.547-11l81.59-81.56C220 30.66 229.8 30.22 236.6 37l24.56 22.75c6.453 5.969 16.59 5.594 22.61-.875c6.016-6.5 5.625-16.59-.8594-22.62l-24.11-22.31c-18-18-49.3-18-67.27 0L109.9 95.5c-18.55 18.53-18.55 48.72 0 67.25C113 165.9 117.1 167.4 121.2 167.4z"/></svg>';
        this.togsatellite.onclick = () => {

            if (this.satellite) {
                this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);
                this.togsatellite.style.background  = Colours.mapControlUnclicked;


            } else {
                this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
                // this.map.flyTo({
                //     center: this.map.getCenter(),
                //     zoom: 16,
                //     speed: 2,
                // });
                this.togsatellite.style.background  = Colours.mapControlClicked;

            }

            this.satellite = !this.satellite;

        };
        this.container.appendChild(this.togsatellite);



        return (
            this.container
        )

    }

}

export default MapCustomControl2;

