import {faBuildings, faHouse} from "@fortawesome/pro-regular-svg-icons";

export const catOptions = [
    {
        icon: faBuildings,
        display: "Office"
    },
    {
        icon: faHouse,
        display: "Residence"
    }
]