import React, {useContext} from 'react';
import mainContext from "../../../contexts/mainContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown} from "primereact/dropdown";
import jInProgressArrow from '../../../../svgs/mapIcons/jInProgressArrow.svg'
import jEndedFlag from '../../../../svgs/mapIcons/jEndedFlag.png';
import {formatTimeOnly} from "../../../../functions/formatting/formatDate";
import {faArrowRight, faVideo} from "@fortawesome/pro-light-svg-icons";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";


const MobileJourneySelect = () => {

    const {journey, journeys, handleJourney} = useContext(mainContext);

    const typeOptionTemplate = (option) => {

        let data = option

        if (option?._id === journey?.details?._id){
            data = journey?.details
        }

        return (

            <div style={{display:'flex', alignItems: 'center'}}>
                {(data?.completed !== null && data?.completed !== undefined && data?.completed) ?
                    <React.Fragment>
                        <img src={jEndedFlag} style={{height: '32px'}}/>
                        <div style={{flex:1}}>
                            {formatTimeOnly(data?.startTime)}
                            &nbsp;
                            <FontAwesomeIcon icon={faArrowRight} style={{color: 'var(--text-color)'}}/>
                            &nbsp;
                            {formatTimeOnly(data?.endTime)}
                        </div>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <img src={jInProgressArrow} style={{height: '32px', transform: 'rotate('+journey?.features[journey?.features?.length - 1]?.properties?.head+'deg)'}}/>
                        <div style={{flex:1}}>
                            {formatTimeOnly(data?.startTime)} (Ongoing)
                        </div>
                    </React.Fragment>

                }
            </div>
        );
    }

    const selectedOptionTemplate = (option, props) => {
        const ogJourney = journeys?.filter(j => j._id === journey?.details?._id)[0];
        if (ogJourney){
            return typeOptionTemplate(ogJourney);
        }
        return <span style={{color: 'var(--text-color)', lineHeight: '24px'}}>{props.placeholder}</span>
    }


    return (
        <div style={{height: '50px', padding: '5px'}}>
            <Dropdown options={journeys} optionLabel="name" itemTemplate={typeOptionTemplate}
                      style={{width: '202px', height: '40px'}}
                      placeholder="Select journey" valueTemplate={selectedOptionTemplate} value={journey}
                      onChange={(e) => handleJourney(e.value._id)}/>
        </div>
    )
}
export default MobileJourneySelect
