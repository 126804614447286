import React, {useContext, useEffect, useRef, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import imageLoading from "../../../../../svgs/noImageThumb.png";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";

import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import fetchEventsVideoFile from "../../../../../api/events/fetchEventsVideoFile";
import videoPlay from "../../../../../svgs/video_play.png";
import hyperlapsePlay from "../../../../../svgs/hyperlapse_play.png";
import MediaUploadInfoIcon from "./mediaUploadInfoIcon";
import EventGridItemSkeleton from "../events/eventGridItemSkeleton";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import FetchMediaToken from "../../../../../api/media/fetchMediaToken";
import {API_URL} from "../../../../../Constants";
import mediaUnavailable from "../../../../../svgs/MediaUnavailable.png"
import gridContext from "../../../../contexts/gridContext";
import LoadingSpinner from "../../../loadingSpinner";


const videoPlayIcon = {
    position: 'absolute',
    width: '100px',
    cursor: 'pointer',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'

}


const NotificationGridMedia = ({notification}) => {

    const {fleets} = useContext(indexContext);
    const {vehicles, handleEvent, handleVehicle, handleFleetsToShow, fleetsToShow, handleVideoToShow} = useContext(mainContext);

    const {addRemoveSelected} = useContext(gridContext);



    const [isLoaded, setIsLoaded] = useState(false);
    const [testImage, setTestImage] = useState();
    const [isMounted, setIsMounted] = useState(true);
    const [hover, setHover] = useState(false);
    const [authorised, setAuthorised] = useState(true);
    const [videoLoaded, setVideoLoaded] = useState(false);

    const videoRef = useRef(null);




    const addDefaultSrc = (e) => {
        e.target.src = imageLoading
    }

    const handleMediaClick = async () => {


        if (authorised){
            if(notification.notificationDetails?.videoRequest?.mediaRecord.filename){
                const r = await fetchEventsVideoFile({
                    dn: notification?.dn,
                    file: notification.notificationDetails?.videoRequest?.mediaRecord.filename
                });
                handleEvent(r[0]);
            } else {
                handleVideoToShow(notification.notificationDetails?.hyperlapseRequest?.hyperlapse);
            }
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        handleMediaClick(notification);
    }


    const handleHover = (val) => {
        if (val){
            videoRef?.current?.play()
        } else {
            videoRef?.current?.pause()
        }

    }


    useEffect(() => {
        return () => {
            setTestImage(null);
            setIsMounted(false);
        }
    }, []);




    useEffect(async () => {
        if(notification && isMounted){

                const file = notification.notificationType.includes("videoUploadComplete") ?  notification.notificationDetails?.videoRequest?.mediaRecord.filename :
                    notification.notificationDetails?.hyperlapseRequest?.hyperlapse.file

                const token = await FetchMediaToken(file);

                if (token !== '401'){
                    setAuthorised(true);
                    setTestImage(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);
                    setTimeout(() => {setIsLoaded(true);}, 500);
                } else {
                    setAuthorised(false);
                }

                setTimeout(() => {setIsLoaded(true);}, 500);
        }
    }, [notification]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(testImage);
        }
    }, []);




    const observerRef = useRef();
    const ref = useRef();
    const [visible, setVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Element is in view
                    setIsVisible(true);
                } else {
                    // Element is out of view
                    setIsVisible(false);
                }
            },
            {
                threshold: 0.1, // Adjust this threshold as needed
            }
        );

        if (ref.current) {
            observerRef?.current?.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observerRef?.current?.unobserve(ref.current);
            }
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        if (isVisible) {
            // Fire the function after 2 seconds of being visible
            timeoutId = setTimeout(() => {
                setVisible(true)
            }, 1000);
        } else {
            // Clear timeout if element is no longer visible
            clearTimeout(timeoutId);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isVisible]);


    useEffect(() => {
        if(visible){
            if (ref.current) {
                observerRef?.current?.unobserve(ref.current);
            }
        }
    }, [visible]);



    return (
        <div ref={ref}>
            {isVisible ?
                <div onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>

                    <div onClick={() => addRemoveSelected(notification._id)} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '5px', padding: '0px 10px 0px 10px'}}>
                        <div style={{flex:1, fontSize: '15px', fontWeight: 'bold'}}>
                            {formatDateNotificationsTable(notification.notificationDetails?.videoRequest?.mediaRecord?.st ?
                                notification.notificationDetails?.videoRequest?.mediaRecord?.st
                                : notification.notificationDetails?.hyperlapseRequest?.hyperlapse?.startTimeHyper)}
                            <br />
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                                 }}>
                                {regFromDn(vehicles, notification?.dn)}
                            </div>
                        </div>

                        <MediaUploadInfoIcon notification={notification} height='25px'/>
                </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}} onClick={(e) => {
                        e.stopPropagation();
                        handleMediaClick();
                    }}>


                        {authorised ?
                            <React.Fragment>
                                <video src={testImage} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9', opacity: videoLoaded ? 1 : 0}}
                                       // onError={(e) => {addDefaultSrc(e)}}
                                    onLoadedData={() => setTimeout(() => setVideoLoaded(true), 1000)}
                                       onClick={(e) => {
                                           e.stopPropagation();
                                           handleMediaClick(notification);
                                       }} loop  controlsList="nodownload" muted={true} ref={videoRef}></video>

                                {!videoLoaded &&

                                    <div style={{width: '100%', aspectRatio: '16/9', marginTop: '-120px', maxHeight: '120px'}}>
                                        <LoadingSpinner />
                                    </div>
                                }



                                {videoLoaded &&
                                    <React.Fragment>
                                        {notification.notificationType.includes('videoUploadComplete') ?
                                            <img src={videoPlay} style={videoPlayIcon}/>
                                            :
                                            <img src={hyperlapsePlay} style={videoPlayIcon}/>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>


                        :
                            <img style={{width: '100%', borderRadius: '3px'}} src={mediaUnavailable} />
                        }


                    </div>

                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '10px 10px 0px 10px'}}
                         onClick={(e) => {
                             e.stopPropagation();
                             handleFleetsToShow(new Set([...fleetsToShow, notification?.fleetId]));
                         }}>
                        {friendlyFleetName(notification?.fleetId, fleets)}
                    </div>

                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '0px 10px 10px 10px'}}
                         onClick={(e) => {
                             e.stopPropagation();
                             handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                         }}>
                        {regFromDn(vehicles, notification?.dn)}

                    </div>
                </div>
                :
                // TODO SKELETON FOR NOTIFICATIONS DISPLAY
                <EventGridItemSkeleton />
            }
        </div>
    )
}
export default NotificationGridMedia
