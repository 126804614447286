import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const VideoStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="pedestrian" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id} style={{height, width: height, verticalAlign: 'middle'}}  data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95" fill="var(--surface-border)"/>
                <path id="video" d="m81.84,94.95h107.22c14.85,0,26.89,12.04,26.89,26.89v107.22c0,14.85-12.04,26.89-26.89,26.89h-107.22c-14.85,0-26.89-12.04-26.89-26.89v-107.22c0-14.85,12.04-26.89,26.89-26.89Zm208.03,15.09c4.36,2.34,7.08,6.87,7.08,11.81v106.8c0,3.91-1.51,7.74-4.43,10.35-4.69,4.2-11.39,4.55-16.43,1.19l-40.2-26.8-5.95-3.98v-67.92l5.95-3.98,40.2-26.8c4.1-2.72,9.38-3.01,13.78-.67h0Z" style={{fill:'var(--text-color)'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:'none'}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill:'none'}}/>
                </g>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} Video</Tooltip>
            }
        </div>
    )
}
export default VideoStacked
