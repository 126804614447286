import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";

const fetchLastJourney = (dn, dateString) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token },
    };


    return fetch(API_URL + `/f-last-journey/${dn}/${dateString}`,  requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
            return Promise.resolve(data);


        }).catch(err=>{
            console.log(err)});
}


export default fetchLastJourney;
