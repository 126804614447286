export const isStarWars = () => {
    const now = new Date();
    const month = now.getMonth();
    const day = now.getDate();

    if (day + '/' + (month + 1) === '4/5'){
        return true;
    } else {
        return false;
    }
}
