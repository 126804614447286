import {API_URL, MIN_DISTANCE} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";

const fetchJourneys = (dn, dateString) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token },
    };


    return fetch(API_URL + `/f-journeys/${dn}/${dateString}`,  requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {

                data.forEach((journey, index) => {
                    journey.minDistance = journey?.distance >= MIN_DISTANCE;
                    // mark as completed if before current journey
                     if (index < data.length - 1) {
                         journey.completed = true;
                     }
                });
                return Promise.resolve(data);


            }).catch(err=>{
            console.log(err)});
}


export default fetchJourneys;
