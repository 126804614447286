import React from 'react';
import './starWarsSplash.css'

const StarWarsSplash = () => {
    return (
        <div style={{position: 'absolute', top: '50%', left:'50%',transform: 'translate(-50%, -50%)'}}>
            <div className="scene">
                <div className="wrap">
                    <div className="wall wall-right"></div>
                    <div className="wall wall-left"></div>
                    <div className="wall wall-top"></div>
                    <div className="wall wall-bottom"></div>
                    <div className="wall wall-back"></div>
                </div>
                <h1 style={{color: 'gold'}}>May the 4th be with you</h1>
                <div className="wrap">
                    <div className="wall wall-right"></div>
                    <div className="wall wall-left"></div>
                    <div className="wall wall-top"></div>
                    <div className="wall wall-bottom"></div>
                    <div className="wall wall-back"></div>
                </div>
            </div>
        </div>

    )
}
export default StarWarsSplash
