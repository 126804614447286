import React, {useContext, useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import {purple} from '../../../panelContent/map/CustomIcons';
import MapCustomControl2 from '../../../panelContent/map/MapCustomControl2.js';
import {Colours} from "../../../../../colours";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import indexContext from "../../../../contexts/indexContext";
import {getMapIcons} from "../../../panelContent/map/mapFunctions/getMapIcons";




const styles = {
    flex: "1",
    position: "relative",
};






const EventMap = ({event}) => {

    console.log(event)

    const {darkMap} = useContext(indexContext)



    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);


    const CustomControl = new MapCustomControl2();

    const generateEventMarker = (event) => {
        return {
            type: 'FeatureCollection',
                features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Point',
                    coordinates: [event.lng, event.lat]
                }
            }
        ]
        }
    }

    const forEachLayer = (text, cb) => {
        map.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }


    const changeStyle = (style) => {
        if (map){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'event-point-layer',
                'event-line-layer',
                'event-data-layer',
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = map.getSource(layer.source).serialize();
                    savedLayers.push(layer);
                });
            });

            map.setStyle(`mapbox://styles/${style}`);

            setTimeout(() => {

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        map.removeImage(icon.name);
                        map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        map.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {

                    const mapLayer = map.getLayer(layer.id);

                    if(typeof mapLayer === 'undefined') {
                        map.addLayer(layer);
                    }
                });

                map.setPaintProperty('event-line-layer', 'line-color', getComputedStyle(document.body).getPropertyValue('--journey-line-colour'))
            }, 1500);
        }
    }


    const generateMapData = (event) => {
        const eventFeatures = [];

        safeParse(event?.eventData).map(obj => {
            if(obj.lat !== 0){
                eventFeatures.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [obj.lng, obj.lat],
                    },
                    properties:
                    obj
                    ,
                });
            }
        });

        return {
            type: 'FeatureCollection',
            features: eventFeatures,
        }

    }


    const generateMapLine = (event) => {
        const lineData = []
        safeParse(event?.eventData).map(obj => {
            if(obj.lat !== 0){
                lineData.push([obj.lng, obj.lat])
            }
        });
        return lineData;
    }

    useEffect(() => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn')
            } else {
                changeStyle('fleetfocus/clyreqdlj00d701qr81uf1gsw')
            }
        }
    }, [darkMap])



    useEffect(() => {


        mapboxgl.accessToken =

            "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";

        // const mapStyle = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';
        // const mapStyle = 'mapbox://styles/fleetfocus/ckzd4tbpk005e14qe1hs9tqmd?optimize=true';
        const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn'
            : 'mapbox://styles/fleetfocus/clyreqdlj00d701qr81uf1gsw';


        // const markerRef = useRef(new mapboxgl.Marker(el));



        const initializeMap = ({setMap, mapContainer}) => {

            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center: [0.9142, 51.9037],
                zoom: 5
            });


            map.addControl(CustomControl, 'top-right');




            map.on('load', () => {

                    map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        map.removeImage(icon.name);
                        map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });




                setMap(map);
                map.resize();

                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();


                if (event.eventData) {
                    map.addSource('event-data-tracks', {
                        type: 'geojson',
                        data: generateMapData(event),
                    });

                    map.addSource('event-data-point', {
                        type: 'geojson',
                        data: generateEventMarker(event),
                    });

                    map.addSource('event-data-line', {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: generateMapLine(event)
                            }
                        }
                    });


                    // now add the layer, and reference the data source above by name

                    map.addLayer({
                        id: 'event-point-layer',
                        source: 'event-data-point',
                        type: 'symbol',
                        layout: {
                            'icon-image': [
                                'case',
                                ['==', 'severe', ['get', 'severity']], 'circle-red-svg',
                                ['==', 'moderate', ['get', 'severity']], 'circle-svg',
                                'circle-svg'
                            ],
                            'icon-ignore-placement': false,
                            'icon-allow-overlap': true,
                            'text-allow-overlap': true,

                            "icon-size": ['interpolate', ['linear'], ['zoom'], 10, 1.25, 15, 1],
                            'icon-anchor': 'center',
                        },
                        paint: {
                            'icon-color': [
                                'case',
                                ['==', 'bump', ['get', 'eventType']], '#3354b8',
                                ['==', 'cornering', ['get', 'eventType']], '#4a8939',
                                ['==', 'braking', ['get', 'eventType']], '#e04242',
                                ['==', 'acceleration', ['get', 'eventType']], '#fa8b3a',
                                '#000000'
                            ],
                            'text-color': '#ffffff'
                        },
                    },);


                    map.addLayer({
                        id: 'event-data-layer',
                        source: 'event-data-tracks',
                        type: 'symbol',
                        filter: ['!', ['has', 'point_count']],
                        layout: {
                            // full list of icons here: https://labs.mapbox.com/maki-icons
                            //'icon-image': 'airport-15',
                            'icon-image': 'lpArrow',
                            'icon-ignore-placement': false,
                            'icon-padding': 0,
                            'icon-allow-overlap': false,
                            // 'icon-size': 0.6,

                            "icon-size": ['interpolate', ['linear'], ['zoom'], 10, 1, 15, 0.5],


                            'icon-rotate': ['get', 'direction'],
                        },
                        paint: {
                            // 'icon-color': '#ffffff'

                            /*'icon-color': {
                                stops: [
                                    [1, '#ffffff'],
                                    [14, '#ffffff'],
                                    [15, '#7318ec']
                                ]
                            },*/

                            'icon-color': [
                                'case',
                                ['==', true, ['get', 'storeForward']], '#ec1818',
                                ['==', false, ['get', 'storeForward']], '#ffffff',
                                ['==', true, ['get', 'alarm']], '#5165fa',
                                '#ffffff'
                            ],


                        }
                    }, 'event-point-layer');


                    map.addLayer({
                        id: 'event-line-layer',
                        type: 'line',
                        source: 'event-data-line',

                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round',
                            'visibility': 'visible'
                        },
                        paint: {
                            //'line-color': '#d723d4',
                            'line-color': getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),

                            /*'line-offset': {    // works only for MAP SNAPPED!!
                                stops: [
                                    [1, 0],
                                    [14, 0],
                                    [18, -5],
                                    [22, -15]
                                ]
                            },*/

                            'line-width': {
                                base: 10,
                                stops: [
                                    [1, 5],
                                    [15, 12]
                                ]
                            },
                            'line-opacity': {
                                stops: [
                                    [1, 1],
                                    [14, 0.5],
                                    [20, 0.0]
                                ]
                            },

                        },

                    }, 'event-data-layer');      // 'eventsLayer');

                    const bounds = generateMapLine(event).reduce(function (b, coord) {
                        return b.extend(coord);
                    }, new mapboxgl.LngLatBounds(generateMapLine(event)[0], generateMapLine(event)[0]));

                    // console.log(bounds);
                    let padding = {};

                    map.fitBounds(bounds, {
                        padding: {bottom: 50, left: 50, top: 50, right: 50}, maxZoom: 18
                    });
                } else {
                    const marker1 = new mapboxgl.Marker()
                        .setLngLat([event.lng, event.lat])
                        .addTo(map);


                    map.flyTo({
                        center: [event.lng, event.lat],
                        zoom: 15,
                        speed: 2
                    });
                }

            });



        };


        if (!map) initializeMap({setMap, mapContainer});
    }, [map]);










    return (

        <div ref={el => (mapContainer.current = el)} style={styles}/>

    );

}
export default EventMap

