import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBusinessTime, faCar, faClock, faLocationDot, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";

const ActivityReportTableHeader = ({date, data}) => {

    const {darkMode} = useContext(indexContext);


    return (
        <div>
            {data?.journeys?.length > 0 ?
                <React.Fragment>

                    <h3 style={{textAlign:'center', height: '40px', background: darkMode ? 'var(--surface-card)' : '#e2e2e2',
                        lineHeight: '40px'}}>
                        {formatDateTimesheetReport(date)}
                    </h3>


                    <div style={{
                        display: 'grid', gridTemplateColumns: '5% 10% 8% 20% 10% 20% 10% 10% 8%',
                        borderBottom: '1px solid var(--surface-border)',
                        height: '30px', marginTop: '12px', pageBreakInside: 'auto', fontWeight: 'bold',
                        textAlign: 'center'}} className="reportTable">


                        <div style={{lineHeight: '30px', paddingLeft: '10px'}}>
                            #
                        </div>


                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faCar}/>
                            &nbsp;Vehicle
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faClock}/>
                            &nbsp;
                            Start time
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            &nbsp;Start place
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faClock}/>
                            &nbsp;
                            End time
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            &nbsp;
                            End place
                        </div>

                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faRuler}/>
                            &nbsp;
                            Distance
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faTimer}/>
                            &nbsp;
                            Driving
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faTimer}/>
                            &nbsp;
                            Stopped
                        </div>

                    </div>
                </React.Fragment>
                :
                <h3 style={{textAlign:'center', height: '40px'}}>{formatDateTimesheetReport(date)} - No Journeys</h3>
            }
        </div>
    )
}
export default ActivityReportTableHeader
