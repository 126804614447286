import React, {useContext, useEffect, useState} from 'react';
import FormatAddress from "../../../../../../functions/formatting/formatAddress";
import {DurationFromSeconds, formatTimeOnly} from "../../../../../../functions/formatting/formatDate";
import {regFromDn} from "../../../../../../functions/formatting/regFromDn";
import indexContext from "../../../../../contexts/indexContext";
import mainContext from "../../../../../contexts/mainContext";
import AddPlaceButton from "../../../grid/cardComponents/addPlaceButton";
import {gToKg} from "../../../../../../functions/formatting/gToKg";


const FleetCO2ReportTableRow = ({data, index}) => {

    const {darkMode} = useContext(indexContext);

    const {vehicles, handleDate, handleJourney, handlePageToShow, vehicle, deviceDetails} = useContext(mainContext);

    const [background, setBackground] = useState(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')


    useEffect(() => {
        setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')
    }, [darkMode])


    const viewJourney = (data) => {
        handleDate(new Date(data?.startTime));
        setTimeout(() => {
            handleJourney(data?._id);
            handlePageToShow('/map');

        }, 500);
    }


    return (
        <React.Fragment>
            {data?.journeys?.length > 0 ?
                <div style={{
                    display: 'grid', gridTemplateColumns: '5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%',
                    borderBottom: '1px solid var(--surface-border)', height: '73px',
                    pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
                    textAlign: 'center'}} className="reportTable"
                     onMouseEnter={() => setBackground(darkMode ? 'rgba(115,24,236, 0.3)' : 'rgba(115,24,236, 0.1)')}
                     onMouseLeave={() => setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')}
                     key={index}

                >
                    <div style={{lineHeight: '73px',paddingLeft: '10px'}} onClick={() => viewJourney(data)}>
                        {(index+1)}
                    </div>

                    <div style={{lineHeight: '73px',paddingLeft: '10px'}}>
                        {regFromDn(vehicles, data?.dn)}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {formatTimeOnly(data?.firstJourney?.startTime)}
                    </div>

                    <div  style={{lineHeight: '73px'}}>
                        {formatTimeOnly(data?.lastJourney?.endTime)}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {data?.journeys?.length}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {data?.totalDistance.toFixed(0)}
                        &nbsp;miles
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {data?.co2Per ?
                            <React.Fragment>{data?.co2Per}&nbsp;g/km</React.Fragment>
                            :
                            <React.Fragment>N/A</React.Fragment>
                        }
                    </div>
                    <div style={{lineHeight: '73px'}}>
                        {data?.co2Per > 0 ?
                            <React.Fragment>{gToKg(((data?.totalDistance * 1.60934) * data?.co2Per).toFixed(0))}</React.Fragment>
                        :
                            <React.Fragment>N/A</React.Fragment>
                        }
                    </div>

                </div>
            :
                <React.Fragment></React.Fragment>

            }
        </React.Fragment>
    )
}
export default FleetCO2ReportTableRow
