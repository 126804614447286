import React, {useContext, useEffect, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpWideShort, faEllipsisVertical} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Chip} from "primereact/chip";
import {friendlyFleetName} from "../../../functions/formatting/friendlyFleetName";
import {faCircleXmark} from "@fortawesome/pro-light-svg-icons";
import mainContext from "../../contexts/mainContext";
import indexContext from "../../contexts/indexContext";
import {Tooltip} from "primereact/tooltip";
import {Chart} from "primereact/chart";
import {getStoreData} from "../../../functions/db";

const VehicleListFilters = ({addChip, orderVehicleList, handleRemoveChip, showFilters, vListOrder,vListTypeOrder,
                                handleShowFilters, handleVListOrder, handleVListTypeOrder, }) => {

    const {vehicleFilterParams, handleVehicleFilterParams, vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext);

    const [inputVal, setInputVal] = useState('');
    const sortOptions = ['Registration', 'Last update', 'Health']
    let a = new Set(['driving', 'parked', 'idling']);



    const dStyle = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: 'var(--v-list-button-selected)',
        color: "var(--v-list-button-text-selected)",
        flex:1,
        border: 'none'
    }

    const dStyle2 = {
        padding: '0.3rem 0.3rem',
        margin: '3px',
        background: "var(--v-list-button)",
        color: "var(--v-list-button-text)",
        flex:1,
        border: 'none'
    }



    const handleKeyPressFilter = async (e) => {



        if(e.key === 'Enter' && e.target.value?.length >1){
            const params = {
                drivingStatus: vehicleFilterParams?.drivingStatus,
                search: "",
                chips: new Set([...vehicleFilterParams?.chips, e?.target?.value]),
                fleetsToShow: vehicleFilterParams?.fleetsToShow
            }
            handleVehicleFilterParams(params);
            setInputVal("");
            clearTimeout(typingTimer?.current);
        }

    }



    const addFleetToShow = (v) => {
        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: new Set([v.value.fleetId])
        }
        handleVehicleFilterParams(params);
    }

    const removeFleetToShow = (v) => {
        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: new Set([])
        }
        handleVehicleFilterParams(params);
    }


    const toggleDrivingState = async (s) => {
        const params = {
            drivingStatus: new Set(s),
            search: vehicleFilterParams?.search,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        }
        handleVehicleFilterParams(params);
    }



    const typingTimer = useRef();
    const doneTyping = (v) => {
        const params = {
            drivingStatus: vehicleFilterParams?.drivingStatus,
            search: v,
            chips: vehicleFilterParams?.chips,
            fleetsToShow: vehicleFilterParams?.fleetsToShow
        }
       handleVehicleFilterParams(params)

    }

    const testButton2 = async (v) => {
        clearTimeout(typingTimer?.current);
        typingTimer.current = setTimeout(() => doneTyping(v), 500);

        setInputVal(v);
    }




    const FleetDropdownOption = (option) => {
        return (
            <div className="country-item">
                {option.country &&
                    <img alt={option.country} src="images/flag/flag_placeholder.png"  className={`flag flag-${option.country.toLowerCase()}`} />
                }
                <div>{option?.fleetName}</div>
            </div>
        );
    }


    const DrivingStatusPie = ({params}) => {

        const [chartData, setChartData] = useState([]);

        const fetchData = async () => {
            const curStoredVehicles = await getStoreData('vehicles');

            const d = curStoredVehicles?.filter(v => v?.properties?.drivingStatus === 'driving');
            const p = curStoredVehicles?.length - d?.length
            setChartData({
                labels: [
                    'Driving ('+ d?.length+ ')',
                    'Parked ('+ p+ ')',
                ],
                datasets: [{
                    label: 'My First Dataset',
                    data: [d?.length, p],
                    backgroundColor: [
                        'green',
                        'red'
                    ]
                }]
            })
        }

        useEffect(async () => {
            await fetchData();
        }, [])



       return  (

           <React.Fragment>
               {params === 'driving' ?
                   <span>{chartData?.datasets?.[0]?.data?.[0]} vehicles</span>
                   :
                   <React.Fragment>
                       {params === 'parked' ?
                           <span>{chartData?.datasets?.[0]?.data?.[1]} vehicles</span>
                           :
                           <span>{chartData?.datasets?.[0]?.data?.[0] + chartData?.datasets?.[0]?.data?.[1]} vehicles</span>
                       }
                   </React.Fragment>
               }
           </React.Fragment>
           // <div style={{width: '150px'}}>
           //     <Chart type="pie" data={chartData} />
           // </div>
       )

    }



    return (
        <div>
            <div style={{width: '100%', display: 'flex', height: '45px'}}>
                <div style={{flex: 1}}>

                    <InputText value={inputVal} placeholder="Type to filter..."
                               onChange={(e) => testButton2(e.target.value)}
                               onKeyPress={(e) => {
                                   handleKeyPressFilter(e)
                               }}
                               style={{
                                   height: '100%', width: '100%', borderRadius: '2px', fontSize: '16px',
                                   border: 'solid 1px var(--surface-border)'
                               }}
                               className="vListFilterInput"/>
                </div>
                <div style={{
                    color: "var(--v-list-button-text)",
                    background: "var(--v-list-button)",
                    cursor: 'pointer',
                    width: '45px',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    borderRadius: '2px 0px 0px 2px'
                }}
                     onClick={() => handleShowFilters(!showFilters)}>

                    {/*<FontAwesomeIcon icon={faFilterList} style={{fontSize: '22px'}}/>*/}
                    <FontAwesomeIcon icon={faEllipsisVertical} style={{fontSize: '22px'}}/>

                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '12px',
                paddingTop: '12px',
                alignItems: 'center'
            }}>
                <Button
                    style={(vehicleFilterParams?.drivingStatus.has('driving') && vehicleFilterParams?.drivingStatus.size === 1) ? dStyle : dStyle2}
                    icon={(vehicleFilterParams?.drivingStatus.has('driving') && a.size === 1) ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    onClick={() => toggleDrivingState(['driving'])} label='Driving'
                    className="drivingStatusTooltip" data-pr-position="bottom"
                    />


                <Button
                    icon={(vehicleFilterParams?.drivingStatus.has('parked') && vehicleFilterParams?.drivingStatus.size === 2) ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    style={vehicleFilterParams?.drivingStatus.size === 2 ? dStyle : dStyle2}
                    onClick={() => toggleDrivingState(['parked', 'idling'])} label='Parked'
                    className="parkedStatusTooltip" data-pr-position="bottom"
                    />


                <Button icon={vehicleFilterParams?.drivingStatus.size === 3 ? 'pi pi-eye' : 'pi pi-eye-slash'}
                        style={vehicleFilterParams?.drivingStatus.size === 3 ? dStyle : dStyle2}
                        onClick={() => toggleDrivingState(['parked', 'driving', 'idling'])} label='All'
                        className="allStatusTooltip" data-pr-position="bottom"
                    />


                <Tooltip target=".drivingStatusTooltip">
                    <DrivingStatusPie params={'driving'} />
                </Tooltip>
                <Tooltip target=".parkedStatusTooltip">
                    <DrivingStatusPie params={'parked'} />
                </Tooltip>
                <Tooltip target=".allStatusTooltip">
                    <DrivingStatusPie params={'all'} />
                </Tooltip>

            </div>

            {showFilters &&
                <div style={{padding: '0px 5px 0px 5px'}}>

                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>

                        <Dropdown options={fleets} onChange={(e) => addFleetToShow(e)} optionLabel="fleetName" filter
                                  showClear
                                  filterBy="fleetName" placeholder="Fleet(s)" itemTemplate={FleetDropdownOption}/>


                        <Dropdown options={sortOptions} value={vListTypeOrder} style={{flex: 0}}
                                  placeholder="Order" onChange={async (e) => {
                            await handleVListTypeOrder(e?.value);
                            await orderVehicleList(e?.value, vListOrder)
                        }}/>

                        <Button icon={<FontAwesomeIcon icon={faArrowDownWideShort}/>}
                                style={vListOrder === 'Descending' ? dStyle : dStyle2}
                                onClick={async () => {
                                    await orderVehicleList(vListTypeOrder, 'Descending');
                                    await handleVListOrder('Descending');

                                }}/>
                        <Button icon={<FontAwesomeIcon icon={faArrowUpWideShort}/>}
                                style={vListOrder === 'Ascending' ? dStyle : dStyle2}
                                onClick={async () => {

                                    await orderVehicleList(vListTypeOrder, 'Ascending');
                                    await handleVListOrder('Ascending');
                                }}/>

                    </div>

                    {/*<div style={{margin: '0 auto', display: 'flex', justifyContent: 'space-between', paddingBottom: '5px'}}>*/}
                    {/*    <Dropdown  options={sortOptions} value={vListTypeOrder}*/}
                    {/*               placeholder="Vehicle order" onChange={(e) => setVListTypeOrder(e?.value)}/>*/}
                    {/*    <Dropdown  options={['Ascending', 'Descending']} value={vListOrder}*/}
                    {/*               placeholder="Asc/Desc" onChange={(e) => setVListOrder(e?.value)}/>*/}
                    {/*</div>*/}


                    {/*<div style={{margin: '0 auto', textAlign: 'center'}}>*/}
                    {/*    <Dropdown  options={fleets} onChange={(e) => addFleetToShow(e)} optionLabel="fleetName" filter showClear*/}
                    {/*               filterBy="fleetName" placeholder="Select Fleet(s)" itemTemplate={FleetDropdownOption} style={{width: '98%'}}/>*/}
                    {/*</div>*/}
                </div>
            }


            <div style={(vehicleFilterParams?.chips?.size > 0 || vehicleFilterParams?.fleetsToShow?.size > 0) ? {height: '53px'} : {}}>
                <div>
                    {[...vehicleFilterParams?.chips].map((c, index) => (
                        <Chip key={c} label={c} className="activeChips" removable onRemove={() => handleRemoveChip(c)}/>
                    ))}
                </div>

                <div>
                    {[...vehicleFilterParams?.fleetsToShow].map((c, index) => (
                        // <Chip key={index} label={getFriendlyFleetName(c)} className="activeChips" removable onRemove={() => removeFleetToShow(c)}/>
                        // <Button key={index} style={dStyle} label={getFriendlyFleetName(c)} onClick={() => removeFleetToShow(c)} />
                        <button key={index} className="fleetFilterButton" onClick={() => removeFleetToShow(c)}>
                            {friendlyFleetName(c, fleets)}

                            &nbsp;<FontAwesomeIcon icon={faCircleXmark}/>
                        </button>
                    ))}
                </div>
            </div>


            {/*<button onClick={() => handleDisplayType(displayType === 'drivers' ? 'vehicles' : 'drivers')}>*/}
            {/*    driver/vehicle toggle*/}
            {/*</button>*/}
        </div>
    )
}

export default VehicleListFilters