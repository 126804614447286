import React, {useContext, useEffect, useState} from 'react';
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import mainContext from "../../../../contexts/mainContext";
import fetchNotifications from "../../../../../api/notifications/fetchNotifications";
import {formatDateMediaGrid} from "../../../../../functions/formatting/formatDate";
import {maxSpeed} from "../../../../../functions/formatting/maxspeed";
import {copyToClipboard} from "../../../../../functions/copyToClipboard";

const InfoTab = () => {
    const {event} = useContext(mainContext);
    const [videoReqNoti, setVideoReqNoti] = useState();


    useEffect(async () => {
        if (event){
            if (event?.videos?.[0] && event?.eventSource === "user"){
                const data = await fetchNotifications({dn: event?.dn, notificationType: {$in : ['videoUploadComplete']},
                    "notificationDetails.videoRequest.mediaRecord.filename": event?.videos?.[0]?.file})
                setVideoReqNoti(data?.[0]?.notificationDetails?.videoRequest?.mediaRecord)
            } else {
                setVideoReqNoti({
                    email: "MFL",
                    requestDate: event?.endTime
                })
            }

        }
    }, [event])



    return (
        <React.Fragment>
            {fetchUserLevel() === 'superuser' ?
                <React.Fragment>
                    <h3 onClick={() => copyToClipboard(event?._id)}>_id: {event?._id}</h3>
                    <table border="1" style={{fontSize: '12px'}}>
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>mph</th>
                            <th>speed limit</th>
                            <th>Direction</th>
                            <th>G Sensor X</th>
                            <th>G Sensor Y</th>
                            <th>G Sensor Z</th>
                            <th>G Sensor Tilt</th>
                            <th>G Sensor Impact</th>
                        </tr>
                        </thead>
                        <tbody>
                        {safeParse(event?.eventData)?.length > 0 && safeParse(event?.eventData).map((el, i) =>
                            <tr key={i}>
                                <td>
                                    {el?.acquisitionTime}
                                </td>
                                <td>
                                    {(el?.speed / 100 * 0.621371).toFixed(2)}
                                </td>
                                <td>
                                    {maxSpeed(el?.maxspeed)}
                                </td>
                                <td>
                                    {el?.direction}
                                </td>
                                <td>
                                    {el?.gSensorX}
                                </td>
                                <td>
                                    {el?.gSensorY}
                                </td>
                                <td>
                                    {el?.gSensorZ}
                                </td>
                                <td>
                                    {el?.gSensorTilt}
                                </td>
                                <td>
                                    {el?.gSensorImpact}
                                </td>
                            </tr>
                        )
                        }
                        </tbody>
                    </table>
                    {event &&
                        <div>
                            <h3>Source</h3>
                            <pre>{event?.eventSource}</pre>
                            <h3>Type</h3>
                            <pre>{event?.eventType}</pre>
                        </div>
                    }

                    {event?.alarmData?.alarmJson &&

                        <div>
                            <h3>Alarm data</h3>
                            <pre>{JSON.stringify(safeParse(event?.alarmData?.alarmJson), null, 2)}</pre>
                        </div>
                    }


                    {event?.analysis &&
                        <div>
                        <h3>Analysis</h3>
                            <pre>
                                        {
                                            JSON.stringify(safeParse(event?.analysis), null, 2)
                                        }
                                    </pre>
                        </div>
                    }
                    {event?.overpass &&
                        <div>
                            <h3>Geospatial</h3>
                            <pre>
                                        {
                                            JSON.stringify(safeParse(event?.overpass), null, 2)
                                        }
                                    </pre>
                        </div>
                    }
                    {event?.rekognition &&
                        <div>
                            <h3>AI</h3>
                            <pre>
                                        {
                                            JSON.stringify(safeParse(event?.rekognition), null, 2)
                                        }
                                    </pre>
                        </div>
                    }
                </React.Fragment>
            :
                <React.Fragment>
                    <h3>Id: {event?._id}</h3>
                    <h3>Event Source: {event?.eventSource}</h3>
                    <h3>Event Type: {event?.eventType}</h3>
                    <h3>User: {videoReqNoti?.email}</h3>
                    {/*<h3>Request Date: {formatDateMediaGrid(videoReqNoti?.notificationDetails?.videoRequest?.mediaRecord?.startTime)}</h3>*/}
                    {/*<h3>Attempt Count: {videoReqNoti?.notificationDetails?.videoRequest?.mediaRecord?.attemptCount}</h3>*/}
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default InfoTab
