import React from 'react';

const SelectedTick = ({height}) => {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" style={{fill: 'var(--surface-card)'}}/>
                <g>
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" fill="none"/>
                </g>
                <g>
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" fill="none"/>
                </g>
                <path d="m291.91,93.44c6.75,6.75,6.75,17.72,0,24.47l-138.27,138.27c-6.75,6.75-17.72,6.75-24.47,0l-69.14-69.14c-6.75-6.75-6.75-17.72,0-24.47s17.72-6.75,24.47,0l56.93,56.88,126.07-126.01c6.75-6.75,17.72-6.75,24.47,0h-.05Z"
                style={{fill: 'var(--text-color)'}}/>
            </svg>
        </div>
    )
}
export default SelectedTick
