import React, {useEffect, useRef, useState} from 'react';
import videoUploadProgress from "./requestingMediaFunctions/videoUploadProgress";
import {ProgressBar} from "primereact/progressbar";
import {uploadTrackStageDisplay} from "./requestingMediaFunctions/uploadTrackStageDisplay";
import fetchVideo from "../../../api/media/fetchVideo";
import {Knob} from "primereact/knob";
import fetchEventsVideoFile from "../../../api/events/fetchEventsVideoFile";
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import {API_URL} from "../../../Constants";
import LoadingSpinner from "../loadingSpinner";


const MultiVideoUploadProgressTracker = React.forwardRef((props, ref) => {

    const {upload, vehicles, addVideo, mediaLoaded, videoLoaded, handleMediaEnded, cam, pauseMedia, playMedia,
    mediaSeeked, handleChnClicked} = props;


    const [uploadProgress, setUploadProgress] = useState({stage: 'pending', percentage: 0});
    const [theDevice, setTheDevice] = useState();
    const [complete, setComplete] = useState(false);
    const [video, setVideo] = useState();
    const [ready, setReady] = useState(false)

    const intervalRef = useRef();

    useEffect( async () => {
        if (upload && vehicles) {
            let theProgress = await videoUploadProgress(upload);
            if (theProgress.stage === 'processing' && theProgress.percentage === 100) setComplete(true)

            if (theProgress?.stage === 'error'){
                console.log('error')
            } else {
                const device = vehicles?.features.filter(v => v?.properties?.dn === upload?.dn);

                setTheDevice(device?.[0]?.properties);


                if (device?.[0]?.properties?.drivingStatus === 'offline') {
                    theProgress.stage = 'offline'
                }

                if (theProgress?.stage === 'processing' && (theProgress?.percentage) > 99){
                    clearInterval(intervalRef.current);

                } else {
                    intervalRef.current = setInterval(async () => {
                        let theProgress = await videoUploadProgress(upload);
                        setUploadProgress(theProgress);
                    }, 1000);
                }
            }
            setUploadProgress(theProgress);

        }
        return () => clearInterval(intervalRef.current)
    }, [upload, vehicles])

    useEffect(async () => {

        if (uploadProgress.stage === 'processing' && uploadProgress.percentage === 100){
            clearInterval(intervalRef.current);
            setComplete(true);
            const file = upload?.file ? upload?.file : upload?.filename
            const token = await FetchMediaToken(file);
            setVideo(API_URL +'/view-video/' + file + '?mt=' + token?.access_token);
        }
    }, [uploadProgress])

    useEffect(async () => {

        await new Promise(r => setTimeout(r, 1000));
        setReady(true);


        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])


    return (
        <React.Fragment>
            {ready ?
                <React.Fragment>
                    {complete ?

                        <React.Fragment>
                            {/*<div style={{width: '96%', aspectRatio: '16/9', position: 'absolute', background: 'red'}}*/}
                            {/*     onClick={() => handleChnClicked(cam?.channel)}></div>*/}
                            <video style={{width: '96%'}} src={video} controls={true} ref={ref}
                                   onLoadedMetadata={() => mediaLoaded()} onPlay={playMedia} onPause={pauseMedia}
                                   onCanPlayThrough={() => videoLoaded(cam.channel)} onEnded={() => handleMediaEnded()}
                                   onSeeked={(e) => mediaSeeked(e, cam)} onSeeking={(e) => handleChnClicked(cam.channel)}



                            />
                        </React.Fragment>


                        :
                        <div style={{paddingTop: '10px', width: '100%', height: '100%'}}>
                            {/*<ProgressBar value={uploadProgress?.percentage?.toFixed(0)} style={{width: '80%', margin: '0 auto'}}/>*/}
                            <Knob value={uploadProgress?.percentage?.toFixed(0)} style={{margin: '0 auto'}} valueTemplate={'{value}%'}/>
                            {theDevice?.deviceStatus !== 'offline' ?
                                <div style={{marginTop: '5px'}}>
                                    {uploadTrackStageDisplay(uploadProgress?.stage)}
                                </div>
                                :
                                <div style={{marginTop: '5px'}}>
                                    Device Offline - Upload Queued
                                </div>
                            }
                        </div>
                    }
                </React.Fragment>

            :
                <LoadingSpinner />
            }
        </React.Fragment>

    )
})

export default MultiVideoUploadProgressTracker
