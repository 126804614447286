import React, {useContext, useRef, useState} from 'react';
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import mainContext from "../../../../contexts/mainContext";
import {faSearch, faLink} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "primereact/button";
import EditCo2Button from "./editCo2";
import EditMpgButton from "./editMpg";
import postUpdateDvlaData from "../../../../../api/device/postUpdateDvlaData";
import {Toast} from "primereact/toast";
import {TOAST_DURATION} from "../../../../../Constants";
import {faL} from "@fortawesome/pro-thin-svg-icons";

const VehicleInfoTable = ({leftCol, rightCol}) => {

    const {fleets, deviceDetails, handleDeviceDetails} = useContext(mainContext);

    const toast = useRef();

    const lookupVehicleData = async () => {
        const result = await postUpdateDvlaData(deviceDetails);
        console.log(result)
        handleDeviceDetails(result);

        if (result?.vehicleDvlaData?.VehicleRegistration){
            toast?.current?.show({ severity: 'success', summary: 'Vehicle data updated', detail: 'Vehicle data updated',
                life: TOAST_DURATION });
        } else {
            toast?.current?.show({ severity: 'error', summary: 'Not updated', detail: 'Vehicle data not found', life: TOAST_DURATION });
        }
    }


    const VehicleDataTable = ({}) => {

        return (
            <table style={{width: '100%', fontSize: '14px', position: 'relative'}}>
                <tbody>
                {deviceDetails && deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Make &&
                    <tr>
                        <td colSpan={2} style={{textAlign: 'center'}}>
                            <img src={"https://cdn.vdicheck.com/badges/"+ deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Make?.toUpperCase()?.replace(' ', '') +".png?width=130"} />
                        </td>
                    </tr>
                }
                <tr>
                    <th style={leftCol}>Registration:</th>
                    <td style={rightCol}> {deviceDetails?.registration}</td>
                </tr>
                <tr>
                    <th style={leftCol}>Fleet name:</th>
                    <td style={rightCol}>
                        {friendlyFleetName(deviceDetails?.fleetId, fleets)}
                    </td>
                </tr>
                <tr>
                    <th style={leftCol}>Vehicle make:</th>
                    <td style={rightCol}>{deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Make}</td>
                </tr>
                <tr>
                    <th style={leftCol}>Vehicle model:</th>
                    <td style={rightCol}>{deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Model}</td>
                </tr>
                <tr>
                    <th style={leftCol}>Vehicle class:</th>
                    <td style={rightCol}>{deviceDetails?.vehicleDvlaData?.VehicleRegistration?.VehicleClass}</td>
                </tr>
                <tr>
                    <th style={leftCol}>Fuel type:</th>
                    <td style={rightCol}>{deviceDetails?.vehicleDvlaData?.VehicleRegistration?.FuelType}</td>
                </tr>
                <tr>
                    <th style={leftCol}>CO2 Emissions <super>*</super>:</th>
                    <td style={rightCol}>
                        <EditCo2Button />
                    </td>
                </tr>
                <tr>
                    <th style={leftCol}>Combined MPG <super>*</super>:</th>
                    <td style={rightCol}>
                        <EditMpgButton />



                        {/*<div style={{position: 'absolute', bottom: '95px', left: '220px', display: 'flex', flexDirection: 'column'}}>*/}
                        {/*    <FontAwesomeIcon icon={faL} style={{transform: 'scaleY(-1)', fontSize: '18px'}}/>*/}
                        {/*    <FontAwesomeIcon icon={faLink} style={{fontSize: '18px'}}/>*/}
                        {/*    <FontAwesomeIcon icon={faL} style={{fontSize: '18px'}} />*/}
                        {/*</div>*/}


                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{paddingTop: '10px', fontSize: '12px'}}>
                        <super>*</super>All emission and CO2 data is estimated using average performance figures.
                        The relationship between MPG and CO2 emissions is calculated using standard industry model.
                        CO2 Emission data is automatically retrieved from DVLA data where available.
                        Enter average vehicle MPG or manufacturer published combined cycle data to calculate CO2.
                    </td>
                </tr>
                </tbody>
            </table>

        )

    };

    return (
        <React.Fragment>
            {deviceDetails?.vehicleDvlaData?.VehicleRegistration ?
                <VehicleDataTable vehicleDvlaData={deviceDetails?.vehicleDvlaData} />
                :

                <div style={{textAlign:'center'}}>
                    No vehicle data
                    <br /><br />
                    <Button className="p-button-success p-button-sm"
                            label={<React.Fragment><FontAwesomeIcon icon={faSearch}/>&nbsp;Lookup vehicle data</React.Fragment>}
                    onClick={() => lookupVehicleData()}/>
                </div>
            }
            <Toast ref={toast} appendTo={null} position="bottom-right"/>
        </React.Fragment>
    )
}
export default VehicleInfoTable
