import React, {useContext, useState} from 'react';
import {MflLogo as NotTextLogo} from "../../svgs/mflLogo"
import {Colours} from "../../colours";
import './rudderMenu.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightFromBracket,
    faBell,
    faCarBump,
    faMapLocationDot,
    faUser,
    faCloudArrowUp, faCircleExclamation
} from "@fortawesome/pro-light-svg-icons";
import indexContext from "../contexts/indexContext";
import clearStorage from "../../functions/localStorage/clearStorage";
import {fetchBlockedEndpoints} from "../../functions/fetchBlockedEndpoints";




const RudderMenu = () => {

    const {handlePageToShow} = useContext(indexContext);


    const [menuOpen, setMenuOpen] = useState(false);




    const handleMapButtonClick = () => {
        if (window.location.pathname !== '/main'){
            // handleNavigation('/main');
            handlePageToShow('/map');
        } else {
            handlePageToShow('/map');
        }
    }

    const handleLogout  = () => {
        clearStorage();
    }










    return (

    <div style={{position:'absolute', bottom:'-5%', left:'50%', transform:'translate(-50%,-5%)'}}>
        <ul id="navbar" className={menuOpen ? "opened" : "closed"}>
            <li className={menuOpen ? "menu-item" : "menu-item hidden"} onClick={() => {setMenuOpen(!menuOpen);handleMapButtonClick();}}>
                <FontAwesomeIcon icon={faMapLocationDot} className="i"/>
            </li>
            {!fetchBlockedEndpoints().includes('view-snapshot') &&
                <li className={menuOpen ? "menu-item" : "menu-item hidden"} onClick={() => {
                    handlePageToShow('/events');
                    setMenuOpen(!menuOpen);
                }}>
                    <FontAwesomeIcon icon={faCircleExclamation} className="i"/>
                </li>
            }
            <li className="menu-item main">
                <div style={{marginTop: '-3px', marginLeft: '2px'}} onClick={() => setMenuOpen(!menuOpen)}>
                    <NotTextLogo size='33px'/>
                </div>
            </li>
            {!fetchBlockedEndpoints().includes('view-video') &&
                <li className={menuOpen ? "menu-item" : "menu-item hidden"} onClick={() => {
                    handlePageToShow('/media');
                    setMenuOpen(!menuOpen);
                }}>
                    <FontAwesomeIcon icon={faCloudArrowUp} className="i"/>
                </li>
            }

            {!fetchBlockedEndpoints().includes('view-snapshot') &&
                <li className={menuOpen ? "menu-item" : "menu-item hidden"} onClick={() => {handlePageToShow('/notifications'); setMenuOpen(!menuOpen);}} >
                    <FontAwesomeIcon icon={faBell} className="i"/>
                </li>
            }

        </ul>
    </div>
    )
}

export default RudderMenu

