import {API_URL} from "../../Constants";
import clearStorage from "../../functions/localStorage/clearStorage";


const fetchVideoBudget = (data) => {


    let token = localStorage.getItem('access_token');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    };

    return fetch(API_URL + '/video-budget/' + data.dn, requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
            return Promise.resolve(
                data
            );
        });
}

export default fetchVideoBudget


