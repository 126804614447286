import React, {useContext} from 'react';
import indexContext from "../../../../../contexts/indexContext";
import VehicleCo2ReportTableHeader from "./vehicleCo2ReportTableHeader";
import CO2ReportTableFooter from "./co2ReportTableFooter";
import VehicleCo2ReportTableRow from "./vehicleCo2ReportTableRow";

const VehicleCo2ReportTable = ({data}) => {

    const {darkMode} = useContext(indexContext)

    const parkedRow = {
        textAlign: 'center',
        lineHeight: '45px',
        borderBottom: '1px solid var(--surface-border)',
        background: (darkMode ? 'var(--surface-card)' : '#e2e2e2')
    }

    return (
        <React.Fragment>
            <VehicleCo2ReportTableHeader date={data.date} data={data}/>


            {data?.journeys?.length >= 1 &&
                <React.Fragment key={data?.journeys?.[0]?._id}>
                    {data?.journeys?.map((item, index) =>
                        <VehicleCo2ReportTableRow data={item} index={index} />

                    )}
                    <CO2ReportTableFooter data={data} />

                </React.Fragment>
            }



        </React.Fragment>

    )
}
export default VehicleCo2ReportTable
