import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const CollisionStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path d="m175.83,108.19c37.02,0,67.02,30.01,67.02,67.02s-30.01,67.02-67.02,67.02-67.02-30.01-67.02-67.02,30.01-67.02,67.02-67.02Zm0,152.33c47.11,0,85.3-38.19,85.3-85.3s-38.19-85.3-85.3-85.3-85.3,38.19-85.3,85.3,38.19,85.3,85.3,85.3ZM85.92,106.59c-4.23-2.74-9.9-1.52-12.64,2.74-12.22,19-19.31,41.62-19.31,65.88s7.08,46.88,19.31,65.88c2.74,4.23,8.38,5.48,12.64,2.74,4.27-2.74,5.48-8.38,2.74-12.64-10.4-16.15-16.41-35.34-16.41-55.98s6.02-39.83,16.41-55.98c2.74-4.23,1.52-9.9-2.74-12.64Zm179.82,0c-4.23,2.74-5.48,8.38-2.74,12.64,10.4,16.15,16.41,35.34,16.41,55.98s-6.02,39.83-16.41,55.98c-2.74,4.23-1.52,9.9,2.74,12.64,4.27,2.74,9.9,1.52,12.64-2.74,12.22-19,19.31-41.62,19.31-65.88s-7.08-46.88-19.31-65.88c-2.74-4.23-8.38-5.48-12.64-2.74h0Zm-98.79,29.69v41.31c0,4.83,3.66,9.07,8.49,9.33,5.22.28,9.51-3.83,9.51-8.99v-42c0-5.16-4.29-9.27-9.51-8.99-4.83.26-8.49,4.49-8.49,9.33Zm21.07,75.49c0-6.73-5.46-12.19-12.19-12.19s-12.19,5.46-12.19,12.19,5.46,12.19,12.19,12.19,12.19-5.46,12.19-12.19Z" fill="var(--text-color)" />
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Collision Warning</Tooltip>
            }
        </div>
    )
}
export default CollisionStacked
