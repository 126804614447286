import React, {useEffect, useState} from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import fetchSnapshot from "../../../api/media/fetchSnapshot";
import imageLoading from '../../../svgs/awaitingSnap.png'
import LoadingSpinner from "../loadingSpinner";
import { Image } from 'primereact/image';

const ImageMfl = ({file, svg, size}) => {
    const [isMounted, setIsMounted] = useState(true);
    const [testImage, setTestImage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        if (isMounted && file){
            setTimeout(async () => {
                const snap = await fetchSnapshot(file, size);
                setTestImage(URL.createObjectURL(snap));
                setTimeout(() => {setLoading(false);}, 500)
            }, 500)
        }
    }, [file]);

    useEffect(() => {
        return () => {
            setTestImage(null);
            setIsMounted(false);
        }
    }, []);


const mph = {
    fontSize: '11px',
    fontWeight: 'normal',
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fill: '#1b6082'
}

const small =  {
        fontSize: '15px',
        fontWeight: 'bold',
        textAnchor: 'middle',
        fontFamily: 'Arial Black'
    }

    const eventSpeed = {
        position: 'absolute',
        top: '25px',
        left: '3px'
    }

    let width = '100%';
    let height;

    if (size === 'lrg') {
         width = '100%';
         height = '100%'
    }

    const addDefaultSrc = (e) => {
        e.target.src = imageLoading
    }

    if (svg) {
        return (
            <div style={{position: 'relative'}}  className="hideOnPrint">
                <img src={testImage}    alt=""   width={width} height={height}
                     onError={(e) => {addDefaultSrc(e)}}
                />
            </div>
        );
    } else {
        return (
            <div style={{textAlign: 'center'}}>
                {loading ?
                    <LoadingSpinner />
                    // <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="4" animationDuration="1s"/>
                :
                    // <img src={testImage}    alt=""  className="product-image" width={width} height={height}
                    //        onError={(e) => {addDefaultSrc(e)}}
                    //
                    // />
                    <Image src={testImage}    alt=""  width={width} height={height}
                         onError={(e) => {addDefaultSrc(e)}}
                           preview

                    />
                }
            </div>
        );
    }
}


export default ImageMfl;
