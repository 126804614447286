import React from 'react';
import {DurationFromSeconds, timeFromSeconds} from "../../../../../../functions/formatting/formatDate";
import {fetchUserLevel} from "../../../../../../functions/fetchUserLevel";

const FleetIdlingReportSummary = ({actTotals, activityData}) => {

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>

            <div className="reportTotalBubble">
                <div><b>Total<br/>Journeys:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total<br/>Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance.toFixed(0)} miles</div>
            </div>

            {/*<div className="reportTotalBubble">*/}
            {/*    <div><b>Average Journey <br />Time:</b></div>*/}
            {/*    <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.duration / actTotals?.journeys)}</div>*/}
            {/*</div>*/}
            <div className="reportTotalBubble">
                <div><b>Average<br/>Distance:</b></div>
                <div
                    style={{paddingBottom: '15px'}}>{(actTotals?.distance.toFixed() / actTotals?.journeys).toFixed(2)} miles
                </div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Average Idling <br/>Time:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {fetchUserLevel() === 'superuser' ?
                        <React.Fragment>
                          i: {(actTotals?.idle / actTotals?.journeys)} v ti: {(actTotals?.trueIdling / actTotals?.journeys)}
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {actTotals?.idle > 0 ?  DurationFromSeconds(actTotals?.idle / actTotals?.journeys) : '0s'}
                        </React.Fragment>
                    }
                </div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Total Idling <br/>Time:</b></div>
                <div style={{paddingBottom: '15px'}}>

                    {fetchUserLevel() === 'superuser' ?
                        <React.Fragment>
                            i: {actTotals?.idle} v ti: {actTotals?.trueIdling}<br />
                            i: {((actTotals?.idle / actTotals?.duration) * 100).toFixed(0)}% v ti:
                            {((actTotals?.trueIdling / actTotals?.duration) * 100).toFixed(0)}%
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {DurationFromSeconds(actTotals?.idle)}
                            ({((actTotals?.idle / actTotals?.duration) * 100).toFixed(0)}%)
                        </React.Fragment>
                    }



                </div>
            </div>

        </div>

    )
}

export default FleetIdlingReportSummary
