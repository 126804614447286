import {MODEL_NUMBER_ARRAY} from '../../Constants.js'

export const modelNumberDisplay = (no) => {
    let display;
    if (no === 'ME41-02'){
        display = 'MFL-2CN4H01-EU';
    } else {
        MODEL_NUMBER_ARRAY.forEach(model => {
            if (model.modelNoDb === no){
                display = model.modelNoDisplay;
            }
        });
    }
    return display;
}
