import React, {useEffect, useRef, useState} from 'react';
import {Slider} from "primereact/slider";
import {formatDate, formatDateMediaGrid, formatTimeOnly, getTimeDifference} from "../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCircleInfo, faCloudArrowUp, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import HyperlapseMarker from "../panelContent/map/mapMarkers/hyperlapseMarker";
import {Button} from "primereact/button";
import fetchAllHyperlapses from "../../../api/media/fetchAllHyperlapses";
import {Message} from "primereact/message";
import {NOTIFICATIONS_CALLBACK_URL, TOAST_DURATION} from "../../../Constants";
import postHyperlapseRequest from "../../../api/media/postHyperlapseRequest";
import {Checkbox} from "primereact/checkbox";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";

const RequestHyperlapseFromRecording = ({recSelected, deviceDetails, vehicle}) => {

    const [rangeValues, setRangeValues] = useState([new Date(recSelected.st).getTime() / 1000, new Date(recSelected.st).getTime() / 1000 + 600])
    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [ requestedMessage, setRequestedMessage ] = useState('')
    const [lessThanTwo, setLessThanTwo] = useState(false);
    const [curUploadingChannels, setCurUploadingChannels]= useState([]);
    const [emailChecked, setEmailChecked] = useState(true);
    const [sendingRequest, setSendingRequest] = useState(false);
    const toast = useRef();






    const handleTimeChange = (e) => {

        setRangeValues(e.value)
        setLessThanTwo((e.value[1] * 1000 - e.value[0] * 1000) < 120000)
    }

    const addRemoveCameraToReq= (v) => {
        if(camsToReq.has(v)){
            camsToReq.delete(v)
            setCamsToReq(new Set([...camsToReq]));
        } else {
            setCamsToReq(new Set([...camsToReq, v]));
        }
    }

    const hlExistingUpload = () => {
        const theCams = deviceDetails?.cameras.filter(cam => curUploadingChannels.includes(cam.channel.toString()))

        if (curUploadingChannels?.length === 1){

            return "A hyperlapse is being uploaded for the "+ theCams[0].camPosition.toLowerCase() + " camera at the moment, please wait for this to complete" +
                " before requesting another from this camera."
        } else {


            let str = "Some cameras already have hyperlapses being uploaded ("

            theCams.forEach(cam => {
                str+= cam.camPosition.toLowerCase() + ", "
            });

            if(str.slice(-2) === ', '){
                str = str.slice(0, -2);
            }

            str+= "). Please wait for these to complete before requesting another from these cameras."
            return str;
        }

    }

    const handleHyperlapseRequest = async () => {
        setSendingRequest(true);


        const difference = (rangeValues[1] * 1000 - rangeValues[0] * 1000);
        let resultInMinutes = Math.round(difference / 60000);

        if (resultInMinutes < 1){
            resultInMinutes = 1;
        }

        const responses = [];

        const arr = [...camsToReq];
        const toastMessages = [];

        for (let i = 0; i < arr.length; i++) {
            const cam = arr[i];

            const dataToSend = {
                dn: deviceDetails.dn,
                channel: cam.channel,
                startTimeHyper: formatDate(rangeValues[0] * 1000),
                durationMinutes: resultInMinutes,
                totalFrames: 100,
                callback: NOTIFICATIONS_CALLBACK_URL
            };

            const r = await postHyperlapseRequest(dataToSend, deviceDetails, i === 0 ? emailChecked : false);

            responses.push(r);

            if (r.status === 'ok'){
                toastMessages.push( { severity: 'success', summary: 'All done', detail: cam.camPosition + ' hyperlapse requested', life: TOAST_DURATION },)
            } else {
                toastMessages.push( { severity: 'error', summary: 'Error', detail: cam.camPosition + ' error ' + r.error, life: TOAST_DURATION },)
            }
        }


        await new Promise(r => setTimeout(r, 2000));

        toast.current.show(toastMessages);
        setSendingRequest(false);
    }



    useEffect(() => {
        if(deviceDetails){
            const front = deviceDetails?.cameras.filter(cam => cam.camPosition === 'Front');
            setCamsToReq(new Set(front))
        }
    }, [deviceDetails]);


    useEffect(async () => {

        const channelsUploading = [];

        const existingHl = await fetchAllHyperlapses(deviceDetails);

        if(existingHl.queued.length > 0){
            existingHl.queued.forEach(hl => {
                const daysSinceReq = (new Date().getTime() - new Date(hl.requestDate).getTime()) / 86400000;

                if (daysSinceReq < 2){
                    channelsUploading.push(hl.channel.toString());
                }
            });
        }
        setCurUploadingChannels(channelsUploading)
    }, [])



    return (




       <div style={{textAlign: 'center'}}>
           <table style={{width: '100%'}}>
               <tbody>
               <tr>
                   <th>Start time</th>
                   <td></td>
                   <th>End time</th>
               </tr>
               <tr>
                   <td>{formatDateMediaGrid(new Date(rangeValues[0] * 1000 ))}</td>
                   <td><FontAwesomeIcon icon={faArrowRight} /></td>
                   <td>{formatDateMediaGrid(new Date(rangeValues[1] * 1000 ))}</td>
               </tr>

               </tbody>
           </table>
            <br />

           <div style={{display: 'flex'}}>
               <div style={{width: '32px', textAlign: 'left'}}>
                   <HyperlapseMarker color="green" />
               </div>
               <div style={{flex:1}}>
                   <Slider value={rangeValues}
                           min={new Date(recSelected.st).getTime() / 1000}
                           max={new Date(recSelected.et).getTime() / 1000}
                           step={20}
                           onChange={(e) => handleTimeChange(e)} range
                           style={{marginTop: '5px'}}
                   />

               </div>
               <div style={{width: '20px', textAlign: 'right'}}>
                   <HyperlapseMarker color="red" />
               </div>
           </div>

           <div style={{fontWeight: 800, fontSize: '16px', paddingTop: '15px'}}>
               {getTimeDifference(rangeValues[0] * 1000 , rangeValues[1] * 1000 )}
           </div>


           {deviceDetails?.cameras.map(function (cam, index){
               return (
                   <div key={index} style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
                       <Button onClick={() => addRemoveCameraToReq(cam)} disabled={curUploadingChannels.includes(cam.channel)}
                               className={camsToReq.has(cam) ? 'p-button' : 'p-button p-button-secondary'}
                               tooltip={'Channel: ' + cam.channel} tooltipOptions={{position: 'bottom'}}>
                           {cam.camPosition}
                       </Button>
                   </div>
               )
           })}

           <br />
           <br />
           <div>
               <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
               <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
           </div>






           <br />
           <br />

           {camsToReq?.size === 0 &&
               <div  style={{width:'80%', margin: '0 auto', marginBottom: '15px'}}>
                   <Message severity="warn" text="Please select camera(s)" />
               </div>
           }

           {!requestedMessage &&
               <button className="p-button" onClick={handleHyperlapseRequest} disabled={camsToReq.size < 1 || lessThanTwo ||
                    sendingRequest}
                       style={{width: '80%'}}>

                   {sendingRequest ?
                       <span className="p-button-label">
                            <FontAwesomeIcon icon={faSpinner} spin/>
                           &nbsp;Sending request
                          </span>
                       :
                       <span className="p-button-label">
                            <FontAwesomeIcon icon={faCloudArrowUp}/>
                           &nbsp;Upload Hyperlapse
                          </span>
                   }
               </button>
           }

           {/*{rangeValues?.[1] - rangeValues?.[0] > 1801 &&*/}
           {/* <Message severity="warn" text="The maximum duration for a hyperlapse is 30 minutes"*/}
           {/*          style={{width: '80%', justifyContent: 'center', marginTop: '10px'}}/>*/}
           {/*}*/}



           {requestedMessage &&
               <Message severity={requestedMessage === 'Success' ? 'success' : 'error'} text={requestedMessage}
                        style={{width: '80%', justifyContent: 'center'}}/>
           }



           {lessThanTwo &&
               <Message severity='warn' text="Hyperlapses must be at least 2 minutes in length"
                        style={{width: '80%', justifyContent: 'center', marginTop: '5px'}}/>
           }

           {curUploadingChannels.length > 0 &&
               <Message severity='warn' text={hlExistingUpload()} style={{width: '80%', justifyContent: 'center', marginTop: '5px'}}/>
           }

           <div style={{textAlign: 'center', marginTop: '20px'}} className="HLUploadFromRecHelp" data-pr-position="top">
               <FontAwesomeIcon icon={faCircleInfo} />
               &nbsp;Help
           </div>

           <Tooltip target=".HLUploadFromRecHelp">
               <div style={{width: '250px'}}>
                   Hyperlapse uploads timelapse journey footage. <br/><br />The start and end times can be finely adjusted
                   using the slider. <br/><br/>You can monitor the progress of your upload here or check your notification Inbox at any time.
               </div>
           </Tooltip>

           <Toast ref={toast} appendTo={null} position="bottom-right"/>

       </div>


    )
}

export default RequestHyperlapseFromRecording
