import React, {useContext, useState} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import mainContext from "../../../../contexts/mainContext";
import postHelpRequest from "../../../../../api/device/postHelpRequest";
import {Message} from "primereact/message";
import {Dropdown} from "primereact/dropdown";

const RequestSupport = ({}) => {

    const {vehicle, deviceDetails} = useContext(mainContext);

    const [value, setValue] = useState('');
    const [showBtn, setShowBtn] = useState(true);
    const [category, setCategory] = useState();

    const categories = ["Tracking Issue", "Video Upload Issue", "Hyperlapse Upload Issue",
    "Device Not Online", "Camera Issue", "Change of Registration"];


    const submitHelpRequest = async () => {

        if (value?.length > 5){
            setShowBtn(false)
            await postHelpRequest({
                email: localStorage.getItem('email'),
                dn: vehicle?.properties?.dn,
                message: value,
                category:category,
                vehicle:deviceDetails
            });
            setTimeout(() => {
                setShowBtn(true)
            }, 2000)
        }
    }

    return (
        <div style={{textAlign: 'center'}}>
            <h4>Submit Support Ticket</h4>
            <div style={{marginBottom: '20px', lineHeight: '20px', marginTop: '20px', fontSize: '12px'}}>
                To open a support ticket for this device,
                please select a category and provide a brief description of the problem.
            </div>

            <div style={{textAlign: 'left'}}>
                <div style={{marginBottom: '10px'}}><b>User:</b> {localStorage.getItem('email')}</div>
                <div style={{marginBottom: '10px'}}><b>Subject:</b> Open Support Ticket</div>
                <div><b>Category:</b>&nbsp;
                    <Dropdown value={category} placeholder={"Please select a category"}
                              options={categories} onChange={(e) => setCategory(e.value)}/>
                </div>
            </div>


            <br/>


            <InputTextarea autoResize value={value} onChange={(e) => setValue(e.target.value)}
                           rows={15} cols={90} style={{width: '100%', height: '300px'}}/>


            <center>
            <br/>
                {showBtn ?
                    <button onClick={submitHelpRequest} className="p-button p-button-sm p-button-secondary">
                        <span className="p-button-label">Submit Ticket</span>
                    </button>
                    :
                    <Message severity="success" text="Ticket submitted"/>
                }
            </center>
        </div>
    )
}

export default RequestSupport
