import React, {useContext, useEffect, useState} from 'react';
import {DurationFromSeconds} from "../../../../../../functions/formatting/formatDate";
import mainContext from "../../../../../contexts/mainContext";
import {gToKg} from "../../../../../../functions/formatting/gToKg";

const FleetCO2ReportTableFooter = ({data}) => {
    const {deviceDetails} = useContext(mainContext);
    const [totalDistance, setTotalDistance] = useState();
    const [totalCo2, setTotalCo2] = useState()

    useEffect(() => {
        if (data){
            let totCo2 = 0;
            let totDist = 0;

            data?.vData?.forEach(v => {
                totCo2 += (v?.totalDistance* 1.60934) * v?.co2Per;
                totDist += v?.totalDistance;
            });

            setTotalDistance(totDist);
            setTotalCo2(totCo2)
        }
    }, [data])



    return (
        <div style={{
            display: 'flex', justifyContent: 'center',
            height: '73px', pageBreakInside: 'avoid', pageBreakAfter: 'auto', textAlign: 'center'}} className="reportTable">

            <div style={{lineHeight: '30px'}}>
                <b>Totals:</b>&nbsp;
                {totalDistance?.toFixed(0)}
                &nbsp;miles travelled,
                &nbsp;
                {gToKg(totalCo2?.toFixed(0))}
                &nbsp; of CO2

            </div>


        </div>
    )
}
export default FleetCO2ReportTableFooter
