import React from 'react';


const Fleetfocus = ({}) => {

        return (
<React.Fragment>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1920 632.08" xmlSpace="preserve">

        <g>
            <g>
                <path d="M416.19,314.27c0-55.93-45.49-101.42-101.42-101.42s-101.43,45.5-101.43,101.42
			c0,55.93,45.5,101.43,101.43,101.43S416.19,370.2,416.19,314.27z" style={{fill: '#00ADB7'}}/>
                <polygon points="572.65,572.16 384.63,572.16 384.63,628.54 629.03,628.54 629.03,384.11 572.65,384.11" style={{fill: '#00ADB7'}}/>
                <polygon points="384.63,0 384.63,56.38 572.65,56.38 572.65,244.41 629.03,244.41 629.03,0" style={{fill: '#00ADB7'}}/>
                <polygon points="56.88,56.38 244.92,56.38 244.92,0 0.5,0 0.5,244.41 56.88,244.41" style={{fill: '#00ADB7'}}/>
                <polygon points="56.88,384.11 0.5,384.11 0.5,628.54 244.92,628.54 244.92,572.16 56.88,572.16" style={{fill: '#00ADB7'}}/>
            </g>
            <g>
                <path d="M768.37,0.21h172.45v45.21H824.38v71.62h89.22v44.41h-89.22v118.03h-56.01V0.21z"  style={{fill: "var(--text-color)"}}/>
                <path d="M999.04,0.21h56.02v279.28h-56.02V0.21z" style={{fill: "var(--text-color)"}}/>
                <path d="M1221.08,283.1c-64.42,0-111.23-44.81-111.23-114.43c0-70.02,45.61-114.43,111.23-114.43
			c64.02,0,109.24,43.61,109.24,109.63c0,7.2-0.4,14.4-1.6,21.61h-162.04c2.8,32.81,25.2,51.21,53.22,51.21
			c24,0,37.21-12,44.41-26.81h60.42C1312.7,250.68,1275.9,283.1,1221.08,283.1z M1167.06,147.86h105.23
			c-0.8-29.21-24.01-47.61-52.81-47.61C1192.67,100.24,1171.47,117.45,1167.06,147.86z"  style={{fill: "var(--text-color)"}}/>
                <path d="M1481.92,283.1c-64.42,0-111.23-44.81-111.23-114.43c0-70.02,45.61-114.43,111.23-114.43
			c64.02,0,109.24,43.61,109.24,109.63c0,7.2-0.4,14.4-1.6,21.61h-162.04c2.8,32.81,25.2,51.21,53.22,51.21
			c24,0,37.21-12,44.41-26.81h60.42C1573.55,250.68,1536.74,283.1,1481.92,283.1z M1427.9,147.86h105.23
			c-0.8-29.21-24.01-47.61-52.81-47.61C1453.51,100.24,1432.31,117.45,1427.9,147.86z"  style={{fill: "var(--text-color)"}}/>
                <path d="M1654.51,103.84h-26.4V57.83h26.4V0.21h56.42v57.62h49.62v46.01h-49.62v107.23c0,14.8,6,21.21,23.61,21.21
			h26.01v47.21h-35.21c-42.41,0-70.82-18-70.82-68.82V103.84z" style={{fill: "var(--text-color)"}}/>
            </g>
            <g>
                <path d="M768.37,349.28h172.4v45.2h-116.4v71.6h89.2v44.4h-89.2v118h-56V349.28z" style={{fill: "var(--text-color)"}}/>
                <path d="M1066.26,632.08c-64,0-112.4-44.8-112.4-114.4c0-70,50-114.4,114-114.4c64,0,114.01,44.4,114.01,114.4
			C1181.86,587.28,1130.67,632.08,1066.26,632.08z M1066.26,583.28c29.6,0,57.99-21.6,57.99-65.6c0-44.4-27.6-65.6-57.2-65.6
			c-29.6,0-56.4,21.2-56.4,65.6C1010.66,561.68,1036.26,583.28,1066.26,583.28z" style={{fill: "var(--text-color)"}}/>
                <path d="M1329.27,403.28c55.21,0,92.4,28.8,104.4,78.4h-60.4c-6.41-18.8-21.2-30.8-44.41-30.8
			c-31.2,0-52,23.6-52,66.8s20.8,66.4,52,66.4c23.2,0,37.6-10.4,44.41-30.4h60.4c-12,47.2-49.2,78.4-104.4,78.4
			c-64,0-109.59-44.8-109.59-114.4S1265.27,403.28,1329.27,403.28z" style={{fill: "var(--text-color)"}}/>
                <path d="M1692.12,628.48h-56.4v-28c-14,18.8-38.4,30.8-66,30.8c-51.6,0-88.8-33.6-88.8-94.4v-130h56v122
			c0,35.6,19.6,54.8,49.2,54.8c30.4,0,49.6-19.2,49.6-54.8v-122h56.4V628.48z" style={{fill: "var(--text-color)"}}/>
                <path d="M1833.3,632.08c-55.6,0-94.4-32.4-96.8-73.6h56.4c2,16.8,17.59,29.6,40,29.6c22,0,34-10,34-22.8
			c0-39.6-125.19-14.4-125.19-95.6c0-36.8,32.8-66.4,87.59-66.4c53.6,0,86,29.2,89.2,73.2h-53.6c-1.6-17.2-15.2-28.8-37.2-28.8
			c-20.8,0-31.6,8.4-31.6,21.2c0,40.4,122.8,15.2,124.4,96.4C1920.5,603.28,1887.3,632.08,1833.3,632.08z" style={{fill: "var(--text-color)"}}/>
            </g>
            <path d="M768.37,0.21" style={{fill: '#283480'}}/>
        </g>
    </svg>
</React.Fragment>

        )
        }
        export default Fleetfocus
