import React, {useContext, useState} from 'react';
import {MflLogo} from "../../svgs/logo-myfleetlive";
import {Colours} from "../../colours";
import indexContext from "../contexts/indexContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightFromBracket,
    faCars,
    faKey, faMapLocationDot,
    faRuler,
    faSpaceStationMoon,
    faUser
} from "@fortawesome/pro-light-svg-icons";
import {InputSwitch} from "primereact/inputswitch";
import clearStorage from "../../functions/localStorage/clearStorage";

import './mobileHeader.css'
import {faSidebar} from "@fortawesome/pro-regular-svg-icons";

const MobileHeader = ({}) => {

    const {darkMode, handleDarkMode, handlePageToShow, darkMap, handleDarkMap} = useContext(indexContext);

    const [showUserSubmenu, setShowUserSubmenu] = useState(false);

    const handleLogout  = () => {
        clearStorage();
    }


    return (
        <div style={{height: '5vh', display: 'flex', alignItems:'center', padding: '0px 5px', justifyContent: 'space-between'}}>
            <div onClick={() => window.location.reload()}>
                <MflLogo style={{fill:Colours.logoTextColour}} size='120px' textColor={darkMode ? 'white' : 'black'}/>
            </div>

            <div style={{position: 'relative', flex:1, textAlign: 'right'}}>
                <FontAwesomeIcon icon={faUser} style={{fontSize: '26px'}}  onClick={() => {setShowUserSubmenu(!showUserSubmenu)}}/>


                <ul className="nav-dropdown__mobile" style={{ display: showUserSubmenu ? 'block': 'none'}}>

                    <li style={{textAlign:'left', fontWeight: 'bold', padding: '5px'}}>

                        <FontAwesomeIcon icon={faSidebar} size="xl" style={{display:'inline-block', float:'left'}}/>

                        <div style={{display:'inline-block', marginLeft: '10px'}}>Dark Menu</div>

                        <InputSwitch checked={darkMode} onChange={handleDarkMode}
                                     style={{display:'inline-block', float:'right'}}/>


                    </li>

                    <li style={{textAlign:'left', fontWeight: 'bold', padding: '5px'}}>

                            <FontAwesomeIcon icon={faMapLocationDot} size="xl" style={{display:'inline-block', float:'left'}}/>

                            <div style={{display:'inline-block', marginLeft: '10px'}}>Dark Map</div>

                            <InputSwitch checked={darkMap} onChange={handleDarkMap}
                                         style={{display:'inline-block', float:'right'}}/>


                    </li>





                    {/*<li>*/}
                    {/*                <span style={{textAlign:'left', paddingLeft:'6px'}} className="logout"*/}
                    {/*                      onClick={handleChangePassword}>*/}
                    {/*                    <FontAwesomeIcon icon={faKey} size="xl"*/}
                    {/*                                     style={{display:'inline-block', float:'left', marginRight:'5px'}}/>*/}
                    {/*                   <div style={{display:'inline-block', marginLeft:'10px'}}>Change password</div>*/}

                    {/*                </span>*/}
                    {/*</li>*/}

                    <li style={{textAlign:'left', fontWeight: 'bold', padding: '5px'}} onClick={handleLogout}>

                        <FontAwesomeIcon icon={faArrowRightFromBracket} size="xl" style={{display:'inline-block', float:'left'}}/>
                        <div style={{display:'inline-block', marginLeft:'10px'}}>Logout</div>

                    </li>

                </ul>
            </div>
        </div>
    )
}

export default MobileHeader
