import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faForward} from "@fortawesome/free-solid-svg-icons";
import {faCircle as farCircle} from "@fortawesome/free-regular-svg-icons";

const HyperlapseMarker = ({color}) => {
    return (
        <span className="fa-layers fa-fw" style={{color: color, marginLeft: '-11px'}}>
            <FontAwesomeIcon icon={faCircle} size='3x' color="white"/>
            <FontAwesomeIcon icon={farCircle} size='3x' color="lightgrey"/>
            <FontAwesomeIcon icon={faForward} size='lg' style={{marginLeft:'11px'}}/>
        </span>
    )
}


export default HyperlapseMarker
