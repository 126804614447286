import React, {useEffect, useState} from 'react';
import {safeParse} from "../../../../../functions/formatting/safeParse";
import {Colours} from "../../../../../colours";
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../../../functions/formatting/uCaseFirst";
import EventClassificationIcon from "./eventClassificationIcon";
import {AdasEventTypes, DmsEventTypes} from "../../../../../Constants";
import PinShockNew from "../../../../../svgs/eventClassificationIcons/pin-shock-new";
import PinBrakeNew from "../../../../../svgs/eventClassificationIcons/pin-brake-new";
import PinTurningNew from "../../../../../svgs/eventClassificationIcons/pin-turning-new";
import PinAccelerationNew from "../../../../../svgs/eventClassificationIcons/pin-acceleration-new";
import PinUserNew from "../../../../../svgs/eventClassificationIcons/pin-user-new";
import PinJourneyEndNew from "../../../../../svgs/eventClassificationIcons/pin-journeyEnd-new";
import PinDms from "../../../../../svgs/eventClassificationIcons/pin-dms";
import PinAdas from "../../../../../svgs/eventClassificationIcons/pin-adas";
import PinEngineerNew from "../../../../../svgs/eventClassificationIcons/pin-engineer-new";
import ShockStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/shock";
import BrakingStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/braking";
import CorneringStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/cornering";
import AccelerationStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/acceleration";
import VideoStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/video";
import JourneyEndStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/journeyEnd";
import EngineerStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/engineer";
import WebfleetStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/webfleet";
import UserStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/user";
import SosStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/sos";
import EventSpeedLimitIcon from "./eventSpeedLimitIcon";


const StackedSeverityClassificationIcon = ({event, size}) => {

    const [analysis, setAnalysis] = useState();
    const [classification, setClassification] = useState();
    const [style, setStyle] = useState();

    useEffect(async () => {

        let analysisRaw = event?.severity ? event?.severity :
            (event?.analysis ? safeParse(event?.analysis)?.severity?.toLowerCase() : event?.commissionStatus);


        let typeRaw = safeParse(event?.analysis)?.eventType ? safeParse(event?.analysis)?.eventType : event?.eventType;


        if (event?.eventSource === 'SOS'){
            typeRaw = 'sos';
        }

        if (event?.eventSource === 'speedReport'){
            typeRaw = 'speeding';
        }

        if (event?.eventSource === 'DMS-ADAS'){
            if (DmsEventTypes.includes(event?.eventType.toLowerCase())){
                typeRaw = 'dms'
            }

            if (AdasEventTypes.includes(event?.eventType.toLowerCase())){
                typeRaw = 'adas'
            }
        }

        if (event?.commissionStatus){
            typeRaw = 'commissioning'
        }

        const obj = {
            width: size,
            height: size,
            borderRadius: size,
            background: Colours.onlineGreen,
            position: 'relative'
        }

        switch(analysisRaw){
            case 'mild':
                break
            case 'moderate':
                obj.background = Colours.standbyYellow;
                break
            case 'severe':
                obj.background = Colours.offlineRed;
                break



            case 'service':
                analysisRaw = "mode set to " + analysisRaw;
                obj.background = Colours.standbyYellow;
                break

            case 'disabled':
                analysisRaw = "mode set to " + analysisRaw;
                obj.background = Colours.offlineRed;
                break

            case 'active':
                analysisRaw = "mode set to " + analysisRaw;
                break

            default:
                obj.background = Colours.onlineGreen;
                break
        }
        setAnalysis(analysisRaw);
        setClassification(typeRaw);
        setStyle(obj);

    }, [event])

    const ClassificationIcon = (data, height) => {
        switch(data){
            case 'bump': return <ShockStacked event={event} height={height}  />
            case 'dms': return <PinDms height={height} event={event} />
            case 'adas': return <PinAdas height={height} event={event} />
            case 'braking':return <BrakingStacked height={height} event={event}/>
            case 'cornering': return <CorneringStacked height={height} event={event}/>
            case 'acceleration': return <AccelerationStacked height={height} event={event}/>
            case 'video': return <VideoStacked height={height} event={event}/>
            case 'journeyEnd': return <JourneyEndStacked height={height} event={event}/>
            case 'webfleet': return <WebfleetStacked height={height} event={event}/>
            case 'sos': return <SosStacked height={height} event={event}/>
            case 'commissioning': return <EngineerStacked height={height} event={event}/>
            case 'speeding': return <EventSpeedLimitIcon speedLimit={event?.analysis?.speeding?.[0]?.limit} size={25} event={event}/>
            default: return <UserStacked height={height} event={event}/>
        }
    }

    return (
        <React.Fragment>
            {ClassificationIcon(classification, size)}
        </React.Fragment>
    )


}

export default StackedSeverityClassificationIcon
