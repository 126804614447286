export const gToKg = (g) => {
    let str;
    const kg = g / 1000;
    if (kg > 1000){
        str = (kg /1000)?.toFixed(2) + ' tonnes';
    } else {
        str = kg?.toFixed(2) + ' kg';
    }

    return str;
}
