import React, {useContext, useEffect, useRef, useState} from 'react';
import {API_URL} from '../../../Constants.js'
import {ProgressSpinner} from "primereact/progressspinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackward, faDownLeftAndUpRightToCenter, faDownload,
    faForward, faGauge, faGaugeHigh, faGaugeLow, faGaugeMax,
    faPause,
    faPlay,
    faUpRightAndDownLeftFromCenter
} from "@fortawesome/pro-regular-svg-icons";
import {Slider} from "primereact/slider";
import RequestVideoFromHyperlapse from "../requestingMedia/requestVideoFromHyperlapse";
import mainContext from "../../contexts/mainContext";
import {faArrowDownLeftAndArrowUpRightToCenter, faGaugeMin} from "@fortawesome/pro-light-svg-icons";
import {formatDate, formatDateOnly, splitStrTime} from "../../../functions/formatting/formatDate";
import {Dropdown} from "primereact/dropdown";
import LoadingSpinner from "../loadingSpinner";


const token = localStorage.getItem('access_token');





const VideoPlayerDesktop = ({video, handleSpeed}) => {
    const {vehicles, event, vehicle} = useContext(mainContext)

    const [src, setSrc] = useState();
    const videoRef = useRef(null);
    const vidContainer = useRef();
    const [isMounted, setIsMounted] = useState(true);
    const [vidLoading, setVidLoading] = useState(false);
    const [mediaDuration, setMediaDuration] = useState('');
    const [mediaCurTime, setMediaCurTime] = useState('');
    const [mediaPlaying, setMediaPlaying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [videoHovered, setVideoHovered] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState({display: '1.00x Speed', val: 1});


    const speedOptions = [
        {display: '0.25x Speed', val: 0.25},
        {display: '0.50x Speed', val: 0.5},
        {display: '0.75x Speed', val: 0.75},
        {display: '1.00x Speed', val: 1},
        {display: '2.00x Speed', val: 2.0},
    ]

    const str_pad_left = (num, size) =>{
        let s = "000000000" + num;
        return s.substr(s.length - size);
    }

    const timeFromFile = (str) => {
        return str.substr(0,4) + "-" + str.substr(4,2) + "-" + str.substr(6,2)
            + " " + str.substr(8,2) + ":" + str.substr(10,2) + ":" + str.substr(12,2)
    }



    const mediaLoaded = (e) => {
        setMediaCurTime('00:00');
        const theDuration = Math.round(videoRef.current.duration);
        const minutes = Math.floor(theDuration / 60);
        const seconds = theDuration - (minutes * 60);
        setMediaDuration(str_pad_left(minutes, 2) + ':' + str_pad_left(seconds, 2));

        const eventVidTimeStr = video.file.split("_")[1];
        const eventVidTimes = eventVidTimeStr.split("-");
        const st = formatDate(new Date(timeFromFile(eventVidTimes[0])).getTime()).replace(' ', 'T');
        const eventTimeObj = event?.eventData.filter(data => data.acquisitionTime === st)[0];
        handleSpeed((eventTimeObj?.speed / 100 * 0.621371).toFixed());
    }



    const mediaUpdated = (e) => {
        const vid = e.target;
        const currentTime = Math.round(vid.currentTime);
        const minutes = Math.floor(currentTime / 60);
        const seconds = currentTime - (minutes * 60);
        setMediaCurTime(str_pad_left(minutes, 2) + ':' + str_pad_left(seconds, 2));
        setPercentage(( vid.currentTime / vid.duration ) * 100);

        const eventVidTimeStr = event?.videos?.[0]?.file.split("_")[1];
        const eventVidTimes = eventVidTimeStr.split("-");
        const st = new Date(timeFromFile(eventVidTimes[0])).getTime();
        const realTime = st + (Math.round(vid.currentTime) * 1000);
        const fomattedRealDate = formatDate(new Date(realTime)).replace(' ', 'T')

        const eventTimeObj = event?.eventData.filter(data => data.acquisitionTime === fomattedRealDate)[0];
        handleSpeed((eventTimeObj?.speed / 100 * 0.621371).toFixed())

    }

    const mediaEnded = () => {
        setMediaPlaying(false);
    }

    const playPauseMedia = () => {
        if (mediaPlaying){
            videoRef.current.pause();
            setMediaPlaying(false);
        } else {
            videoRef.current.play();
            setMediaPlaying(true);
        }

    }


    const downloadFile = () => {


        const splitStr = video.file.split('_');
        const times = splitStr[1].split("-");

        const vehicle = vehicles?.features.filter(v => v?.properties?.dn?.toString() === splitStr[0]?.toString())[0];
        let camPos;

        if (event?.vehicleDetails){
            event.vehicleDetails[0].cameras.forEach(cam => {
                if (cam.channel === video.ch){
                    camPos = cam.camPosition
                }
            });
        } else if (video.startTimeHyper){
            camPos = 'Front';
        }

        let date

        if (video.startTimeHyper){
            date = formatDateOnly(video.startTimeHyper);
        } else {
            date = splitStrTime(times[0]);
        }

        const str = vehicle.properties.registration + " " +  date + " " + camPos;

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.target = '_blank'
        a.href = src;
        a.download = str;
        a.click();
    }



    const nextHlFrame = () => {
        if (video.intervalSeconds){
            videoRef.current.currentTime = videoRef.current.currentTime + (videoRef.current.duration / video.frames);
        }
    }

    const prevHlFrame = () => {
        if (video.intervalSeconds){
            videoRef.current.currentTime = videoRef.current.currentTime - (videoRef.current.duration / video.frames);
        }
    }


    const handleScrubMouseMove = (percentageWidth) => {
        videoRef.current.currentTime = videoRef.current.duration * (percentageWidth.value/100)
    }

    const handleFsChange = (e) => {
        if (document.fullscreenElement || document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            setFullscreen(true);
        } else {
            setFullscreen(false)
        }
    }


    const toggleFullscreen = (val) => {
        setFullscreen(val);
        if (val){
            videoRef.current.style.maxHeight = '95vh';
            vidContainer?.current?.requestFullscreen();

        } else {
            videoRef.current.style.maxHeight = '45vh';
            document.exitFullscreen();

        }
    }
    
    
    const changeSpeed = (e) => {
        setPlaybackSpeed(e.value)
        videoRef.current.playbackRate = e.value.val;
    }

    const speedOptionTemplate = (option, selected) => {

        let icon = faGauge;

        switch (option.val){
            case 0.25: icon = faGaugeMin; break;
            case 0.5: icon = faGaugeLow; break;
            case 0.75: icon = faGaugeLow; break;
            case 2.0: icon = faGaugeMax; break;
        }

        return (
            <div>
                <FontAwesomeIcon icon={icon} />
                {!selected &&
                    <React.Fragment>
                        &nbsp;{option.display}
                    </React.Fragment>
                }
            </div>
        );
    }

    const selectedSpeedOptionTemplate = (option) => {
        if (option) {
            return speedOptionTemplate(option, true)
        }
    }
    

    useEffect(() => {
        if (video){
            setVidLoading(true)

            const filename = video?.file ? video.file : video.filename

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Range", `0-`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                // body: raw,
                redirect: 'follow'
            };

            fetch(`${API_URL}/view-video/${filename}`, requestOptions)
                .then(res => res.blob())
                .then( blob => {
                    var file = URL.createObjectURL(blob);
                    setSrc(file);
                    setVidLoading(false);
                });
        }
    }, [video])



    useEffect(() => {

        document.addEventListener('fullscreenchange', handleFsChange);

        return () => {
            URL.revokeObjectURL(src)
            document.removeEventListener('fullscreenchange', handleFsChange);

            setSrc(null);
            setVidLoading(null);
            setIsMounted(false);
        }
    }, []);

    return (
        <div>

            {vidLoading &&
                <div style={{width: '100%', height: '600px', position: 'relative'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                            'translate(-50%, -50%)'}}>
                        <LoadingSpinner />
                    </div>
                    {/*// <ProgressSpinner strokeWidth="4" animationDuration="1s"*/}
                    {/*                 style={{width: '50px', height: '50px', position: 'absolute', top: '50%', left: '50%', transform:*/}
                    {/*                         'translate(-50%, -50%)'}}/>*/}
                </div>
            }


            {!vidLoading &&
                <center ref={vidContainer}>
                    <video src={src}
                           autostart="false" autoPlay={false} controls={false} style={{width: '100%', maxHeight: '45vh'}}
                           ref={videoRef} onTimeUpdate={(e) => {mediaUpdated(e)}}
                           onEnded={mediaEnded} onLoadedMetadata={(e) => {mediaLoaded(e)}}
                           onMouseEnter={() => setVideoHovered(true)} onMouseLeave={() => setVideoHovered(false)}>
                    </video>


                    <div style={{display:'flex',justifyContent:'center',alignItems: 'center',marginTop:'5px',width:'100%',
                        maxWidth: '1100px'}}>
                        <button onClick={playPauseMedia} className="p-button p-button-secondary">
                            {mediaPlaying &&
                                <FontAwesomeIcon icon={faPause} className="p-button-label"/>
                            }
                            {!mediaPlaying &&
                                <FontAwesomeIcon icon={faPlay} className="p-button-label"/>
                            }

                        </button>



                        <Dropdown options={speedOptions} style={{padding: '0.154rem 0.5rem', height: '33.56px', marginLeft: '2px'}}
                                  valueTemplate={selectedSpeedOptionTemplate} itemTemplate={speedOptionTemplate}
                                  onChange={e => changeSpeed(e)} value={playbackSpeed} optionLabel="display" tooltip="Playback Speed"
                        />
                        

                        {video?.startTimeHyper &&
                            <button onClick={prevHlFrame} className="p-button p-button-secondary" style={{marginLeft: '5px'}}>
                                <FontAwesomeIcon icon={faBackward} className="p-button-label"/>
                            </button>
                        }


                        <div style={{textAlign: 'center',marginLeft: '10px'}}>{mediaCurTime}</div>

                        <div style={{flex:1, paddingLeft: '15px', paddingRight: '15px'}}>
                            <Slider value={percentage} onChange={(e) => handleScrubMouseMove(e)} style={{width: '100%', cursor: 'pointer'}} />
                        </div>

                        <div style={{marginRight: '10px'}}>{mediaDuration}</div>

                        {video?.startTimeHyper &&
                            <button onClick={nextHlFrame} className="p-button p-button-secondary" style={{marginRight: '5px'}}>
                                <FontAwesomeIcon icon={faForward} className="p-button-label"/>
                            </button>
                        }


                        <button className="p-button p-button-secondary" onClick={downloadFile}>
                            <FontAwesomeIcon icon={faDownload} className="p-button-label" />
                        </button>

                        <button className="p-button p-button-secondary" style={{marginLeft: '2px'}}
                                onMouseEnter={() => setVideoHovered(true)} onMouseLeave={() => setVideoHovered(false)}
                                onClick={() => toggleFullscreen(!fullscreen)}>
                            <FontAwesomeIcon icon={!fullscreen ? faUpRightAndDownLeftFromCenter : faArrowDownLeftAndArrowUpRightToCenter}
                                             className="p-button-label"/>

                        </button>
                    </div>

                    {video?.startTimeHyper && !fullscreen &&
                        <RequestVideoFromHyperlapse video={video} videoRef={videoRef} />
                    }

                </center>
            }

            {/*<video src="https://myfleetlive.org/view-video-test/1000903_20230512120309-20230512120339_4_20_h265" controls style={{width:'250px', height: '180px'}} />*/}
        </div>
    );
};

export default VideoPlayerDesktop;
