import {formatDate} from "../../../../../functions/formatting/formatDate";
import fetchEventsTags from "../../../../../api/events/fetchEventsTags";

export const fetchEventsForJourney = async (journey, pageToShow) => {
    const endTime = journey?.details?.endTime ? journey.details.endTime.replaceAll('T', ' ') : formatDate(new Date());

    const data = {
        startTime: journey.details.startTime.replaceAll('T', ' '),
        endTime: endTime,
        dn: journey.details.dn,
        eventTags:["-hyperlapse","-journey end","-health", "-driverId"]
    }

    const events = await fetchEventsTags(data, pageToShow)

    let toReturn = {
        type: 'FeatureCollection',
        features: [],
    };

    if (events?.result?.length > 0){

        for (let i = 0; i < events?.result?.length; i++) {
            const theEvent = events?.result?.[i];

            if (typeof theEvent?.lat !== '0') {

                /*so can be used by mapbox get*/
                theEvent.eventType = theEvent?.analysis?.eventType ? theEvent?.analysis?.eventType : theEvent?.eventSource;
                theEvent.severity = theEvent?.analysis?.severity;

                toReturn.features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [theEvent.lng, theEvent.lat],
                    },
                    properties: theEvent
                });
            }
        }
    }

    return toReturn

}
