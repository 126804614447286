import React, {useContext, useEffect, useRef, useState} from 'react';
import {purple} from "../../map/CustomIcons";
import indexContext from "../../../../contexts/indexContext";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import {fetchBlockedEndpoints} from "../../../../../functions/fetchBlockedEndpoints";
import mainContext from "../../../../contexts/mainContext";
import gridContext from "../../../../contexts/gridContext";

const ViewOnEventsMap = ({}) => {

    const styles = {
        flex: "1",
        position: "relative",
        marginTop: '-64px',
    };



    const {darkMap} = useContext(indexContext);
    const {handleEvent} = useContext(mainContext);
    const {gridData} = useContext(gridContext)


    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);

    const forEachLayer = (text, cb) => {
        map.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }
// Changing the map base style
    const changeStyle = (style) => {
        if (map){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'events-layer',
                'clusters',
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = map.getSource(layer.source).serialize();
                    savedLayers.push(layer);
                });
            });

            map.setStyle(`mapbox://styles/${style}`);

            setTimeout(() => {

                purple.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => {
                        if (!map.hasImage(icon.name)){
                            map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                        }
                    }
                    customIcon.src = icon.src;
                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        map.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {
                    const mapLayer = map.getLayer(layer.id);
                    if(typeof mapLayer === 'undefined') {
                        map.addLayer(layer);
                    }
                });
            }, 1500);
        }
    }


    useEffect(() => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn/draft')
            } else {
                changeStyle('fleetfocus/clfbkl32z000h01qln1hmq2b2/draft')
            }
        }
    }, [darkMap])

    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";
        const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn/draft'
            : 'mapbox://styles/fleetfocus/clfbkl32z000h01qln1hmq2b2/draft';
        const initializeMap = ({setMap, mapContainer}) => {

            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center: [0.9142, 51.9037],
                zoom: 0,
                maxZoom: 19,
                minZoom: 1,
                pitchWithRotate: true,
                dragRotate: true
            });


            // disable map rotation using right click + drag
            // map.dragRotate.disable();

            // disable map rotation using touch rotation gesture
            // map.touchZoomRotate.disableRotation();

            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    countries: 'gb',
                    placeholder: 'address search',
                    mapboxgl,

                }),  'bottom-right'
            );


            // map.addControl(CustomControl, 'top-right');

            map.on('load', () => {

                // if (window.myJourneyInterval !== undefined && window.myJourneyInterval !== 'undefined') {
                //     window.clearInterval(window.myJourneyInterval);
                // }

                purple.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    customIcon.src = icon.src;
                });

                setMap(map);
                map.resize();

                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();

                map.addSource('events-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                    cluster: true,
                    clusterMaxZoom: 16, // Max zoom to cluster points on
                    clusterRadius: 60
                });


                if (!fetchBlockedEndpoints().includes('view-snapshot')) {
                    map.addLayer({
                        id: 'events-layer',
                        source: 'events-data',
                        type: 'symbol',
                        filter: ['!', ['has', 'point_count']],
                        layout: {
                            'icon-image': [
                                'case',
                                // ['==', 'severe', ['get', 'severity']], 'circle-red-svg',
                                // ['==', 'moderate', ['get', 'severity']], 'circle-svg',
                                ['==', 'user', ['get', 'eventSource']], 'userCircle',

                                ['in', 'Collision warning', ['get', 'eventTags']], 'collisionCircle',
                                ['in', 'cornering', ['get', 'eventTags']], 'corneringCircle',
                                ['in', 'Distraction', ['get', 'eventTags']], 'distractionCircle',
                                ['in', 'Inattentive', ['get', 'eventTags']], 'distractionCircle',
                                ['in', 'Drowsiness', ['get', 'eventTags']], 'fatigueCircle',
                                ['in', 'Fatigue', ['get', 'eventTags']], 'fatigueCircle',
                                ['in', 'Lane Departure', ['get', 'eventTags']], 'corneringCircle',
                                ['in', 'Pedestrian', ['get', 'eventTags']], 'pedestrianCircle',
                                ['in', 'Mobile phone', ['get', 'eventTags']], 'phoneCircle',
                                ['in', 'Smoking', ['get', 'eventTags']], 'smokingCircle',
                                ['in', 'speeding', ['get', 'eventTags']], 'speedingCircle',
                                ['in', 'Tailgating', ['get', 'eventTags']], 'tailgatingCircle',
                                'circle-svg'
                            ],
                            'icon-ignore-placement': false,
                            'icon-allow-overlap': true,
                            'text-allow-overlap': true,
                            'icon-size': 1,
                            'icon-anchor': 'center',
                            'visibility': 'visible'
                        },
                        // paint: {
                        //     'icon-color': [
                        //         'case',
                        //         ['==', 'bump', ['get', 'eventType']], '#3354b8',
                        //         ['==', 'cornering', ['get', 'eventType']], '#4a8939',
                        //         ['==', 'braking', ['get', 'eventType']], '#e04242',
                        //         ['==', 'acceleration', ['get', 'eventType']], '#fa8b3a',
                        //         '#000000'
                        //     ],
                        //     'text-color': '#ffffff'
                        // }
                    }, );


                }




                map.on('click', 'events-layer', (e) => {
                    handleEvent(e.features[0].properties);
                });

                map.on('click', 'clusters', (e) => {

                    //e.originalEvent.stopPropagation();
                    // console.log('clicked cluster');
                    const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
                    const clusterId = features[0].properties.cluster_id;
                    // console.log(clusterId);

                    const point_count = features[0].properties.point_count;
                    // console.log(point_count);
                    const clusterSource = map.getSource('events-data');

                    clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                        // console.log('getClusterLeaves', err, aFeatures);
                        // console.log('zoom' + map.getZoom());
                        if (map.getZoom() === 16) {
                            this.openClusterPopup(aFeatures);
                        }
                    });

                    map.getSource('events-data').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom
                        });

                        // console.log('zoom2: ' + map.getZoom());

                    });
                });

                map.addLayer({
                    id: 'clusters',
                    type: 'symbol',
                    source: 'events-data',
                    filter: ['has', 'point_count'],

                    layout: {
                        'icon-image': 'cluster-marker',
                        //'icon-image': 'rectangle-orange-5',

                        'text-field': '{point_count_abbreviated}',
                        // 'icon-ignore-placement': true,

                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,

                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'icon-size': 0.9,
                        // 'icon-anchor': 'bottom-left',
                        // 'icon-offset': [-20, 0],
                        'text-anchor': 'bottom',
                        'text-offset': [0, 0.4],
                    }
                });




            });


        };


        if (!map) initializeMap({setMap, mapContainer});
    }, [map]);

    useEffect(() => {
        if (gridData && map){
            let arr = [];
            gridData?.forEach(item => {
                if (item?.lat && item?.lng){
                    arr.push({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [item?.lng, item.lat],
                        },
                        properties:
                            item
                        ,
                    });
                }
            });

            map.getSource('events-data').setData({
                features: arr,
                type: "FeatureCollection"
            });

            let coords = [];
            arr.forEach((f) => {
                coords.push(f?.geometry?.coordinates);
            });

            const bounds = coords?.reduce(function (b, coord) {
                if (JSON.stringify(coord) !== JSON.stringify([0,0])){
                    return b?.extend(coord);
                } else {
                    return b
                }

            }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

            let padding = {};


            if (Object.keys(bounds).length > 0){
                map.fitBounds(bounds, {
                    padding: {bottom: 50, left: 50, top: 10, right: 150}, maxZoom: 16
                });
            }

        }

    }, [gridData, map]);




    return (
        <div className="mapContainer" id="mapContainer"
             style={{marginTop: '63px', flexDirection: 'column', position: 'relative'}}>
            <div ref={el => (mapContainer.current = el)} style={styles}/>
        </div>
    )
}

export default ViewOnEventsMap