import {getTimeDifference} from "./formatDate";

export const lastTrackTime = (latestVehicle, prepend, append) => {
    let diff = getTimeDifference(latestVehicle?.properties?.lastLocation?.at, new Date());

    if(diff.length > 1){
        prepend += diff + append;
    } else {
        prepend += 'less than a minute' + append
    }
    return prepend;
}