import React, {useContext, useEffect, useState} from 'react'
import mainContext from "../../../../contexts/mainContext";
import {fetchActivityData} from "../reportFunctions/fetchActivityData";
import {
    formatDateOnly,
    formatDateTimesheetReport
} from "../../../../../functions/formatting/formatDate";
import {MflLogo} from "../../../../../svgs/logo-myfleetlive";
import {Colours} from "../../../../../colours";
import LoadingSpinner from "../../../loadingSpinner";
import VehicleCo2ReportSummary from "../co2Report/vehicle/vehicleCo2ReportSummary";

import FleetCo2ReportSummary from "../co2Report/fleet/fleetCo2ReportSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faLocationDot, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {faCar, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import {faSmoke} from "@fortawesome/pro-regular-svg-icons/faSmoke";
import {gToKg} from "../../../../../functions/formatting/gToKg";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import ReportsParameterSelection from "../reportsParameterSelection";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetIdling from "../../../../../api/postFleetIdling";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";



const Co2ReportOverview = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {

    const {vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext);
    const {theVehicle, theFleet} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState(false);

    const handleGenerateReport = (val) => {
        setGenerateReport(val)
    }



    const accumulator = (data, prop) => {
        return data.reduce((totalValue, item) => {
            switch (prop) {
                case 'journeys': return totalValue + item?.journeys?.length;
                case 'startTime':
                    const st = new Date(item?.firstJourney?.start?.at);
                    const stMins = (st.getHours() * 60) + st.getMinutes();
                    return  item?.firstJourney ? totalValue +  stMins : totalValue + 0;
                case 'endTime':
                    const et = new Date(item?.lastJourney?.endTime);
                    const etMins = (et.getHours() * 60) + et.getMinutes();
                    return  item?.lastJourney ? totalValue +  etMins : totalValue + 0;
                default : return totalValue + item?.[prop];
            }
        }, 0);
    }

    const generateReportData = async () => {
        const data = {
            fleetId: theFleet?.fleetId,
            startDate: formatDateOnly(dateRange?.[0]).split('-').reverse().join('-'),
            endDate: formatDateOnly(dateRange?.[1]).split('-').reverse().join('-')
        }

        const r = await postFleetIdling(data);

        let distance = 0;
        let journeys = 0;
        let duration = 0;
        let co2 = 0;



        for (let i = 0; i < r?.length; i++) {
            const v = r[i]

            const details = await fetchDeviceDetails({properties: {dn: v?._id}});
            const co2Per = details?.[0]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions;

            if (co2Per) {
                const totCo2 = ((v?.distance * 1.60934) * co2Per);
                v.co2 = totCo2;
                co2 += totCo2;
            }

            distance += v?.distance;
            journeys += v?.journeyCount
            duration += v?.durationSeconds

        }



        r.sort(function(a, b){return b.totCo2-a.totCo2});


        setActivityData(r);


        const total = {
            journeys,
            distance,
            co2,
            duration
        }

        const cache = {
            actData: r,
            totals: total,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }

        setActTotals(total);

        localStorage.setItem('co2ReportOverview', JSON.stringify(cache));
        handleReportLoading(false);
    }

    useEffect(async () => {

        if (localStorage.getItem('co2ReportOverview')){
            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('co2ReportOverview'));


            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }




            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }




    }, [dateRange, theVehicle, theFleet, generateReport]);



    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {activityData?.length > 0 ?
                                <div style={{
                                    height: '100%',
                                    width: '100%',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    paddingTop: '20px'
                                }}
                                     className={reportToPrint === 'co2Overview' ? "section-to-print" : ''}>
                                    <div className="hideNormalShowPrint">
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <h1>
                                                    Co2 Overview report
                                                </h1>
                                                {theVehicle &&
                                                    <div style={{
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Driver:</b>&nbsp;
                                                        {(theVehicle?.properties?.driver && theVehicle?.properties.driver.length > 0) ?
                                                            <span>{theVehicle.properties.driver} ({theVehicle.properties.registration})</span>
                                                            :
                                                            <span>No driver assigned  ({theVehicle.properties.registration})</span>
                                                        }
                                                        <br/>

                                                        <b>Date range:</b>&nbsp;
                                                        {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                        &nbsp;->&nbsp;
                                                        {formatDateTimesheetReport(activityData?.[activityData.length - 1]?.date)}
                                                    </div>
                                                }
                                                {theFleet &&
                                                    <div style={{
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                        <br/>

                                                        <b>Date range:</b>&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[0])}
                                                        &nbsp;->&nbsp;
                                                        {formatDateTimesheetReport(dateRange?.[1])}
                                                    </div>
                                                }

                                            </div>
                                            <div>
                                                <MflLogo style={{fill: Colours.logoTextColour}} size='180px'
                                                         textColor={'black'}/>
                                                <div style={{
                                                    marginTop: '25px',
                                                    fontSize: "1.3em",
                                                    lineHeight: "25px",
                                                    padding: "20px 0px 20px 0px"
                                                }}>
                                                    <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                    <br/>
                                                    <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                                </div>
                                            </div>
                                        </div>

                                        {showKpi && theVehicle &&
                                            <VehicleCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                        }

                                        {showKpi && theFleet && !theVehicle &&
                                            <FleetCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                        }


                                    </div>

                                    <div className="hideOnPrint">
                                        {showKpi && theVehicle &&
                                            <VehicleCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                        }

                                        {showKpi && theFleet && !theVehicle &&
                                            <FleetCo2ReportSummary actTotals={actTotals} activityData={activityData}/>
                                        }


                                        {theVehicle &&
                                            <div style={{
                                                fontSize: "1.3em",
                                                lineHeight: "25px",
                                                padding: "20px 0px 20px 0px"
                                            }}>
                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(activityData?.[0]?.date)}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(activityData?.[activityData.length - 1]?.date)}
                                                <br/>
                                                <b>Driver:</b>&nbsp;
                                                {(theVehicle.properties.driver && theVehicle.properties.driver.length > 0) ?
                                                    <span>{theVehicle.properties.driver} ({theVehicle.properties.registration})</span>
                                                    :
                                                    <span>No driver assigned ({theVehicle.properties.registration})</span>
                                                }
                                            </div>
                                        }

                                        {theFleet &&
                                            <div style={{
                                                fontSize: "1.3em",
                                                lineHeight: "25px",
                                                padding: "20px 0px 20px 0px"
                                            }}>
                                                <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                <br/>

                                                <b>Date range:</b>&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[0])}
                                                &nbsp;->&nbsp;
                                                {formatDateTimesheetReport(dateRange?.[1])}
                                            </div>
                                        }
                                    </div>


                                    {theFleet &&
                                        <div style={{overflowY: 'auto', height: '61vh', overflowX: 'hidden'}}
                                             className="scroller">

                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: '20% 20% 20% 20% 20% ',
                                                borderBottom: '1px solid var(--surface-border)',
                                                height: '30px',
                                                marginTop: '12px',
                                                pageBreakInside: 'auto',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }} className="reportTable">

                                                <div style={{lineHeight: '30px', paddingLeft: '10px'}}>
                                                    <FontAwesomeIcon icon={faCar}/>
                                                    &nbsp;Vehicle
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faSignsPost}/>
                                                    &nbsp;
                                                    Journeys
                                                </div>

                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faRuler}/>
                                                    &nbsp;
                                                    Distance
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faSmoke}/>
                                                    &nbsp;
                                                    Co2
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faSmoke}/>
                                                    &nbsp;
                                                    Average Co2
                                                </div>
                                            </div>


                                            {activityData && Object?.entries(activityData)?.map(([k, v]) => (


                                                <React.Fragment>
                                                    {v?.co2 ?

                                                        <div style={{
                                                            display: 'grid',
                                                            gridTemplateColumns: '20% 20% 20% 20% 20% ',
                                                            borderBottom: '1px solid var(--surface-border)',
                                                            height: '73px',
                                                            pageBreakInside: 'avoid',
                                                            pageBreakAfter: 'auto',
                                                            cursor: 'pointer',
                                                            textAlign: 'center'
                                                        }} className="reportTable" key={k}

                                                        >
                                                            <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                                                                {regFromDn(vehicles, v?._id)}
                                                            </div>

                                                            <div style={{lineHeight: '73px'}}>
                                                                {v?.journeyCount}
                                                            </div>
                                                            <div style={{lineHeight: '73px'}}>
                                                                {v?.distance.toFixed(0)}
                                                                &nbsp;miles
                                                            </div>
                                                            <div style={{lineHeight: '73px'}}>
                                                                {v?.co2 ? (gToKg(v?.co2.toFixed(0))) : 'N/A'}
                                                            </div>

                                                            <div style={{lineHeight: '73px'}}>
                                                                {v?.co2 ? (gToKg((v?.co2 / v?.journeyCount).toFixed(0))) : 'N/A'}
                                                            </div>

                                                        </div>
                                                        :
                                                        <React.Fragment/>
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    }


                                </div>

                            :
                                <div style={{
                                    fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                                }}>
                                    No CO2 Data for the given fleet for the given date range
                                </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px', width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{
                        fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                    }}>
                        Please select a fleet and timespan

                        <br/>
                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>


                    </div>
                </div>

            }
        </React.Fragment>
    )
}
export default Co2ReportOverview

