import React from 'react';
import {Tooltip} from "primereact/tooltip";

const HealthStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path d="m74.95,124.64v107.61c0,3.68,3.01,6.69,6.69,6.69h107.61c3.68,0,6.69-3.01,6.69-6.69v-107.61c0-3.68-3.01-6.69-6.69-6.69h-107.61c-3.68,0-6.69,3.01-6.69,6.69Zm6.78-26.69h107.44c14.79,0,26.78,11.99,26.78,26.78v107.44c0,14.79-11.99,26.78-26.78,26.78h-107.44c-14.79,0-26.78-11.99-26.78-26.78v-107.44c0-14.79,11.99-26.78,26.78-26.78Zm194.22,125.83v-90.14l-47,21.15v-21.98l43.68-19.64c3.78-1.7,8.08-1.97,12.2-.56,6.76,2.3,11.12,8.88,11.12,16.02,0,20.09,0,80.05,0,100.14,0,7.14-4.36,13.72-11.12,16.02-4.13,1.41-8.43,1.15-12.2-.56l-43.68-19.64v-21.98l47,21.15Z" style={{fill: 'var(--text-color)', strokeWidth: '0px'}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:'none'}}/>
                </g>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Severe - {event?.notificationDetails.title}</Tooltip>
            }
        </div>
    )
}
export default HealthStacked
