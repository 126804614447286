import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import postEditTags from "../../../../../api/device/postEditTags";
import {InputText} from "primereact/inputtext";
import {faCircleX, faCloudArrowUp, faX} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";
import {Fieldset} from "primereact/fieldset";

const VehicleTags = () => {

    const {vehicle, vehicles, refreshVehicles, handleVehicle} = useContext(mainContext);

    const [tagsArr, setTagsArr] = useState(new Set([]));
    const [inputVal, setInputVal] = useState();
    const [fleetTags, setFleetTags] = useState(new Set([]))
    const [showError, setShowError] = useState(false);
    const [showSaved, setShowSaved] = useState(false)


    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: '#dbd9d9',
        color: '#404040',
        fontSize: '14px',
        cursor: 'auto',
        minWidth: '2rem'
    }

    const addTag = (val) => {
        if (val?.length < 13 ){
            const copy = new Set([...tagsArr])
            copy.add(val);

            if (copy.size < 4){
                setTagsArr(copy)
            } else {
                setShowError(true);
                setTimeout(() => setShowError(false), 2000)
            }

        }
    }

    const removeTag = (val) => {
        const copy = new Set([...tagsArr])
        copy.delete(val);
        setTagsArr(copy)
    }


    const saveTags = async () => {
        setShowSaved(true)
        const tags = Array.from(tagsArr)

        const data = {
            dn: vehicle?.properties?.dn,
            tags
        }


        try {
            await postEditTags(data);


        } catch (e){

        }


        setTimeout(async () => {
            await refreshVehicles();
            setShowSaved(false)


        }, 1000)

    }



    useEffect(() => {
        if (vehicles && vehicle){

            // bodge to keep modal consistant when tags update without having to play around with states etc
            const v2 = vehicles?.features.filter(v => v.properties.dn === vehicle?.properties?.dn)[0];

            let set = v2.properties.tags?.length > 0 ? [...v2.properties.tags]  :[]

            setTagsArr(new Set(set))
            const filtered = vehicles.features.filter(v => v?.properties?.fleetId === vehicle.properties.fleetId);
            const filteredTags = filtered.reduce((acc, curVal) => acc.concat(curVal?.properties?.tags), []);
            setFleetTags(new Set(filteredTags));

        }
    }, [vehicles, vehicle])

    return (
        <React.Fragment>
            <div>


                Add up to three information tags to this vehicle, they could include depot, vehicle type, DVS status and more.
                Tags are displayed in the fleet list and can be filtered using the search box.
                Create a new tag or click an existing fleet tag to add it to this vehicle.

                 <br /><br />

                <Fieldset legend="Vehicle Tags">
                    {tagsArr?.size > 0 ?
                        <div style={{paddingTop: '15px', paddingBottom: '15px', textAlign: 'center'}}>
                            {Array.from(tagsArr)?.sort()?.map(tag => (
                                <Button style={tStyle} key={tag}
                                        label={<React.Fragment>{tag}&nbsp;<FontAwesomeIcon icon={faCircleX}/></React.Fragment>}
                                        className="p-button p-mb-2" onClick={() => removeTag(tag)} />
                            ))}
                        </div>
                        :
                        <div style={{textAlign:'center'}}>
                            No tags set for vehicle, enter using the controls below
                        </div>
                    }

                    <br />

                    <div className="p-inputgroup">
                        <InputText value={inputVal} onChange={(e) => setInputVal(e.target.value)}
                                   placeholder="create a new tag (max 10 characters)" className={inputVal?.length > 10 && 'p-invalid'}/>

                        <Button onClick={() => {
                            addTag(inputVal);
                            if (inputVal?.length < 13){
                                setInputVal('');
                            }
                        }} label='Add' className="p-button-secondary"/>
                    </div>
                    {inputVal?.length > 10 &&
                        <small className="p-error block">Tag must be 10 characters or less.</small>
                    }
                </Fieldset>

                <br />

                <Fieldset legend="Existing Fleet Tags">
                    {Array.from(fleetTags).map(tag => (
                       <React.Fragment>
                           {tag &&
                               <Button style={tStyle} key={tag}
                                       label={tag}
                                       className="p-button p-mb-2" onClick={() => addTag(tag)} />
                           }
                       </React.Fragment>
                    ))}
                </Fieldset>
            </div>

            <br />


            <center>
                {showError ?
                    <Message severity="error" text="Only 3 tags per vehicle are allowed" />

                :

                    <React.Fragment>
                        {showSaved ?
                            <Message severity="success" text="Tags updated" />
                            :
                            <Button onClick={saveTags} label='Save changes' className="p-button-secondary"/>

                        }
                    </React.Fragment>
                }






            </center>

        </React.Fragment>
    )
}

export default VehicleTags
