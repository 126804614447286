import React from 'react';
import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";
import {maxSpeed} from "../../functions/formatting/maxspeed";


const FetchJourney = (id) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token },
    };


    return fetch(API_URL + '/f-journey/' + id, requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
                const features = [];

                if (typeof data.tracks !== 'undefined') {

                    if (data.tracks !== null) {

                        for (let i = 0; i < data.tracks.length; i++) {


                            const feature = {
                                type: 'Feature',
                                properties: {
                                    head: data.tracks[i].head,
                                    dataSource: data.tracks?.[i]?.dataSource ? data.tracks[i].dataSource : 'tracks',
                                    color: data.tracks[i].color ? data.tracks[i].color : 'green',
                                    distance: data.tracks[i].distance,
                                    distanceCumulative: data.tracks[i].distanceCumulative,
                                    speed: Math.round(data.tracks[i].speed / 100 * 0.621371),
                                    time: data.tracks[i].at,
                                    gpsAcc: data.tracks[i].gpsAcc,
                                    sigInt: data.tracks[i].sigInt,
                                    netType: data.tracks[i].netType,
                                    maxspeed: maxSpeed(data.tracks[i].maxspeed),
                                    storeForward: data.tracks[i].storeForward,
                                    alarm: data.tracks[i].alarm,
                                    voltage: data.tracks[i].voltage,

                                },
                                geometry: {
                                    type: 'Point',
                                    coordinates: [data.tracks[i].lng, data.tracks[i].lat]
                                }

                            };

                            if (data.tracks[i].at){
                                features.push(feature);
                            }

                        }
                    }
                }

                delete(data.tracks);
                delete(data.events);

                return Promise.resolve({
                    type: 'FeatureCollection',
                    details: data,
                    features: features,
                });


            }
        );
}


export default FetchJourney;
