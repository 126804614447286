import React, {useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight, faBan, faChevronLeft} from '@fortawesome/pro-regular-svg-icons';
import indexContext from "../../../contexts/indexContext";
import {isWebfleet} from "../../../../functions/isWebfleet";
import {formatDateOnly} from "../../../../functions/formatting/formatDate";

const DisableDateChangeNext = ({date, disableDateChange, handleDate, availableDates})  =>  {

    const {pageToShow} = useContext(indexContext);



    const handleNextDate = () => {

        if (isWebfleet() && pageToShow === '/map'){
            if (!date){
                handleDate(availableDates[0])
            } else {
                const index = [...availableDates].indexOf(formatDateOnly(date))
                if ((index - 1) > -1){
                    handleDate(new Date([...availableDates][index - 1].split('-').reverse().join('-')))
                } else {
                    handleDate(new Date([...availableDates][0].split('-').reverse().join('-')))
                }
            }
        } else {
            handleDate(date, 1)
        }
    }




    return (new Date(date).setHours(0,0,0,0) ===  new Date().setHours(0,0,0,0) || disableDateChange) ?

        <div style={{color: "var(--v-list-button-text)", backgroundColor: "var(--v-list-button)", border: 'none', marginLeft: '-1px', width: '45px', borderRadius: '0px 2px 2px 0px', position: 'relative'}}>
            <FontAwesomeIcon icon={faBan} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
        </div>
        :
        <div style={{color: "var(--v-list-button-text)", backgroundColor: "var(--v-list-button)", border: 'none', marginLeft: '-1px', width: '45px', borderRadius: '2px', position: 'relative',
            cursor: 'pointer'}}
             onClick={handleNextDate} >
            <FontAwesomeIcon icon={faChevronRight} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
        </div>


}

export default DisableDateChangeNext
