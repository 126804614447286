import React from 'react';
import {DurationFromSeconds, timeFromSeconds} from "../../../../../functions/formatting/formatDate";

const ActivityReportSummary = ({actTotals, activityData}) => {

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>
            <div className="reportTotalBubble">
                <div><b>Average Start <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{timeFromSeconds(actTotals?.startTime / activityData?.length) }</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Finish <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{timeFromSeconds(actTotals?.endTime / activityData?.length) }</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Total<br />Journeys:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total<br />Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance.toFixed(0)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Journey <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.driving / actTotals?.journeys)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Stop <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.stopped / actTotals?.journeys)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total Driving <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.driving)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total Parked <br />Time:</b></div>
                <div style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.stopped)}</div>
            </div>
        </div>

    )
}

export default ActivityReportSummary
