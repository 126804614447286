import React, {useContext, useEffect, useRef, useState} from 'react';
import fetchStaticMapImage from "../../../../api/media/fetchStaticMapImage";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import EventGridItemSkeleton from "../grid/events/eventGridItemSkeleton";
import indexContext from "../../../contexts/indexContext";
import {faEdit, faTrash} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "primereact/tooltip";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";

import VehicleLiveLocationV2 from "../../vehicleList/vehicleLiveLocationV2";


const PlacesGridCard = ({place, openShowPlaceModal, handleDeletePoi}) => {

    const {fleets, darkMap} = useContext(indexContext);
    const [img, setImg] = useState();
    const [isLoaded, setIsLoaded] = useState(false);


    const confirmDeletePoi = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete this POI?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // call parent function to delete poi and remove element
                handleDeletePoi(place)
            },
            reject: () => {
                return
            }
        });
    };




    useEffect(() => {
        if (place){
            fetchStaticMapImage(place?.location?.coordinates[1], place?.location?.coordinates[0], darkMap).then(r => {
                setImg(URL.createObjectURL(r));
                setTimeout(() => {setIsLoaded(true)}, 500)
            });
        }
    }, [darkMap])


    return (
        <React.Fragment>
            <ConfirmPopup />

            {isLoaded ?
                <div style={{padding: '10px 0px 5px 0px', borderRadius: 'inherit', width: '100%', height: '100%', cursor: 'pointer'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '5px', padding: '0px 10px 0px 10px'}}>
                        <div style={{flex: 1, fontSize: '15px', fontWeight: 'bold'}}>
                            {place?.name}
                            <br/>


                            <div style={{
                                textAlign: 'left', position: 'relative', margin: '0 auto', borderRadius: '2px',
                                fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'
                            }}>
                                {place?.cat ? <i>{place?.cat}</i> : <i>No category set</i>}
                            </div>
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}>
                                <VehicleLiveLocationV2
                                    d={{lat: place?.location?.coordinates[1], lng: place?.location?.coordinates[0]}}
                                    icon={false}/>
                            </div>


                        </div>

                        <div>


                            <Tooltip target={".delete" + place?._id} id={'delete' + place?._id}>Delete</Tooltip>


                            <span className={"delete" + place._id} data-pr-position="bottom"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      confirmDeletePoi(e);
                                  }}>

                                     <FontAwesomeIcon icon={faTrash} style={{
                                         cursor: 'pointer',
                                         fontSize: "18px",
                                         marginRight: '5px'
                                     }}/>

                                </span>




                            {/*<Tooltip target={".edit" + place?._id} id={'edit'+place?._id}>Edit</Tooltip>*/}


                            {/*<span className={"edit" + place._id} data-pr-position="bottom"*/}
                            {/*      onClick={(e) => {*/}
                            {/*          e.stopPropagation();*/}
                            {/*          openShowPlaceModal(place);*/}
                            {/*      }}>*/}

                            {/*         <FontAwesomeIcon icon={faEdit} style={{*/}
                            {/*             cursor: 'pointer',*/}
                            {/*             fontSize: "18px",*/}
                            {/*             marginRight: '5px'*/}
                            {/*         }}/>*/}

                            {/*    </span>*/}




                        </div>
                    </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        <img src={img} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9'}} onClick={(e) => {
                            e.stopPropagation();
                            openShowPlaceModal(place);
                        }}/>
                        <div style={{background: 'var(--surface-card)',width:'100%', height: '8.5%', marginTop: '-5.5%',position:'absolute'}} />
                    </div>






                </div>
                :
                <EventGridItemSkeleton />
            }

        </React.Fragment>
    )
}
export default PlacesGridCard
