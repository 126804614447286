import React, {useContext} from 'react';
import {Skeleton} from "primereact/skeleton";
import indexContext from "../../../../contexts/indexContext";


const EventGridItemSkeleton = () => {

    const {pageToShow} = useContext(indexContext)

    const eventBoxStyle = {
        // padding: '10px',
        borderRadius: 'inherit',
        width: '100%!important',
        height: '100%',
    }


    return (
        <div style={eventBoxStyle} >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative',
                padding: '10px 10px 0px 10px'}}>
              <div style={{width:"40%", display: 'flex', flexDirection: 'column', gap: '5px'}}>
                  <Skeleton height="15px"/>
                  <Skeleton  height="15px" />
              </div>
                <div style={{display: 'flex', gap: '5px'}}>
                   <Skeleton size="25px"  shape="circle"/>
                    {pageToShow === '/events' && <Skeleton size="25px"  shape="circle"/>}


                </div>
            </div>
            <br />
            <div style={{marginBottom: '2px', aspectRatio: '16/9'}}>
                <Skeleton width="100%" height="100%" />
            </div>

            <div style={{width: '95%', margin:'0 auto', padding: '10px 10px 5px 10px'}}>
                <Skeleton width="100%" height="15px" />
            </div>
            <div style={{padding: '0px 10px 10px 10px', width: '95%', margin:'0 auto'}}>
                <Skeleton width="100%" height="15px" />
            </div>
        </div>
    )
}
export default EventGridItemSkeleton
