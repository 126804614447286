import React, {useEffect, useState, useRef, useContext} from "react";
import './ListJourneys.css';
import ResizeObserver from 'resize-observer-polyfill';
import {Tooltip} from "primereact/tooltip";
import ImageMfl from "../../viewMedia/ImageMfl";
import fetchLastJourney from "../../../../api/journeys/fetchLastJourney";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import CalendarDatePicker from "../datePicker/calendarDatePicker";
import MobileJourneySelect from "./mobileJourneySelect";
import JourneyCaterpillars from "./journeyCaterpillars";
import JCaterpillarTooltip from "./jCaterpillarTooltip";

const ListJourneys = ( {} ) => {

    const {pageToShow, winWidth} = useContext(indexContext);
    const {journeys, journey, date, handleDate, disableDateChange, vehicle} = useContext(mainContext)
    const [viewportX, setViewportX] = useState();
    const [lastJourney, setLastJourney] = useState();
    const [jHover, setJHover] = useState()
    const ref = useRef(false)
    const resizeObserverRef = useRef(null);


    const handleJHover = (j) => setJHover(j)


    useEffect(() => {
        if (journeys){
            let date = new Date();


            const dateString = [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).slice(-2),
                ('0' + date.getDate( date.setDate(date.getDate() - 1))).slice(-2)
            ].join('-');

            // last j for first j start snap
            fetchLastJourney(vehicle?.properties?.dn, dateString).then(r => {
               setLastJourney(r?.[0]);
            });
        }
    }, [journeys])



    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                setViewportX(width);
            });
        });
        if (ref.current) resizeObserverRef.current.observe(ref.current);
        return () => {
            if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
        };
    }, [ref, journey]);



    return (
        <div style={{background: 'var(--surface-ground)', position:'relative', paddingBottom: '1px'}}>

                <div style={(journeys?.length === 0 || journeys === null) ? {display: 'flex', alignItems: 'center', paddingLeft: '10px', height: '54px'} :
                    {display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
                    {pageToShow !== '/notifications' && pageToShow !== '/media' &&
                        <CalendarDatePicker date={date} handleDate={handleDate} disableDateChange={disableDateChange} />
                    }

                    {(journeys?.length === 0 || journeys === null) ?
                        <div style={winWidth > 800 ? {position:'absolute', top: '30%', left: '42%'} : {paddingLeft: '10px'}}>
                            No Journeys for the day
                        </div>
                    :
                        <div ref={ref} style={{flex: 1, paddingLeft: '10px', position: 'relative'}}>

                            {jHover &&
                                <JCaterpillarTooltip jHover={jHover} />

                            }



                            {winWidth > 800 ?
                                // j bar
                                <JourneyCaterpillars lastJourney={lastJourney} viewportX={viewportX} handleJHover={handleJHover}/>
                                :
                                // mobile j display
                                <MobileJourneySelect />
                            }
                        </div>
                    }

                </div>


        </div>
    )}
export default ListJourneys;
