import {API_URL, NOTIFICATIONS_API_URL} from '../../Constants.js'

const postVideoRequest = async (videoObj, vehicle, emailNotification = false) => {


    let error = '';
    let obj = {};

    let token = localStorage.getItem('access_token');

    const requestOptions = (obj) => {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token  },
            body: JSON.stringify(obj)
        }
    }


    try {
        let device = vehicle?.properties ? vehicle.properties : vehicle

        let camPosSelected;

        device?.cameras?.forEach(cam => {
            if (videoObj.ch.toString() === cam.channel.toString()){
                camPosSelected = cam?.camPosition;
            }
        });

        const notificationData = {
            mflGroup: localStorage.getItem('mflGroup'),
            notificationRead: [],
            notificationCreatedTimestamp: new Date(),
            dn: device?.dn,
            registration: device?.registration,
            fleetId: device?.fleetId,
            notificationType: ['videoRequested'],
            notificationDetails: {
                title: 'Video requested',
                description: 'A new video upload has been requested',
                eventCreatedBy: localStorage.getItem('email'),
                videoRequest: videoObj,
                camPosition: camPosSelected
            },
        };


        //await fetch(NOTIFICATIONS_API_URL + '/addNotification', requestOptions(notificationData));
        const raw = await fetch(API_URL + '/video-request', requestOptions(videoObj));

        const data = await raw.json();
        obj.filename = data?.mediaRequest.filename;
        obj.media_id  = data?.mediaRequest?._id
        obj.mflGroup = localStorage.getItem('mflGroup');
        obj.fleetId = device?.fleetId;
        obj.camPosition = camPosSelected;
        obj.emailNotification = emailNotification;
        obj.email = localStorage.getItem('email');
        obj.dn = device?.dn;
        obj.requestDate = data?.mediaRequest?.requestDate;
        obj.st = data?.mediaRequest?.st;
        obj.et = data?.mediaRequest?.et;


        await fetch(NOTIFICATIONS_API_URL + '/addToMediaQueue', requestOptions(obj))



    } catch (e) {
        error = e;
        console.log(e)
    }

    const toReturn = {
        error: error,
        status: error?.length > 0 ? 'error' : 'ok',
        data: obj
    }

    return Promise.resolve(toReturn);
}


export default postVideoRequest;
