export const camPosFromCh = (device, ch) => {
    let camPosSelected;
    device?.cameras?.forEach(cam => {
        if (ch?.toString() === cam?.channel?.toString()){
            camPosSelected = cam?.camPosition;
        }
    });

    return camPosSelected;
}
