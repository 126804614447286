import React, {useContext, useEffect, useState} from 'react';
import SnapshotWithAi from "./snapshotWithAi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/pro-regular-svg-icons";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import mainContext from "../../../../contexts/mainContext";
import {DmsEventTypes} from "../../../../../Constants";
import {Button} from "primereact/button";
import {uCaseFirst} from "../../../../../functions/formatting/uCaseFirst";
import {toTitleCase} from "../../../../../functions/formatting/titleCase";

const SnapshotTab = ({camPosArray, frontChn, driverChn}) => {
    const {event} = useContext(mainContext)

    const [snapshotSelected, setSnapshotSelected] = useState();
    const [aiDataSelected, setAiDataSelected] = useState([]);
    const [snapArray, setSnapArray] = useState([]);

    const tStyle = {
        padding: '2px 2px',
        margin: '3px',
        background: 'var(--surface-300)',
        fontSize: '11px',
        color: 'var(--text-color)'
    }



    useEffect(() => {


        if (event && frontChn && camPosArray) {


            let newSnapArray = []

            if (camPosArray) {

                if (safeParse(event?.snapshots)?.length >= 1) {
                    for (const snap of safeParse(event?.snapshots)) {

                        // determine if driverBehaviour is dsm, if show show driver cam as snapshot, if driver cam snap exists

                            if (snap?.ch?.toString() === driverChn?.toString() && snap.status === 'uploaded' &&
                                event?.eventSource === 'DMS-ADAS' && DmsEventTypes.includes(event?.eventType.toLowerCase())){
                                newSnapArray.splice(0, 0, snap)
                            } else if (snap?.ch?.toString() === frontChn?.toString() && snap.status === 'uploaded'){
                                newSnapArray.splice(0, 0, snap)
                            } else {
                                newSnapArray.push(snap)
                            }

                    }
                }
                setSnapArray(newSnapArray)

                handleSnapChange(newSnapArray[0])
            } else {
                setSnapArray(safeParse(event?.snapshots))
            }
        }




    }, [event, frontChn, camPosArray])



    const handleSnapChange = (val) => {
        if (val){
            setSnapshotSelected(val);
            setAiDataSelected(null)
            if (safeParse(event?.rekognition)?.length > 0){

                safeParse(event?.rekognition).forEach(ai => {
                    if(ai.filename === val.file){
                        setAiDataSelected(ai.response);
                    }
                });
            }
        }
    }


    const SnapshotButtons = () => {
        if(snapArray.length > 0){
            return (
                <div style={{textAlign: 'center', color: 'rgba(0,0,0,0.6)', width: '100%'   }}>

                    <FontAwesomeIcon icon={faImage} style={{marginRight: '8px', color: 'var(--text-color)'}}/>

                    {snapArray.map((item, index) =>
                        <span key={index} onClick={() => handleSnapChange(item)} style={{cursor: 'pointer', color: 'var(--text-color)'}}>
                            {camPosArray[item.ch]}
                            {snapArray.length > 1 && index !== snapArray.length - 1 &&
                                <span>&nbsp;|&nbsp;</span>
                            }
                        </span>
                    )}


                </div>
            )
        } else {
            return <div />
        }
    }

    return (
        <div style={{height: '48vh'}}>
            <SnapshotButtons />
            <br />
            <SnapshotWithAi snapshot={snapshotSelected} aiData={aiDataSelected}/>
        </div>
    )
}

export default SnapshotTab
