import {KEY, DEFAULT_UPLOAD_TIME_LIMIT} from '../../../../Constants.js'
import CryptoJS from "crypto-js";

export const videoDurationOptions = (deviceDetails) => {


    const mediaLengthOptions = [
        {
            duration: 10,
            label: 'Video duration: 10 Seconds'
        },
        {
            duration: 20,
            label: 'Video duration: 20 Seconds'
        },
        {
            duration: 30,
            label: 'Video duration: 30 Seconds'
        }
    ]





    const timeLimit =  localStorage.getItem('timeLimit') && localStorage.getItem('timeLimit') < 600 ?
        localStorage.getItem('timeLimit') : 600;

    if (timeLimit > 30){
        for (let i = 1; i <= Math.floor(timeLimit / 60); i++){
            mediaLengthOptions.push({
                duration: 60 * i,
                label: 'Video duration: ' + i + ' Minute' + (i > 1 ? 's' : '')
            })
        }
    }

    return mediaLengthOptions;

}
