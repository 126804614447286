import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMicrochip,
    faLocationCrosshairs,
    faSignalBars,
    faSteeringWheel,
    faVideo,
    faDatabase,
    faBan
} from '@fortawesome/pro-regular-svg-icons'
import LatestCamSnapshot from "../../../viewMedia/latestCamSnapshot";


const HealthSummaryTable = ({healthData, theLimit, deviceDetails, leftCol, centerCol, rightCol}) => {


    const [deviceHealth, setDeviceHealth] = useState();

    const greenPip = {
        width: '15px',
        height: '15px',
        borderRadius:' 50%',
        background: '#5C8459',
        marginLeft: 'auto'
    }

    const yellowPip = {
        width: '15px',
        height: '15px',
        borderRadius:' 50%',
        background: '#FFBF00',
        marginLeft: 'auto'
    }

    const redPip = {
        width: '15px',
        height: '15px',
        borderRadius:' 50%',
        background: '#F24141',
        marginLeft: 'auto'
    }

    const greyPip = {
        width: '15px',
        height: '15px',
        borderRadius:' 50%',
        background: 'lightgrey',
        marginLeft: 'auto'
    }


    useEffect(() => {
        if (healthData && healthData?.length > 1){
            let data = {
                connection: '',
                gforce: '',
                gps: '',
                cellular: '',
                storage: ''
            }

            let satelliteTotal = 0;
            let cellularTotal = 0;

            healthData.reverse().forEach((alarm, index) => {

                // most recent record
                if (index === (healthData.length - 1)){
                    const secondsSinceUpdate = ((new Date().getTime() - new Date(alarm.alarmData.statusData.deviceTime).getTime()) / 1000);
                    const d = Math.floor(secondsSinceUpdate / (3600 * 24));

                    if (d < 5) {
                        data.connection = 'green';
                    } else if (d < 10) {
                        data.connection = 'yellow';
                    } else if (d > 10) {
                        data.connection = 'red';
                    }

                    data.gforce = '';

                    if (alarm?.alarmData?.statusData?.speed === 0 ){
                        if (Math.abs(alarm.alarmData.statusData.gSensorImpact) < 10){
                            data.gforce = 'green';
                        } else {
                            data.gforce = 'red';
                        }
                    }

                    if ((alarm?.alarmData?.statusData?.HardDiskSizeMb / 1000) >= 75){
                        data.storage = 'green';
                    } else if ((alarm?.alarmData?.statusData?.HardDiskSizeMb / 1000) >= 35){
                        data.storage = 'yellow';
                    } else {
                        data.storage = 'red';
                    }
                }

                satelliteTotal += alarm?.alarmData?.statusData?.satellitesQuantity;

                if (alarm?.alarmData?.statusData?.networkType === 5){
                    cellularTotal ++;
                }
            });

            if ((satelliteTotal / theLimit) === 0){
                data.gps = 'red';
            } else if ((satelliteTotal / theLimit) < 5){
                data.gps = 'yellow';
            } else {
                data.gps = 'green';
            }

            if ((cellularTotal / theLimit) * 100 < 30){
                data.cellular = 'red';
            } else if ((cellularTotal / theLimit) * 100 < 50){
                data.cellular = 'yellow';
            } else {
                data.cellular = 'green';
            }
            setDeviceHealth(data)
        }
    }, [healthData]);




    const HealthPip = ({colour}) => {
        let style;

        if (colour === 'red'){
            style = redPip
        } else if (colour === 'yellow'){
            style = yellowPip
        } else if (colour === 'green'){
            style = greenPip
        } else {
            style = greyPip;
        }

        return (
            <div style={style}></div>
        )
    }





    return (
        <React.Fragment>
        <table style={{width: '100%', fontSize: '14px'}}>
            <tbody>
                {/*<tr>*/}
                {/*    <th style={leftCol}></th>*/}
                {/*    <th style={rightCol}>Health</th>*/}
                {/*    /!*<th style={rightCol}>Status</th>*!/*/}
                {/*</tr>*/}

                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faMicrochip} />
                        &nbsp;Device Connection:
                    </th>
                    <td style={rightCol}>
                        <HealthPip colour={deviceHealth?.connection} />
                    </td>
                    {/*<td style={rightCol}>cur status</td>*/}
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faDatabase} />
                        &nbsp;Memory status:
                    </th>
                    <td style={rightCol}>
                        <HealthPip colour={deviceHealth?.storage} />
                    </td>
                    {/*<td style={rightCol}>cur status</td>*/}
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                        &nbsp;GPS signal strength:
                    </th>
                    <td style={rightCol}>
                        <HealthPip colour={deviceHealth?.gps} />
                    </td>
                    {/*<td style={rightCol}>cur status</td>*/}
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faSignalBars} />
                        &nbsp;4G signal strength:
                    </th>
                    <td style={rightCol}>
                        <HealthPip colour={deviceHealth?.cellular} />
                    </td>
                    {/*<td style={rightCol}>cur status</td>*/}
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faSteeringWheel} />
                        &nbsp;G-force sensor:
                    </th>
                    <td style={rightCol}>
                        {!deviceDetails?.gsensorBlocked ?
                            <HealthPip colour={deviceHealth?.gforce} />
                        :
                            <FontAwesomeIcon icon={faBan} />
                        }
                    </td>
                    {/*<td style={rightCol}>cur status</td>*/}
                </tr>
            </tbody>
        </table>
            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', gap: '2%', paddingTop: '20px'}}>
                {deviceDetails && deviceDetails?.cameras.map((cam, i) => {
                    return (
                        <div style={{flexBasis: '48%', textAlign: 'center'}} key={i}>
                            <LatestCamSnapshot cam={cam} />
                        </div>
                    )
                })}
            </div>

        </React.Fragment>

    )
}

export default HealthSummaryTable
