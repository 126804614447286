import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBusinessTime, faCar, faClock, faLocationDot, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {formatDateTimesheetReport} from "../../../../../../functions/formatting/formatDate";
import indexContext from "../../../../../contexts/indexContext";

const VehicleIdlingReportTableHeader = ({date, data}) => {

    const {darkMode} = useContext(indexContext);


    return (
        <div>
            {data?.journeys?.length > 0 ?
                <React.Fragment>

                    <h3 style={{textAlign:'center', height: '40px', background: darkMode ? 'var(--surface-card)' : '#e2e2e2',
                        lineHeight: '40px'}}>
                        {formatDateTimesheetReport(date)}
                    </h3>


                    <div style={{
                        display: 'grid', gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
                        borderBottom: '1px solid var(--surface-border)',
                        height: '30px', marginTop: '12px', pageBreakInside: 'auto', fontWeight: 'bold',
                        textAlign: 'center'
                    }} className="reportTable">


                        <div style={{lineHeight: '30px', paddingLeft: '10px'}}>
                            #
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            &nbsp;
                            Start
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            &nbsp;
                            End
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faRuler}/>
                            &nbsp;
                            Distance
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faTimer}/>
                            &nbsp;
                            Duration
                        </div>
                        <div style={{lineHeight: '30px'}}>
                            <FontAwesomeIcon icon={faTimer}/>
                            &nbsp;
                            Idling
                        </div>

                    </div>
                </React.Fragment>
                :
                <h3 style={{textAlign: 'center', height: '40px'}}>{formatDateTimesheetReport(date)} - No Journeys</h3>
            }
        </div>
    )
}
export default VehicleIdlingReportTableHeader
