import React from 'react';
import {MflLogo as NotTextLogo} from "./svgs/mflLogo";

const ErrorPage = ({error, resetErrorBoundary}) => {

    return (
        <div style={{position: 'absolute', top: '50%', left:'50%', transform: 'translate(-50%, -50%)',
            textAlign: 'center', fontSize: '24px', width: '100%'}}>

            <NotTextLogo size='175px'/>


            <p>
                Sorry something has gone wrong, please try again.<br/>
                If the problem persists please contact support@myfleetlive.co.uk
                {/*<a href="mailto:support@myfleetlive.co.uk?subject=mflappError&body">*/}
                {/*    support@myfleetlive.co.uk*/}
                {/*</a>*/}

            </p>

            <pre style={{fontSize: '12px'}}>{error.message}</pre>

            <button onClick={() => window.location.reload()}>Try again</button>
        </div>
    )
}

export default ErrorPage
