import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const EngineerStacked = ({event, height, showTool = true}) => {




    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" data-pr-position="bottom" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>

                <path d="m85.95,79.05c0-12.15,9.85-22,22-22v205.26l60.65-43.24c3.86-2.79,9.12-2.79,12.98,0l60.37,43.24V78.95H107.95v-22h145.05c6.6,0,11.95,5.35,11.95,11.95v216.6c0,.93-.19,1.86-.6,2.7-3.52,7.33-11.65,8.79-17.07,4.93l-71.83-51.19-71.83,51.19c-5.42,3.85-13.55,2.4-17.07-4.93-.4-.84-.6-1.77-.6-2.7V79.05Z" style={{fill:"var(--text-color)"}}/>
                <g id="boundingbox">
                    <rect x="57.21" y="56.88" width="238.77" height="238.77" style={{fill:"none"}}/>
                </g>
                <g id="boundingbox-2" data-name="boundingbox">
                    <rect x="41.65" y="54.91" width="282.78" height="282.78" style={{fill:"none"}}/>
                </g>

            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>Device Mode Changed - {event?.commissionStatus}</Tooltip>
            }
        </div>
    )
}
export default EngineerStacked
