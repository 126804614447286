import React, {useContext, useEffect, useRef, useState} from 'react';
import indexContext from "../../../contexts/indexContext";
import CalendarDatePicker from "../datePicker/calendarDatePicker";
import {Dropdown} from "primereact/dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {
    faVideoCamera, faRectangleHistory, faArrowDownBigSmall,
    faFilter, faMap, faGrid4, faGaugeMax, faMobile, faSmoking, faTrashSlash, faTrash, faEye
} from "@fortawesome/pro-regular-svg-icons";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {Button} from "primereact/button";
import {groupBy} from "../../../../functions/groupBy";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {InputText} from "primereact/inputtext";
import abovePanelContext from "../../../contexts/abovePanelContext";
import EventCheckboxes from "./eventButtonFilters";
import {Checkbox} from "primereact/checkbox";
import {uCaseFirst} from "../../../../functions/formatting/uCaseFirst";
import {confirmPopup} from "primereact/confirmpopup";
import EventButtonFilters from "./eventButtonFilters";
import {InputSwitch} from "primereact/inputswitch";
import {Tooltip} from "primereact/tooltip";
import {SelectButton} from "primereact/selectbutton";

const EventFilters = ({lastDaysOfEvents, handleLastDaysOfEvents}) => {

    const {winWidth} = useContext(indexContext);

    const {disableDateChange, handleSuperTags, gridDataRaw,  handleGridData,pageToShow, handleSelectAll, gridData,
        selectAll, action, actionOptions, handleAction, markSelectedRead, refresh, handleGridDate, gridDate,
        handleEventParams, eventCounts, eventParams, handleViewMode, viewMode, handleShowEventFilters,
        handleDateSortEvents, handleScoreSortEvents, dateSortEvents, scoreSortEvents, readNotis
    } = useContext(abovePanelContext)

    const [activeIndex, setActiveIndex] = useState(2);

    const [curTagFilters, setCurTagFilters] = useState(eventParams?.length > 0 ? JSON.parse(JSON.stringify(eventParams)) : [])
    const [tagsToDisplay, setTagsToDisplay] = useState();
    const [mediaTypes, setMediaTypes] = useState([]);
    const [mediaSelected, setMediaSelected] = useState();

    const [showOptions, setShowOptions] = useState(false);
    const [showChart, setShowChart] = useState(false);

    const [showTagFilters, setShowTagFilters] = useState(false);


    const chartStyle = {
        display: "none",
    }

    const optionsStyle = {
        display: "none",
    }

    const showOptionsStyle = {
        position: "absolute",
        display: "block",
        zIndex: "100000000!important",
        right:"10px",
        boxShadow:" 0 3px 12px rgba(0, 0, 0, 0.15)",
        top: "82px",
        padding: '10px',
        background: 'var(--surface-card)',
        borderRadius: '3px',
        textAlign: 'center'
    }

    const showChartStyle = {
        position: "absolute",
        display: "flex",
        flexDirection: 'column',
        zIndex: "10000000!important",
        right:"0px",
        left: "0px",
        boxShadow:" 0 3px 12px rgba(0, 0, 0, 0.15)",
        top: "92px",
        padding: '10px',
        background: 'var(--surface-card)',
        borderRadius: '3px',
        textAlign: 'center',
        //height: '300px'
    }


    const itemsMobile = [
        // {display: 'AI Filter', value: 'interesting', index: 0, icon: isStarWars() ? faRobotAstromech: faMicrochipAi},
        // {display: 'Severe', value: 'severe', index: 1, icon: faCircleExclamation},
        {display: 'Speeding', value: ['speeding'], index: 0, icon: faGaugeMax},
        {display: 'Phone', value: ['Mobile phone', 'mobile'], index: 1, icon: faMobile},
        {display: 'Smoking', value: ['Smoking', 'smoking'], index: 2, icon: faSmoking},
        {display: 'Video', value: ['video'], index: 3, icon: faVideoCamera},
        {display: 'Eyes closed', value: ['Drowsiness'], index: 4, icon: faEye},
        {display: 'All', value: [], index: 5, icon: faRectangleHistory},
        //{display: 'Speeding', value: ['speeding', '-deceleration', '-acceleration', '-Tailgating'], index: 4, icon: faGaugeMax},
        // {value: 'read', index: 5, icon: faTrash},
        // {value: 'starred', index: 6, icon: faStar}
    ];



    const changeDropdownTab = async (e) => {
        const obj = {
            value: {
                value: e
            },
            index : itemsMobile.filter(item => item.value === e)[0].index
        }
        await changeTab(obj)
    }

    const changeTab = async (e) => {

        if (!disableDateChange) {

            if (e.index === 5){
                setCurTagFilters([])
                handleEventParams([]);
            } else {
                if (e.value.value.includes('user')) handleDateSortEvents(true)

                handleEventParams(e.value.value)
            }
        }
    }

    const selectedTypeTemplateMobile = () => {
        const test = itemsMobile.filter(item => item.index === activeIndex)[0]
        return selectedOptionTemplate(test)
    }


    const handleHideChart = () => setShowChart(false)


    useEffect(() => {

        setCurTagFilters(eventParams)
        if (eventParams?.length < 1) setActiveIndex(5)
    }, [eventParams])




    useEffect(() => {

        let newCategories = {};
        if (eventCounts?.length > 0){
            if (!tagsToDisplay){
                eventCounts?.forEach(item => {
                    if (!newCategories?.[item?.tagDetails?.[0]?.context.toLowerCase()]) newCategories[item?.tagDetails?.[0]?.context.toLowerCase()] = []
                    if (item?.tagDetails?.[0]?.displayInApp) newCategories[item?.tagDetails?.[0]?.context.toLowerCase()].push(item);
                });

            } else {
                newCategories = Object.assign({}, tagsToDisplay);
                let added = [];


                Object.keys(newCategories).forEach(cat => {
                    newCategories[cat].forEach(count => {

                        added.push(count._id);

                        const updated = eventCounts?.filter(newItem => newItem._id === count._id)
                        if (updated?.length > 0){
                            count.tags = updated[0].tags
                        } else {
                            count.tags = 0
                        }
                    })
                });

                eventCounts.forEach(count => {
                    if (!added?.includes(count?._id)){
                        if(!newCategories[count?.tagDetails?.[0]?.context.toLowerCase()]) newCategories[count?.tagDetails?.[0]?.context.toLowerCase()] = []

                        if (count?.tagDetails?.[0]?.displayInApp){
                            newCategories?.[count?.tagDetails?.[0]?.context.toLowerCase()].push(count)
                        }
                    }
                })

            }

        }
        setTagsToDisplay(newCategories)



    }, [eventCounts])

    const SuperUserTagSearch = () => {
        const [superTags, setSuperTags] = useState('')

        return (
            <React.Fragment>
                <div className="p-inputgroup" style={{width: '200px'}}>
                    <InputText type="text" placeholder="Tags" value={superTags}
                               onInput={(e) => {
                                   setSuperTags(e?.target?.value)
                               }}/>
                    <Button icon="pi pi-search" className="p-button-warning" onClick={() => handleSuperTags(superTags)} />
                </div>
            </React.Fragment>
        )
    }


    const itemTemplate  = (item) => {
        return (
            <div onClick={(e) => handleCheckBoxClick(item?._id)}>
                <Checkbox id={item?._id} onChange={(e) => handleCheckBoxClick(item?._id)}
                          checked={curTagFilters?.includes(item?._id)}/>
                &nbsp;
                <label htmlFor={item?._id} className="ml-2">
                    {item.tagDetails?.[0]?.displayName ? item.tagDetails?.[0]?.displayName : item?._id}&nbsp;
                    <span style={{
                        color: 'var(--text-color-secondary)',
                        verticalAlign: 'super',
                        fontSize: '12px'
                    }}>{item?.tags}</span>


                    {/*{item.tagDetails?.[0]?.origin &&*/}
                    {/*    <React.Fragment>*/}
                    {/*        {item.tagDetails?.[0]?.origin === 'cloud AI' ?*/}
                    {/*            <FontAwesomeIcon icon={faCloud} />*/}
                    {/*        :*/}
                    {/*            <FontAwesomeIcon icon={faMicrochipAi} />*/}
                    {/*        }*/}
                    {/*    </React.Fragment>*/}
                    {/*}*/}



                </label>


            </div>
        )
    }

   


    const handleCheckBoxClick = (val) => {

        if (!disableDateChange) {
            let newFilters = JSON.parse(JSON.stringify(curTagFilters))

            if (curTagFilters?.includes(val)) {
                const index = newFilters.indexOf(val);
                if (index > -1) newFilters.splice(index, 1);
            } else {
                newFilters.push(val)
            }


            setCurTagFilters(newFilters)
            handleEventParams(newFilters);
        }
    }


    const confirmDeleteAll = (e) => {

        confirmPopup({
            target: e.currentTarget,
            message: `Are you sure you want to ${readNotis ? 'undelete' : 'delete'} all events?`,
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await markSelectedRead()
            },
            reject: () => {
                return
            }
        });

    }


    const sortTemplate = (option) => {
        return (
            <span className="p-button-label">
                <FontAwesomeIcon icon={faArrowDownBigSmall} />
                <br/>{option}
            </span>
        );
    }


    return (
        <div style={{display: 'flex', borderBottom: '1px solid var(--surface-border)', alignItems: 'center',
            padding: winWidth > 800 ? '5px 0px 5px 10px'  : '1px 20px 1px 20px', justifyContent: 'space-between', background: 'var(--surface-ground)'}}>
            {pageToShow !== '/notifications' && pageToShow !== '/media' &&
                <div style={{marginTop: '-1px'}}>
                    <CalendarDatePicker date={gridDate} handleDate={handleGridDate} disableDateChange={disableDateChange}
                                        pageToShow={pageToShow} lastDaysOfEvents={lastDaysOfEvents} handleLastDaysOfEvents={handleLastDaysOfEvents}/>
                </div>

            }

            {winWidth > 800 ?
                <React.Fragment>
                    {/*<TabMenu model={items2} activeIndex={activeIndex} onTabChange={(e) => changeTab(e)}*/}
                    {/*         className="eventsFiltersTabs" style={{marginLeft: '10px', borderBottom: 'none!important'}}/>*/}


                    <div style={{
                        marginTop: '-1px',
                        overflow: 'hidden',
                        display: 'flex',
                        marginLeft: '10px',
                        flexFlow: 'row wrap',
                        maxHeight: '46px',
                        rowGap: '10px'
                    }}>
                        {/*{itemsMobile.map(item => (*/}
                        {/*    <button className={item.index === activeIndex ? "tStyleSelected p-mb-2" : "tStyle p-mb-2"}*/}
                        {/*            key={item.index}*/}
                        {/*            onClick={() => changeTab({index: item.index, value: item})}>*/}
                        {/*        <span className="p-button-label"*/}
                        {/*              style={item?.display ? {} : {padding: '0px 10px 0px 10px'}}>*/}
                        {/*            <FontAwesomeIcon icon={item.icon}/>*/}
                        {/*            &nbsp;{item.display}*/}
                        {/*        </span>*/}
                        {/*    </button>*/}
                        {/*))}*/}


                        <EventButtonFilters itemTemplate={itemTemplate} tagsToDisplay={tagsToDisplay}
                                            showTagFilters={showTagFilters} changeTab={changeTab}
                                            itemsMobile={itemsMobile} eventParams={eventParams}
                                            sortTemplate={sortTemplate} scoreSortEvents={scoreSortEvents}
                                            handleScoreSortEvents={handleScoreSortEvents}
                                            handleDateSortEvents={handleDateSortEvents}/>


                    </div>


                    <div style={{flex: 1, background: 'var(--surface-ground)'}}/>

                    {/*<div style={{paddingRight: '5px'}}>*/}
                    {/*    <InputSwitch checked={scoreSortEvents} onChange={() => {*/}
                    {/*        if (scoreSortEvents) handleDateSortEvents();*/}
                    {/*        else handleScoreSortEvents();*/}
                    {/*    }} />*/}
                    {/*    <span style={{paddingLeft: "5px"}}>Score order</span>*/}
                    {/*</div>*/}
                    <button className="p-button p-button-sm eventFilterButton" style={{
                        marginLeft: '5px',
                        background: dateSortEvents ? 'var(--v-list-button-selected)' : 'var(--v-list-button)',
                        color: dateSortEvents ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                        borderTopRightRadius: '0px', borderBottomRightRadius: '0px'
                    }}
                            onClick={() => handleDateSortEvents()}>

                        <span className="p-button-label" style={{fontWeight: 'normal'}}>
                            <FontAwesomeIcon icon={faArrowDownBigSmall}/>
                            <br/>Date
                        </span>
                    </button>

                    <button className="p-button p-button-sm eventFilterButton" style={{
                        marginRight: '4px',
                        background: scoreSortEvents ? 'var(--v-list-button-selected)' : 'var(--v-list-button)',
                        color: scoreSortEvents ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                        borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'
                    }}
                            onClick={() => handleScoreSortEvents()}>

                        <span className="p-button-label" style={{fontWeight: 'normal'}}>
                            <FontAwesomeIcon icon={faArrowDownBigSmall}/>
                            <br/>Score
                        </span>
                    </button>

                    <button
                        className="p-button p-button-sm eventFilterButton"
                        style={{
                            background: showTagFilters ? 'var(--v-list-button-selected)' : 'var(--v-list-button)',
                            color: showTagFilters ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                            marginRight: '5px'
                        }}
                        onClick={() => setShowTagFilters(!showTagFilters)}>
                                <span className="p-button-label" style={{fontWeight: 'normal'}}>
                                    <FontAwesomeIcon icon={faFilter}/>
                                    <br/>Filters
                                </span>
                    </button>

                    {/*<button*/}
                    {/*    className={JSON.stringify(gridData) !== JSON.stringify(gridDataRaw) ? "tStyleSelected p-mb-2" : "tStyle p-mb-2"}*/}
                    {/*    style={{width: '50px'}}*/}
                    {/*    onClick={() => handleViewMode(viewMode === 'grid' ? "map" : 'grid')}*/}
                    {/*    aria-haspopup aria-controls="overlay_panel">*/}
                    {/*            <span className="p-button-label">*/}
                    {/*                <FontAwesomeIcon icon={viewMode === 'grid' ? faMap : faGrid4}/>*/}
                    {/*            </span>*/}
                    {/*</button>*/}


                    <button className="p-button p-button-sm eventFilterButton" style={{
                        marginRight: '5px',
                        background: 'var(--v-list-button)',
                        color: 'var(--v-list-button-text)'
                    }}
                            onClick={() => {
                                if (!disableDateChange) {
                                    refresh();
                                }
                            }}>
                        <span className="p-button-label" style={{fontWeight: 'normal'}}>
                            <FontAwesomeIcon icon={faArrowsRotate}/>
                            <br/>Refresh
                        </span>
                    </button>

                    <button className="p-button p-button-sm eventFilterButton" style={{
                        marginRight: '20px',
                        background: 'var(--v-list-button)',
                        color: 'var(--v-list-button-text)'
                    }}
                            disabled={false}
                            onClick={(e) => confirmDeleteAll(e)}>

                        <span className="p-button-label" style={{fontWeight: 'normal'}}>
                            {readNotis ?
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faTrashSlash}/>
                                    <br/>Undelete
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faTrash}/>
                                    <br/>Delete
                                </React.Fragment>
                            }
                        </span>
                    </button>


                </React.Fragment>
                :
                <Dropdown value={activeIndex} onChange={(e) => changeDropdownTab(e.value)} options={itemsMobile}
                          optionLabel="label"
                          placeholder="Select event filter" valueTemplate={selectedTypeTemplateMobile}
                          itemTemplate={(e) => optionTemplate(e)}
                          style={{height: '40px', marginTop: '2px', marginBottom: '2px'}}/>
            }

        </div>
    )
};

export default EventFilters
