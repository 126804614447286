import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {    faCarMirrors, faTrash} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {Tooltip} from "primereact/tooltip";
import {faBuildings} from "@fortawesome/pro-regular-svg-icons";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {fleetFromDn} from "../../../../../functions/formatting/fleetFromDn";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import EventClassificationIcon from "../cardComponents/eventClassificationIcon";
import MediaUploadInfoIcon from "./mediaUploadInfoIcon";
import VideoUploadProgressTracker from "../../../requestingMedia/videoUploadProgressTracker";
import deleteNotificationMediaQueue from "../../../../../api/notifications/deleteNotificationMediaQueue";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import fetchEventsVideoFile from "../../../../../api/events/fetchEventsVideoFile";
import postVideoRerequest from "../../../../../api/media/postVideoRerequest";
import VideoUploadProgressTrackerV2 from "../../../requestingMedia/uploadProgressTrackerV2";


const NotificationGridUpload = ({notification}) => {

    const {vehicles, handleEvent, handleVehicle} = useContext(mainContext);
    const {fleets, handleRefreshNotifications} = useContext(indexContext)



    const deleteMediaQueue = async (item) => {

        const data = {
            dn: item.dn,
            mediaId: item._id,
            requestType: 'cancel'
        }

        // await postVideoRerequest(data);
        await deleteNotificationMediaQueue(item);

        const ele = document.getElementById(item?._id);
        ele.parentNode.remove();

        handleRefreshNotifications();
    }


    const handleMediaClick = () => {
        if(notification.filename){
            fetchEventsVideoFile({
                dn: notification?.dn,
                file: notification.filename
            }).then(r => {
                handleEvent(r[0])
            });
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        handleMediaClick(notification);
    }


    return (
        <React.Fragment>
            <div key={notification?._id} style={{padding: '10px',borderRadius: 'inherit',width: '100%', height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin:'5px', position: 'relative'}}>
                    <div style={{flex:1}}>
                        <div>
                            <FontAwesomeIcon icon={faClock} style={{paddingRight: '5px'}}/>
                            <span style={{fontWeight: 800, fontSize: '1.2em'}}>
                                {formatDateNotificationsTable(notification?.requestDate)}
                            </span>
                        </div>

                        <div style={{maxWidth: 'calc(85% - 100px)', marginTop: '10px'}}>
                            <div style={{width: '100%',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}>
                                <FontAwesomeIcon icon={faBuildings} style={{paddingRight: '5px'}}/>
                                {friendlyFleetName(notification?.fleetId ? notification.fleetId : fleetFromDn(vehicles, notification?.dn), fleets)}
                            </div>
                        </div>

                        <div style={{marginTop: '10px'}}  onClick={(e) => {
                            e.stopPropagation();
                            handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                        }}>

                            <FontAwesomeIcon icon={faCarMirrors} style={{paddingRight: '5px'}}/>
                            <span className={"event"+ notification._id + + "reg"} data-pr-position="bottom">
                                {regFromDn(vehicles, notification?.dn)}
                            </span>
                            <Tooltip target={".driverBehaviour"+ notification._id + + "reg"}>{notification.dn}</Tooltip>

                        </div>
                    </div>

                    {/*<div className="eventClassificationContainer surface-ground" style={{position:'absolute', right: 0, background:'#ffffff'}}>*/}
                    {/*    <div className="eventClassificationTitle">Details</div>*/}
                    {/*    <EventClassificationIcon event={{analysis: {eventType: 'video'}}} height='30px' />*/}
                    {/*    <MediaUploadInfoIcon notification={notification} />*/}
                    {/*</div>*/}

                </div>


                <div style={{textAlign: 'center', margin: '0 auto', marginTop: '25px', position: 'relative'}} onClick={handleClick}>
                    <VideoUploadProgressTrackerV2 upload={notification} vehicles={vehicles} handleEvent={handleEvent} page={'notifications'}
                                                deleteMediaQueue={deleteMediaQueue} handleRefreshNotifications={handleRefreshNotifications}/>
                </div>



            </div>
        </React.Fragment>
    )
}
export default NotificationGridUpload
