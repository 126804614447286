import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Hls from "hls.js";
import startStream from "../../../api/startStream.js";
import {ProgressSpinner} from "primereact/progressspinner";
import {LIVESTREAM_URL} from '../../../Constants.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons'
import mflLoading from '../../../svgs/mfl_waiting.gif'
import {Button} from "primereact/button";

import './streamingModalStyles.css'
import indexContext from "../../contexts/indexContext";
import FetchMediaToken from "../../../api/media/fetchMediaToken";
import startStreamStatus from "../../../api/startStreamStatus";
import {Fieldset} from "primereact/fieldset";
import {Message} from "primereact/message";
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";





const divStyle = {
    margin: '0px!important',
    // textAlign: 'center',
    padding: '0px!important'
};

const hyperlapseBtn = {
    padding: '0.3rem 0.3rem',
    margin: '3px',
    display: 'inline-flex',
    alignItems: 'center',
    border: 'none',
    background: 'transparent'
}


const buttonStyle = {
    padding: '2px 8px',
    cursor: 'pointer',
    borderRadius: '5px',
    background: '#ffffff !important',
    color: 'black !important',
    border: 'solid 1px black !important',
    fontWeight: '500!important',
    fontSize: '14px',
    boxSizing: 'border-box',
    position: 'relative',
    outline: 'none',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    margin: 0,
    minWidth: '64px',
    lineHeight: '28px',
    boxShadow: '2px 2px 0 0 rgb(0 0 0 / 20%)'
}



const StreamHLS = ({dn, channels, token, liveStaging, deviceDetails, vehicle, closeModal}) => {




    const defaultFrontCam = () => {
        let front = 1;
        for (const cam of deviceDetails.cameras){
            if (cam.camPosition === 'Front'){
                front = cam.channel;
            }
        }
        return front;
    }

    const {winWidth} = useContext(indexContext);

    const [camerasToStream, setCamerasToStream] = useState(new Set([]));
    const [showMinCamWarn, setShowMinCamWarn] = useState(false);

    let videoPlayerRef = useRef({});

    const [loadingFlag, setLoadingFlag] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [playingFlag, setPlayingFlag] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [stoppedFlag, setStoppedFlag] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [connectedFlag, setConnectedFlag] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [timeoutFlag, setTimeoutFlag] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [videoHovered, setVideoHovered] = useState(() => Array.apply(null, Array(8)).map(function () { return false; }));
    const [statusData, setStatusData] = useState(() => Array.apply(null, Array(8)).map(function () { return []; }));
    const [deviceOffline, setDeviceOffline] = useState(false);
    const [stopBeforeAddMore, setStopBeforeAddMore] = useState(false);


    // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhcmFoQHN1cGVyIiwicGFzc3dvcmQiOiJFMWZtYXJneTRqWGE0M3ljdlkxNCIsImF1dGhvcmlzYXRpb24iOiJzdXBlcnVzZXIiLCJkbkFycmF5IjpbIjEwMDAwMjAiLCIxMDAwMjAiLCIxMDAwMDQwIiwiNTQzMzUyMjciLCI1NDMzNTI2OCIsIjU0MzM1MjUwIiwiNTQzMzUyMDEiLCI1NDMzNTIxOSIsIjU0MzM1Mjc2IiwiMTAwMDUwMCIsIjEwMDAwNTAiLCIxMDAwMDUxIiwiMTAwMDYyNCIsIjEwMDA5MjQiLCIxMDAwMDQxIiwiMTAwMDU4OSIsIjEwMDAyMzQiLCIxMDAwODk0IiwiMTIwMDA0MCIsIjEwMDA3OTkiLCIxMDAwNzk3IiwiMTAwMDA3MCIsIjEwMDA5MTYiLCIzNTU3NDMwODAzMzQ0MjQiLCIxMDAxNjg3IiwiMTAwMTMxOSIsIjEwMDA0NjEiLCIxMDAxODA4Il0sIm1mbEdyb3VwIjoiUXVhcnRpeCIsImlhdCI6MTY0OTgzMTE2NywiZXhwIjoxNjQ5ODY3MTY3fQ.imxHbblYdoxtwutbBPUt9lUpIcElOJI9E5xQfTvybLQ';


    const config = {
        manifestLoadingRetryDelay: 100,
        liveDurationInfinity: true,
        backBufferLength: 2,
        maxLiveSyncPlaybackRate: 2,
        xhrSetup: xhr => {
            // headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token')  }
            xhr.setRequestHeader('Authorization', 'Bearer ' + token)
        }
    }

    let hls = [];

    let timers = [];

    let status = [];



    const startHLS = (playerNumber) => {
        if (!Hls.isSupported() && videoPlayerRef.current[playerNumber].canPlayType('application/vnd.apple.mpegurl')){
            return;
        }

        // console.log(typeof playerNumber);
        // console.log(playerNumber);

        timers[playerNumber] = 0;

        if(hls[playerNumber]) {
            console.log('destroy' + playerNumber)
            hls[playerNumber].destroy();
        }

        hls[playerNumber] = new Hls(config);
        console.log(`${LIVESTREAM_URL}/stream/${dn}_${playerNumber}_0.m3u8`);

        hls[playerNumber].loadSource(`${LIVESTREAM_URL}/stream/${dn}_${playerNumber}_0.m3u8`);



        // hls.detachMedia();
        hls[playerNumber].attachMedia(videoPlayerRef.current[playerNumber]);
        hls[playerNumber].on(Hls.Events.MANIFEST_PARSED, function () {
            console.log('MANIFEST_PARSED');
            const newLoadingFlag = [...loadingFlag];   //[...loadingFlag]
            newLoadingFlag[playerNumber ] = false;
            setLoadingFlag(newLoadingFlag);


            if (!window[`int_${eval(playerNumber )}`]){


                window[`int_${eval(playerNumber )}`] = setInterval(() => {
                    startStreamStatus(dn, playerNumber).then(r2 => {

                        if (r2?.ok){
                            if (r2?.timeClosed){
                                const newTimeoutFlag = [...timeoutFlag];   //[...loadingFlag]
                                newTimeoutFlag[playerNumber ] = true;
                                setTimeoutFlag(newTimeoutFlag);
                                videoPlayerRef.current[playerNumber].pause();

                                // setStoppedFlag(() => channels.map(() => true));
                                setPlayingFlag(() => Array.apply(null, Array(8)).map(function () { return false; }));

                                clearInterval(window[`int_${eval(playerNumber )}`]);
                                delete window[`int_${eval(playerNumber )}`];
                            }

                            const newStatusData = [...statusData];   //[...loadingFlag]
                            newStatusData[playerNumber].push(r2);
                            setStatusData(newStatusData);
                            setDeviceOffline(false);
                        } else {
                            clearInterval(window[`int_${eval(playerNumber )}`]);
                            delete window[`int_${eval(playerNumber )}`];
                            setDeviceOffline(true);
                        }
                    });
                }, 2000);
            }






            videoPlayerRef.current[playerNumber].play();
        });



        // startStreamStatus(dn, playerNumber).then(r2 => {
        //     status[playerNumber ].push(r2);
        //     console.log(status[playerNumber ]);
        // });




        hls[playerNumber].on(Hls.Events.ERROR, function (event, data) {
            timers[playerNumber] ++;
            console.log(timers[playerNumber])
            if(data.details === 'bufferStalledError') {
                console.log('bufferStalledError' + playerNumber);
                // clearInterval(window[`int_${eval(playerNumber )}`]);
                // delete window[`int_${eval(playerNumber )}`]

            }
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error

                        if(data.details === 'manifestParsingError' ) {
                            console.log(`waiting for stream to start ... probably ${playerNumber}`);
                        } else {
                            console.log('unknown network error encountered, try to recover');
                        }


                        if(timers[playerNumber] < 60){
                            setTimeout(() => {
                                hls[playerNumber].loadSource(`${LIVESTREAM_URL}/stream/${dn}_${playerNumber}_0.m3u8`);
                            }, 1000);
                        }
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log('fatal media error encountered, try to recover');
                        // hls[playerNumber].destroy();
                        // kickStream(stream, playerNumber)
                        hls[playerNumber].recoverMediaError();
                        break;
                    default:
                        // cannot recover
                        console.log('cannot recover');
                        hls[playerNumber].destroy();
                        // kickStream(stream, playerNumber)
                        break;
                }
            }


        });
    }


    const stopStream = (playerNumber) => {


        clearInterval(window[`int_${eval(playerNumber )}`]);
        delete window[`int_${eval(playerNumber )}`]

        timers[playerNumber] = 100;


        const newStoppedFlag = [...stoppedFlag];
        newStoppedFlag[playerNumber.channel ] = true;
        setStoppedFlag(newStoppedFlag);



        const newStatusData = [...statusData];   //[...loadingFlag]
        newStatusData[playerNumber.channel ] = [];

        console.log(newStatusData)
        setStatusData(newStatusData);


        startStream(`${dn}_${playerNumber.channel}_0`, 0,  token, liveStaging).then(r => {
            clearInterval(window[`int_${eval(playerNumber.channel)}`]);
            delete window[`int_${eval(playerNumber.channel)}`]
        });


    }

    const StreamingChannelHeader = ({channel}) => {
        let camPos;
        if (deviceDetails !== null){
            for (const cam of deviceDetails?.cameras){
                if (cam.channel == channel){
                    camPos = cam.camPosition;
                }
            }
        }
        return (
            <div style={{textAlign:'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%', margin: '0 auto', padding: '10px 0px 10px 0px'}}>

                <div style={{fontWeight: 'bold', fontSize: '16px'}}>
                    {camPos} camera
                </div>
                {statusData?.[channel]?.length > 1 && (connectedFlag[channel] && !stoppedFlag[channel ] && !loadingFlag[channel ]) &&
                    <div>
                        {`${kbps(channel)} kbps`}
                    </div>

                }
            </div>

        )
    }

    const addRemoveCameraToView = (v) => {
        if(camerasToStream.has(v)){
            camerasToStream.delete(v)
            setCamerasToStream(new Set([...camerasToStream]));
            stopStream(v);
        } else {
            if (winWidth > 800){
                if (camerasToStream.size < 4){
                    setCamerasToStream(new Set([...camerasToStream, v]));
                }
            } else {
                handleStopAllStreams();
                setCamerasToStream(new Set([v]));
            }
        }


    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            //Do whatever when esc is pressed
            stopAllAndClose()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);



    // useEffect(() => {
    //     handleStream({channel: defaultFrontCam()});
    // }, [deviceDetails])


    const stopAllAndClose = () => {
        camerasToStream.forEach(cam => {
            setTimeout(() => {
                stopStream({channel: cam});
            }, 500)
        });
        closeModal('displayLivestreamModal');
    }

    const handleStopAllStreams = () => {
        // const newConnectedFlag = [...connectedFlag];
        camerasToStream.forEach(cam => {
            videoPlayerRef?.current?.[cam].pause();
            // newConnectedFlag[cam ] = false
            setTimeout(() => {
                stopStream({channel: cam});
            }, 500)
        });
        // setConnectedFlag(newConnectedFlag);

        setStoppedFlag(() => channels.map(() => true));
        setPlayingFlag(() => Array.apply(null, Array(8)).map(function () { return false; }));
        setDeviceOffline(false);


    }

    const handleStartAllStreams = () => {

        if (camerasToStream.size > 0){
            status = [];
            const newPlayingFlag = [...playingFlag]
            const newLoadingFlag = [...loadingFlag];
            const newStoppedFlag = [...stoppedFlag];
            setTimeoutFlag(() => Array.apply(null, Array(8)).map(function () { return false; }))

            // loop of its own as the loop where streams start adds significant delay to loading flag being updated
            camerasToStream.forEach(cam => {
                newPlayingFlag[cam ] = true;
                newLoadingFlag[cam ] = true;
                newStoppedFlag[cam ] = false;
            });


            setPlayingFlag(newPlayingFlag);
            setLoadingFlag(newLoadingFlag);
            setStoppedFlag(newStoppedFlag);


            const newStatusData = [...statusData];   //[...loadingFlag]
            const newTimeoutFlag = [...timeoutFlag];   //[...loadingFlag]


            setTimeout(() => {
                const newConnectedFlag = [...connectedFlag];
                camerasToStream.forEach( cam => {

                    startStream(`${dn}_${cam}_0`, 1, token, liveStaging).then(r => {
                        setTimeout(() => {
                            startStreamStatus(dn, cam).then(r2 => {
                                newConnectedFlag[cam] = true;
                                if (r2?.bytesRead){


                                    newStatusData[cam].push(r2);

                                    startHLS(cam);
                                } else {
                                    setDeviceOffline(true)
                                    newTimeoutFlag[cam ] = true;
                                    videoPlayerRef.current[cam ].pause();
                                }
                            });
                        }, 5000)
                    });
                });
                setConnectedFlag(newConnectedFlag);
                setTimeoutFlag(newTimeoutFlag);
                setStatusData(newStatusData);
            }, 500);


        }
    }

    const getVideoStyling = (channel) => {
        if (camerasToStream.has(channel)) {
            if (camerasToStream.size === 1){
                return {width: '100%'}
            } else {
                return {width: '49%'}
            }
        } else {
            return {display: 'none'}
        }
    }

    const handleVideoHover = (channel, flag) => {
        const newVideoHover = [...videoHovered]
        newVideoHover[channel] = flag;
        setVideoHovered(newVideoHover);
    }

    const kbps = (data) => {
        return Math.round((statusData?.[data]?.[statusData?.[data]?.length-1]?.bytesRead -
            statusData?.[data]?.[statusData?.[data]?.length -2]?.bytesRead / 8) / 3000)
    }





    return (

        <div style={divStyle}>
            <div style={{position: 'absolute', top: '25px', right:'25px'}}>
                <button type="button" className="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close"
                        onClick={stopAllAndClose}>
                    <span className="p-dialog-header-close-icon pi pi-times" style={{color: 'var(--text-color)'}}>&nbsp;</span>
                </button>
            </div>

            <Fieldset legend="Select up to four cameras to livestream">
                <div style={{display: 'flex', justifySpace: 'space-evenly'}}>
                    {!showMinCamWarn && deviceDetails.cameras.map(function (cam, index){
                        return (
                            <React.Fragment>
                                {cam?.isRec === '1' &&
                                    <button onClick={() => {
                                        if ((camerasToStream.size > 0 && playingFlag.filter(item => {return item}).length > 0)){
                                            setStopBeforeAddMore(true)
                                            setTimeout(() => setStopBeforeAddMore(false), 2000)
                                        } else {
                                            addRemoveCameraToView(cam.channel)

                                        }}}
                                            className={camerasToStream.has(cam.channel) ? `tStyleSelected p-mb-2` : `tStyle p-mb-2`}
                                            style={{flex:1}}
                                    >

                                    <span className="p-button-label">
                                        {cam.camPosition}
                                    </span>
                                    </button>
                                }

                            </React.Fragment>
                        )
                    })}

                    {

                    }



                    {camerasToStream.size > 0 && playingFlag.filter(item => {return item}).length > 0 ?


                        <button className="tStyleSelected p-mb-2" aria-label="Play selected" onClick={handleStopAllStreams}
                                style={{background: 'rgba(255, 0, 36, 0.9)', flex:1 }}>
                            <span className="p-button-label">
                                <FontAwesomeIcon icon={faStop} style={{fontSize: '1.1em'}}/>
                                &nbsp;Stop
                            </span>
                        </button>

                    :
                        <button className="tStyleSelected p-mb-2"
                                aria-label="Play selected" onClick={handleStartAllStreams }
                                style={{background: '#5C8459', flex:1, opacity: camerasToStream.size > 0 ? 1 : 0.5, cursor: camerasToStream.size > 0 ? 'pointer' : 'default'}}>
                            <span className="p-button-label">
                                <FontAwesomeIcon icon={faPlay} style={{fontSize: '1.1em'}}/>
                                &nbsp;Start
                            </span>
                        </button>
                    }
                </div>
                {stopBeforeAddMore &&
                    <div style={{textAlign: 'center', marginTop: '5px'}}>
                        <Message severity="warn" text="stop stream before adding more cameras" />
                    </div>
                }
            </Fieldset>

            <div style={{display: 'flex', flexWrap: 'wrap', gap: '5px', rowGap: '5px', justifyContent: 'space-between', position: 'relative',
            overflowY: 'hidden'}}>
                {channels.map(function (channel, index) {
                    return (
                        <div style={getVideoStyling(channel)} key={index}>

                            <div style={{textAlign: 'center', position: 'relative'}}>

                                {timeoutFlag[channel ] && !stoppedFlag[channel ] &&
                                    <div style={{position: 'absolute', top: '50%', left: '50%', zIndex: 5000000, transform: 'translate(-50%, -50%)'}}>
                                        <Message severity="warn" text="Stream ended" />
                                    </div>
                                }

                                {deviceOffline &&
                                    <div style={{position: 'absolute', top: '50%', left: '50%', zIndex: 5000000, transform: 'translate(-50%, -50%)'}}>
                                        <Message severity="error" text="Stream not connected" />
                                    </div>
                                }


                                {(connectedFlag[channel]  && !loadingFlag[channel ]) &&
                                    <StreamingChannelHeader channel={channel}/>
                                }

                                {loadingFlag[channel ] && !stoppedFlag[channel ] && !deviceOffline &&
                                    // <ProgressSpinner style={{width: '50px', height: '50px', position: 'absolute', top: '50%', left: '50%',
                                    //     transform: 'translate(-50%, -50%)'}} />
                                    // <div>Buffering Stream...</div>

                                    <img src={mflLoading} style={{maxWidth: '150px'}} />
                                }
                                {connectedFlag[channel] && !stoppedFlag[channel ] && loadingFlag[channel ] &&

                                    <div> stream connected - buffering</div>
                                }


                                <div style={{width: '100%', position: 'relative'}}>
                                    <video
                                        loop autoplay controls="true" style={{width: '70%', height: 'auto',
                                        visibility: (connectedFlag[channel] && !loadingFlag[channel ]) ? 'visible' : 'hidden'}}
                                        className="stream"
                                        // controls
                                        // ref={videoPlayerRef[channel]}
                                        ref={ref => videoPlayerRef.current[channel] = ref}
                                        // autoPlay={false}
                                        onMouseEnter={() => {handleVideoHover(channel , true)}}
                                        onMouseLeave={() => {handleVideoHover(channel , false)}}
                                    />




                                </div>

                                {!loadingFlag[channel ] && playingFlag[channel ] && !stoppedFlag[channel ] && videoHovered[channel ] &&
                                    <button onClick={() => videoPlayerRef.current[channel].requestFullscreen()} className="p-button p-button-success"
                                            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                                            onMouseEnter={() => {handleVideoHover(channel , true)}}
                                            onMouseLeave={() => {handleVideoHover(channel , false)}}>
                                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                        &nbsp;Fullscreen
                                    </button>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default StreamHLS;


// f1000894_1_0
