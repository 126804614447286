import React, {useEffect, useState} from 'react';
import {Tooltip} from "primereact/tooltip";
import TailgatingStacked from "./stackedClassificationIcons/tailgating";
import PedestrianStacked from "./stackedClassificationIcons/pedestrian";
import CollisionStacked from "./stackedClassificationIcons/collision";
import LaneDepartureStacked from "./stackedClassificationIcons/laneDeparture";
import AccelerationStacked from "./stackedClassificationIcons/acceleration";

const PinAdas = ({height, _id, event}) => {

    return (
        <React.Fragment>
            {event?.eventType === 'Tailgating' &&
                <TailgatingStacked event={event} height={height} />
            }

            {event?.eventType === 'Pedestrian warning' &&
                <PedestrianStacked event={event} height={height} />
            }

            {event?.eventType === 'Collision warning' &&
                <CollisionStacked event={event} height={height} />
            }

            {event?.eventType === 'Lane departure' &&
                <LaneDepartureStacked event={event} height={height} />
            }

            {event?.eventType === 'Harsh acceleration' &&
                <AccelerationStacked event={event} height={height} />
            }

        </React.Fragment>

    )
}

export default PinAdas
