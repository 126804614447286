import React, {useContext, useEffect, useState} from 'react';
import fetchHealth from "../../../../api/device/fetchHealth";
import HealthSummaryTable from "./deviceDetailsModalComponents/healthSummaryTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWavePulse, faRouter, faTags, faCog, faCalendar, faUserHeadset} from '@fortawesome/pro-regular-svg-icons'
import {TabPanel, TabView} from "primereact/tabview";
import CalendarDetails from "./deviceDetailsModalComponents/calendarDetails";
import mainContext from "../../../contexts/mainContext";
import DeviceSettingsTable from "./deviceDetailsModalComponents/deviceSettingsTable";
import VehicleInfoTable from "./deviceDetailsModalComponents/vehicleInfoTable";
import VehicleTags from "./deviceDetailsModalComponents/vehicleTags";
import RequestSupport from "./deviceDetailsModalComponents/requestSupport";

const DeviceDetailsModal = ({}) => {

    const {vehicle, deviceDetails} = useContext(mainContext);
    const [healthData, setHealthData] = useState(null);


    const theLimit = 10;

    const leftCol = {
        textAlign: 'left',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '50%'
    }


    const centerCol = {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
    }

    const rightCol = {
        textAlign: 'right',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '50%'
    }

    const sectionHeader = {
        display: 'flex',
        background: '#000000d4',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        height: '50px',
        alignItems: 'center',
        paddingLeft: '10px'
    }


    
    
    
    const PanelTitle = (options) => {
        switch (options.titleElement.props.children){
            case 'tags' :
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faTags} />
                        &nbsp;Tags
                    </button>
                )
            case 'status':
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faWavePulse} />
                        &nbsp;Diagnostics
                    </button>
                )
            case 'vehicle':
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faRouter} />
                        &nbsp;Vehicle
                    </button>
                )
            case 'settings':
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faCog} />
                        &nbsp;Config
                    </button>
                )
            case 'calendar':
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faCalendar} />
                        &nbsp;Recordings
                    </button>
                )
            case 'support':
                return (
                    <button type="button" onClick={options.onClick} className={options.className}>
                        <FontAwesomeIcon icon={faUserHeadset} />
                        &nbsp;Support
                    </button>
                )
        }

    }

    useEffect(() => {

        if (deviceDetails){
            fetchHealth({
                dn: deviceDetails?.dn,
                limit: theLimit
            }).then(r => {
                setHealthData(r);
            })
        }


    }, []);


    return (
                <div>
                    <TabView scrollable>
                        <TabPanel header="status" headerTemplate={PanelTitle}>
                            <HealthSummaryTable healthData={healthData} theLimit={theLimit} deviceDetails={deviceDetails} leftCol={leftCol}
                                                centerCol={centerCol} rightCol={rightCol}/>
                        </TabPanel>
                        <TabPanel header="tags"  headerTemplate={PanelTitle}>
                            <VehicleTags />
                        </TabPanel>
                        <TabPanel header="settings" headerTemplate={PanelTitle}>
                            <DeviceSettingsTable leftCol={leftCol} rightCol={rightCol} />
                        </TabPanel>
                        <TabPanel header="vehicle" headerTemplate={PanelTitle}>
                            <VehicleInfoTable leftCol={leftCol} rightCol={rightCol} />
                        </TabPanel>
                        <TabPanel header="calendar" headerTemplate={PanelTitle}>
                            <CalendarDetails  />
                        </TabPanel>
                        <TabPanel header="support" headerTemplate={PanelTitle}>
                            <RequestSupport />
                        </TabPanel>
                    </TabView>

        </div>
    )
}

export default DeviceDetailsModal
