import React, {useContext} from 'react';
import ActivityReportTableHeader from "./activityReportTableHeader";
import ActivityReportTableRow from "./ActivityReportTableRow";
import ActivityReportTableFooter from "./activityReportTableFooter";
import {DurationFromSeconds} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";

const ActivityReportTable = ({data}) => {

    const {darkMode} = useContext(indexContext)

    const parkedRow = {
        textAlign: 'center',
        lineHeight: '20px',
        borderBottom: '1px solid var(--surface-border)',
    }

    return (
        <React.Fragment>
            <ActivityReportTableHeader date={data.date} data={data}/>


            {data?.journeys?.length >= 1 &&
                <React.Fragment key={data?.journeys?.[0]?._id}>
                    {data?.journeys?.map((item, index) =>
                        <React.Fragment>

                            {item?.stopped &&
                                <React.Fragment>
                                    {item?.stopped > 60 ?
                                        <div style={parkedRow}>
                                            Parked for&nbsp;{DurationFromSeconds(item?.stopped)}
                                        </div>
                                        :
                                        <div style={parkedRow}>Parked for 0m</div>
                                    }
                                </React.Fragment>

                            }


                            <ActivityReportTableRow data={item} index={index} />
                        </React.Fragment>

                    )}
                    <ActivityReportTableFooter data={data} />

                </React.Fragment>
            }



        </React.Fragment>

    )
}
export default ActivityReportTable
