import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const WebfleetStacked = ({event, height, showTool = true}) => {
    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={event?.severity === 'severe' ? 'red' : 'orange'}/>
                <path d="m156.51,206.95c-12.82-7.66-30.35-19.91-37.51-35.04-6.97-15.13-5.28-32.93-2.83-51.5.94-6.13.19-12.45-2.64-18.38-7.35-15.89-26.01-22.59-41.66-15.13-15.65,7.47-22.24,26.42-14.89,42.31,2.83,5.94,7.16,10.53,12.25,13.79,15.65,9.76,30.54,19.72,37.51,34.85,6.97,15.13,5.09,36.76,2.83,51.7-.94,6.13-.19,12.45,2.64,18.57,7.35,15.89,26.01,22.59,41.66,15.13s22.24-26.42,14.89-42.31c-2.64-6.13-6.97-10.91-12.25-13.98Zm83.7,0c-12.82-7.66-30.35-19.91-37.51-35.04-6.97-15.13-5.28-32.93-2.83-51.5.94-6.13.19-12.45-2.64-18.38-7.35-15.89-26.01-22.59-41.66-15.13-15.65,7.47-22.24,26.42-14.89,42.31,2.83,5.94,7.16,10.53,12.25,13.79,15.65,9.76,30.54,19.72,37.51,34.85,6.97,15.13,5.09,36.76,2.83,51.7-.94,6.13-.19,12.45,2.64,18.57,7.35,15.89,26.01,22.59,41.66,15.13,15.65-7.47,22.24-26.42,14.89-42.31-2.64-6.13-6.97-10.91-12.25-13.98Zm49.39-110.67c-10.37-13.98-29.97-16.66-43.73-6.13-10.18,7.85-14.33,20.87-11.5,32.74q8.11,34.27,10.37,43.65c1.13,4.6,5.66,7.66,10.18,6.51,1.89-.38,3.58-1.53,4.9-2.87q6.22-7.47,28.65-34.27c9.43-10.91,10.18-27.57,1.13-39.63Z" style={{fill: 'white'}}/>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - {uCaseFirst(event?.eventType)}</Tooltip>
            }
        </div>
    )
}
export default WebfleetStacked
