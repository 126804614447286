import React, {useContext, useEffect, useRef, useState} from 'react';
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";
import fetchStaticMapImage from "../../../../../api/media/fetchStaticMapImage";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {Tooltip} from "primereact/tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faTrash, faTrashSlash} from "@fortawesome/pro-light-svg-icons";
import EventGridItemSkeleton from "../events/eventGridItemSkeleton";
import DeviceMissingStacked
    from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/deviceMissing";
import Star from "../cardComponents/star";
import fetchSnapshot from "../../../../../api/media/fetchSnapshot";
import HealthStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/pin-health";
import noImg from "../../../../../svgs/noImageThumb.png";
import {Dropdown} from "primereact/dropdown";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import cameraUnavailable from '../../../../../svgs/cameraUnavailable.png'
import hardDiskHealth from '../../../../../svgs/storageUnavailable.png'
import gridContext from "../../../../contexts/gridContext";
import SelectedTick from "../../../../../svgs/eventClassificationIcons/selectedTick";
import StackedSeverityClassificationIcon from "../cardComponents/stackedSeverity&ClassificationIcon";
import HardDiskStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/hardDisk";


const NotificationGridHealth = ({notification, handleClick}) => {

    const {fleets} = useContext(indexContext)
    const {fleetsToShow, handleFleetsToShow, handleVehicle, vehicles, handleEvent} = useContext(mainContext);

    const {readNotis, addRemoveSelected, handleNotificationStarred, handleNotificationRead, starred, selected} = useContext(gridContext);


    const [img, setImg] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [options, setOptions] = useState();
    const [hover, setHover] = useState(false)

    let timer = useRef();



    const handleHover = () => {
        setHover(true)
    }

    const handleHoverOut = () => {
        setHover(false)
    }




    // look if missing or cam health
    useEffect(async () => {

        if (notification?.notificationType.includes('missing')){
            if (notification?.notificationDetails?.lastLocation){
                fetchStaticMapImage(notification?.notificationDetails?.lastLocation?.lat, notification?.notificationDetails?.lastLocation?.lng).then(r => {
                    setImg(URL.createObjectURL(r));
                });
            } else {
                setImg(noImg);
            }
        } else if (notification?.notificationType.includes('cameraHealth') && !notification?.notificationType.includes('unavailableCamera')){
            const optionsArr = [];
            if (notification?.notificationDetails?.events?.length > 0){
                notification?.notificationDetails?.events?.forEach((item, index) => {
                    optionsArr.push({
                        i: index,
                        value: item
                    });
                });
            }

            setOptions(optionsArr);

            const r = await fetchSnapshot(notification.notificationDetails?.snapshot, 'sm');
            setImg(URL.createObjectURL(r));
        } else if (notification?.notificationType.includes('hardDiskHealth')){
            setImg(hardDiskHealth);
        } else {
            setImg(cameraUnavailable)

        }
        await new Promise(r => setTimeout(r, 500));
        setIsLoaded(true);
        return () => {
            setImg(null)
        }
    }, [])


    return (
        <React.Fragment>
            {isLoaded ?
                <div onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
                    <div onClick={() => addRemoveSelected(notification._id)} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '5px', padding: '0px 10px 0px 10px'}}>
                        <div style={{flex:1, fontSze: '15px', fontWeight: 'bold'}}>
                            {notification?.notificationDetails?.title}

                            {fetchUserLevel() === 'superuser' && notification.notificationType.includes('cameraHealth') &&
                                !notification.notificationType.includes('unavailableCamera') &&

                                <span style={{marginLeft: '5px'}}>({notification?.notificationDetails?.events?.length}/10)</span>

                            }

                            <br />
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                                 }}>
                                {regFromDn(vehicles, notification?.dn)}
                            </div>
                        </div>


                        {hover &&
                            <div style={{
                                paddingLeft: '4px',
                                lineHeight: '29px',
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                background: 'var(--surface-border)'
                            }}>
                                {readNotis ?
                                    <Tooltip target={".markReadButton" + notification._id}
                                             id={'delete' + notification._id}>Undelete</Tooltip>
                                    :
                                    <Tooltip target={".markReadButton" + notification._id}
                                             id={'delete' + notification._id}>Delete</Tooltip>
                                }
                                <span className={"markReadButton" + notification._id} data-pr-position="bottom"
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          handleNotificationRead(e, notification, !readNotis ? 'read' : 'unread')
                                      }}>

                                     {!readNotis ?
                                         <FontAwesomeIcon icon={faTrash} style={{
                                             cursor: 'pointer',
                                             fontSize: "18px",
                                             marginRight: '5px'
                                         }}/>
                                         :
                                         <FontAwesomeIcon icon={faTrashSlash} style={{
                                             cursor: 'pointer',
                                             fontSize: "18px    ",
                                             marginRight: '5px'
                                         }}/>
                                     }

                                </span>
                            </div>
                        }


                        {/*<div onClick={(e) => {*/}
                        {/*    e.stopPropagation();*/}
                        {/*    handleNotificationStarred(notification, notification?.starredBy?.includes(notification._id) ? "unstar" : "star")*/}
                        {/*}}>*/}
                        {/*    <Star data={notification} starred={starred}/>*/}
                        {/*</div>*/}


                        {selected?.has(notification?._id) ?
                            <SelectedTick height={'25px'}/>
                            :
                            <React.Fragment>
                                {notification?.notificationType.includes('missing') &&
                                    <DeviceMissingStacked event={notification} height="25px"/>
                                }
                                {notification?.notificationType.includes('cameraHealth') &&
                                    <HealthStacked event={notification} height="25px"/>
                                }
                                {notification?.notificationType.includes('hardDiskHealth') &&
                                    <HardDiskStacked event={notification} height="25px"/>
                                }
                            </React.Fragment>
                        }


                    </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        <img src={img} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9'}}
                             onClick={() => handleClick(notification)}/>
                    </div>

                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '10px 10px 0px 10px'}}>
                        {formatDateNotificationsTable(notification.notificationCreatedTimestamp)}

                    </div>


                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '0px 10px 0px 10px'}}
                         onClick={(e) => {
                             e.stopPropagation();
                             handleFleetsToShow(new Set([...fleetsToShow, notification?.fleetId]));
                         }}>
                        {friendlyFleetName(notification?.fleetId, fleets)}
                    </div>

                    {notification?.notificationType?.includes('cameraHealth') && fetchUserLevel() === 'superuser' &&
                        !notification?.notificationType?.includes('unavailableCamera') &&
                        <div style={{ textAlign: 'left', position:'relative',
                            zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '0px 10px 10px 10px'}}>

                            <Dropdown onChange={(e) => handleEvent({_id: e.value, dn: notification.dn})}
                                      options={options} editable placeholder="Select Event"  optionLabel="i"/>

                        </div>
                    }


                </div>
            :
                <EventGridItemSkeleton />
            }

        </React.Fragment>
    )
}
export default NotificationGridHealth
