export const Colours = {
    loginContainer: 'white',
    loginButtonBackground: 'var(--main-accent-colour)',
    loginButtonText: '#ffffff',
    footerBackground: '#ffffff',
    footerText: '#000000',
    offlineRed: 'rgba(255, 0, 36, .9)',
    onlineGreen: '#5C8459',
    standbyYellow: 'rgb(255, 133, 0)',
    logoSpriteColour: 'var(--main-accent-colour)',
    logoTextColour: '#000000',
    journeyLineColour: 'var(--main-accent-colour)',
    journeySelectedCaterpillar: 'rgba(115,24,236,0.55)',
    incompleteJourneyCaterpillar: 'rgba(92,132,89,0.95)',
    mapControlUnclickedTextColour: 'black',
    mapControlClickedTextColour: 'var(--main-accent-colour)',
    mapControlClicked: 'rgba(0,0,0,.25)',
    mapControlUnclicked: 'rgba(0,0,0,.05)'



}
