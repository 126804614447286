import React, {useContext, useRef, useState} from 'react';
import {ConfirmPopup} from "primereact/confirmpopup";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLocationPlus, faTrash} from "@fortawesome/pro-regular-svg-icons";
import mainContext from "../../../../contexts/mainContext";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import postUpdateVehicle from "../../../../../api/device/postUpdateVehicle";
import {TOAST_DURATION} from "../../../../../Constants";

const EditMpgButton = ({}) => {

    const {deviceDetails, handleDeviceDetails} = useContext(mainContext);

    const [visible, setVisible] = useState(false);
    const [mpg, setMpg] = useState(deviceDetails?.vehicleDvlaData?.TechnicalDetails?.Consumption?.Combined?.Mpg);
    const toast = useRef(null);
    const id = useRef(Math.random())



    const popupBody = () => {
        return (
            <div>
                <div style={{marginBottom: '5px',fontWeight: 'bold', fontSize: '14px'}}>Edit Estimated average MPG</div>
                <InputNumber value={mpg} onChange={(e) => setMpg(e.value)} placeholder="Mpg"/>
                {/*<br />*/}
                {/*Category:*/}
            </div>
        )
    }

    const saveMpgChanges = async () => {
        if (deviceDetails){

            const {appVersion, deviceDob, deviceStatus, healthChecked, id, lastConnetion, lastDisconnected, lastHeartbeat,
                lastLocation, latestSnapshots, notes, overrun, overrunRecording,  timezone, _id, ...newVehicle} = deviceDetails


            newVehicle.vehicleDvlaData.TechnicalDetails.Consumption.Combined.Mpg = mpg;

            let factor = 2.31;

            if (newVehicle.vehicleDvlaData.VehicleRegistration.FuelType === 'DIESEL'){
                factor = 2.68;
            }


            let co2 = 0;

            if (mpg > 0) co2 = ((1/ (mpg*0.352))*factor)*1000;

            newVehicle.vehicleDvlaData.VehicleRegistration.Co2Emissions = co2.toFixed(2);

            const res = await postUpdateVehicle(newVehicle);

            if (res?.modifiedCount === 1 && res?.matchedCount === 1){
                toast?.current?.show({ severity: 'success', summary: 'All done', detail: 'Estimated average MPG updated',
                    life: TOAST_DURATION });
                await new Promise(r => setTimeout(r, 2000));
                await handleDeviceDetails(newVehicle);
            } else if (res?.modifiedCount === 0 && res?.matchedCount === 1) {
                toast?.current?.show({ severity: 'warn', summary: 'Not saved', detail: 'Nothing to update', life: TOAST_DURATION });
            } else{
                toast?.current?.show({ severity: 'error', summary: 'Not saved', detail: 'Something has gone wrong!', life: TOAST_DURATION });
            }
        } else {
            toast?.current?.show({ severity: 'error', summary: 'Not saved', detail: 'Device details missing', life: TOAST_DURATION });
        }
    }



    return (
        <React.Fragment>

            {mpg} miles per gallon

            <Button style={{padding: '2px 2px', margin: '3px', background: 'var(--surface-300)', fontSize: '11px', color: 'var(--text-color)'}}
                    label={<FontAwesomeIcon icon={faEdit} />} className="p-mb-2" onClick={(e) => {
                setVisible(true)
            }} />



            <ConfirmPopup target={document.getElementById(id?.current)} visible={visible}
                          onHide={() => setVisible(false)}
                          icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}
                          message={popupBody}
                          accept={saveMpgChanges}
                          reject={() => {
                              setMpg(deviceDetails?.vehicleDvlaData?.TechnicalDetails?.Consumption?.Combined?.Mpg)}
                          }
                          rejectClassName="p-button-danger"
                          acceptClassName="p-button-success"
            />

            <Toast ref={toast} appendTo={null} position="bottom-right"/>
        </React.Fragment>
    )
}

export default EditMpgButton
