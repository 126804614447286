import React, {useEffect, useState} from "react";
import {Tooltip} from "primereact/tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCalendar, faCamera, faCircleInfo, faClock, faUser} from "@fortawesome/pro-light-svg-icons";
import {
    DurationFromSeconds,
    formatDateOnly,
    formatTimeOnly, fullDurationFromSeconds,
    getTimeDifference
} from "../../../../../functions/formatting/formatDate";


const MediaUploadInfoIcon = ({notification, height = '32px'}) => {

    const [mediaData, setMediaData] = useState();
    
    useEffect(() => {
        if (notification){
            let data
            if (notification?.notificationDetails?.videoRequest?.mediaRecord){
                data = notification.notificationDetails.videoRequest.mediaRecord;
                data.camPosition = notification?.notificationDetails?.camPosition;
            } else if (notification?.notificationDetails?.hyperlapseRequest?.hyperlapse) {
                data = notification.notificationDetails.hyperlapseRequest.hyperlapse;
                data.camPosition = notification?.notificationDetails?.camPosition
            } else {
                data = notification
            }

            setMediaData(data);
        }

    }, [notification])





    return (
        <div>
            {notification &&
                <Tooltip target={".tooltipTargetEventSeverity" + notification?._id}>
                    {mediaData?.st ?
                        <div>
                            Request Date:
                            &nbsp;{formatDateOnly(mediaData?.requestDate)} <br />
                            Request Time:
                            &nbsp;{formatTimeOnly(mediaData?.requestDate)} <br />
                            Media Date:
                            &nbsp;{formatDateOnly(mediaData?.st)} <br />
                            Media Time:
                            &nbsp;{formatTimeOnly(mediaData?.st)}&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />
                            &nbsp;{formatTimeOnly(mediaData?.et)}<br />
                            Duration:&nbsp;{fullDurationFromSeconds((new Date(mediaData?.et )- new Date(mediaData?.st))/1000)}

                            {mediaData?.camPosition &&
                                <span>
                                    <br />
                                    Camera:
                                            &nbsp;{mediaData?.camPosition}
                                </span>
                            }
                            {mediaData?.email &&
                                <span>
                                    <br />
                                    Requested by:
                                    &nbsp;{mediaData?.email.substring(0,17)}
                                    {/*TODO REPLACE WITH CSS*/}
                                    {mediaData?.email?.length > 17 &&
                                        <span>...</span>
                                    }
                                </span>
                            }
                            <br/>Attempt count:&nbsp;{(mediaData?.attemptCount + 1)}

                        </div>
                        :
                        <div>
                            Request Date:
                            &nbsp;{formatDateOnly(mediaData?.requestDate)} <br />
                            Request Time:
                            &nbsp;{formatTimeOnly(mediaData?.requestDate)} <br />
                            Hyperlapse Date:
                            &nbsp;{formatDateOnly(mediaData?.startTimeHyper)} <br />
                            Hyperlapse Time:
                            &nbsp;{formatTimeOnly(mediaData?.startTimeHyper)}&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />&nbsp;
                            {formatTimeOnly(new Date(mediaData?.startTimeHyper).setMinutes(new Date(mediaData?.startTimeHyper).getMinutes() + mediaData?.durationMinutes))}

                            {mediaData?.camPosition &&
                                <span>
                                    <br />
                                    Camera:
                                    &nbsp;{mediaData?.camPosition}
                                </span>
                            }

                            {mediaData?.email &&
                                <span>
                                    <br />
                                    Requested by:
                                    &nbsp;{mediaData?.email.substring(0,17)}


                                    {/*TODO REPLACE WITH CSS*/}
                                    {mediaData?.email?.length > 17 &&
                                        <span>...</span>
                                    }
                                </span>

                            }
                        </div>
                    }
                </Tooltip>
            }
            <FontAwesomeIcon icon={faCircleInfo} style={{height}} className={"tooltipTargetEventSeverity" + notification?._id} data-pr-position="bottom"/>
        </div>
    )
}


export default MediaUploadInfoIcon
