import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartColumn} from "@fortawesome/pro-light-svg-icons";
import {DurationFromSeconds, timeFromSeconds} from "../../../../../functions/formatting/formatDate";

const TimesheetReportSummary = ({timesheetData}) => {

    const [totDistance, setTotDistance] = useState(0)
    const [totDuration, setTotDuration] = useState(0)
    const [totJourneys, setTotJourneys] = useState(0)
    const [totShift, setTotShift] = useState(0)
    const [totStartTime, setTotStartTime] = useState(0)
    const [totEndTime, setTotEndTime] = useState(0)

    useEffect(() => {
        if(timesheetData && timesheetData?.length > 0){

            let totalDistance = 0;
            let totalDur = 0;
            let totalJs = 0;
            let totalShift = 0;


            timesheetData.forEach(item => {
                totalDistance += item?.totalDistance;
                totalDur += item?.totalDur;
                totalJs += item.journeys.length;
                totalShift += item?.shiftDur;
            });

            setTotDistance(totalDistance)
            setTotDuration(totalDur);
            setTotJourneys(totalJs);
            setTotShift(totalShift);



        }
    }, [timesheetData])




    return (

        <div  style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>
            <div className="reportTotalBubble">
                <div><b>Total Working <br />Time:</b></div>
                <div>{DurationFromSeconds(totShift)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total Driving <br />Time:</b></div>
                <div>{DurationFromSeconds(totDuration)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total<br />Journeys:</b></div>
                <div>{totJourneys}</div>
            </div>
            <div  className="reportTotalBubble">
                <div><b>Total Distance <br />Driven:</b></div>
                <div>{totDistance.toFixed(0)} miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Daily <br />Journeys:</b></div>
                <div>{(totJourneys / timesheetData?.length).toFixed(0)}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Daily <br />Distance:</b></div>
                <div>{(totDistance / timesheetData?.length).toFixed(0)} miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Average Daily Driving Time:</b></div>
                <div>{DurationFromSeconds(totDuration / timesheetData?.length)}</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Average Daily Working Time:</b></div>
                <div>{DurationFromSeconds(totShift / timesheetData?.length)}</div>
            </div>
        </div>
    )
}

export default TimesheetReportSummary
