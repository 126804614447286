import postNotificationRead from "../../../../../api/notifications/postNotificationRead";
import postMarkEventRead from "../../../../../api/events/postMarkEventRead";
import {TOAST_DURATION} from "../../../../../Constants";

export const handleNotificationReadV2 = async (notification, type = 'read', handleNotiCount, toast, closeModal) => {
    const ele = document.getElementById(notification._id);
    let message;

    if (notification?.notificationDetails){
        await postNotificationRead({
            notification,
            user: localStorage.getItem('email'),
        });
        if (type === 'read'){
            handleNotiCount(-1);
            message = 'Alert deleted';
        } else {
            handleNotiCount(1);
            message = 'Alert deleted';
        }
        ele.style.display = 'none';

    } else {
        await postMarkEventRead({
            id: notification?._id,
            dn: notification.dn,
            unread: type === 'unread'
        });
        ele.style.display = 'none';
        message = 'Event ' + (type === 'unread' ? 'undeleted' : 'deleted');
    }
    toast.current.show({ severity: 'success', summary: 'All done', detail: message, life: TOAST_DURATION });
    closeModal('displayEventClickModal');
   return;

}
