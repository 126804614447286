import React from 'react';
import {formatDateMediaGrid} from "../../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/pro-light-svg-icons";
import {TabPanel, TabView} from "primereact/tabview";
import {faFilmSimple, faForward} from "@fortawesome/pro-regular-svg-icons";
import RequestHyperlapseFromRecording from "../../../requestingMedia/requestHyperlapseFromRecording";
import RequestVideoFromRecording from "../../../requestingMedia/requestVideoFromRecording";

const RequestMediaFromCalendar = ({recSelected, deviceDetails, vehicle}) => {


    const ReqMediaHeader = (type) => {
        if (type === 'hyperlapse'){
            return (
                <div>
                    <FontAwesomeIcon icon={faForward} />
                    &nbsp;Upload Hyperlapse
                </div>
            )
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={faFilmSimple} />
                    &nbsp;Upload Video
                </div>
            )
        }
    }

    return (
        <div>
            {/*<table style={{width: '100%', textAlign: 'center'}}>*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th>Start Time:</th>*/}
            {/*        <th />*/}
            {/*        <th>End Time:</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td>{formatDateMediaGrid(recSelected?.st)}</td>*/}
            {/*        <td>*/}
            {/*            <FontAwesomeIcon icon={faArrowRightLong} />*/}
            {/*        </td>*/}
            {/*        <td>{formatDateMediaGrid(recSelected?.et)}</td>*/}
            {/*    </tr>*/}

            {/*    </tbody>*/}
            {/*</table>*/}


            <TabView>
                <TabPanel header={ReqMediaHeader('video')}>
                    <RequestVideoFromRecording recSelected={recSelected} deviceDetails={deviceDetails} vehicle={vehicle}/>
                </TabPanel>
                <TabPanel header={ReqMediaHeader('hyperlapse')}>
                    <RequestHyperlapseFromRecording recSelected={recSelected} deviceDetails={deviceDetails} vehicle={vehicle}/>
                </TabPanel>
            </TabView>
        </div>

)
}
export default RequestMediaFromCalendar
