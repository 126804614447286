import React, {useEffect, useState} from 'react';
import {Message} from "primereact/message";

const VideoBudgetMessage = ({camsToReq, budget, duration}) => {

    const [message, setMessage]= useState('');

    const [severity, setSeverity] = useState('');

    useEffect(() => {
        if(budget && duration && camsToReq){

            if((budget?.totalFleetBudget - budget?.usedSeconds) < (duration)*camsToReq.size){
                setSeverity('error');
                setMessage("Insufficient budget to make the request(s)");
            } else {
                setSeverity('info');

                const total = (budget?.totalFleetBudget - budget?.usedSeconds);
                const used = (duration * camsToReq.size);
                const percentage = ((used/  total) * 100).toFixed(2)

                setMessage("This request will use " + percentage + "% of your available budget")
            }
        }
    },[budget, duration, camsToReq])

    return (
        <div>
            {camsToReq.size > 0 && duration > 0 &&
                <Message severity={severity} text={message} className="budgetMessage"/>
            }
        </div>
    )
}
export default VideoBudgetMessage
