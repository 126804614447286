import React, {useContext} from 'react';
import {DurationFromSeconds} from "../../../../../../functions/formatting/formatDate";
import mainContext from "../../../../../contexts/mainContext";
import {gToKg} from "../../../../../../functions/formatting/gToKg";

const CO2ReportTableFooter = ({data}) => {


    const {deviceDetails} = useContext(mainContext);


    return (
        <div style={{
            display: 'flex', justifyContent: 'center',
            height: '73px', pageBreakInside: 'avoid', pageBreakAfter: 'auto', textAlign: 'center'}} className="reportTable">

            <div style={{lineHeight: '30px'}}>
                <b>Day Totals:</b>&nbsp;
                {data.totalDistance.toFixed(0)}
                &nbsp;miles travelled,
                &nbsp;
                {gToKg(((data?.totalDistance * 1.60934) * deviceDetails?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions).toFixed(0))}
                &nbsp; of CO2

            </div>


        </div>
    )
}
export default CO2ReportTableFooter
