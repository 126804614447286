import postNotificationStarred from "../../../../../api/notifications/postNotificationStarred";
import postMarkEventStarred from "../../../../../api/events/postEventStarred";
import {TOAST_DURATION} from "../../../../../Constants";

export const handleNotificationStaring = async (starred, notification, toast) => {
    let newStarred = [...starred];
    let action;
    let message;

    if(starred.includes(notification._id)){
        action = 'remove'
        newStarred.splice(starred.indexOf(notification._id), 1);
    } else {
        action = 'add';
        newStarred.push(notification._id)
    }
    if (notification?.notificationDetails){
        await postNotificationStarred({
            notification,
            user: localStorage.getItem('email'),
        });
        if (action === 'add'){
            message = 'Alert starred';
        } else {
            message = 'Alert unstarred';
        }
    } else {
        try {
            await postMarkEventStarred({
                id: notification?._id,
                dn: notification.dn,
                star: action
            });

            message = 'Event ' + (action === 'add' ? 'starred' : 'unstarred');
        } catch (e) {

        }
    }
    toast.current.show({ severity: 'success', summary: 'All done', detail: message,
        life: TOAST_DURATION });

    return newStarred
}
