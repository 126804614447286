import React, {useContext, useEffect, useState} from 'react';
import LoadingSpinner from "../../../loadingSpinner";
import ReportsParameterSelection from "../reportsParameterSelection";
import {
    formatDateOnly,
    formatDateTimesheetReport,
    fullDurationFromSeconds
} from "../../../../../functions/formatting/formatDate";
import postFleetIdling from "../../../../../api/postFleetIdling";
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import reportsContext from "../../../../contexts/reportsContext";
import postFleetSpeeding from "../../../../../api/postFleetSpeeding";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faExclamationCircle, faSignsPost} from "@fortawesome/pro-regular-svg-icons";
import {faRuler, faTimer, faPercent} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {Colours} from "../../../../../colours";
import VehicleIdlingReportSummary from "../idlingReport/vehicle/vehicleIdlingReportSummary";
import FleetIdlingReportSummary from "../idlingReport/fleet/fleetIdleReportSummary";
import {MflLogo} from "../../../../../svgs/mflLogo";
import SpeedingOverviewReportSummary from "./speedingOverviewReportSummary";

const SpeedingReportOverview = ({dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi}) => {

    const {vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext)
    const {theVehicle, theFleet} = useContext(reportsContext)

    const [activityData, setActivityData] = useState(null);
    const [actTotals, setActTotals] = useState(null);
    const [generateReport, setGenerateReport] = useState();


    const handleGenerateReport = (val) =>setGenerateReport(val);

    const generateReportData = async () => {

        const data = {
            fleetId: theFleet?.fleetId,
            startDate: formatDateOnly(dateRange?.[0]).split('-').reverse().join('-'),
            endDate: formatDateOnly(dateRange?.[1]).split('-').reverse().join('-')
        }

        const r = await postFleetSpeeding(data);

        let distance = 0;
        let journeys = 0;
        let duration = 0;
        let speedDur = 0;
        let speedRatio = 0;
        let speedEvents = 0;
        let reportData = {};


        r?.forEach(v => {
            distance += v?.distance;
            journeys += v?.journeyCount;
            speedDur += v?.sumSpeedingDuration;
            duration += v?.durationSeconds;
            speedRatio += v?.speedingRatio;
            speedEvents += v?.sumSpeedCount;
        });


        setActivityData(r);


        const total = {
            journeys,
            distance,
            speedDur,
            duration,
            speedRatio,
            speedEvents
        }

        const cache = {
            actData: reportData,
            totals: total,
            dateRange: JSON.stringify(dateRange),
            fleet: theFleet
        }

        setActTotals(total);

        localStorage.setItem('speedingReportOverview', JSON.stringify(cache));
        handleReportLoading(false);

    }



    useEffect(async () => {

        if (localStorage.getItem('speedingReportOverview')){
            handleReportLoading(false);
            const cache = JSON.parse(localStorage.getItem('speedingReportOverview'));

            if (cache.dateRange === JSON.stringify(dateRange) &&
                ((theVehicle?.properties?.dn && cache.dn === theVehicle?.properties?.dn) || (theFleet && cache.fleet === theFleet))){


                if (theVehicle && cache.dn === theVehicle?.properties?.dn){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else if (!theVehicle && theFleet && cache.fleet === theFleet){
                    setActTotals(cache.totals);
                    setActivityData(cache.actData);
                } else {
                    if (generateReport) {
                        handleReportLoading(true);
                        generateReportData();
                    }
                }
            } else {
                if (generateReport) {
                    handleReportLoading(true);
                    generateReportData();
                }
            }
        } else {
            if(dateRange && (theVehicle || theFleet) && generateReport){
                handleReportLoading(true);
                generateReportData();
            }
        }




    }, [dateRange, theVehicle, theFleet, generateReport]);


    return (
        <React.Fragment>
            {generateReport ?
                <React.Fragment>
                    {reportLoading ?
                        // <ProgressSpinner />
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                                    'translate(-50%, -50%)'}}>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                                {activityData?.length > 0 ?
                                    <div style={{
                                        height: '100%',
                                        width: '100%',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingTop: '20px'
                                    }}
                                         className={reportToPrint === 'speedingOverview' ? "section-to-print" : ''}>
                                        <div className="hideNormalShowPrint">
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>
                                                    <h1>
                                                        Fleet Speeding Report
                                                    </h1>

                                                    {theFleet &&
                                                        <div style={{
                                                            fontSize: "1.3em",
                                                            lineHeight: "25px",
                                                            padding: "20px 0px 20px 0px"
                                                        }}>
                                                            <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                            <br/>

                                                            <b>Date range:</b>&nbsp;
                                                            {formatDateTimesheetReport(dateRange?.[0])}
                                                            &nbsp;->&nbsp;
                                                            {formatDateTimesheetReport(dateRange?.[1])}
                                                        </div>
                                                    }

                                                </div>
                                                <div>
                                                    <MflLogo style={{fill: Colours.logoTextColour}} size='180px'
                                                             textColor={'black'}/>
                                                    <div style={{
                                                        marginTop: '25px',
                                                        fontSize: "1.3em",
                                                        lineHeight: "25px",
                                                        padding: "20px 0px 20px 0px"
                                                    }}>
                                                        <b>Report generated by:</b>&nbsp;{localStorage.getItem('email')}
                                                        <br/>
                                                        <b>Report generated on:</b>&nbsp;{formatDateOnly(new Date())}
                                                    </div>
                                                </div>
                                            </div>

                                            {showKpi && theFleet && !theVehicle &&
                                                <SpeedingOverviewReportSummary actTotals={actTotals} />
                                            }


                                        </div>

                                        <div className="hideOnPrint">

                                            {showKpi && theFleet &&
                                                <SpeedingOverviewReportSummary actTotals={actTotals} />
                                            }

                                            {theFleet &&
                                                <div style={{
                                                    fontSize: "1.3em",
                                                    lineHeight: "25px",
                                                    padding: "20px 0px 20px 0px"
                                                }}>
                                                    <b>Fleet:</b>&nbsp;{friendlyFleetName(theFleet?.fleetId, fleets)}
                                                    <br/>

                                                    <b>Date range:</b>&nbsp;
                                                    {formatDateTimesheetReport(dateRange?.[0])}
                                                    &nbsp;->&nbsp;
                                                    {formatDateTimesheetReport(dateRange?.[1])}
                                                </div>
                                            }

                                        </div>


                                    {theFleet &&
                                        <div style={{overflowY: 'scroll', height: '61vh', overflowX: 'hidden'}}
                                             className="scroller">


                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: '15% 15% 15% 15% 10% 15% 15%',
                                                borderBottom: '1px solid var(--surface-border)',
                                                height: '30px',
                                                marginTop: '12px',
                                                pageBreakInside: 'auto',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }} className="reportTable">

                                                <div style={{lineHeight: '30px', paddingLeft: '10px'}}>

                                                    <FontAwesomeIcon icon={faCar}/>
                                                    &nbsp;Vehicle
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faSignsPost}/>
                                                    &nbsp;
                                                    Journeys Completed
                                                </div>

                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faRuler}/>
                                                    &nbsp;
                                                    Distance Travelled
                                                </div>

                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faTimer}/>
                                                    &nbsp;
                                                    Driving Duration
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faTimer}/>
                                                    &nbsp;
                                                    Speeding Duration
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faPercent}/>
                                                    &nbsp;
                                                    Speeding Percentage
                                                </div>
                                                <div style={{lineHeight: '30px'}}>
                                                    <FontAwesomeIcon icon={faExclamationCircle}/>
                                                    &nbsp;
                                                    Speeding Events
                                                </div>
                                            </div>


                                            {activityData && activityData?.map(v => (
                                                <div style={{
                                                    display: 'grid', gridTemplateColumns: '15% 15% 15% 15% 10% 15% 15%',
                                                    borderBottom: '1px solid var(--surface-border)', height: '73px',
                                                    pageBreakInside: 'avoid', pageBreakAfter: 'auto'    ,
                                                    textAlign: 'center'
                                                }} className="reportTable" key={v?._id}>
                                                    <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                                                        {regFromDn(vehicles, v?._id)}
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {v?.journeyCount}
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {v?.distance.toFixed(0)} miles
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {fullDurationFromSeconds(v?.durationSeconds)}
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {fullDurationFromSeconds(v?.sumSpeedingDuration)}
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {(v?.speedingRatio * 100).toFixed(2)}%
                                                    </div>
                                                    <div style={{lineHeight: '73px'}}>
                                                        {v?.sumSpeedCount}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    </div>
                                    :
                                    <div style={{fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                        No speeding data for the given fleet for the given date range
                                    </div>

                                }
                            </React.Fragment>

                    }
                </React.Fragment>
                :
                <div style={{padding: '40px 10px 10px 10px', width: '100%', height: '100%', position: 'relative'}}>
                    <div style={{
                        fontWeight: 'bold', fontSize: '18px', position: 'absolute', textAlign: 'center',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                    }}>
                        Please select a fleet and timespan

                        <br/>

                        <ReportsParameterSelection handleGenerateReport={handleGenerateReport}/>

                    </div>
                </div>

            }
        </React.Fragment>
    )
}

export default SpeedingReportOverview