import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp, faX} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "primereact/button";
import postVideoRequest from "../../../api/media/postVideoRequest";
import {Message} from "primereact/message";
import {formatDate} from '../../../functions/formatting/formatDate'
import {NOTIFICATIONS_CALLBACK_URL} from "../../../Constants";
import VideoBudgetMessage from "./videoBudgetMessage";
import fetchVideoBudget from "../../../api/device/fetchVideoBudget";
import fetchHyperlapseDetail from "../../../api/media/fetchHyperlapseDetail";
import mainContext from "../../contexts/mainContext";
import {Checkbox} from "primereact/checkbox";


const RequestVideoFromHyperlapse = ({videoRef, video}) => {

    const {deviceDetails} = useContext(mainContext);


    const vidLength = 20;


    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [requestedMessage, setRequestedMessage] = useState();
    const [budget, setBudget] = useState();
    const [showUpload, setShowUpload] = useState();
    const [emailChecked, setEmailChecked] = useState(false);



    const addRemoveCameraToReq= (v) => {
        if(camsToReq.has(v)){
            camsToReq.delete(v)
            setCamsToReq(new Set([...camsToReq]));
        } else {
            setCamsToReq(new Set([...camsToReq, v]));
        }
    }

    const calcPointInTimeHL = () => {

        fetchHyperlapseDetail(video._id).then(r => {
            const timeOfVid = videoRef.current.currentTime;
            const lengthOfVid = videoRef.current.duration;

            const fpsOfVid = Math.floor(r.frames / lengthOfVid);

            const timeAtPointInVid = r.timesUTC[Math.floor((timeOfVid * fpsOfVid))].time;


            camsToReq.forEach(cam => {
                const videoToRequest = {
                    st: timeAtPointInVid,
                    et: formatDate(new Date(timeAtPointInVid).getTime() + 20000),
                    dn: r.dn,
                    ch: cam,
                    callback: NOTIFICATIONS_CALLBACK_URL,
                };

                postVideoRequest(videoToRequest, deviceDetails, emailChecked).then(()=> {

                });


            });
            setCamsToReq(new Set([]))
            setRequestedMessage('Success');

            setTimeout(() => {
                setRequestedMessage(null);
            }, 3000);



        });
    }



    useEffect(() => {
        if (video){
            const filename = video?.file ? video.file : video.filename
            fetchVideoBudget({dn: filename.split("_")[0]}).then(r => {
                setBudget(r)
            });
        }
    }, [video])


    return (
        <div>
            {showUpload ?
                <div style={{textAlign: 'center', padding: '5px'}}>

                    <div style={{marginBottom: '10px'}} >
                        <button onClick={() => setShowUpload(false)} className="p-button p-button-danger">
                            <FontAwesomeIcon icon={faX} />
                            &nbsp;Cancel
                        </button>

                        <h4>Select camera view(s)w:</h4>

                    </div>

                    {deviceDetails?.cameras.map(function (cam, index){
                        return (
                            <div key={index} style={{display: 'inline-block', marginRight: '5px'}}>
                                <Button onClick={() => addRemoveCameraToReq(cam.channel)}
                                        className={camsToReq.has(cam.channel) ? 'p-button p-button-success' : 'p-button p-button-secondary'}
                                        tooltip={'Channel: ' + cam.channel} tooltipOptions={{position: 'bottom'}}>
                                    {cam.camPosition}
                                </Button>
                            </div>
                        )
                    })}


                    <br />


                    <div style={{marginTop: '5px'}}>
                        <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
                        <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
                    </div>

                    <div style={{marginTop: '5px'}}>
                        <VideoBudgetMessage budget={budget} camsToReq={camsToReq} duration={vidLength} />
                    </div>


                    {!requestedMessage &&
                        <button onClick={() => calcPointInTimeHL()} className="p-button" disabled={camsToReq.size < 1}>
                            <FontAwesomeIcon icon={faCloudArrowUp} />
                            &nbsp;Upload
                        </button>
                    }


                    {requestedMessage &&
                        <Message severity={requestedMessage === 'Success' ? 'success' : 'error'} text={requestedMessage}
                                 style={{width: '80%', justifyContent: 'center'}}/>
                    }


                </div>
            :
                <button onClick={() => setShowUpload(true)} className="p-button"
                        style={{marginTop: '5px', color: 'var(--v-list-button-text-selected)',
                            background: 'var(--v-list-button-selected)'}}>
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                    &nbsp;Upload from this point
                </button>
            }
        </div>
    )
}

export default RequestVideoFromHyperlapse
