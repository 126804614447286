import React, {useEffect, useState} from 'react';
import {Tooltip} from "primereact/tooltip";
import TailgatingStacked from "./stackedClassificationIcons/tailgating";
import PedestrianStacked from "./stackedClassificationIcons/pedestrian";
import CollisionStacked from "./stackedClassificationIcons/collision";
import DistractionStacked from "./stackedClassificationIcons/distraction";
import FatigueStacked from "./stackedClassificationIcons/fatigue";
import SmokingStacked from "./stackedClassificationIcons/smoking";
import PhoneStacked from "./stackedClassificationIcons/phoneStacked";
import CamerasCoveredStacked from "./stackedClassificationIcons/cameraCovered";
import EyesClosedStacked from "./stackedClassificationIcons/eyesClosed";

const PinDms = ({height, _id, event}) => {
    
    return (
        <React.Fragment>


            {(event?.eventType === 'Distraction' || event?.eventType === 'Inattentive' || event?.eventType === 'Inattentive') &&
                <DistractionStacked event={event} height={height} />
            }

            {event?.eventType === 'Fatigue' &&
                <FatigueStacked event={event} height={height} />
            }

            {event?.eventType === 'Drowsiness' &&
                <EyesClosedStacked event={event} height={height} />
            }

            {event?.eventType === 'Smoking' &&
                <SmokingStacked event={event} height={height} />
            }

            {event?.eventType === 'Mobile phone' &&
                <PhoneStacked event={event} height={height} />
            }

            {event?.eventType === 'Camera cover' &&
                <CamerasCoveredStacked event={event} height={height} />
            }







        </React.Fragment>

    )
}

export default PinDms
