export const friendlyFleetName = (vFleet, fleets) => {

    let result

    fleets?.forEach(fleet => {
        if(fleet.fleetId === vFleet ) {
            result = fleet?.fleetName ? fleet?.fleetName : fleet?.fleetId;
        }
    });

    return result
}

