import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import fetchSnapshot from "../../../../../api/media/fetchSnapshot";
import imageLoading from "../../../../../svgs/awaitingSnap.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCarMirrors, faTrash, faTrashSlash, faMapMarkerAlt, faStar} from "@fortawesome/pro-light-svg-icons";
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {Tooltip} from "primereact/tooltip";
import {faBuildings} from "@fortawesome/pro-regular-svg-icons";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {fleetFromDn} from "../../../../../functions/formatting/fleetFromDn";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import EventClassificationIcon from "../cardComponents/eventClassificationIcon";
import EventSeverityIcon from "../cardComponents/eventSeverityIcon";
import {faStar as faSolidStar} from "@fortawesome/pro-solid-svg-icons";
import EventGridItemSkeleton from "../events/eventGridItemSkeleton";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import {faCompassSlash} from "@fortawesome/pro-regular-svg-icons/faCompassSlash";
import EngineerStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/engineer";
import deviceDisabled from "../../../../../svgs/deviceDisabled.png";
import Star from "../cardComponents/star";
import DeviceMissingStacked
    from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/deviceMissing";
import gridContext from "../../../../contexts/gridContext";
import SelectedTick from "../../../../../svgs/eventClassificationIcons/selectedTick";


const NotificationGridModeChanged= ({notification, handleClick}) => {

    const {fleets} = useContext(indexContext);
    const {vehicles, handleVehicle, fleetsToShow, handleFleetsToShow} = useContext(mainContext);

    const {starred, readNotis, addRemoveSelected, handleNotificationStarred, handleNotificationRead, selected} = useContext(gridContext);

    const [isLoaded, setIsLoaded] = useState(false);
    const [testImage, setTestImage] = useState();
    const [isMounted, setIsMounted] = useState(true);

    const addDefaultSrc = (e) => {
        e.target.src = imageLoading
    }

    useEffect(() => {
        return () => {
            setTestImage(null);
            setIsMounted(false);
        }
    }, []);

    useEffect(() => {
        if (notification && isMounted){

            if (notification.notificationDetails?.commissioning?.snaps){
                const keys = Object.keys(notification.notificationDetails?.commissioning?.snaps);

                if (keys?.length > 0){

                    const fileRaw = notification.notificationDetails?.commissioning?.snaps?.[keys[0]]?.[0]?.file

                    if (fileRaw?.length > 0){
                        const index = fileRaw.lastIndexOf("_");
                        const file = fileRaw.substring(0, index);

                        fetchSnapshot(file, 'sm').then(r => {
                            setTestImage(URL.createObjectURL(r));
                        }).finally(() => {
                            setTimeout(() => {setIsLoaded(true)}, 500)
                        })
                    } else {
                        setTestImage(imageLoading)
                        setTimeout(() => {setIsLoaded(true)}, 500)
                    }
                } else {
                    setTestImage(imageLoading)
                    setTimeout(() => {setIsLoaded(true)}, 500)
                }
            } else {
                setTestImage(deviceDisabled)
                setTimeout(() => {setIsLoaded(true)}, 500)
            }
        }
    }, [notification])

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(testImage);
        }
    }, []);



    return (

        <React.Fragment>
            {isLoaded ?
                <div>

                    <div onClick={() => addRemoveSelected(notification._id)} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '5px', padding: '0px 10px 0px 10px'}}>
                        <div style={{flex:1, fontSize: '15px', fontWeight: 'bold'}}>
                            {/*{notification?.notificationDetails?.title}*/}
                            Device mode changed
                            <br />
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     handleVehicle(vehicles.features.filter(v => v.properties.dn === notification.dn)?.[0])
                                 }}>
                                {regFromDn(vehicles, notification?.dn)}
                            </div>
                        </div>

                        <div>
                            {readNotis ?
                                <Tooltip target={".markReadButton" + notification._id}>Undelete</Tooltip>
                                :
                                <Tooltip target={".markReadButton" + notification._id}>Delete</Tooltip>
                            }
                            <span className={"markReadButton" + notification._id} data-pr-position="bottom"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      handleNotificationRead(e, notification, !readNotis ? 'read' : 'unread')
                                  }}>

                                     {!readNotis ?
                                         <FontAwesomeIcon icon={faTrash} style={{
                                             cursor: 'pointer',
                                             fontSize: "18px",
                                             marginRight: '5px'
                                         }}/>
                                         :
                                         <FontAwesomeIcon icon={faTrashSlash} style={{
                                             cursor: 'pointer',
                                             fontSize: "18px    ",
                                             marginRight: '5px'
                                         }}/>
                                     }

                                </span>
                        </div>


                        <div onClick={(e) => {
                            e.stopPropagation();
                            handleNotificationStarred(notification, notification?.starredBy?.includes(notification._id) ? "unstar" : "star")
                        }}>
                            <Star data={notification} starred={starred}/>
                        </div>


                        {selected?.has(notification?._id) ?
                            <SelectedTick height={'25px'} />
                            :
                            <EngineerStacked event={notification?.notificationDetails?.commissioning} height="25px" />
                        }



                    </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        <img src={testImage} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9'}}
                             onClick={() => handleClick(notification?.dn)} onError={(e) => {addDefaultSrc(e)}}/>
                    </div>

                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '10px 10px 0px 10px'}}>
                        {formatDateNotificationsTable(notification.notificationCreatedTimestamp)}

                    </div>


                    <div style={{ textAlign: 'left', position:'relative',
                        zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '0px 10px 10px 10px'}}
                         onClick={(e) => {
                             e.stopPropagation();
                             handleFleetsToShow(new Set([...fleetsToShow, notification?.fleetId]));
                         }}>
                        {friendlyFleetName(notification?.fleetId, fleets)}
                    </div>


                </div>

                :
                <EventGridItemSkeleton />
            }

        </React.Fragment>
    )
}
export default NotificationGridModeChanged
