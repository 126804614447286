import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const TailgatingStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89"  style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path id="Tailgating" fill="var(--text-color)" d="m62.95,103.47c4.99,0,9,3.98,9,8.93v125.09c0,4.95-4.01,8.93-9,8.93s-9-3.98-9-8.93v-125.09c0-4.95,4.01-8.93,9-8.93Zm82.9,25.48c3.62,3.59,3.62,9.39,0,12.94l-24.23,24.06h108.35l-23.7-23.53c-3.54-3.51-3.54-9.19,0-12.66,3.54-3.47,9.25-3.51,12.75,0l39.12,38.84c3.54,3.51,3.54,9.19,0,12.66l-39.12,38.88c-3.54,3.51-9.25,3.51-12.75,0-3.5-3.51-3.54-9.19,0-12.66l23.7-23.53h-108.21l24.05,23.88c3.59,3.56,3.59,9.32,0,12.85s-9.39,3.56-12.94,0l-39.75-39.42c-3.59-3.56-3.59-9.32,0-12.85l39.71-39.46c3.59-3.56,9.39-3.56,12.94,0h.07Zm152.1-16.54v125.09c0,4.95-4.01,8.93-9,8.93s-9-3.98-9-8.93v-125.09c0-4.95,4.01-8.93,9-8.93s9,3.98,9,8.93Z"/>
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Tailgating</Tooltip>
            }
        </div>
    )
}
export default TailgatingStacked
