import React, {useContext, useEffect, useRef, useState} from 'react';
import fetchReverseGeocode from "../../../../api/fetchReverseGeocode";
import useToken from "../../../../login/useToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGaugeMax, faMapMarkerAlt, faForward, faFilmSimple, faCircleInfo,
    faRuler, faHourglass, faTimeline, faStreetView, faImage
} from "@fortawesome/pro-regular-svg-icons";
import { TabView, TabPanel } from 'primereact/tabview';
import RequestVideo from "../../requestingMedia/requestVideo";
import RequestHyperlapse from "../../requestingMedia/requestHyperlapse";
import fetchOverpass from "../../../../api/fetchOverpass";
import fetchLastJourney from "../../../../api/journeys/fetchLastJourney";
import SpeedLimit from "../eventModal/eventModalComponents/speedLimit";
import ImageMfl from "../../viewMedia/ImageMfl";
import {faFlag, faFlagCheckered} from "@fortawesome/free-solid-svg-icons";
import SnapshotWithAi from "../eventModal/eventModalComponents/snapshotWithAi";
import JourneyTimeline from "../../panelContent/map/journeyTimeline";
import {formatDateOnly, timeFromSeconds} from "../../../../functions/formatting/formatDate";
import EventSpeedLimitIcon from "../../panelContent/grid/cardComponents/eventSpeedLimitIcon";
import indexContext from "../../../contexts/indexContext";
import {Fieldset} from "primereact/fieldset";
import googleMapsLogo from '../../../../svgs/google-maps-old.svg'
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {maxSpeed} from "../../../../functions/formatting/maxspeed";
import fetchAnalytics from "../../../../api/journeys/fetchAnalytics";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";
import RequestSnapshot from "../../requestingMedia/requestSnapshot";
import RequestHyperlapseV2 from "../../requestingMedia/requestHyperlapseV2";
import AddPlaceButton from "../../panelContent/grid/cardComponents/addPlaceButton";
import mainContext from "../../../contexts/mainContext";


const TrackModal = ({feature, handleHyperlapseLine, latLng}) => {


    const {deviceDetails, journey, vehicle, journeys, date, calendar} = useContext(mainContext)


    const [geoData, setGeoData] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showJourneyEnd, setShowJourneyEnd] = useState(false);
    const [showJourneyStart, setShowJourneyStart] = useState(false);
    const [prevJourney, setPrevJourney] = useState(false);
    const [recForDay, setRecForDay] = useState();
    const [analytics, setAnalytics] = useState();
    const [blocked, setBlocked] = useState(fetchBlockedEndpoints());
    const [showTabs, setShowTabs] = useState(false);





    const ReqMediaHeader = (type) => {
        if (type === 'hyperlapse'){
            return (
                <div style={{fontSize: '15px'}}>
                    <FontAwesomeIcon icon={faForward} />
                    &nbsp;Upload Hyperlapse
                </div>
            )
        } else if (type === 'video') {
            return (
                <div style={{fontSize: '15px'}}>
                    <FontAwesomeIcon icon={faFilmSimple} />
                    &nbsp;Upload Video
                </div>
            )
        } else {
            return (
                <div style={{fontSize: '15px'}}>
                    <FontAwesomeIcon icon={faImage} />
                    &nbsp;Photo
                </div>
            )
        }
    }



    const tabChange = (e) => {
        handleHyperlapseLine(null);
        setActiveIndex(e.index);
    }

    const handleShowDetails = async (val) => {

        if (val){
            const analytics = await fetchAnalytics({dn: vehicle?.properties?.dn, at: feature?.properties?.time});
            setAnalytics(analytics);
        }

        setShowDetails(val);
    }

    const GoogleIcon = ({road, poi = true}) => {
        return (
           <React.Fragment>
               {!road ?
                   <React.Fragment>
                       <a target="_blank" style={{marginLeft: '10px'}}
                          href={`http://maps.google.com/maps?q=&layer=c&cbll=${feature?.geometry?.coordinates[1]},${feature?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                           <img src={googleMapsLogo} height="14px" width="14px" />
                       </a>
                       {poi &&
                           <AddPlaceButton lng={feature?.geometry?.coordinates[0]} lat={feature?.geometry?.coordinates[1]} fleetId={vehicle?.properties?.fleetId} />
                       }

                   </React.Fragment>
                   :
                   <React.Fragment/>
               }
           </React.Fragment>
        )
    }

    const TrackModalAddress = () => {
        return (
            <React.Fragment>
                {geoData?.features?.[0]?.properties?.address?.road ?
                    <div>
                        {geoData?.features?.[0]?.properties?.address?.road}
                        <GoogleIcon road={false}/>
                    </div>
                    :
                    <React.Fragment/>
                }
                <React.Fragment>
                    {geoData?.features?.[0]?.properties?.address?.village ?
                        <div>
                            {geoData?.features?.[0]?.properties?.address?.village}
                            <GoogleIcon road={geoData?.features?.[0]?.properties?.address?.road}/>
                        </div>
                        :
                        <div>
                            {geoData?.features?.[0]?.properties?.address?.town ?
                                <div>
                                    {geoData?.features?.[0]?.properties?.address?.town}
                                    <GoogleIcon road={geoData?.features?.[0]?.properties?.address?.road}/>
                                </div>
                                :
                                <div>
                                    {geoData?.features?.[0]?.properties?.address?.city}
                                    <GoogleIcon road={geoData?.features?.[0]?.properties?.address?.road}/>
                                </div>
                            }
                        </div>
                    }
                </React.Fragment>
                {geoData?.features?.[0]?.properties?.address?.postcode}
            </React.Fragment>
        )
    }

    const geoDataDisplay = React.useMemo(() => {
            return  <TrackModalAddress />
    }, [geoData]);



    useEffect(async () => {
        if (feature){

            const time = feature?.time ? feature?.time : feature?.properties?.time;
            let theLat;
            let theLng;

            if (latLng?.lat){
                theLat = latLng.lat;
                theLng = latLng.lng
            } else {
                theLat = feature?.geometry?.coordinates[1];
                theLng = feature?.geometry?.coordinates[0];
            }

            setShowJourneyEnd(journey.features[journey.features.length - 1].properties.time === time && journey?.details?.completed)
            setShowJourneyStart(journey.features[0].properties.time === time);

            const data = await fetchReverseGeocode(theLat, theLng)

            await setGeoData(data);
        }
    }, [feature])


    useEffect(() => {
        if(journey && journeys){

            if(journeys?.length >= 1 ){
                let prevJourney;
                journeys.forEach((item, index) => {
                   if (item._id === journey.details._id){
                       if (index === 0){
                           let date = new Date(journeys?.[0]?.startTime);
                           date.setDate(date.getDate() - 1)

                           const dateString = [
                               date.getFullYear(),
                               ('0' + (date.getMonth() + 1)).slice(-2),
                               ('0' + date.getDate()).slice(-2)
                           ].join('-');

                           fetchLastJourney(journeys?.[0]?.dn, dateString).then(r => {
                              setPrevJourney(r[0]);
                           });
                       } else {
                        prevJourney = journeys[index-1];
                       }
                   }
                });
                setPrevJourney(prevJourney)
            }
        }
    }, [journey, journeys])


    useEffect(() => {
        if(feature){


        let recDay = false;
        const daysRecs = calendar?.modRecordingHistory.filter(item => formatDateOnly(date) === formatDateOnly(item.st) ||
                        formatDateOnly(date) === formatDateOnly(item.et));

        if (daysRecs?.length > 0){
            for (let i = 0; i < daysRecs?.length; i++) {
                const rec = daysRecs[i];
                if (new Date(rec.st) < new Date(feature?.properties?.time) && new Date(rec.et) > new Date(feature?.properties?.time)){
                    recDay = true;
                    break;
                }
            }
        }


        setRecForDay(recDay)


        const blocked = fetchBlockedEndpoints();

        // if (!recDay){
        //     blocked.push('video-request')
        //     blocked.push('hyperlapse-request')
        // }

            setBlocked(blocked)

        }
    }, [date, calendar])

    useEffect(() => {

        if (blocked?.length > 0){
            let blockedCount = 0;

            if (blocked?.includes('snapshot-request')){
                setActiveIndex(1);
                blockedCount++;
            }

            if (blocked?.includes('video-request')){
                setActiveIndex(2);
                blockedCount++;
            }

            if (blocked?.includes('hyperlapse-request')){
                blockedCount++;
            }

            if(blockedCount === 3) {
                setShowTabs(false)
            } else {
                setShowTabs(true);
            }
        } else {
            setShowTabs(true)
        }
    }, [blocked])

    return (
        <div>

            {!showJourneyEnd ?
                <React.Fragment>
                    <Fieldset legend="Track details">
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                                <table style={{width: '100%', fontSize: '13px'}}>
                                    <tbody>
                                    {geoData &&

                                        <tr>
                                            <td style={{verticalAlign: 'top'}}>
                                                {/*<TrackModalAddress/>*/}

                                                {(showJourneyStart && journey?.details?.startAddress?.poi?.name) ?
                                                    <React.Fragment>
                                                        {journey?.details?.startAddress?.poi?.name}
                                                        &nbsp; ({journey?.details?.startAddress?.place})
                                                        <GoogleIcon road={false} poi={false}/>
                                                    </React.Fragment>
                                                :
                                                    geoDataDisplay
                                                }

                                            </td>
                                            <td>
                                                {fetchUserLevel() == 'superuser' &&
                                                    <FontAwesomeIcon icon={faCircleInfo} style={{cursor: 'pointer'}}
                                                                     onClick={() => handleShowDetails(!showDetails)}/>
                                                }
                                            </td>
                                        </tr>
                                    }

                                    {/*<tr>*/}

                                    {/*    <td colSpan="2">*/}
                                    {/*        <a className="p-button p-button-sm"*/}
                                    {/*           style={{background: "var(--v-list-button)"}} target="_blank"*/}
                                    {/*           href={`http://maps.google.com/maps?q=&layer=c&cbll=${feature?.geometry?.coordinates[1]},${feature?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>*/}
                                    {/*            <FontAwesomeIcon className="p-button-label" icon={faStreetView} />*/}
                                    {/*        </a>*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}

                                    </tbody>
                                </table>

                                {(fetchUserLevel() == 'superuser' && showDetails && analytics) &&
                                    <p style={{userSelect: 'text'}}>
                                        Time: {feature?.properties?.time} <br/>
                                        GPS Accuracy: {analytics?.gpsAcc}<br/>
                                        Signal Intensity: {analytics?.sigInt}<br/>
                                        Network Type: {analytics?.netType}<br/>
                                        Store Forward: {feature?.properties?.storeForward ? <span>true</span> : <span>false</span>}<br/>
                                        Voltage: {(feature?.properties?.voltage/100)} V<br/>
                                    </p>

                                }
                                {(fetchUserLevel() == 'superuser' && showDetails && !analytics) &&
                                    <p>No Data</p>

                                }
                            </div>

                            <div className="eventClassificationContainer" style={{marginTop: '10px'}}>
                                <div className="eventClassificationTitle">Speed/Limit</div>
                                {/*<div className="eventClassificationTitle">Speed/Limit</div>*/}
                                <div style={{fontSize: '25px', fontWeight: 'bold', marginRight: '2px'}}>
                                    {typeof feature?.properties?.speed === 'number' &&
                                        (feature?.properties?.speed).toFixed()
                                    }
                                    <span style={{fontSize: '8px'}}>
                                mph
                            </span>
                                </div>

                                {/*<SpeedLimit feature={feature}/>*/}
                                <div style={{flex: 1, textAlign: 'right', paddingRight: '10px'}}>
                                    <div style={feature?.properties?.maxspeed ? {position: 'relative',   width: '40px', marginLeft: 'auto', marginRight: 0,
                                        height: '36px',
                                        verticalAlign: 'middle',
                                        borderRadius: '36px',
                                        border: 'solid 3px red'} : {}}>

                                        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',fontSize: '18px', fontWeight: 'bold'}}>
                                            {feature?.properties?.maxspeed}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Fieldset>
                </React.Fragment>
            :
                <div style={{marginTop: '5px'}}>

                    <Fieldset legend="Journey Details">
                        <table style={{width: '100%', fontSize: '13px'}}>
                            <tbody>
                            <tr>
                                <td>
                                    Start:
                                </td>
                                <td style={{width: '90%', paddingLeft: '5px'}}>
                                    {journey?.details?.startAddress?.poi?.name ?
                                        <React.Fragment>
                                            {journey?.details?.startAddress?.poi?.name}
                                            &nbsp;({journey?.details?.startAddress?.place})
                                            <a target="_blank" style={{marginLeft: '10px'}}
                                               href={`http://maps.google.com/maps?q=&layer=c&cbll=${journey?.features[0]?.geometry?.coordinates[1]},${journey?.features[0]?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                                                <img src={googleMapsLogo} height="14px" width="14px"/>
                                            </a>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                journey.details.startMarkerLocation?.road &&
                                                <span>{journey.details.startMarkerLocation?.road},</span>
                                            }
                                            {
                                                journey.details.startMarkerLocation?.town &&
                                                <span>&nbsp;{journey.details.startMarkerLocation?.town},</span>
                                            }
                                            {
                                                journey.details.startMarkerLocation?.county &&
                                                <span>&nbsp;{journey.details.startMarkerLocation?.county},</span>
                                            }
                                            {
                                                journey.details.startMarkerLocation?.postcode &&
                                                <span>&nbsp;{journey.details.startMarkerLocation?.postcode}</span>
                                            }

                                            <a target="_blank" style={{marginLeft: '10px'}}
                                               href={`http://maps.google.com/maps?q=&layer=c&cbll=${journey?.features[0]?.geometry?.coordinates[1]},${journey?.features[0]?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                                                <img src={googleMapsLogo} height="14px" width="14px" />
                                            </a>

                                            <AddPlaceButton lng={journey?.features[0]?.geometry?.coordinates[0]}
                                                            lat={journey?.features[0]?.geometry?.coordinates[1]}
                                                            fleetId={journey?.features[0]?.properties?.fleetId} />


                                        </React.Fragment>
                                    }

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    End:
                                </td>
                                <td style={{width: '90%', paddingLeft: '5px'}}>
                                    {journey?.details?.endAddress?.poi?.name ?
                                        <React.Fragment>
                                            {journey?.details?.endAddress?.poi?.name}
                                            &nbsp;({journey?.details?.endAddress?.place})
                                            <a target="_blank" style={{marginLeft: '10px'}}
                                               href={`http://maps.google.com/maps?q=&layer=c&cbll=${journey?.features[journey?.features?.length -1]?.geometry?.coordinates[1]},${journey?.features[journey?.features?.length -1 ]?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                                                <img src={googleMapsLogo} height="14px" width="14px"/>
                                            </a>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            {
                                                journey.details.endMarkerLocation?.road &&
                                                <span>{journey.details.endMarkerLocation?.road},</span>
                                            }
                                            {
                                                journey.details.endMarkerLocation?.town &&
                                                <span>&nbsp;{journey.details.endMarkerLocation?.town},</span>
                                            }
                                            {
                                                journey.details.endMarkerLocation?.county &&
                                                <span>&nbsp;{journey.details.endMarkerLocation?.county},</span>
                                            }
                                            {
                                                journey.details.endMarkerLocation?.postcode &&
                                                <span>&nbsp;{journey.details.endMarkerLocation?.postcode}</span>
                                            }


                                            <a target="_blank" style={{marginLeft: '10px'}}
                                               href={`http://maps.google.com/maps?q=&layer=c&cbll=${journey?.features[journey?.features?.length -1]?.geometry?.coordinates[1]},${journey?.features[journey?.features?.length -1]?.geometry?.coordinates[0]}&cbp=11,0,0,0,0`}>
                                                <img src={googleMapsLogo} height="14px" width="14px" />
                                            </a>

                                            <AddPlaceButton lng={journey?.features[journey?.features?.length - 1]?.geometry?.coordinates[0]}
                                                            lat={journey?.features[journey?.features?.length - 1]?.geometry?.coordinates[1]}
                                                            fleetId={vehicle?.properties?.fleetId} />

                                        </React.Fragment>
                                    }
                                </td>
                            </tr>
                            <tr style={{verticalAlign: 'top'}}>
                                <td>
                                    Distance:
                                </td>
                                <td style={{width: '90%', paddingLeft: '5px'}}>
                                    {parseFloat(journey.details.distance).toFixed(1)}&nbsp; miles
                                </td>
                            </tr>
                            <tr style={{verticalAlign: 'top'}}>
                                <td>
                                    Duration:
                                </td>
                                <td style={{width: '90%', paddingLeft: '5px'}}>

                                    {journey?.details?.duration}

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </Fieldset>




                </div>
            }

            <br />

            {showTabs &&
                <React.Fragment>
                    {showJourneyEnd || (showJourneyStart && prevJourney) ?
                        <TabView onTabChange={(e) => tabChange(e)} activeIndex={activeIndex} scrollable>

                            <TabPanel header={ReqMediaHeader('photo')} disabled={blocked?.includes('view-snapshot')}>
                                {showJourneyEnd ?
                                    <React.Fragment>
                                        <SnapshotWithAi aiData={null} snapshot={{file:journey.details.endJpeg }} />
                                        {/*<SpeedChartV2 />*/}
                                    </React.Fragment>
                                    :
                                    <SnapshotWithAi aiData={null} snapshot={{file: prevJourney?.endJpeg}}/>
                                }
                            </TabPanel>

                            <TabPanel header={ReqMediaHeader('video')} disabled={blocked?.includes('video-request')}>
                                <RequestVideo deviceDetails={deviceDetails} st={feature?.properties?.time}
                                              vehicle={vehicle}/>
                            </TabPanel>
                            <TabPanel header={ReqMediaHeader('hyperlapse')} disabled={blocked?.includes('hyperlapse-request')}>
                                <RequestHyperlapseV2 feature={feature} handleHyperlapseLine={handleHyperlapseLine}/>
                            </TabPanel>
                        </TabView>
                        :
                        <React.Fragment>
                            {(recForDay || new Date(feature?.properties?.time).getTime() >= (new Date().getTime() - 172800000) )  ?

                                <TabView onTabChange={(e) => tabChange(e)} activeIndex={activeIndex} scrollable>

                                    {/*<TabPanel header={ReqMediaHeader('photo')} disabled={blocked?.includes('view-snapshot')}>*/}
                                    {/*    <RequestSnapshot st={feature?.properties?.time} />*/}
                                    {/*</TabPanel>*/}

                                    <TabPanel header={ReqMediaHeader('video')} disabled={blocked?.includes('video-request')}>
                                        <RequestVideo deviceDetails={deviceDetails} st={feature?.properties?.time}
                                                      vehicle={vehicle}/>
                                    </TabPanel>

                                    <TabPanel header={ReqMediaHeader('hyperlapse')} disabled={blocked?.includes('hyperlapse-request')}>
                                        <RequestHyperlapseV2 feature={feature} handleHyperlapseLine={handleHyperlapseLine}/>
                                    </TabPanel>
                                </TabView>
                            :
                                <div style={{textAlign:'center'}}>
                                    We are unable to upload video because the calendar does not contain recordings for this time.
                                    If it is a very recent journey it may be that the calendar has not yet updated. If the problem
                                    persists please contact support@fleetfocus.co.uk.
                                </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }



        </div>
    );
}


export default TrackModal;
