export const safeParse = (str) => {
    let parsed

    try {
        parsed = JSON.parse(str);
    } catch (e) {
        parsed =  str
    }
    return parsed;
}
