import React, {useContext} from 'react';
import VehicleDetail from "./vehicle/VehicleDetail";
import ListJourneys from "./journeys/ListJourneys";
import PanelHeader from "./panelHeader";
import EventFilters from "./events/eventFilters";
import NotificationsFilters from "./notifications/notificationsFilters";
import {faStar, faTrash, faTrashSlash} from "@fortawesome/pro-light-svg-icons";
import {faStar as solidStar} from "@fortawesome/pro-solid-svg-icons";
import indexContext from "../../contexts/indexContext";
import MediaFilters from "./media/mediaFilters";
import mainContext from "../../contexts/mainContext";
import abovePanelContext from "../../contexts/abovePanelContext";

const AbovePanel = ({handleLastDaysOfEvents, lastDaysOfEvents}) => {


     const {vehicle, date, handleDate, disableDateChange, sideBarWidth, fleetsToShow} = useContext(mainContext);

    const {winWidth, pageToShow} = useContext(indexContext);


    const actionOptions = [
        {val: 'read', display: 'Delete', icon: faTrash},
        {val: 'unread', display: 'Undelete', icon: faTrashSlash},
        {val: 'star', display: 'Star', icon: solidStar},
        {val: 'unstar', display: 'Unstar', icon: faStar},
    ]

    const calcStyling = () => {
        let style =  {width: '0px', position: 'absolute', zIndex: 1, transitionDuration: '300ms',
            transitionProperty: 'width', transitionTimingFunction: 'ease'}

        if (winWidth > 800){
            style.width = sideBarWidth.marginLeft !== '-300px' ? 'calc(100vw - 300px)' : '100%';
        } else {
            style.width =  '100%';
            style.position = 'relative';
        }

       return style
    }

    return (
        <div style={calcStyling()} className="text-color">

            {!vehicle &&
                <PanelHeader pageToShow={pageToShow} fleetsToShow={fleetsToShow} handleDate={handleDate} date={date}
                disableDateChange={disableDateChange}/>
            }

            {vehicle && pageToShow !== '/reports' &&
                <VehicleDetail />
            }

            {vehicle && pageToShow === '/map' &&
                <ListJourneys  />
            }

            {pageToShow === '/events' &&
                <EventFilters handleLastDaysOfEvents={handleLastDaysOfEvents} lastDaysOfEvents={lastDaysOfEvents}/>
            }

            {pageToShow === '/media' &&
                <MediaFilters />
            }



            {pageToShow === '/notifications' &&
                <NotificationsFilters />
            }
        </div>
    )
}
export default AbovePanel
