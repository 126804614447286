import React, {useContext, useEffect, useRef, useState} from 'react';
import useToken from '../../../login/useToken.js';
import StreamHLS from './StreamHLS';
import Livestream from "./livestream";
import indexContext from "../../contexts/indexContext";
import mainContext from "../../contexts/mainContext";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
const env = require('../../../Constants.js');

const LivestreamModal = ({}) => {

    const { token } = useToken();

    const {winWidth} = useContext(indexContext);
    const {vehicle, deviceDetails, closeModal} = useContext(mainContext);

    const [toShow, setToShow] = useState([]);
    const [play, setPlay] = useState(false);
    const [deviceOnline, setDeviceOnline] = useState(true);
    const [startStopDisable, setStartStopDisable] = useState(false)

    const handleDeviceOnline = (val) => {
        setDeviceOnline(val);
        if (!val) setPlay(val);
    }




    const addCam = (cam) => {
        if (winWidth > 800){
            const newToShow = [...toShow]



            if (newToShow.includes(cam?.channel)) {
                const index = newToShow.indexOf(cam?.channel);
                if (index > -1) newToShow.splice(index, 1);
            } else {
                newToShow.push(cam?.channel);
            }


            if(newToShow?.length < 1) setPlay(false);

            setToShow(newToShow)
        } else {
            setToShow([cam?.channel])
        }
    }




    useEffect(() => {
        if (deviceDetails){
            let front

            for (const cam of deviceDetails.cameras){
                if (cam.camPosition === 'Front'){
                    front = cam.channel;
                }
            }

            setToShow([front])
        }
    }, [deviceDetails])



    return (
        <React.Fragment>
            <div style={{position: 'absolute', top: '25px', right: '25px'}}>
                <button type="button" className="p-link" aria-label="Close" style={{borderRadius: '50%'}}
                        onClick={async () => {
                            if (play) await setPlay(false);
                            closeModal('displayLivestreamModal');
                        }}>
                    <span className="p-dialog-header-close-icon pi pi-times"
                          style={{color: 'var(--text-color)', padding: '3px'}}></span>
                </button>
            </div>


            <Fieldset legend="Select up to four cameras to livestream">
                <div style={{display: 'flex', justifySpace: 'space-evenly', gap: '5px'}}>
                    {deviceDetails?.cameras.map(cam => (
                        <Button onClick={() => addCam(cam)}
                                style={{flex:1}} severity={toShow?.includes(cam?.channel) ? '' : 'secondary'}

                        disabled={play || (toShow?.length === 4 && !toShow?.includes(cam?.channel)) || !deviceOnline}
                                label={cam?.camPosition}
                        />

                    ))}

                    <Button disabled={toShow?.length < 1 || !deviceOnline || startStopDisable}
                            style={{flex:1,
                                background: !play ? '#5C8459' : 'var(--notification-badge)',
                                color: 'white'}}
                            onClick={() => {
                                setStartStopDisable(true);
                                setPlay(!play);
                                setTimeout(() => setStartStopDisable(false), 500)
                            }} label={!play ? 'Play' : 'Stop'}/>
                </div>

            </Fieldset>


            {deviceOnline ?
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {deviceDetails?.cameras?.map((cam) => <Livestream dn={deviceDetails?.dn} cam={cam} play={play}
                                                                      toShow={toShow}
                                                                      handleDeviceOnline={handleDeviceOnline}
                                                                      deviceOnline={deviceOnline}/>)}
                </div>
            :
                <div style={{textAlign: 'center'}}>Device is offline, please try again when the device is online</div>

            }


        </React.Fragment>
    )
};

export default LivestreamModal
