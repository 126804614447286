import React from 'react';
import {API_URL, NOTIFICATIONS_API_URL} from "../../Constants";

const postHyperlapseRequest = async (videoObj, vehicle, emailNotification = false) => {

    let token = localStorage.getItem('access_token');

    const requestOptions = (obj) => {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token  },
            body: JSON.stringify(obj)
        }
    }

    let error = ''
    let obj = {};

    let device = vehicle?.properties ? vehicle.properties : vehicle;

    let camPosSelected;

    device?.cameras?.forEach(cam => {
        if (videoObj.channel.toString() === cam.channel.toString()){
            camPosSelected = cam?.camPosition;
        }
    });

    const notificationData = {
        mflGroup: localStorage.getItem('mflGroup'),
        notificationRead: [],
        notificationCreatedTimestamp: new Date(),
        dn: device.dn,
        registration: device.registration,
        fleetId: device.fleetId,
        notificationType: ['hyperlapseRequested'],
        notificationDetails: {
            title: 'Hyperlapse requested',
            description: 'A new hyperlapse upload has been requested',
            eventCreatedBy: localStorage.getItem('email'),
            hyperlapseRequest: videoObj,
            camPosition: camPosSelected
        },
    };


    try {
        let device = vehicle?.properties ? vehicle.properties : vehicle;

        let camPosSelected;

        device?.cameras?.forEach(cam => {
            if (videoObj.channel.toString() === cam.channel.toString()){
                camPosSelected = cam?.camPosition;
            }
        });

        const notificationData = {
            mflGroup: localStorage.getItem('mflGroup'),
            notificationRead: [],
            notificationCreatedTimestamp: new Date(),
            dn: device.dn,
            registration: device.registration,
            fleetId: device.fleetId,
            notificationType: ['hyperlapseRequested'],
            notificationDetails: {
                title: 'Hyperlapse requested',
                description: 'A new hyperlapse upload has been requested',
                eventCreatedBy: localStorage.getItem('email'),
                hyperlapseRequest: videoObj,
                camPosition: camPosSelected
            },
        };

        await  fetch(NOTIFICATIONS_API_URL + '/addNotification', requestOptions(notificationData));
        const raw = await fetch(API_URL + '/hyperlapse-request', requestOptions(videoObj));
        const data = await raw.json()
        obj.mflGroup = localStorage.getItem('mflGroup');
        obj.email = localStorage.getItem('email');
        obj.fleetId = device?.fleetId;
        obj.dn = device?.dn;
        obj.camPosition = camPosSelected;
        obj.emailNotification = emailNotification
        obj.startTimeHyper = data?.hyperlapse?.startTimeHyper
        obj.requestDate = data?.hyperlapse?.requestDate;
        obj.durationMinutes = data?.hyperlapse?.durationMinutes
        obj.media_id = data?.hyperlapse?._id
        await fetch(NOTIFICATIONS_API_URL + '/addToMediaQueue', requestOptions(obj));    } catch (e) {
      error = e
    }

    const toReturn = {
        error: error,
        status: error?.length > 0 ? 'error' : 'ok',
        data: obj
    }


    return Promise.resolve(toReturn);

}

export default postHyperlapseRequest

