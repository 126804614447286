import React, {useContext, useEffect, useRef, useState} from 'react';
import HyperlapseMarker from "../panelContent/map/mapMarkers/hyperlapseMarker";
import {Slider} from "primereact/slider";
import {Message} from "primereact/message";
import {Checkbox} from "primereact/checkbox";
import mainContext from "../../contexts/mainContext";
import {Button} from "primereact/button";
import {
    formatDate,
    formatTimeOnly,
    getSecondsDifference,
    getTimeDifference
} from "../../../functions/formatting/formatDate";
import {NOTIFICATIONS_CALLBACK_URL, TOAST_DURATION} from "../../../Constants";
import postHyperlapseRequest from "../../../api/media/postHyperlapseRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleInfo,
    faCloudArrowUp,
    faFastForward,
    faSpinner
} from "@fortawesome/pro-regular-svg-icons";
import {videoDurationOptions} from "./requestingMediaFunctions/videoDurationOptions";
import {Dropdown} from "primereact/dropdown";
import VideoBudgetMessage from "./videoBudgetMessage";
import {Toast} from "primereact/toast";
import {Tooltip} from "primereact/tooltip";
import fetchAllHyperlapses from "../../../api/media/fetchAllHyperlapses";
import fetchVideoBudget from "../../../api/device/fetchVideoBudget";

const RequestHyperlapseV2 = ({feature, handleHyperlapseLine}) => {

    const {deviceDetails, closeModal, journey} = useContext(mainContext)

    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [lessThanTwo, setLessThanTwo] = useState(false);
    const [rangeValues, setRangeValues] = useState();
    const [curUploadingChannels, setCurUploadingChannels]= useState([]);
    const [emailChecked, setEmailChecked] = useState(true);
    const [duration, setDuration] = useState()
    const [sendingRequest, setSendingRequest] = useState(false);
    const [durationOptions, setDurationOptions] = useState([]);
    const [requestSent, setRequestSent] = useState(false);
    const [sliderVal, setSliderVal] = useState();

    const toast = useRef(null)




    useEffect(async () => {
        if (journey && feature){
            let options = [];
            const a = journey?.details?.duration?.split(':');
            const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
            if (seconds / 300 > 1) options.push({label:'Hyperlapse duration: 5 Minutes', val: 5})
            if (seconds / 600 > 1) options.push({label:'Hyperlapse duration: 10 Minutes', val: 10})
            if (seconds / 1200 > 1) options.push({label:'Hyperlapse duration: 20 Minutes', val: 20})
            if (seconds / 1800 > 1) options.push({label:'Hyperlapse duration: 30 Minutes', val: 30})
            if (seconds / 3600 > 1) options.push({label:'Hyperlapse duration: 1 Hour', val: 60})
            if (seconds / 7200 > 1) options.push({label:'Hyperlapse duration: 2 Hours', val: 120})
            options.push({label:'Hyperlapse duration: Full journey', val: -1})
            // options.push({label:'Hyperlapse duration: Full Day', val: 0})
            await setDurationOptions(options);
            setDuration(options?.[0])
        }
    }, [journey, feature])


    useEffect(async () => {
        if (duration){
            if (duration.val > 0){
                const dur = duration.val * 60 * 1000;
                const mid = new Date(feature?.properties?.time).getTime();
                let st = new Date(journey?.features?.[0]?.properties?.time).getTime()
                if (new Date(journey?.features?.[0]?.properties?.time) < mid-(dur/2)) st = mid-(dur/2);
                let et = new Date(journey?.features?.[journey?.features?.length - 1]?.properties?.time).getTime()
                if (new Date(journey?.features?.[journey?.features?.length - 1]?.properties?.time) > mid+(dur/2)) et = mid+(dur/2);
                setRangeValues([st, et])
                handleHLFeatArr([st, et])
            } else if (duration.val === 0) {
                console.log('all day')
            } else {
                let st = new Date(journey?.features?.[0]?.properties?.time).getTime()
                let et = new Date(journey?.features?.[journey?.features?.length - 1]?.properties?.time).getTime()
                setRangeValues([st, et])
                handleHLFeatArr([st, et])
            }
        }
    }, [duration]);

    useEffect(() => {
        if(deviceDetails){
            const front = deviceDetails?.cameras.filter(cam => cam.camPosition === 'Front');
            setCamsToReq(new Set(front))
        }
    }, [deviceDetails]);


    const handleDurationChange = async (val = durationOptions?.[0]) => {
        await setDuration(val)


    }


    const handleHLFeatArr = (times) => {
        let points = [];
        for (const track of journey.features){
            if  (new Date(track?.properties?.time).getTime() >= times[0] && new Date(track?.properties?.time).getTime()<= times[1]){
                points.push(track);
            }
        }
        handleHyperlapseLine(points)
    }

    const handleTimeChange = async (e) => {

        const et = e.value + (duration.val * 60000);
        setSliderVal(e.value)
        setRangeValues([e.value, et])

        setLessThanTwo(e.value + (duration.val * 60000) < 120000);
        handleHLFeatArr([e.value, et])
    }

    const addRemoveCameraToReq= (v) => {
        setCamsToReq(new Set([v]));
    }


    const handleHyperlapseRequest = async () => {

        setSendingRequest(true);

        const difference = rangeValues[1] - rangeValues[0];
        let resultInMinutes = Math.round(difference / 60000);

        if (resultInMinutes < 1){
            resultInMinutes = 1;
        }

        const toastMessages = [];
        const arr = [...camsToReq];

        for (let i = 0; i < arr.length; i++) {

            const cam = arr[i];

            const dataToSend = {
                dn: deviceDetails.dn,
                channel: cam.channel,
                startTimeHyper: formatDate(rangeValues[0]),
                durationMinutes: resultInMinutes,
                totalFrames: 100,
                callback: NOTIFICATIONS_CALLBACK_URL
            };

           const r = await  postHyperlapseRequest(dataToSend, deviceDetails, i === 0 ? emailChecked : false);


            if (r.status === 'ok'){
                toastMessages.push( { severity: 'success', summary: 'All done', detail: cam.camPosition + ' hyperlapse requested', life: TOAST_DURATION },)
            } else {
                toastMessages.push( { severity: 'error', summary: 'Error', detail: cam.camPosition + ' error ' + r.error, life: TOAST_DURATION },)
            }

        }
        await new Promise(r => setTimeout(r, 2000));
        toast.current.show(toastMessages);
        setSendingRequest(false);
        setRequestSent(true);



    }

    const frameTiming = () => {
        const seconds = getSecondsDifference(rangeValues?.[0], rangeValues?.[1]);
        const ratio = seconds / 100;
        return `1 snapshot per ${ratio} seconds`
    }


    useEffect(async () => {

        if (deviceDetails){
            const channelsUploading = [];

            const existingHl = await fetchAllHyperlapses(deviceDetails);

            if(existingHl.queued.length > 0){
                existingHl.queued.forEach(hl => {

                    const daysSinceReq = (new Date().getTime() - new Date(hl.requestDate).getTime()) / 86400000;

                    if (daysSinceReq < 2){
                        channelsUploading.push(hl.channel.toString());
                    }
                });
            }
            setCurUploadingChannels(channelsUploading)
        }
    }, [deviceDetails])


    return (
        <div style={{textAlign: 'center', padding: '5px'}}>



            {deviceDetails?.cameras.map(function (cam, index){
                return (
                    <div key={index} style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
                        <Button onClick={() => addRemoveCameraToReq(cam)} disabled={curUploadingChannels.includes(cam.channel)}
                                className={camsToReq.has(cam) ? 'p-button' : 'p-button p-button-secondary'}
                                tooltip={'Channel: ' + cam.channel} tooltipOptions={{position: 'bottom'}}>
                            {cam.camPosition}
                        </Button>
                    </div>
                )
            })}

            <br /><br />


                <Dropdown value={duration} options={durationOptions} onChange={(e) => handleDurationChange(e.value)}
                          placeholder="Select hyperlapse duration" optionLabel="label" style={{width: '80%', height: '40px', lineHeight: '30px'}}/>

            <br />
            <br />


            {/*<div style={{display: 'flex'}}>*/}
            {/*    <div style={{width: '32px', textAlign: 'left', fontSize: '16px'}}>*/}
            {/*        /!*<HyperlapseMarker color="green" />*!/*/}
            {/*        <FontAwesomeIcon icon={faFastForward} />*/}
            {/*    </div>*/}
            {/*    <div style={{flex:1}}>*/}
            {/*        <Slider value={sliderVal}*/}
            {/*                min={new Date(journey?.features?.[0]?.properties?.time).getTime()}*/}
            {/*                max={new Date(journey?.features?.[journey?.features?.length -1]?.properties?.time).getTime()}*/}
            {/*                step={2000}*/}
            {/*                onChange={(e) => handleTimeChange(e)}*/}
            {/*                style={{marginTop: '5px'}}*/}
            {/*        />*/}

            {/*    </div>*/}
            {/*    <br/> <br/>*/}
            {/*    <div style={{width: '20px', textAlign: 'right', fontSize: '16px'}}>*/}
            {/*        /!*<HyperlapseMarker color="red" />*!/*/}
            {/*        <FontAwesomeIcon icon={faFastForward} />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <br/>

            <div>
                <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
                <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
            </div>

            <br />

            <div style={{display: 'inline-block', marginBottom: '5px'}}>
                <h4>Upload summary:</h4>
                Start time: {formatTimeOnly(rangeValues?.[0])}<br />
                End time: {formatTimeOnly(rangeValues?.[1])} <br />
                Duration: {getTimeDifference(rangeValues?.[0], rangeValues?.[1])} ({frameTiming()})
            </div>




            <br />
            <br />


            {camsToReq?.size === 0 && curUploadingChannels?.length < 1 &&
                <div  style={{width:'80%', margin: '0 auto', marginBottom: '15px'}}>
                    <Message severity="warn" text="Please select camera(s)" />
                </div>
            }

            {curUploadingChannels?.length > 0 &&
                <div  style={{width:'80%', margin: '0 auto', marginBottom: '15px'}}>
                    <Message severity="warn" text="Please wait for any in progress hyperlapse uploads to complete before requesting more" />
                </div>
            }


                <button className="p-button" onClick={handleHyperlapseRequest}
                        disabled={camsToReq.size < 1 || lessThanTwo || sendingRequest || curUploadingChannels?.length > 0 || requestSent} style={{width: '80%'}}>

                    {sendingRequest ?
                        <span className="p-button-label">
                            <FontAwesomeIcon icon={faSpinner} spin/>
                            &nbsp;Sending request
                          </span>
                        :
                        <React.Fragment>
                            {requestSent ?
                                <span className="p-button-label">
                                  <FontAwesomeIcon icon={faCircleCheck}/>
                                    &nbsp;Hyperlapse Requested
                                </span>
                                :
                                <span className="p-button-label">
                                <FontAwesomeIcon icon={faCloudArrowUp}/>
                                &nbsp;Upload Hyperlapse
                            </span>
                            }
                        </React.Fragment>
                    }
                </button>


            {lessThanTwo &&
                <Message severity='warn' text="Hyperlapses must be at least 2 minutes in length"
                         style={{width: '80%', justifyContent: 'center', marginTop: '5px'}}/>
            }


            <div style={{textAlign: 'center', marginTop: '20px'}} className="HLUploadFromTrackHelp" data-pr-position="top">
                <FontAwesomeIcon icon={faCircleInfo} />
                &nbsp;Help
            </div>

            <Tooltip target=".HLUploadFromTrackHelp">
                <div style={{width: '250px'}}>
                    Hyperlapse uploads timelapse journey footage. Select the start point on the journey line and click Set Hyperlapse Start,
                    then select an end point on the journey line and click Set Hyperlapse End.
                    <br/><br/>You can monitor the progress of your upload here or check your notification Inbox at any time.
                </div>
            </Tooltip>

            <Toast ref={toast} appendTo={null} position="bottom-right"/>

        </div>
    )
}

export default RequestHyperlapseV2
