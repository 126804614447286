import React, {useContext} from 'react';
import {Dialog} from "primereact/dialog";
import indexContext from "../../../contexts/indexContext";
import PlaceModal from "./placeModal";

const PlaceModalParent = ({placeSelected, showPlaceModal, handleShowPlaceModal, handleRefreshPois , saveChanges,fleet}) => {

    const {winWidth} = useContext(indexContext);


    return (
        <Dialog visible={showPlaceModal} style={{width: winWidth >= 800 ? '55vw' : '100vw',
            height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}}
                onHide={() => {
                    handleRefreshPois(false);
                    handleShowPlaceModal(false)
                }} >
            <div style={{marginTop: '-50px', position: 'absolute', fontWeight: 'bold', fontSize: '16px', width: '250px'}}>

                {placeSelected?._id ? "Editing place of interest" : "Adding place of interest"}


                {/*<div style={{fontWeight: 'normal', marginBottom: '20px'}}>*/}
                {/*    {formatDateModalHeader(videoToShow?.st ? videoToShow?.st : videoToShow?.startTimeHyper)}*/}
                {/*    <br />*/}
                {/*    <span style={{fontSize: '1rem'}}>*/}
                {/*            <FontAwesomeIcon icon={faBuildings} style={{marginRight: '7px'}}/>*/}
                {/*        {friendlyFleetName(fleetFromDn(vehicles, videoToShow?.dn), fleets)}*/}
                {/*        </span>*/}
                {/*</div>*/}


            </div>



            {/*<VideoPlayer video={videoToShow} />*/}
            <div style={{}}>
                <PlaceModal placeSelected={placeSelected} saveChanges={saveChanges} handleRefreshPois={handleRefreshPois}
                            fleet={fleet} handleShowPlaceModal={handleShowPlaceModal}/>
            </div>
        </Dialog>
    )
}
export default PlaceModalParent
