import React from 'react';
import {Colours} from "../colours";

export const MflLogo = (props) => {

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 95 95" style={{enableBackground:'new 0 0 442.3872375 92.8457031', width:props.size}} xmlSpace="preserve">
            <g id="Logos">
                <g>
                    <path  style={{fill: Colours.logoSpriteColour}} d="M46.4249077,37.1361389c5.1291313,0,9.2863007,4.1571732,9.2863007,9.2863007
                        c0,5.1299515-4.1571693,9.2862968-9.2863007,9.2862968c-5.1245956,0-9.2817688-4.1563454-9.2817688-9.2862968
                        C37.1431389,41.2933121,41.300312,37.1361389,46.4249077,37.1361389z M27.8543682,55.7087364
                        c-5.1245975,0-9.2817688-4.1563454-9.2817688-9.2862968c0-5.1291275,4.1571712-9.2863007,9.2817688-9.2863007
                        c5.1291294,0,9.2867107-4.1571732,9.2867107-9.2813587c0-5.1299515,4.1571732-9.2862988,9.2863007-9.2862988
                        c5.1241875,0,9.2813568-4.1579962,9.2813568-9.2871237C55.7087364,4.1571717,51.5515671,0,46.4273796,0
                        c-5.1291275,0-9.2863007,4.1571717-9.2863007,9.2813578c0,5.1291275-4.1575813,9.2871237-9.2867107,9.2871237
                        c-5.1245975,0-9.2817688,4.1563473-9.2817688,9.2862988c0,5.1241856-4.1571712,9.2813587-9.2862997,9.2813587
                        C4.1567597,37.1361389,0,41.2933121,0,46.4224396c0,5.1299515,4.1567597,9.2862968,9.2862997,9.2862968
                        c5.1291285,0,9.2862997,4.1530533,9.2862997,9.2821846s4.1571712,9.2863007,9.2817688,9.2863007
                        c5.1291294,0,9.2867107-4.1571732,9.2867107-9.2863007S32.9834976,55.7087364,27.8543682,55.7087364z M83.5635223,37.1361389
                        c-5.1291351,0-9.2863007-4.1571732-9.2863007-9.2813587c0-5.1299515-4.1522293-9.2862988-9.2817688-9.2862988
                        s-9.2867165,4.1563473-9.2867165,9.2862988c0,5.1241856,4.1571732,9.2813587,9.2867165,9.2813587
                        s9.2817688,4.1571732,9.2817688,9.2863007c0,5.1299515-4.1522293,9.2862968-9.2817688,9.2862968
                        s-9.2867165,4.1530533-9.2867165,9.2821846s-4.1571693,9.2863007-9.2813568,9.2863007
                        c-5.1291275,0-9.2863007,4.1571655-9.2863007,9.2821808c0,5.1283035,4.1571732,9.2863007,9.2863007,9.2863007
                        c5.1241875,0,9.2813568-4.1579971,9.2813568-9.2863007c0-5.1250153,4.1571732-9.2821808,9.2867165-9.2821808
                        s9.2817688-4.1571732,9.2817688-9.2863007s4.1571655-9.2821846,9.2863007-9.2821846
                        c5.1291275,0,9.286293-4.1563454,9.286293-9.2862968C92.8498154,41.2933121,88.6926498,37.1361389,83.5635223,37.1361389z"/>
                </g>
            </g>

        </svg>
    );
}

