import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const PhoneStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" style={{height, width: height, verticalAlign: 'middle'}} className={"tooltipTargetEventClass" + event?._id} data-pr-position="bottom">
                <circle cx="174.95" cy="174.95" r="174.95"  fill={'var(--surface-border)'}/>
                <path id="phone" style={{fill:'var(--text-color)'}} d="m113.95,83.55v182.79c0,4.18,3.42,7.61,7.61,7.61h106.79c4.18,0,7.61-3.42,7.61-7.61V83.55c0-4.18-3.42-7.61-7.61-7.61h-106.79c-4.18,0-7.61,3.42-7.61,7.61Zm8.43-29.61h106.15c16.8,0,30.43,13.62,30.43,30.43v182.15c0,16.8-13.62,30.43-30.43,30.43h-106.15c-16.8,0-30.43-13.62-30.43-30.43V84.37c0-16.8,13.62-30.43,30.43-30.43Zm37.82,190h29.67c3.84,0,7.29,2.73,7.77,6.54.57,4.55-3,8.46-7.44,8.46h-29.67c-3.84,0-7.29-2.73-7.77-6.54-.57-4.55,3-8.46,7.44-8.46Z" />
            </svg>

            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Mobile phone</Tooltip>
            }
        </div>
    )
}
export default PhoneStacked
