import {API_URL, NOTIFICATIONS_API_URL} from '../../Constants.js'


const postUpdateDvlaData = (data) => {

    let token = localStorage.getItem('access_token');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(data)
    };

    return fetch(NOTIFICATIONS_API_URL + '/updateDvlaData', requestOptions)
        .then((response) => {
            if(response.status === 403) {
                localStorage.removeItem('token');
            }
            return response.json()
        })
        .then((data) => {
            return data
        });
}


export default postUpdateDvlaData;
