import React, {useContext, useEffect, useState} from 'react';
import FormatAddress from "../../../../../../functions/formatting/formatAddress";
import {
    DurationFromSeconds,
    formatTimeOnly,
    fullDurationFromSeconds
} from "../../../../../../functions/formatting/formatDate";
import {regFromDn} from "../../../../../../functions/formatting/regFromDn";
import indexContext from "../../../../../contexts/indexContext";
import mainContext from "../../../../../contexts/mainContext";
import AddPlaceButton from "../../../grid/cardComponents/addPlaceButton";
import reportsContext from "../../../../../contexts/reportsContext";
import {fetchUserLevel} from "../../../../../../functions/fetchUserLevel";



const VehicleIdlingReportTableRow = ({data, index}) => {

    const {darkMode} = useContext(indexContext);

    const {vehicles, handleDate, handleJourney, handlePageToShow, vehicle} = useContext(mainContext);

    const {theVehicle} = useContext(reportsContext);

    const [background, setBackground] = useState(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')


    useEffect(() => {
        setBackground(index === 0 || !!(index && !(index%2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')
    }, [darkMode])


    const viewJourney = (data) => {
        handleDate(new Date(data?.startTime));
        setTimeout(() => {
            handleJourney(data?._id);
            handlePageToShow('/map');

        }, 500);
    }

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
            textAlign: 'center'
        }} className="reportTable"
             onMouseEnter={() => setBackground(darkMode ? 'rgba(115,24,236, 0.3)' : 'rgba(115,24,236, 0.1)')}
             onMouseLeave={() => setBackground(index === 0 || !!(index && !(index % 2)) ? (darkMode ? 'var(--surface-card)' : '#e2e2e2') : '')}
             key={index}

        >
            <div style={{lineHeight: '73px', paddingLeft: '10px'}} onClick={() => viewJourney(data)}>
                {(index + 1)}
            </div>

            <div style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="start"/>
                <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={data?.finish?.lat} lng={data?.finish?.lng}/>
            </div>


            <div style={{height: '73px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                <FormatAddress data={data} type="finish"/>
                <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={data?.finish?.lat} lng={data?.finish?.lng}/>
            </div>

            <div style={{lineHeight: '73px'}}>
                {data?.distance.toFixed(0)}
                &nbsp;miles
            </div>
            <div style={{lineHeight: '73px'}}>
                {data?.duration}
            </div>
            <div style={{lineHeight: '73px'}}>


                {fetchUserLevel() === 'superuser' ?
                    <span>( i-{data.idling} v ti-{data?.trueIdling})</span>
                :
                   <React.Fragment>
                       {data?.idling > 0 ? <React.Fragment>{fullDurationFromSeconds(data?.idling)}</React.Fragment> :
                           <React.Fragment>N/A</React.Fragment>}
                   </React.Fragment>
                }

            </div>

        </div>
    )
}
export default VehicleIdlingReportTableRow
