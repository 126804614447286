import {API_URL, LIVESTREAM_URL} from "../Constants";


const startStreamStatus = (dn, ch) => {
    const requestOptions = {
        method: 'GET',
        // headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token').access_token  },
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token')  },
    };

    return fetch(`${LIVESTREAM_URL}/stream-start-status/${dn}_${ch}_0`, requestOptions)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            data.ok = true;
            return Promise.resolve(data);
        }).catch(err => {
            return {ok: false}
        })

}


export default startStreamStatus;
