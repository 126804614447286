import React, {useEffect, useState} from 'react';
import {safeParse} from "../../../../../functions/formatting/safeParse";
import fetchOverpass from "../../../../../api/fetchOverpass";
import {Tooltip} from "primereact/tooltip";


const EventSpeedLimitIcon = ({speedLimit, size = 30, event}) => {


    return (
        <React.Fragment>
            <div style={{
                position: 'relative', width: `${size}px`,
                height: `${size}px`,
                verticalAlign: 'middle',
                borderRadius: '50%',
                border: `${size === 30 ? 3 : 2}px solid red`,
            }} className={`tooltipTargetEventClass${event?._id}`}>

                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}>
                    {speedLimit ? speedLimit : '?'}
                </div>
            </div>

            <Tooltip target={".tooltipTargetEventClass" + event?._id}>Speeding</Tooltip>
        </React.Fragment>

    )


}

export default EventSpeedLimitIcon
