import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";

const fetchVideoDetails = (filename) => {
const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'Get',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token  },
    };

    return fetch(API_URL + '/f-video-details/' + filename, requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json()
            } else {
                return {}
            }
        })
        .then((data) => {
            return Promise.resolve(data);
        });
}


export default fetchVideoDetails;
