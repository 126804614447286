import React, {useContext, useState} from 'react';
import {
    DurationFromSeconds,
    formatDateTimesheetReport, formatTimeOnly,
} from "../../../../../functions/formatting/formatDate";
import FormatAddress from "../../../../../functions/formatting/formatAddress";
import AddPlaceButton from "../../grid/cardComponents/addPlaceButton";
import mainContext from "../../../../contexts/mainContext";
import reportsContext from "../../../../contexts/reportsContext";


const TimesheetReportTableRow = ({item, index}) => {

    const {theVehicle} = useContext(reportsContext);
    const [background, setBackground] = useState(index === 0 || !!(index && !(index%2)) ? 'rgba(0,0,0,0.11)' : 'var(--surface-card)')

    return (
        <React.Fragment>
            {item?.journeys?.length > 0 ?

                <div style={{
                    display: 'grid', gridTemplateColumns: 'repeat(9, minmax(0, 1fr))',
                    borderBottom: '1px solid var(--surface-border)', height: '73px',
                    pageBreakInside: 'avoid', pageBreakAfter: 'auto', background: background, textAlign: 'center'
                }} className="reportTable">
                    <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                        {formatDateTimesheetReport(item.date)}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {formatTimeOnly(item?.firstJourney?.start?.at ? item?.journeys?.[0]?.start?.at :
                            item?.journeys?.[0]?.startTime)}
                    </div>

                    <div style={{height: '73px', display: 'flex', alignItems: 'center', maxWidth: '100px',textAlign:'left'}}>
                        <FormatAddress data={item?.firstJourney} type="start"/>
                        <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={item?.firstJourney?.start?.lat} lng={item?.firstJourney?.start?.lng} />
                    </div>


                    <div style={{lineHeight: '73px'}}>
                        {formatTimeOnly(item?.lastJourney?.finished?.at ? item?.lastJourney?.finished?.at : item?.lastJourney?.finish?.at)}
                    </div>


                    <div style={{height: '73px', display: 'flex', alignItems: 'center', maxWidth: '100px',textAlign:'left'}}>
                        <FormatAddress data={item?.lastJourney} type="finish"/>
                        <AddPlaceButton fleetId={theVehicle?.properties?.fleetId} lat={item?.lastJourney?.finish?.lat} lng={item?.lastJourney?.finish?.lng} />
                    </div>



                    <div style={{lineHeight: '73px'}}>
                        {item?.journeys?.length}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {item?.totalDistance.toFixed(0)}
                        &nbsp;miles
                    </div>


                    <div style={{paddingTop: '21px'}}>
                        {DurationFromSeconds(item?.totalDur)}
                    </div>

                    <div style={{paddingTop: '21px'}}>
                        {DurationFromSeconds(item?.shiftDur)}
                    </div>
                </div>



                :


                <div style={{

                    display: 'grid', gridTemplateColumns: '15% 85%',
                    borderBottom: '1px solid var(--surface-border)', height: '73px'
                }}>
                    <div style={{lineHeight: '73px', paddingLeft: '20px'}} className="reportTable">
                        {formatDateTimesheetReport(item.date)}
                    </div>
                    <div style={{lineHeight: '73px', textAlign: 'center'}}  className="reportTable">
                        No Journeys To Show
                    </div>
                </div>
            }

        </React.Fragment>





    )
}

export default TimesheetReportTableRow
