import React, {useContext} from 'react';
import {DurationFromSeconds} from "../../../../../../functions/formatting/formatDate";
import indexContext from "../../../../../contexts/indexContext";
import FleetIdlingReportTableHeader from "./fleetIdleReportTableHeader";
import FleetIdlingReportTableRow from "./fleetIdleReportTableRow";
import FleetIdlingReportTableFooter from "./fleetIdleReportTableFooter";

const FleetIdlingReportTable = ({data}) => {

    const {darkMode} = useContext(indexContext)

    const parkedRow = {
        textAlign: 'center',
        lineHeight: '45px',
        borderBottom: '1px solid var(--surface-border)',
        background: (darkMode ? 'var(--surface-card)' : '#e2e2e2')
    }

    return (
        <React.Fragment>
            <FleetIdlingReportTableHeader date={data.date} data={data}/>

            {data?.vData?.length >= 1 &&
                <React.Fragment key={data?.vData?.[0]?.dn}>
                    {data?.vData?.map((item, index) =>
                        <FleetIdlingReportTableRow data={item} index={index} />
                    )}
                    <FleetIdlingReportTableFooter data={data} />

                </React.Fragment>
            }



        </React.Fragment>

    )
}
export default FleetIdlingReportTable
