import React, {useContext, useEffect, useState} from 'react';
import {formatDateModalHeader} from "../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuildings} from "@fortawesome/pro-regular-svg-icons";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import {fleetFromDn} from "../../../../functions/formatting/fleetFromDn";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import ChunkedVideoPlayer from "../../viewMedia/chunkedVideoPlayer";
import fetchHyperlapseDetail from "../../../../api/media/fetchHyperlapseDetail";
import {camPosFromCh} from "../../../../functions/formatting/camPosFromCh";

const VideoPlayerModal = ({}) => {

    const {deviceDetails, videoToShow, vehicles} = useContext(mainContext);
    const {fleets} = useContext(indexContext);

    const [hyperlapseDetail, setHyperlapseDetail] = useState()


    useEffect(async () => {
        if (videoToShow){
            const id = videoToShow.file.split('_')
            const hl = await fetchHyperlapseDetail(id?.[1]);
            setHyperlapseDetail(hl)
        }
    }, [videoToShow]);


    return (
        <React.Fragment>
            <div style={{
                marginTop: '-50px',
                position: 'absolute',
                fontWeight: 'bold',
                fontSize: '16px',
                width: '250px'
            }}>

                Hyperlapse&nbsp;
                {deviceDetails?.registration}
                <div style={{fontWeight: 'normal', marginBottom: '20px'}}>
                    {formatDateModalHeader(hyperlapseDetail?.startTimeHyper)}
                    <br/>
                    <span style={{fontSize: '1rem'}}>
                            <FontAwesomeIcon icon={faBuildings} style={{marginRight: '7px'}}/>
                        {friendlyFleetName(deviceDetails?.fleetId, fleets)}
                        </span>
                </div>


            </div>

            <div style={{marginTop: '30px', textAlign: 'center'}}>
                This hyperlapse for the {camPosFromCh(deviceDetails, hyperlapseDetail?.channel)?.toLowerCase()} camera spans a duration of {hyperlapseDetail?.durationMinutes} minutes, where the time between
                frames is {hyperlapseDetail?.intervalSeconds} seconds.
            </div>

            <div style={{marginTop: '30px'}}>
                {/*<VideoPlayerDesktop video={videoToShow} />*/}
                <ChunkedVideoPlayer video={videoToShow} hyperlapseDetail={hyperlapseDetail}/>
            </div>


        </React.Fragment>
    )
}

export default VideoPlayerModal