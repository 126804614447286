import React, {useEffect, useState} from 'react';
import {Divider} from "primereact/divider";
import {Password} from "primereact/password";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faKey } from '@fortawesome/pro-regular-svg-icons'
import {Message} from "primereact/message";
import updatePassword from "../../api/updatePassword";
import PasswordStrengthBar from 'react-password-strength-bar';



const ChangePasswordModal = () => {

    const [newPass, setNewPass] = useState();
    const [pass, setPass] = useState();
    const [newPassConfirm, setNewPassConfirm] = useState();
    const [message, setMessage] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const suggestionCheck = {
        color: 'green',
        textDecoration: 'line-through'
    }

    const suggestionCross = {
        color: 'var(--text-color)'
    }




    const handleUpdatePassword = () => {
        updatePassword({
            "email": localStorage.getItem('email'),
            "password": pass,
            "newPwd": newPass
        }).then(r => {
            setMessage({
                severity: r.message === 'Password changed' ? 'success' : 'warn',
                text: r.message
            });
            setTimeout(() => {
                setMessage(null)
            }, 3000)
        })
    }

    useEffect(() => {
        let disabled = true
        if (newPass?.length >= 8 && newPassConfirm?.length >= 8){
            if (newPass === newPassConfirm) disabled = false
        }
        console.log(disabled)
        setSubmitDisabled(disabled)
    }, [newPass, newPassConfirm]);


    return (
        <div>

            To create a new password please enter your current password followed by your new password. Please note the new
            password must be 8 or more characters long.<br /><br/>
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-12 lg:col-6">
                    <form className="w-full">
                          <div style={{fontWeight:'bold'}}>
                              Current Password
                          </div>
                          <input name="pass" type="password" value={pass} onChange={(e) => setPass(e.target.value)}
                                 className="p-inputtext w-full" autoComplete="off"/>

                          <div style={{fontWeight:'bold', marginTop: '15px'}}>
                              New Password
                          </div>
                          <input name="newPass" type="password" value={newPass} onChange={(e) => setNewPass(e.target.value)}
                                 className="p-inputtext w-full" autoComplete="off"/>

                          <div style={{fontWeight:'bold', marginTop: '15px'}}>
                              Confirm New Password
                          </div>
                          <input name="newPassConfirm"  type="password" value={newPassConfirm} onChange={(e) => setNewPassConfirm(e.target.value)}
                             className="p-inputtext w-full" autoComplete="off"/>

                    </form>
                </div>

                <div className="col-12 sm:col-12 md:col-12 lg:col-6 text-center">

                    <div style={{fontWeight: 'bold', fontSize: '14px', marginBottom: '15px', textAlign:'center'}}>Password strength</div>

                    <div style={{width: '90%'}}>
                        <PasswordStrengthBar password={newPass} minLength={8}/>
                    </div>

                </div>

            </div>
            <div style={{textAlign:'center', marginTop: '5px'}}>
                {!message ?
                    <div>
                        {!submitDisabled ?
                            <button disabled={submitDisabled}

                                    className="p-button"
                                    onClick={handleUpdatePassword}>
                                <FontAwesomeIcon icon={faKey} />
                                &nbsp;Update password
                            </button>
                            :
                            <Message severity="warn" text="Please ensure the passwords match"/>
                        }
                    </div>
                :
                    <Message severity={message.severity} text={message.text} />
                }

            </div>
        </div>
    )
}

export default ChangePasswordModal
