import {API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";

const fetchHyperlapseDetail = (id) => {
const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'Get',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token  },
    };

    return fetch(API_URL + '/hyperlapse-detail/' + id, requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
            return Promise.resolve(data);
        });
}


export default fetchHyperlapseDetail;


