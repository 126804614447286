import {useEffect, useState} from 'react';
import {KEY} from '../Constants.js'
import CryptoJS from "crypto-js";

export default function useToken() {

    const getToken = () => {
        return localStorage.getItem('access_token');
    };

    const [token, setToken] = useState(getToken());




    const saveToken = userToken => {



        localStorage.setItem('access_token', userToken.access_token);
        localStorage.setItem('email', userToken.result.email);
        localStorage.setItem('_id', userToken.result._id);
        localStorage.setItem('authorisation', userToken.result.authorisation);
        localStorage.setItem('mflGroup', userToken.result?.mflGroup);
        // localStorage.setItem('token', JSON.stringify(userToken));
        localStorage.setItem('timeLimit', userToken.result.videoRequestTimeLimit ? userToken.result.videoRequestTimeLimit.toString() : '600')
        localStorage.setItem('blockedEndpoints', userToken.result?.blockedEndpoints);
        localStorage.setItem('adminFleets', userToken?.result?.adminFleets);
        setToken(userToken);
    };
    return {
        setToken: saveToken,
        token
    }
}
