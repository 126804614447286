import React, {useContext, useEffect, useRef, useState} from 'react';
import {Calendar} from "primereact/calendar";
import fetchVideoBudget from "../../../api/device/fetchVideoBudget";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import VideoBudgetMessage from "./videoBudgetMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp, faClock, faCircleInfo, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {Message} from "primereact/message";
import {formatDate, formatDateMediaGrid} from "../../../functions/formatting/formatDate";
import {NOTIFICATIONS_CALLBACK_URL, TOAST_DURATION, videoUploadOrder} from "../../../Constants";
import postVideoRequest from "../../../api/media/postVideoRequest";
import {Slider} from "primereact/slider";
import VideoUploadProgressTracker from "./videoUploadProgressTracker";
import mainContext from "../../contexts/mainContext";
import {videoDurationOptions} from "./requestingMediaFunctions/videoDurationOptions";
import fetchEventsVideoFile from "../../../api/events/fetchEventsVideoFile";
import {Checkbox} from "primereact/checkbox";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import {camPosFromCh} from "../../../functions/formatting/camPosFromCh";
import VideoUploadProgressTrackerV2 from "./uploadProgressTrackerV2";


const RequestVideoFromRecording = ({recSelected, deviceDetails, vehicle}) => {

    const {vehicles, handleRefreshNotifications} = useContext(mainContext)

    const [time, setTime] = useState(new Date(recSelected.st).getTime());
    const [mediaDuration, setMediaDuration] = useState({
        duration: 20,
        label: 'Video duration: 20 Seconds'
    });

    const [emailChecked, setEmailChecked] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const toast = useRef(null)








    const [camsToReq, setCamsToReq] = useState(new Set([]));
    const [requestedMessage, setRequestedMessage] = useState();
    const [budget, setBudget] = useState();




    const handleMediaDurationChange = (e) => {
        setMediaDuration(e)
    }

    const addRemoveCameraToReq = (v) => {

        if(setHasAllCams()){
            setCamsToReq(new Set([v]))
        } else {
            if(camsToReq.has(v)){
                camsToReq.delete(v)
                setCamsToReq(new Set([...camsToReq]));
            } else {
                setCamsToReq(new Set([...camsToReq, v]));
            }
        }
    }


    const setHasAllCams = () => {
        return JSON.stringify(camsToReq) === JSON.stringify(new Set(deviceDetails?.cameras)) && camsToReq.size === deviceDetails?.cameras?.length
    }

    const addAllCamsToReq = () => {

        if (setHasAllCams()){
            setCamsToReq(new Set([]))

        } else {
            setCamsToReq(new Set(deviceDetails?.cameras?.filter(cam => cam?.isRec === '1')))

        }

    }

    useEffect(() => {
        fetchVideoBudget({dn: deviceDetails?.dn}).then(r => {
            setBudget(r)
        });

        const front = deviceDetails?.cameras.filter(cam => cam.camPosition === 'Front');
        setCamsToReq(new Set(front))
    }, [deviceDetails])



    const requestMedia = async () => {
        ////


        //
        // if (camsToReq.size > 0){
        //     let cams = [...camsToReq];
        //     const order = videoUploadOrder;
        //
        //     cams.sort((a, b) => order.indexOf(a.camPosition) - order.indexOf(b.camPosition));
        //
        //
        //     const videoToRequest = {
        //         st: formatDate(new Date(new Date(st).getTime() + (startTime * 1000))),
        //         et: formatDate(new Date(new Date(st).getTime() + (startTime * 1000) + (mediaDuration.duration*1000))),
        //         dn: deviceDetails.dn,
        //         ch: cams[0]?.channel,
        //         callback: NOTIFICATIONS_CALLBACK_URL,
        //     };
        //
        //     const r = await postVideoRequest(videoToRequest, deviceDetails, emailChecked);
        //     requests.push(r.data);
        //
        //     if (r.status === 'ok'){
        //         toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[0].camPosition + ' video requested', life: 2000 },)
        //     } else {
        //         toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[0].camPosition + ' error ' + r.error, life: 5000 },)
        //     }
        //
        //
        //     setRequestedMessage({type: cams?.length === 1 ? 'Video Requested' : 'Videos Requested' , data: []});
        //     await new Promise(r => setTimeout(r, 2000));
        //
        //     if (cams?.length > 1){
        //         const event = await fetchEventsVideoFile({
        //             dn: deviceDetails.dn,
        //             file: r?.data?.filename
        //         });
        //
        //         for (let i = 1; i < cams?.length; i++) {
        //             const subReqs = {
        //                 st: formatDate(new Date(new Date(st).getTime() + (startTime * 1000))),
        //                 et: formatDate(new Date(new Date(st).getTime() + (startTime * 1000) + (mediaDuration.duration*1000))),
        //                 dn: deviceDetails.dn,
        //                 ch: cams[i].channel,
        //                 callback: NOTIFICATIONS_CALLBACK_URL,
        //                 eventId: event[0]._id
        //             };
        //             const subReqRes = await  postVideoRequest(subReqs, deviceDetails);
        //
        //             if (r.status === 'ok'){
        //                 toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[i].camPosition + ' video requested', life: 2000 },)
        //             } else {
        //                 toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[i].camPosition + ' error ' + r.error, life: 5000 },)
        //             }
        //
        //             requests.push(subReqRes.data);
        //         }
        //     }
        //
        //     setRequestedMessage({type: 'video', data: requests});
        //
        //     toast.current.show(toastMessages);
        //     setSendingRequest(false);
        // }



        /////



        setSendingRequest(true);
        let requests = [];
        let toastMessages =  [];
        const order = videoUploadOrder


        if (camsToReq.size > 0){
            let cams = [...camsToReq];


            cams.sort((a, b) => order.indexOf(a.camPosition) - order.indexOf(b.camPosition));


            const videoToRequest = {
                st: formatDate(new Date(time).getTime()),
                et: formatDate(new Date(time).getTime() + (mediaDuration.duration*1000)),
                dn: deviceDetails.dn,
                ch: cams[0]?.channel,
                callback: NOTIFICATIONS_CALLBACK_URL,
            };

            const r = await postVideoRequest(videoToRequest, deviceDetails, emailChecked);
            requests.push(r.data);

            if (r.status === 'ok'){
                toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[0].camPosition + ' video requested', life: TOAST_DURATION },)
            } else {
                toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[0].camPosition + ' error ' + r.error, life: TOAST_DURATION },)
            }

            await new Promise(r => setTimeout(r, 2000));

            if (cams?.length > 1){
                const event = await fetchEventsVideoFile({
                    dn: deviceDetails.dn,
                    file: r.filename
                });

                for (let i = 1; i < cams?.length; i++) {
                    const subReqs = {
                        st: formatDate(new Date(time).getTime()),
                        et: formatDate(new Date(time).getTime() + (mediaDuration.duration*1000)),
                        dn: deviceDetails.dn,
                        ch: cams[i].channel,
                        callback: NOTIFICATIONS_CALLBACK_URL,
                        eventId: event[0]._id
                    };
                    const subReqRes = await  postVideoRequest(subReqs, deviceDetails);
                    requests.push(subReqRes.data);

                    if (subReqRes.status === 'ok'){
                        toastMessages.push( { severity: 'success', summary: 'All done', detail: cams[i].camPosition + ' video requested', life: TOAST_DURATION },)
                    } else {
                        toastMessages.push( { severity: 'error', summary: 'Error', detail: cams[i].camPosition + ' error ' + r.error, life: TOAST_DURATION },)
                    }
                }
            }
            setRequestedMessage({type: 'video', data: requests});

            toast.current.show(toastMessages);
            setSendingRequest(false);
        }
    };



    const resetForm = () => {
        setRequestedMessage(null);
        setCamsToReq(new Set([]));
        setMediaDuration(null);

    }

    return (
        <div style={{textAlign: 'center'}}>
            {!requestedMessage &&
                <React.Fragment>

                    {deviceDetails?.cameras.length > 1 &&
                        <Button onClick={() => addAllCamsToReq()} style={{marginRight: '5px', marginBottom: '5px'}}
                                className={setHasAllCams() ?
                                    'p-button' : 'p-button p-button-secondary'}
                                tooltip={'All cameras'} tooltipOptions={{position: 'bottom'}}>
                            <span className="p-button-label">All</span>

                        </Button>
                    }

                    {deviceDetails?.cameras.map(function (cam, index){
                        return (
                            <React.Fragment>
                                {cam?.isRec === '1' &&
                                    <div key={index} style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
                                        <Button onClick={() => addRemoveCameraToReq(cam)} style={{marginTop: '5px'}}
                                                className={camsToReq.has(cam) ? 'p-button' : 'p-button p-button-secondary'}
                                                tooltip={'Channel: ' + cam.channel} tooltipOptions={{position: 'bottom'}}>
                                            {cam.camPosition}
                                        </Button>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })}

                    <br /><br />

                    <Dropdown value={mediaDuration} options={videoDurationOptions(deviceDetails)} onChange={(e) => handleMediaDurationChange(e.value)}
                              placeholder="Select duration" optionLabel="label" style={{width: '80%'}}/>

                    <br />
                    <br />

                    {formatDateMediaGrid(time)}

                    <br />
                    <br />

                    <Slider value={time} onChange={(e) => setTime(e.value)} min={new Date(recSelected.st).getTime()}
                            max={new Date(recSelected.et).getTime() - 30000} step={20000}/>

                    <br />


                    <div>
                        <span style={{marginRight: '10px'}}>Notify me by email when complete</span>
                        <Checkbox onChange={e => setEmailChecked(e.checked)} checked={emailChecked}></Checkbox>
                    </div>



                    <div style={{marginTop: '10px', marginBottom: '10px'}}>
                        <VideoBudgetMessage budget={budget} camsToReq={camsToReq} duration={mediaDuration?.duration} />
                    </div>

                    <button onClick={requestMedia} className="p-button" disabled={!mediaDuration || camsToReq.size < 1 || sendingRequest ||
                        ((budget?.totalFleetBudget - budget?.usedSeconds) < (mediaDuration?.duration)*camsToReq.size)} style={{width: '80%'}}>

                        {sendingRequest ?
                            <span className="p-button-label">
                            <FontAwesomeIcon icon={faSpinner} spin/>
                                &nbsp;Sending request
                          </span>
                            :
                            <span className="p-button-label">
                            <FontAwesomeIcon icon={faCloudArrowUp}/>
                                &nbsp;Upload video
                          </span>
                        }
                    </button>


                    <div style={{textAlign: 'center', marginTop: '20px'}} className="videoUploadFromRecHelp" data-pr-position="top">
                        <FontAwesomeIcon icon={faCircleInfo} />
                        &nbsp;Help
                    </div>

                    <Tooltip target=".videoUploadFromRecHelp">
                        <div style={{width: '250px'}}>
                            To create a new event and upload video, select the camera view(s) and duration required and click upload.<br/><br/>
                            The start time of the video can be finely adjusted using the slider.

                            <br/><br/>You can monitor the progress of your upload here or check your notification Inbox at any time.
                        </div>
                    </Tooltip>


                </React.Fragment>
            }


            {requestedMessage?.type === 'video' &&
                <React.Fragment>
                    {requestedMessage.data.map(req => (
                        <div style={{marginTop: '20%'}}>
                            {camPosFromCh(deviceDetails, req?.ch)}
                            <VideoUploadProgressTrackerV2 upload={req} vehicles={vehicles} page="trackModal" handleRefreshNotifications={handleRefreshNotifications}/>
                        </div>
                    ))}
                </React.Fragment>
            }


            <Toast ref={toast} appendTo={null} position="bottom-right"/>


        </div>
    )
}
export default RequestVideoFromRecording
