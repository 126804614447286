import {purple, teal, pink} from '../CustomIcons'

export const getMapIcons = () => {

    let custIcons = purple;
    if (localStorage.getItem('darkMode').includes('Teal')){
        custIcons = teal;
    } else if (localStorage.getItem('darkMode').includes('Pink')){
        custIcons = pink
    }

    return custIcons

}