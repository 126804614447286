import React from 'react';
import {DurationFromSeconds} from "../../../../../functions/formatting/formatDate";

const SpeedingOverviewReportSummary = ({actTotals}) => {

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>

            <div className="reportTotalBubble">
                <div><b>Total<br/>Journeys Completed:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.journeys}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total<br/>Distance Travelled:</b></div>
                <div style={{paddingBottom: '15px'}}>{actTotals?.distance.toFixed(0)} miles</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Total<br/>Driving Duration:</b></div>
                <div
                    style={{paddingBottom: '15px'}}>{DurationFromSeconds(actTotals?.duration)}
                </div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Total Speeding <br/>Duration:</b></div>
                <div style={{paddingBottom: '15px'}}>{ actTotals?.speedDur > 0 ? DurationFromSeconds(actTotals?.speedDur) : '0s'}</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Speeding <br/>Percentage:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {((actTotals?.speedDur / actTotals?.duration) * 100).toFixed(2)}%
                </div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Speeding <br/>Events:</b></div>
                <div style={{paddingBottom: '15px'}}>
                    {actTotals?.speedEvents}
                </div>
            </div>

        </div>
    )
}
export default SpeedingOverviewReportSummary