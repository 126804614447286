import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {uCaseFirst} from "../../../functions/formatting/uCaseFirst";



const AccelerationStacked = ({event, height, showTool = true}) => {

    return (
        <div>

            <svg id="Layer_1" data-name="collision" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.89 349.89" className={"tooltipTargetEventClass" + event?._id}  style={{height, width: height, verticalAlign: 'middle'}}>
                <circle cx="174.95" cy="174.95" r="174.95" fill={'var(--surface-border)'}/>
                <path d="m151.46,253.9c11.2,0,21.82-2.32,31.46-6.51,2.82,5.6,6.36,10.74,10.55,15.27-12.68,6.09-26.96,9.52-42,9.52-53.85,0-97.49-43.64-97.49-97.49s43.64-97.49,97.49-97.49c45.47,0,83.63,31.11,94.44,73.19-.99-.04-2.02-.08-3.05-.08-5.33,0-10.47.61-15.46,1.79-9.71-32.75-40.02-56.63-75.93-56.63-43.76,0-79.21,35.45-79.21,79.21s35.45,79.21,79.21,79.21Zm-20.51-42.84c-.16-8.53,4.8-15.94,12-19.37v-67.68c0-4.83,3.66-9.07,8.49-9.33,5.22-.28,9.51,3.83,9.51,8.99v68.02c7.09,3.38,12,10.61,12,18.98,0,11.97-10.04,21.61-22.14,20.97-10.88-.58-19.65-9.68-19.86-20.57Zm-28.24-72.93c0-6.73,5.46-12.19,12.19-12.19s12.19,5.46,12.19,12.19-5.46,12.19-12.19,12.19-12.19-5.46-12.19-12.19Zm-6.09,24.37c6.73,0,12.19,5.46,12.19,12.19s-5.46,12.19-12.19,12.19-12.19-5.46-12.19-12.19,5.46-12.19,12.19-12.19Zm79.21-24.37c0-6.73,5.46-12.19,12.19-12.19s12.19,5.46,12.19,12.19-5.46,12.19-12.19,12.19-12.19-5.46-12.19-12.19Zm67.02,24.37c30.29,0,54.84,24.55,54.84,54.84s-24.55,54.84-54.84,54.84-54.84-24.55-54.84-54.84,24.55-54.84,54.84-54.84Zm6.09,30.44c0-3.55-3.13-6.41-6.77-5.95-3.05.38-5.23,3.15-5.23,6.22v17.73s-17.73,0-17.73,0c-3.07,0-5.84,2.19-6.22,5.23-.46,3.64,2.4,6.77,5.95,6.77h18v17.73c0,3.07,2.19,5.84,5.23,6.22,3.64.46,6.77-2.4,6.77-5.95v-18h17.73c3.07,0,5.84-2.19,6.22-5.23.46-3.64-2.4-6.77-5.95-6.77h-18v-18Z" style={{fill:'var(--text-color)'}}/>
            </svg>


            {showTool &&
                <Tooltip target={".tooltipTargetEventClass" + event?._id}>{uCaseFirst(event?.severity)} - Acceleration</Tooltip>
            }
        </div>
    )
}
export default AccelerationStacked
